import {
  useModalDialog,
  DataServiceContext,
  Button,
  Form,
  Field,
  Row,
  FieldTypes,
  StyledMenuItemDiv,
  useToasterContext,
} from "athena-next-ui-lib";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useContext, useRef } from "react";
import { ReportContext } from "../ReportContextProvider";
import {
  FieldAlertVolumeSelector,
  FieldOutboundIntegrationSelector,
  FieldPrioritySelector,
} from "../fields";

export const DialogAlertAccept = (props) => {
  const {
    openConfirmationDialog,
    closeModalDialog,
    openModalDialog,
  } = useModalDialog();

  const dataService = useContext(DataServiceContext);
  const alertContext = useContext(ReportContext);
  const { itypeData, loadIncidentTypeData, enterEditKeysMode } = alertContext;

  const { addToast } = useToasterContext();
  const processing = false;

  const IncidentTypeForm = useRef();

  let extensionFieldRefs = {};
  extensionFieldRefs[`itype_ext_f`] = useRef();
  extensionFieldRefs[`itype_ext_f1`] = useRef();
  extensionFieldRefs[`itype_ext_f2`] = useRef();
  extensionFieldRefs[`itype_ext_f3`] = useRef();
  extensionFieldRefs[`itype_ext_f4`] = useRef();
  extensionFieldRefs[`itype_ext_f5`] = useRef();
  extensionFieldRefs[`itype_ext_f6`] = useRef();
  extensionFieldRefs[`itype_ext_f7`] = useRef();
  extensionFieldRefs[`itype_ext_f8`] = useRef();
  extensionFieldRefs[`itype_ext_f9`] = useRef();
  extensionFieldRefs[`itype_ext_f10`] = useRef();

  const outboundIntegrationRef = useRef();

  const itype_volume = useRef();
  const itype_title = useRef();
  const itype_outbound_integration_ids = useRef();
  const itype_outbound_priority = useRef();
  const itype_tag_ids = useRef();

  let fieldRefs = [
    itype_title,
    itype_outbound_priority,
    itype_outbound_integration_ids,
    itype_tag_ids,
    itype_volume,
  ];

  fieldRefs = fieldRefs.concat(extensionFieldRefs); //combining out-of-box with custom defined fields
  Object.keys(extensionFieldRefs).forEach((name) =>
    fieldRefs.push(extensionFieldRefs[name])
  );

  const update = () => {
    return Promise.resolve()
      .then(() => loadIncidentTypeData())
      .then(() => closeModalDialog())
      .then(() => dialogChangeAlertKeys())
      .then(() =>
        addToast({
          type: "info",
          title: `Accepted Suggested`,
          desc: `An alert rule has been created.`,
        })
      );
  };

  const processChangeKeys = () => {
    return Promise.resolve()
      .then(() => enterEditKeysMode())
      .then(() => closeModalDialog());
  };

  const dialogChangeAlertKeys = () => {
    openModalDialog({
      title: "Advanced Alert Rule Settings",
      submitLabel: "Continue to Advanced Settings",
      cancelLabel: "Keep Existing Settings",
      hideCloseIcon: true,
      content: (
        <>
          You can:
          <ul>
            <li>Modify alert rule keys.</li>
            <li>Add additional information e.g. owner, description.</li>
          </ul>
        </>
      ),
      submit: () => processChangeKeys(),
    });
  };

  const processAccept = () => {
    const itype_props = Object.keys(itypeData).filter(
      (p) => p.indexOf("itype") === 0
    );
    let values = {};
    itype_props.forEach((p) => (values[p] = itypeData[p]));
    values.itype_volume = "day";
    values.itype_state = "accepted";

    return Promise.resolve()
      .then(() => IncidentTypeForm?.current?.getValues())
      .then((formValues) => {
        if (formValues?.errors.length) {
          const errors = formValues.errors.map((err) => {
            return <div key={err.replace(/\s+/gi, "-")}>{err}</div>;
          });
          const formError = { message: errors };
          throw formError;
        } else {
          //TODO: multi select dropdown getValue returns string
          // comma separated values
          if ("itype_outbound_integration_ids" in formValues) {
            formValues.itype_outbound_integration_ids = outboundIntegrationRef.current.deriveSelectedValues(
              formValues.itype_outbound_integration_ids
            );
          }
          if (formValues.itype_outbound_priority === "default") {
            formValues.itype_outbound_priority = "";
          }
          values = { ...values, ...formValues };
        }
      })
      .then(() => dataService.fetch("incidenttype/create", values))
      .then((response) => {
        if (response.response.code === 200) {
          return update();
        } else {
          throw response.response;
        }
      });
  };

  const confirmAccept = () => {
    const fieldProps = { labelWidth: "200px" };

    openConfirmationDialog({
      title: `Accept this Suggestion and create an Alert Rule?`,
      icon: faExclamationTriangle,
      submit: () => processAccept(),
      submitLabel: "Continue",
      closeOnSubmit: false,
      minWidth: "520px",
      content: (
        <div>
          <p>
            This action will create an alert rule based on the ML selected event
            keys.
          </p>

          <Form ref={IncidentTypeForm} refs={fieldRefs}>
            <Row>
              <Field
                ref={itype_title}
                {...fieldProps}
                fieldWidth="200px"
                label={"Alert Rule Name"}
                required={true}
                value={itypeData?.itype_title}
                name={"itype_title"}
                type={FieldTypes.TEXT}
                disabled={processing}
              />
            </Row>
            <Row align="center">
              <FieldOutboundIntegrationSelector
                {...fieldProps}
                required={true}
                ref={outboundIntegrationRef}
                label={"Send Notifications To"}
                fieldRef={itype_outbound_integration_ids}
                selectedOutboundChannels={
                  itypeData?.itype_outbound_integration_ids
                }
              ></FieldOutboundIntegrationSelector>
            </Row>
            <Row>
              <FieldPrioritySelector
                {...fieldProps}
                label="Notification Priority"
                value={"placeholder"}
                fieldRef={itype_outbound_priority}
                selectedPriorityString={itypeData?.itype_outbound_priority}
                disabled={processing}
              />
            </Row>
            <Row>
              <FieldAlertVolumeSelector
                {...fieldProps}
                fieldRef={itype_volume}
                label={"Alert Volume"}
                selectedAlertVolume={itypeData?.itype_volume}
              />
            </Row>
          </Form>
        </div>
      ),
    });
  };

  if (props.renderType === "menu-item") {
    return (
      <StyledMenuItemDiv onClick={() => confirmAccept()}>
        {props.label}
      </StyledMenuItemDiv>
    );
  }

  return (
    <Button
      type="secondary"
      onClick={() => confirmAccept()}
      cssStyle={{ width: "200px", background: "#789900" }}
    >
      {props.label}
    </Button>
  );
};

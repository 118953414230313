import React from "react";
import { CustomIcon } from "athena-next-ui-lib";

export const ListModes = {
  read: "read",
  edit: "edit",
  peek: "peek",
};

const iconOptions = { rotate: 30, wrapInSVG: true };
export const ZoomLevels = [
  {
    label: "keys",
    hidden: true,
    value: 0,
    title: "Alert Keys",
    help: "These are the events that we use as keys to find matches in future.",
  },
  {
    label: "Level 1: Keys & Hallmarks",
    value: 1,
    title: "Level 1: Alert Keys & Hallmarks",
    help: (
      <>
        <p>
          <CustomIcon name={"key"} options={iconOptions} />
          &nbsp;Alert keys are the events that we use to find matches in future.
        </p>

        <p>
          <CustomIcon name={"hand"} options={iconOptions} />
          &nbsp;Hallmarks are the events of interest that you may wish to review
          closely.
        </p>
      </>
    ),
  },
  {
    label: "Level 2: Core Events",
    value: 2,
    title: "Level 2: Core Events",
    help: (
      <>
        <p>
          <CustomIcon name={"key"} options={iconOptions} />
          &nbsp;Alert keys are the events that we use to find matches in future.
        </p>

        <p>
          <CustomIcon name={"hand"} options={iconOptions} />
          &nbsp;Hallmarks are the events of interest that you may wish to review
          closely.
        </p>

        <p>Core also includes additional anomalous events for your review.</p>
      </>
    ),
  },
  {
    label: "level 3",
    value: 3,
    title: "Level 3",
    help: "Shows core events as well as up to 200 related events",
  },
  {
    label: "level 4",
    value: 4,
    title: "Level 4",
    help: "Shows core events as well as up to 400 related events",
  },
  {
    label: "level 5",
    value: 5,
    title: "Level 5",
    help: "Shows core events as well as up to 600 related events",
  },
  {
    label: "Peek Mode",
    hidden: true,
    value: 6,
    title: "Peek Mode",
    help:
      "Shows up to the nearest 2,000 events found on a particular logtype and generator",
  },
];

export const DisplayModeNames = {
  timeline: "timeline",
  wordCloud: "word-cloud",
  list: "list",
};

export const DisplayModes = [
  { label: "List", value: DisplayModeNames.list },
  { label: "Timeline", value: DisplayModeNames.timeline },
  { label: "Word Cloud", value: DisplayModeNames.wordCloud },
];

export const IncidentQuality = {
  response: { code: 200, timestamp: 111 },
  values: [
    { label: "Poor", value: 1 },
    { label: "Fair", value: 2 },
    { label: "Good", value: 3 },
    { label: "Very Good", value: 4 },
    { label: "Excellent", value: 5 },
  ],
};

export const TAG_TYPE_LABELS = {
  evts_inc: "Matches Include Rule",
  user_defined: "Matches Custom Alert Rule",
  routing: "Matches ML Routing Rule",
  manual: "Matches Manual Tag",
};

import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Row,
  Column,
  DataServiceContext,
  PageContext,
  Utilities,
  tooltipStyles,
} from "athena-next-ui-lib";
import Moment from "moment";
import {
  ReportContext,
  displayTagRules,
  Title,
  Banner,
  OccurrencesGraph,
  getReportHeaderHeight,
  DialogRelatedSuggestions,
} from "/components-biz";
import {
  StyledTitleRowDiv,
  StyledScrollableDiv,
  StyledBannerRowDiv,
} from "../css";

const SEARCH_TIME_RANGE_HOUR_OFFSET = 2; //2 hours

export const Content = (props) => {
  const pageContext = useContext(PageContext);
  const reportContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);

  const { itypeData, events } = reportContext;
  const { itype_state } = itypeData;
  const [title, setTitle] = useState("");
  const [tagId, setTagId] = useState(null);
  const [tag, setTag] = useState(null);
  const [tagEventType, setTagEventType] = useState(null);
  const [tagRules, setTagRules] = useState(null);
  const [tagModified, setTagModified] = useState(null);
  const [relatedSuggestionClicked, setRelatedSuggestionClicked] =
    useState(false);

  const relatedSuggestionsRef = useRef();

  useEffect(() => {
    if (itypeData) {
      const { inci_tags } = itypeData;
      const tag = inci_tags?.find((tag) => tag.tag_type === "user_driven");
      setTitle(tag ? tag.tag_name : "");
      setTagId(tag ? tag.tag_id : null);
    }
  }, [itypeData]);

  useEffect(async () => {
    if (tagId) {
      const apiCall = await dataService.fetch("tag/read", {
        filter: [`tag_id=${tagId}`],
        args: { load_rules: true },
      });

      if (apiCall?.response?.code === 200) {
        setTag(apiCall.data[0]);
      }
    }
  }, [tagId]);

  useEffect(() => {
    if (tag?.tag_etroot) {
      setTagEventType("Matching event type: " + tag.tag_etroot);
    }
    if (tag?.tag_rules) {
      setTagRules(displayTagRules(tag.tag_rules));
    }
    if (tag?.modify_user_name) {
      const formattedTS = Utilities.TZ.formatTS(
        tag.ts,
        pageContext.timeZone,
        "MMM DD HH:mm:ss"
      );
      setTagModified(
        <i>
          Last modified by <strong>{tag.modify_user_name}</strong> on{" "}
          <strong>{formattedTS}</strong>
        </i>
      );
    }
  }, [tag, pageContext.timeZone]);

  const showRelatedSuggestionsDialog = () => {
    const theEvent = events?.data?.[0];

    const searchTimeFrom = {
      displayTS: Utilities.TZ.formatTS(
        Moment(theEvent.ievt_ts).subtract(SEARCH_TIME_RANGE_HOUR_OFFSET, "h"),
        dataService.getUserTimeZone(),
        "MMM DD HH:mm"
      ),
      unixTime: Moment(theEvent.ievt_ts)
        .subtract(SEARCH_TIME_RANGE_HOUR_OFFSET, "h")
        .unix(),
    };

    const searchTimeTo = {
      displayTS: Utilities.TZ.formatTS(
        Moment(theEvent.ievt_ts).add(SEARCH_TIME_RANGE_HOUR_OFFSET, "h"),
        dataService.getUserTimeZone(),
        "MMM DD HH:mm"
      ),
      unixTime: Moment(theEvent.ievt_ts)
        .add(SEARCH_TIME_RANGE_HOUR_OFFSET, "h")
        .unix(),
    };

    let dialogData = {
      searchTimeRange: { from: searchTimeFrom, to: searchTimeTo },
    };

    return Promise.resolve()
      .then(() => setRelatedSuggestionClicked(true))
      .then(() =>
        dataService.fetch("incident/read/list", {
          time_from: searchTimeFrom.unixTime,
          time_to: searchTimeTo.unixTime,
          itype_states: ["suggested", "accepted", "rejected"],
          ievt_event: theEvent.ievt_event,
        })
      )
      .then((fetchCall) => {
        dialogData.alertListResponse = { ...fetchCall?.response };
        dialogData.alertListData = fetchCall?.data ? [...fetchCall?.data] : [];
        if (fetchCall.response.code !== 200) {
          throw fetchCall.response;
        }
        return;
      })
      .finally(() => {
        relatedSuggestionsRef?.current?.showDialog(dialogData);
        setRelatedSuggestionClicked(false);
      });
  };

  const tooltip = {
    width: "500px",
    whiteSpace: "normal",
    wordBreak: "break-word",
  };

  return (
    <Row width="*" css={{ margin: "8px 8px" }}>
      <Column width="*" css={{ margin: "0 0" }}>
        <Row align="center">
          <StyledTitleRowDiv>
            <StyledBannerRowDiv>
              <Banner state={itype_state} />
            </StyledBannerRowDiv>
            <Row align="center">
              <Column>
                <Title state="custom" title={title} />
              </Column>
              <Column>
                <div
                  className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouth}`}
                >
                  <Button
                    type={"primary"}
                    size={"sm"}
                    processing={relatedSuggestionClicked}
                    disabled={relatedSuggestionClicked}
                    onClick={showRelatedSuggestionsDialog}
                  >
                    Show Related Alerts & Suggestions
                  </Button>
                  <div className={tooltipStyles.cssTooltipText} style={tooltip}>
                    This can help to explain the root cause of this problem by
                    showing other ML-generated alerts and suggestions that
                    contain the same event.
                  </div>
                </div>
              </Column>
            </Row>
          </StyledTitleRowDiv>
        </Row>
        <p>{tagEventType}</p>
        <Row>
          <DialogRelatedSuggestions ref={relatedSuggestionsRef} />
        </Row>

        <Row>
          <Column>
            <StyledScrollableDiv $height="60px">
              <p>{tagRules}</p>
            </StyledScrollableDiv>
          </Column>
        </Row>
      </Column>

      <Column width="400px" css={{ margin: "0 24px 0 0" }}>
        <Row>
          <OccurrencesGraph
            width={400}
            height={getReportHeaderHeight(itype_state) - 85}
          />
        </Row>
      </Column>
    </Row>
  );
};

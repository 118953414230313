import styled from "styled-components";

export const reportHeaderCSS = {
  custom: {
    title: { color: "#a3d5ff" },
    banner: {
      color: "#a3d5ff", // "#ffffff",
      background: "transparent", //#3b7bb0",
    },
    header: {
      color: "#fff",
      background: "transparent", //"rgba(17, 48, 74,1)",
    },
  },
  suggested: {
    title: { color: "#ffd500" },
    banner: {
      color: "#ffd500", //"#be7119",
      background: "transparent", // "#ffd500",
    },
    header: {
      color: "#fff",
      background: "transparent", //"#887100", // "rgba(45,37,0,1)",
    },
  },
  accepted: {
    title: { color: "#9bc110" },
    banner: {
      color: "#9bc110", // "rgba(255, 255, 255, 0.8)",
      background: "transparent", //"#789900",
    },
    header: {
      color: "#fff",
      background: "transparent", //"#495d00", //"rgba(37,37,0,1)",
    },
  },

  rejected: {
    title: { color: "#ffa7a7" },
    banner: {
      color: "#ffa7a7", //"rgba(255, 255, 255, 0.8)",
      background: "transparent", //"#990000",
    },
    header: {
      color: "#fff",
      background: "transparent", //"rgb(112 1 0)", //"rgba(51,0,0,1)",
    },
  },

  loading: {
    title: { color: "rgba(255,255,255,.2)" },
    banner: {
      color: "#ffffff",
      background: "rgba(255,255,255,.2)",
    },
    header: {
      color: "#fff",
      background: "rgba(255,255,255,.2)",
    },
  },
};

// todo: promote this styled component to UI lib to enable reuse

export const StyledTitleRowDiv = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 8px;

  .lightTheme & {
    color: #000;
  }
`;

export const StyledBannerRowDiv = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  .lightTheme & {
    color: #000;
  }
`;

export const StyledScrollableDiv = styled("div")`
  position: relative;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${(props) => props.$height || "100px"};
  scrollbar-color: rgba(255, 255, 255, 0.25) transparent;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.15);
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: $md - 2px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const StyledHLayout = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

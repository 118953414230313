import React from "react";
import styles from "./WordCloudCss.module.scss";
import { Utilities, LoadingAnim } from "athena-next-ui-lib";
// export const WORD_CLOUD_COLOR_RAMP = [
//   "#f7e6c2",
//   "#ffe3aa",
//   "#ffc655",
//   "#ffaa00",
//   "#ff7100",
//   "#ff3900",
//   "#ff0000",
//   "#d50621",
// ];

export const WORD_CLOUD_COLOR_RAMP = [
  "#ffd335",
  "#ffb807",
  "#ffa500",
  "#ff9400",
  "#ff8300",
  "#ff7200",
  "#e05600",
  "#b73a00",
];

//const colors = [ "#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600"]
const maxFontSize = 2.5;
const minFontSize = 1.2;

export function WordCloud(props) {
  const defaultProps = { "data-testid": "word-cloud-container" };

  if (props.loading)
    return (
      <div {...defaultProps} align={"right"}>
        <LoadingAnim />
      </div>
    );
  if (!props.words)
    return (
      <div
        {...defaultProps}
        className={styles.wordCloud}
        style={{ ...props.style }}
      />
    );
  if (Array.isArray(props.words) === false)
    return (
      <div {...defaultProps} style={{ ...props.style }}>
        Malformed inci_words; expected an array
      </div>
    );
  if (props.words.length === 0)
    return (
      null
      // <div {...defaultProps} style={{ ...props.style }}>
      //   No word cloud defined
      // </div>
    );

  const maxS = 8;
  const wordCloudWidth = 350;
  const charWidth = 10;
  const tags = props.words.map((word, index) => {
    let size = minFontSize + (word.s / maxS) * (maxFontSize - minFontSize);
    let text = word.w;
    let textArray = Array.from(text);
    let tooltip = null;
    if (textArray.length * size * charWidth > wordCloudWidth) {
      text = Utilities.Strings.middleTruncateString(text, 25);
      tooltip = word.w;
    }

    const styles = {
      fontSize: `${size}rem`,
      color: WORD_CLOUD_COLOR_RAMP[word.b - 1],
    };

    const wordProps = { "data-testid": "word-cloud" };

    if (props.callback) {
      const wordProps = { "data-testid": "word-clout" };
      return (
        <a
          {...wordProps}
          key={word.w}
          title={tooltip}
          onClick={() => props.callback(word.ow || word.w)}
          style={styles}
        >
          {text}
        </a>
      );
    } else {
      return (
        <a
          {...wordProps}
          key={word.w}
          href={`${props.wordURL}&find=${word.ow || word.w}`}
          title={tooltip}
          style={styles}
        >
          {text}
        </a>
      );
    }
  });

  let css =
    props.scrollable && props.scrollable === true
      ? `${styles.wordCloud} ${styles.scrollable}`
      : styles.wordCloud;

  return (
    <div
      className={css}
      style={{ ...props.style, minWidth: `${wordCloudWidth}px` }}
    >
      <div>{tags}</div>
    </div>
  );
}

import React, { useContext } from "react";

import { ReportContext } from "./ReportContextProvider";
import { FilterSummary } from "/components-ui";

/*
 *  FilterSummary used on both report and list view, only report is relying on alert context
 */
export const FilterSummaryWrapper = (props) => {
  const alertContext = useContext(ReportContext);
  const { filterSummary } = alertContext;

  //detail view
  if (!filterSummary) return null;

  return <FilterSummary {...props} filterSummary={filterSummary} />;
};



export const IntegrationDisplayNameLookup = {
    "appdynamics": "AppDynamics",
    "datadog": "Datadog",
    "dynatrace": "Dynatrace",
    "grafana": "Grafana",
    "email": "Email",
    "mattermost": "Mattermost",
    "slack": "Slack",
    "teams": "Microsoft Teams",
    "webexteams": "Webex Teams",
    "opsgenie": "Opsgenie",
    "opsramp": "OpsRamp",
    "pagerduty": "PagerDuty",
    "victorops": "VictorOps",
    "webhook": "Outbound RCA",
    "generic": "Inbound RCA",

    "anomaly_webhook": "Anomaly Webhook"
}


const IntegrationUtils = {
    getOutboundChannels:(pageContext, dataServiceContext)=> {
        const isSaas = pageContext.env.PRODUCT_TYPE.toLowerCase() == "saas"
        const deploymentFilter =dataServiceContext.getUserProfile().deployment_id;

        const filterBase = ["out_enabled=true",`db_schema=${deploymentFilter}`,  "category!=observability-dashboard,dashboard-extension,telemetry"];

        let filter = isSaas ? filterBase :  filterBase.concat(["integration!=email,community"]);

        return Promise.resolve()
            .then(() =>dataServiceContext.fetch(
                'integration/read',
                {"sort": ["name"], "filter": filter}
            ));
    }
}

export default IntegrationUtils

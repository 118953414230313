import React, {
  useState,
  createContext,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useRouter } from "next/router";

import {
  PageContext,
  DataServiceContext,
  Utilities,
  tooltipStyles,
} from "athena-next-ui-lib";
import {
  SEQ_BASE,
  timeRanges,
  significance,
  confidence,
  occurrencesFilterList,
  Occurrences_Sortables,
} from "../constants/filter-constants";
import { loadTagFiltersForIncidents } from "/components-biz";
import Moment from "moment";

export const IncidentListContext = createContext();

const BUCKET_ALERT_LIMIT = 10;

export const IncidentListContextProvider = (props) => {
  const dataService = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);

  const router = useRouter();

  const [history, setHistory] = useState([]);
  const [readyToFetch, setReadyToFetch] = useState(false);
  const [pageInitialized, setPageInitialized] = useState(false);
  const [isLoadingAlertData, setIsLoadingAlertData] = useState(true);

  const [filters, setFilters] = useState([]);
  const [filterableManualTags, setFilterableManualTags] = useState([]);
  const [allManualTags, setAllManualTags] = useState([]);
  const [customRules, setCustomRules] = useState([]);
  const [routingRules, setRoutingRules] = useState([]);

  const [serviceGroups, setServiceGroups] = useState({});
  const [search, setSearch] = useState(null);
  const [, setSortableDimensions] = useState([]);
  const [filterSummary, setFilterSummary] = useState([]);

  const [alertData, setAlertData] = useState([]);
  const [, setTimeRangeInfo] = useState({});
  const [defaultTimeRange] = useState("last-7-days");

  const [currentFilter, setCurrentFilter] = useState(null);

  const [findTerm, setFindTerm] = useState();
  const [findMatches, setFindMatches] = useState([]);
  const [findTermProcessing, setFindTermProcessing] = useState(false);

  const minBucketWidth = 16;
  const [maxNumBuckets, setMaxNumBuckets] = useState(64);

  const [ingestMode, setIngestMode] = useState("stream");

  const [alertLoadError, setAlertLoadError] = useState(null);
  const [spikeState, setSpikeState] = useState([]);

  const queryState = useRef();
  const setQueryState = (qs) => {
    const { env } = pageContext;

    const encodedQs = { ...qs };
    Object.keys(encodedQs).forEach(
      (prop) => (encodedQs[prop] = encodeURI(qs[prop]))
    );

    const url = `/root-cause/list?${Utilities.JS.generateQueryString(
      encodedQs
    )}`;
    router.push(url, Utilities.Router.createAsPath(url, env));
    queryState.current = { ...qs }; //not encoded
    return true;
  };

  const _setQueryState = (qs) => {
    setQueryState(qs);
  };

  const getQueryState = () => {
    const user = dataService.getUserProfile();
    let qs = { ...queryState.current, deployment_id: user.deployment_id };
    return qs;
  };

  useMemo(() => {
    if (currentFilter) {
      setFilters([...filters, currentFilter]);
    }
  }, [currentFilter]);

  useEffect(() => {
    //store the queryState once router reports it is initialized
    if (pageContext.isReady === true) {
      setReadyToFetch(true);
    }
  }, [pageContext.isReady]);

  useEffect(() => {
    if (readyToFetch) {
      initPage();
    }
  }, [readyToFetch]);

  useEffect(() => {
    if (pageContext.timeZone) {
      const info = deriveAlertListAPIPayload();
      setFilterSummary(info.filterSummary);
    }
  }, [pageContext.timeZone]);

  useEffect(() => {
    if (pageInitialized) {
      const user = dataService.getUserProfile();
      setIngestMode(user?.historic_inci ? "bundle" : "stream");
      const qs = getQueryState();
      const timeRange = qs.time_range || user.defaultTimeRange || "last-7-days";
      clearHistory(timeRange);
      loadAlertsData();
      setPageInitialized(false);
    }
  }, [pageInitialized]);

  const toggleSpikeExpansionState = (bucketIndex) => {
    let _spikeState = [...spikeState];

    if (_spikeState.findIndex((item) => item === bucketIndex) > -1) {
      _spikeState = _spikeState.filter((item) => item !== bucketIndex);
    } else {
      _spikeState.push(bucketIndex);
    }
    setSpikeState(_spikeState);
  };

  const loadTagFilter = () => {
    const setters = {
      setCurrentFilter,
      setAllManualTags,
      setFilterableManualTags,
      setRoutingRules,
      setCustomRules,
    };

    return loadTagFiltersForIncidents(
      dataService,
      getQueryState(),
      setters,
      SEQ_BASE
    );
  };

  const loadSortFeatures = () => {
    let sortables = [];

    const selected = getQueryState() || {};
    const selectedOccurrences = (selected.sort_occurrences || "").split(",");

    sortables.push({
      seq: SEQ_BASE + 3,
      expanded: true,
      category: "Occurrences",
      items: Occurrences_Sortables.map((s) => {
        s.selected = selectedOccurrences.indexOf(s.value) > -1;
        return s;
      }),
      selectionStyle: "single",
      apiParam: "occurrences",
    });

    setSortableDimensions(sortables);
  };

  const loadOccurrencesFilter = () => {
    const response = {
      data: occurrencesFilterList,
      response: { timestamp: 1, code: 200 },
      selectedIndex: 0,
    };

    const { repeating_incidents, inci_itype_ttl } = getQueryState();

    setCurrentFilter({
      seq: SEQ_BASE + 10,
      expanded: false,
      category: "Alert Occurrences",
      items: response.data.map((item) => {
        let isSelected = false;
        if (
          item.value === inci_itype_ttl ||
          (!inci_itype_ttl && item.value === repeating_incidents)
        )
          isSelected = true;

        return { ...item, selected: isSelected };
      }),
      selectionStyle: "singleAndRequired",
      apiParam: "repeating_incidents",
      getSelectedValues: "transformValuesIntoString",
    });
  };

  const loadAlertStateFilter = () => {
    if (dataService.isUIReportCentric()) return true;

    const itype_states = getQueryState().itype_states || "";

    const response = {
      data: [
        {
          label: "Custom",
          name: "Custom",
          value: "custom",
        },
        {
          label: "Suggested",
          name: "Suggested",
          value: "suggested",
        },
        {
          label: "Accepted",
          name: "Accepted",
          value: "accepted",
        },
        {
          label: "Rejected",
          name: "Rejected",
          value: "rejected",
        },
      ],
      response: { timestamp: 1, code: 200 },
      selectedIndex: 0,
    };

    setCurrentFilter({
      seq: SEQ_BASE + 11,
      expanded: false,
      category: "Alert Rule State",
      items: response.data.map((item) => {
        return { ...item, selected: itype_states.indexOf(item.value) > -1 };
      }),
      selectionStyle: "multiple",
      apiParam: "itype_states",
      getSelectedValues: "transformValuesIntoString",
    });
  };

  const loadServiceGroups = () => {
    return dataService
      .fetch("incident/read/servicegroups", {
        time_from: 1,
        time_to: 99999999999,
      })
      .then((sgReadCall) => {
        const selected = (getQueryState().service_groups || "").split(",");

        let items = [
          { name: "Shared Services", value: "default" },
          ...sgReadCall.data,
        ];

        setCurrentFilter({
          seq: SEQ_BASE + 23,
          expanded: false,
          category: "Service Group",
          items: items.map((item) => {
            return {
              ...item,
              selected: selected.includes(item.value || item.name),
            };
          }),
          selectionStyle: "multiple",
          apiParam: "service_groups",
        });

        setServiceGroups(sgReadCall);
      });
  };

  const loadConfidenceFilter = () => {
    const response = {
      data: confidence,
      response: { timestamp: 1, code: 200 },
      selectedIndex: 0,
    };

    const confItems = response.data.map((item) => {
      const confItem = confidence.find((conf) => conf.value === item.value);
      return { name: confItem.label, value: item.value };
    });

    setCurrentFilter({
      seq: SEQ_BASE + 12,
      expanded: false,
      category: "AI Confidence",
      items: confItems.map((item) => {
        return {
          ...item,
          selected:
            getQueryState().itype_nlp_judgment &&
            getQueryState().itype_nlp_judgment === item.value
              ? true
              : false,
        };
      }),
      selectionStyle: "single",
      apiParam: "itype_nlp_judgment",
      getSelectedValues: "transformValuesIntoString",
    });
  };

  const loadSignificanceFilter = () => {
    const response = {
      data: significance,
      response: { timestamp: 1, code: 200 },
      selectedIndex: 0,
    };

    const sigItems = response.data.map((item) => {
      const sigItem = significance.find((sig) => sig.value === item.value);
      return { name: sigItem.label, value: item.value };
    });

    setCurrentFilter({
      seq: SEQ_BASE + 12,
      expanded: false,
      category: "Significance",
      items: sigItems.map((item) => {
        return {
          ...item,
          selected:
            getQueryState().inci_significance &&
            getQueryState().inci_significance === item.value
              ? true
              : false,
        };
      }),
      selectionStyle: "single",
      apiParam: "inci_significance",
      getSelectedValues: "transformValuesIntoString",
    });
  };

  const loadLogNames = () => {
    return dataService
      .fetch("incidentevent/read/logtypes", {
        time_from: 1,
        time_to: 99999999999,
      })
      .then((response) => {
        const selected = (getQueryState().logtypes || "").split(",");

        setCurrentFilter({
          seq: SEQ_BASE + 20,
          expanded: false,
          category: "Logtype",
          items: response.data.map((item) => {
            return { ...item, selected: selected.includes(item.name) };
          }),
          selectionStyle: "multiple",
          apiParam: "logtypes",
        });
      });
  };

  const loadSourceNames = () => {
    return dataService
      .fetch("incidentevent/read/sources", {
        time_from: 1,
        time_to: 99999999999,
      })
      .then((response) => {
        const selected = (getQueryState().hosts || "").split(",");

        setCurrentFilter({
          seq: SEQ_BASE + 21,
          expanded: false,
          category: "Source",
          items: response.data.map((item) => {
            return { ...item, selected: selected.includes(item.name) };
          }),
          selectionStyle: "multiple",
          apiParam: "sources",
        });
      });
  };

  const loadHostNames = () => {
    return dataService
      .fetch("incidentevent/read/hosts", {
        time_from: 1,
        time_to: 99999999999,
      })
      .then((response) => {
        const selected = (getQueryState().hosts || "").split(",");

        setCurrentFilter({
          seq: SEQ_BASE + 22,
          expanded: false,
          category: "Host",
          items: response.data.map((item) => {
            return { ...item, selected: selected.includes(item.name) };
          }),
          selectionStyle: "multiple",
          apiParam: "hosts",
        });
      });
  };

  const loadSearchTerm = () => {
    const searchString = getQueryState().search;

    if (searchString && searchString.length > 0) setSearch(searchString);
  };

  const loadFindTerm = () => {
    const findString = getQueryState().find;

    if (findString && findString.length > 0) setFindTerm(findString);
  };

  const resolveTimeRange = (time_range) => {
    if (!time_range) return;

    let customStart, customEnd;

    const convertedTimeRange = Utilities.TZ.translateTimeRange(
      time_range,
      pageContext.timeZone
    );

    let summaryOfFilters = [];
    if (time_range && time_range.length && time_range.indexOf(",") > -1) {
      customStart = convertedTimeRange.fromObj;
      customEnd = convertedTimeRange.toObj;

      const yearFormat =
        customStart.year() === customEnd.year() ? "" : ", YYYY";
      summaryOfFilters = [
        <span key={0}>
          Time Range:{" "}
          <b>
            {customStart.format(`MMM D${yearFormat} HH:mm`)} <s>to</s>
            {customEnd.format(`MMM D${yearFormat} HH:mm`)}
          </b>
        </span>,
      ];
    } else {
      const user = dataService.getUserProfile();
      time_range =
        time_range && time_range.length > 0
          ? time_range
          : user.rc_time_range || "last-7-days";
      summaryOfFilters = [
        <span key={0}>
          Time Range:{" "}
          <b>
            {
              (
                timeRanges.find((tr) => tr.value === time_range) || {
                  label: "Last 7 Days",
                }
              ).label
            }
          </b>
        </span>,
      ];
    }

    // API Requires...
    // Ranges must be expressed on whole minute intervals
    // the sum of all minutes in the range must be evenly divisible by the number of buckets

    let from, to, numBuckets;

    from = Moment(convertedTimeRange.fromObj).startOf("minute");
    to = Moment(convertedTimeRange.toObj).startOf("minute");
    let totalMins = Moment(to).diff(from, "minutes");

    // we can calc num buckets based on end time landing at the beginning or end of the minute
    // here we calc largest factor for each time range
    // that is also <= maxNumBuckets we can fit on this graphWidth
    // then choose the bigger num of buckets
    // since more buckets more means visible alerts and  fewer spikes
    const totalMinsA = totalMins;
    const totalMinsB = totalMins + 1;
    let numBucketsA = -1;
    let numBucketsB = -1;

    //console.log(`calc A:${totalMinsA}`)
    for (let i = 2; i <= totalMinsA; i++) {
      // check if number is a factor
      if (totalMinsA % i === 0 && i <= maxNumBuckets) {
        //console.log(`a:${i}`)
        numBucketsA = i;
      }
    }
    //console.log(`calc B:${totalMinsB}`)
    for (let j = 2; j <= totalMinsB; j++) {
      // check if number is a factor
      if (totalMinsB % j === 0 && j <= maxNumBuckets) {
        //console.log(`b:${j}`)
        numBucketsB = j;
      }
    }

    if (numBucketsA == numBucketsB) {
      numBuckets = 2;
      if (totalMinsA % 2 !== 0) {
        totalMins = totalMinsA + 1;
        to.add(1, "minute");
      }
    } else if (numBucketsA < numBucketsB) {
      numBuckets = numBucketsB;
      totalMins = totalMinsB;
      to.add(1, "minute");
    } else {
      numBuckets = numBucketsA;
      totalMins = totalMinsA;
    }

    if (totalMins <= 1) {
      numBuckets = 1;
      totalMins = 1;
      to = from.clone().add(1, "minute");
    }

    console.log(
      `maxNumBuckets: ${maxNumBuckets} totalMins: A:${totalMinsA} B: ${totalMinsB} ${totalMins} numBuckets A:${numBucketsA} B:${numBucketsB} final:${numBuckets} minsPerBucket ${
        totalMins / numBuckets
      }`
    );

    setTimeRangeInfo({
      num_buckets: numBuckets,
      minsPerBucket: totalMins / numBuckets,
      from: from.format("YYYY-MM-DDTHH:mm:ss"),
      to: to.format("YYYY-MM-DDTHH:mm:ss"),
      time_from: from.format("YYYY-MM-DDTHH:mm:ssZ"),
      time_to: to.format("YYYY-MM-DDTHH:mm:ssZ"),
    });

    return {
      num_buckets: numBuckets,
      minsPerBucket: totalMins / numBuckets,
      time_from: from.format("YYYY-MM-DDTHH:mm:ssZ"),
      time_to: to.format("YYYY-MM-DDTHH:mm:ssZ"),
      filterSummary: summaryOfFilters,
    };
  };

  const deriveAlertListAPIPayload = () => {
    let summaryOfFilters = [];
    let payload = {
      abortEarlierCalls: true,
      repeating_incidents: "first",
      bucket_alerts_limit: BUCKET_ALERT_LIMIT,
      ...getQueryState(),
      ingestMode,
    };

    const range = resolveTimeRange(payload.time_range) || {};
    payload = { ...payload, ...range, filterSummary: null };
    summaryOfFilters = range.filterSummary || [];

    if (payload?.filter && typeof payload.filter === "string") {
      payload.filter = payload.filter.split(",");
    }

    if (payload.repeating_incidents === "last") {
      if (payload?.inci_itype_ttl) {
        const filter = occurrencesFilterList.find(
          (f) => f.value === payload?.inci_itype_ttl
        );
        summaryOfFilters.push(<b>{filter?.label}</b>);
      }
    } else if (payload.repeating_incidents === "first") {
      summaryOfFilters.push(<b>First Alert Occurrence Only</b>);
    } else {
      summaryOfFilters.push(<b>All Alert Occurrences</b>);
    }

    // now that custom alerts are counted, we can remove this clarification...
    // if(!payload.itype_states || payload?.itype_states?.includes("custom")){
    //     summaryOfFilters.push(<b>All Custom Alerts</b>)
    // }

    if (payload.inci_significance) {
      const labelLookup = significance.find(
        (r) => r.value === payload.inci_significance
      );
      if (labelLookup)
        summaryOfFilters.push(
          <span>
            Significance: <b>{labelLookup.label}</b>
          </span>
        );
    }

    if (payload.itype_nlp_judgment) {
      const labelLookup = confidence.find(
        (r) => r.value === payload.itype_nlp_judgment
      );
      if (labelLookup)
        summaryOfFilters.push(
          <span>
            AI Confidence: <b>{labelLookup.label}</b>
          </span>
        );
    }

    if (payload.manual_tag) {
      const tagNamesString = payload.manual_tag;
      const tagNames = tagNamesString.split(","); //convert into array

      //convert tag names to tag ids
      const matchingTags = filterableManualTags.filter((tag) => {
        const findIndex = tagNames.findIndex(
          (t) => t.toLowerCase() == tag.tag_name.toLowerCase()
        );
        return findIndex > -1;
      });

      const tagIds = matchingTags.map((tg) => tg.id).join(",");
      const displayTagNames = matchingTags.map((tg) => tg.name).join(",");

      if (tagIds?.length > 0) {
        payload.itype_tag_ids = tagIds;
      }
      if (displayTagNames)
        summaryOfFilters.push(
          <span>
            Tags: <b>{displayTagNames}</b>
          </span>
        );
    }
    delete payload.manual_tag;

    if (payload.custom_rules) {
      const tagNamesString = payload.custom_rules;
      const tagNames = tagNamesString.split(","); //convert into array

      //convert tag names to tag ids
      const matchingTags = customRules.filter((tag) => {
        const findIndex = tagNames.findIndex(
          (t) => t.toLowerCase() == tag.tag_name.toLowerCase()
        );
        return findIndex > -1;
      });

      const tagIds = matchingTags.map((tg) => tg.id).join(",");
      const displayTagNames = matchingTags.map((tg) => tg.name).join(", ");

      if (tagIds?.length > 0) {
        payload.inci_custom_ids = tagIds;
      }
      if (displayTagNames)
        summaryOfFilters.push(
          <span>
            Alert Rules: <b>{displayTagNames}</b>
          </span>
        );
    }
    delete payload.custom_rules;

    if (payload.routing_rules) {
      const tagNamesString = payload.routing_rules;
      const tagNames = tagNamesString.split(","); //convert into array

      //convert tag names to tag ids
      const matchingTags = routingRules.filter((tag) => {
        const findIndex = tagNames.findIndex(
          (t) => t.toLowerCase() == tag.tag_name.toLowerCase()
        );
        return findIndex > -1;
      });

      const tagIds = matchingTags.map((tg) => tg.id).join(",");
      const displayTagNames = matchingTags.map((tg) => tg.name).join(", ");

      if (tagIds?.length > 0) {
        payload.inci_routing_ids = tagIds;
      }
      if (displayTagNames)
        summaryOfFilters.push(
          <span>
            ML Routing Rules: <b>{displayTagNames}</b>
          </span>
        );
    }
    delete payload.routing_rules;

    if (payload.logtypes) {
      summaryOfFilters.push(
        <span>
          Logtype: <b>{payload.logtypes.split(",").join(", ")}</b>
        </span>
      );
      payload.logtypes = payload.logtypes.split(",");
    }

    if (payload.hosts) {
      summaryOfFilters.push(
        <span>
          Host: <b>{payload.hosts.split(",").join(", ")}</b>
        </span>
      );
      payload.hosts = payload.hosts.split(",");
    }

    if (payload.sources) {
      summaryOfFilters.push(
        <span>
          Source: <b>{payload.sources.split(",").join(", ")}</b>
        </span>
      );
      payload.sources = payload.sources.split(",");
    }

    if (payload.itype_states) {
      // && !dataService.isUIReportCentric()) {
      summaryOfFilters.push(
        <span>
          Alert State: <b>{payload.itype_states.split(",").join(", ")}</b>
        </span>
      );
      payload.itype_states = payload.itype_states.split(",");
    }

    if (payload.service_groups) {
      const svc_grps = (payload.service_groups =
        payload.service_groups.split(","));
      const svc_grps_trunc = Utilities.Strings.middleTruncateString(
        svc_grps.join(", "),
        100
      );

      if (svc_grps_trunc.length >= 100) {
        const svc_grps_tip = svc_grps.map((sg) => (
          <span
            key={sg}
            style={{ margin: "0 4px", color: "white", whiteSpace: "nowrap" }}
          >
            {sg}
          </span>
        ));
        summaryOfFilters.push(
          <span
            className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouth}`}
          >
            Service&nbsp;Group: <b>{svc_grps_trunc}</b>
            <div
              className={tooltipStyles.cssTooltipText}
              style={{
                maxWidth: "500px",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {svc_grps_tip}
            </div>
          </span>
        );
      } else {
        summaryOfFilters.push(
          <span>
            Service&nbsp;Group: <b>{svc_grps.join(", ")}</b>
          </span>
        );
      }
    }

    if (!payload.sort_occurrences) {
      payload.occurrences = "none";
    } else {
      payload.occurrences = payload.sort_occurrences;
      summaryOfFilters.push(
        <span>
          <u>Sorted by</u>{" "}
          <b>
            {
              Occurrences_Sortables.find(
                (sort) => sort.value === payload.occurrences
              ).name
            }
          </b>
        </span>
      );
    }

    if (payload.search) {
      payload.ievt_level = parseInt(payload.ievt_level);
      summaryOfFilters.push(
        <span>
          Reports <u>containing</u>
          <b>{payload.search}</b>
        </span>
      );
    }

    delete payload.sort_occurrences;
    delete payload.sort_time_buckets;

    return { payload: payload, filterSummary: summaryOfFilters };
  };

  const loadAlertList = () => {
    const info = deriveAlertListAPIPayload();

    return Promise.resolve()
      .then(() => {
        setFilterSummary(info.filterSummary);
        setIsLoadingAlertData(true);
        setAlertLoadError(null);
        return true;
      })
      .then(() => {
        return dataService.fetch("incident/read/buckets", info.payload);
      })
      .then((listCall) => {
        if (listCall.response.code === dataService.IGNORE_RESPONSE_CODE) {
          //do nothing
          return true;
        } else if (listCall.response.code > 200) {
          setAlertData([]);
          setAlertLoadError(listCall.response.message);
          throw listCall.response;
        } else {
          setAlertData(listCall.data);
          return true;
        }
      })
      .finally(() => {
        setIsLoadingAlertData(false);
      })
      .catch((response) => {
        console.error(response);
        return true;
      });
  };

  const alertBuckets = useMemo(() => {
    const decoratedData = alertData.map((bucket, bucketIndex) => {
      bucket.id = bucketIndex;
      bucket.start = Moment(bucket.time_from);
      bucket.end = Moment(bucket.time_to);

      const findBucket = findMatches?.length && findMatches[bucketIndex];
      bucket.findMatchCount = findBucket?.alerts_count
        ? findBucket?.alerts_count
        : 0;

      let findId = 0;
      (bucket.alerts || []).forEach((alert) => {
        alert.matched =
          (findBucket?.alerts || []).findIndex(
            (m) => m.inci_id === alert.inci_id
          ) > -1;
        alert.inci_events = (alert.inci_events || []).map((event) => {
          event.event_text =
            search?.length || alert.matched
              ? applyTextHighlights(
                  event.ievt_etext,
                  search,
                  findTerm,
                  findId++
                )
              : event.ievt_etext;
          return { ...event };
        });
      });

      return bucket;
    });

    return decoratedData;
  }, [alertData, search, findMatches]);

  const alertList = useMemo(() => {
    return alertBuckets.slice(0).reverse();
  }, [alertBuckets]);

  const [displayedAlertCount, setDisplayedAlertCount] = useState(0);
  const [totalAlertCount, setTotalAlertCount] = useState(0);

  const alerts = useMemo(() => {
    let tc = 0;
    let dc = 0;
    const data = [];
    alertBuckets.forEach((bucket) => {
      tc += bucket.alerts_count;
      dc += (bucket.alerts || []).length;
      (bucket.alerts || []).forEach((a) => data.push(a));
    });

    setDisplayedAlertCount(dc);
    setTotalAlertCount(tc);

    return data;
  }, [alertBuckets]);

  const initPage = () => {
    setQueryState(router.query);

    return Promise.resolve()
      .then(() => loadTagFilter())
      .then(() => {
        loadSortFeatures();
        loadOccurrencesFilter();
        loadServiceGroups();
        loadSignificanceFilter();
        // loadConfidenceFilter();
        loadAlertStateFilter();
        loadLogNames();
        loadHostNames();
        loadSourceNames();
        loadSearchTerm();
        loadFindTerm();
      })
      .then(() => setPageInitialized(true));
  };

  const clearHistory = (timeRange) => {
    setHistory([timeRange]);
  };

  const addToHistory = (timeRange) => {
    if (timeRange !== history[history.length - 1]);
    {
      let h = history.slice(0);
      h.push(timeRange);
      setHistory(h);
    }
  };

  const historyBack = () => {
    let h = history.slice(0, -1); //copy the history except for the last timeRange; it is the current timeRange
    const timeRange = h[h.length - 1]; //set the time range to the `new` last timeRange
    setHistory(h);
    const params = { ...getQueryState() };
    params.time_range = timeRange;
    refreshAlertsData(params);
  };

  const loadAlertsData = () => {
    setQueryState(router.query);
    loadAlertList();
  };

  const refreshAlertsData = (params) => {
    return Promise.resolve()
      .then(() => setQueryState(params))
      .then(() => loadAlertList())
      .then(() => applyFindTerm(findTerm));
  };

  const fetchIncidentsForTimeRange = (time_range) => {
    const info = deriveAlertListAPIPayload();
    const range = resolveTimeRange(time_range);
    const payload = {
      ...info.payload,
      ...range,
      num_buckets: 1,
      bucket_alerts_limit: 0,
    };

    return Promise.resolve().then(() => {
      return dataService.fetch("incident/read/buckets", payload);
    });
  };

  const onTimeRangeUpdate = (time_range) => {
    //time_range is in UTC
    let params = { ...getQueryState() };
    delete params.inci_code;
    delete params.inci_itype_ttl;
    params.time_range = time_range;

    clearHistory(params.time_range);

    return Promise.resolve()
      .then(() => setQueryState(params))
      .then(() => loadAlertList())
      .then(() => applyFindTerm(findTerm));
  };

  const onFilterUpdate = (selectedFilters) => {
    let params = { ...getQueryState() };
    delete params.inci_code;
    delete params.inci_itype_ttl;

    if (!params.repeating_incidents || !selectedFilters.repeating_incidents) {
      params.repeating_incidents = "all";
    } else {
      switch (selectedFilters.repeating_incidents) {
        case ">=100":
          params.repeating_incidents = "last";
          params.inci_itype_ttl = ">=100";
          params.filter = ["inci_itype_ttl>=100"];
          break;
        case ">=10":
          params.repeating_incidents = "last";
          params.inci_itype_ttl = ">=10";
          params.filter = ["inci_itype_ttl>=10"];
          break;
        case "<=10":
          params.repeating_incidents = "last";
          params.inci_itype_ttl = "<=10";
          params.filter = ["inci_itype_ttl<=10"];
          break;
        case "=1":
          params.repeating_incidents = "last";
          params.inci_itype_ttl = "=1";
          params.filter = ["inci_itype_ttl=1"];
          break;
        case "last":
        case "all":
        case "first":
        default:
          delete params.filter;
          params.repeating_incidents = selectedFilters.repeating_incidents;
          break;
      }
    }

    if (selectedFilters.time_range) {
      params.time_range = selectedFilters.time_range;
    }

    clearHistory(params.time_range);

    if (selectedFilters.inci_significance) {
      const valueLookup = significance.find(
        (r) => r.value === selectedFilters.inci_significance
      );
      if (valueLookup) params.inci_significance = valueLookup.value;
    } else {
      delete params.inci_significance;
    }

    if (selectedFilters.itype_nlp_judgment) {
      const valueLookup = confidence.find(
        (r) => r.value === selectedFilters.itype_nlp_judgment
      );
      if (valueLookup) params.itype_nlp_judgment = valueLookup.value;
    } else {
      delete params.itype_nlp_judgment;
    }

    if (selectedFilters.manual_tag) {
      //convert an array of tag ids stored in filter to tag names shown in url
      const tagNames = filterableManualTags
        .filter((tag) => {
          //matching id (value field)
          const findIndex = selectedFilters.manual_tag.findIndex(
            (t) => t.toLowerCase() == tag.value.toLowerCase()
          );
          return findIndex > -1;
        })
        .map((tg) => tg.name)
        .join(",");

      params.manual_tag = tagNames.toLowerCase(); //comma separated tag name
    } else {
      delete params.manual_tag;
    }

    if (selectedFilters.custom_rules) {
      //convert an array of tag ids stored in filter to tag names shown in url
      const tagNames = customRules
        .filter((tag) => {
          //matching id (value field)
          const findIndex = selectedFilters.custom_rules.findIndex(
            (t) => t.toLowerCase() == tag.value.toLowerCase()
          );
          return findIndex > -1;
        })
        .map((tg) => tg.name)
        .join(",");

      params.custom_rules = tagNames.toLowerCase(); //comma separated tag name
    } else {
      delete params.custom_rules;
    }

    if (selectedFilters.routing_rules) {
      //convert an array of tag ids stored in filter to tag names shown in url
      const tagNames = routingRules
        .filter((tag) => {
          //matching id (value field)
          const findIndex = selectedFilters.routing_rules.findIndex(
            (t) => t.toLowerCase() == tag.value.toLowerCase()
          );
          return findIndex > -1;
        })
        .map((tg) => tg.name)
        .join(",");

      params.routing_rules = tagNames.toLowerCase(); //comma separated tag name
    } else {
      delete params.routing_rules;
    }

    if (selectedFilters.logtypes) {
      params[`logtypes`] = `${selectedFilters.logtypes.join(",")}`;
    } else {
      delete params.logtypes;
    }

    if (selectedFilters.hosts) {
      params[`hosts`] = `${selectedFilters.hosts.join(",")}`;
    } else {
      delete params.hosts;
    }

    if (selectedFilters.sources) {
      params[`sources`] = `${selectedFilters.sources.join(",")}`;
    } else {
      delete params.sources;
    }

    if (selectedFilters.service_groups) {
      params[`service_groups`] = `${selectedFilters.service_groups.join(",")}`;
    } else {
      delete params.service_groups;
    }

    if (search && search.length) {
      params[`search`] = `${search}`;
    }

    if (selectedFilters.itype_states) {
      params[`itype_states`] = `${selectedFilters.itype_states
        .split(",")
        .join(",")}`;
    } else {
      delete params.itype_states;
    }

    return Promise.resolve()
      .then(() => setQueryState(params))
      .then(() => loadAlertList())
      .then(() => applyFindTerm(findTerm));
  };

  const onSearchTermUpdate = (searchTerm, scope) => {
    const params = { ...getQueryState() };
    delete params.inci_code;

    if (searchTerm.length) {
      params.search = searchTerm;
      params.ievt_level = scope.value || 2;
    } else {
      delete params.search;
      delete params.ievt_level;
    }

    return Promise.resolve()
      .then(() => setSearch(searchTerm))
      .then(() => setQueryState(params))
      .then(() => loadAlertList());
  };

  const selectAlert = (inci_id) => {
    const alert = document.getElementById("inci-" + inci_id);
    if (alert) alert.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const applyFindTerm = (term) => {
    const params = { ...getQueryState() };

    if (term && term.length) params.find = term;
    else delete params.find;

    setFindTerm(term);
    setFindTermProcessing(true);

    if (term && term.length) {
      const info = deriveAlertListAPIPayload();
      info.payload.find = term;

      return Promise.resolve()
        .then(() => _setQueryState(params))
        .then(() =>
          dataService.fetch("incident/read/bucketsfind", info.payload)
        )
        .then((incidentFindCall) => {
          if (incidentFindCall.response.code > 200) {
            setFindMatches([]);
            throw incidentFindCall.response;
          } else {
            setFindMatches(incidentFindCall.data);
          }
        })
        .finally(() => {
          setFindTermProcessing(false);
        });
    } else {
      return Promise.resolve()
        .then(() => _setQueryState(params))
        .then(() => setFindMatches([]));
    }
  };

  const navigateToReport = (itype_id, inci_id, word, tagObj) => {
    storeRCLinkURL(inci_id);
    window.location = deriveReportLink(itype_id, inci_id, word, tagObj);
  };

  const storeRCLinkURL = (inci_id) => {
    const { env } = pageContext;
    const params = { ...getQueryState() };

    params.current = inci_id;

    let url = `${
      env.INGRESS_PREFIX
    }/root-cause/list?${Utilities.JS.generateQueryString(params)}`;
    window.localStorage.setItem(`rcListURL${inci_id}`, url);
  };

  const deriveReportLink = (itype_id, inci_id, word, tagObj) => {
    const { env } = pageContext;
    const user = dataService.getUserProfile();

    let reportLink = `/root-cause/report?deployment_id=${user.deployment_id}&itype_id=${itype_id}&inci_id=${inci_id}`;
    const ruleMap = { routing: "routing_rules", evts_int: "include_rules" };

    if (word) {
      reportLink += `&find=${word}&ievt_level=5`;
    } else if (getQueryState().hasOwnProperty("find")) {
      reportLink += `&find=${getQueryState().find}&ievt_level=5`;
    } else if (getQueryState().hasOwnProperty("search")) {
      reportLink += `&search=${getQueryState().search}&ievt_level=5`;
    } else {
      reportLink += `&ievt_level=2`;
    }

    if (tagObj) {
      reportLink += `&${ruleMap[tagObj.tag_type]}=${tagObj.tag_name}`;
    }

    return Utilities.Router.createAsPath(reportLink, env);
  };

  const contextObj = {
    pageInitialized,
    isLoadingAlertData,

    queryState: queryState.current,
    getQueryState,
    setQueryState,

    refreshAlertsData,
    defaultTimeRange,
    alertBuckets: alertBuckets,
    alerts: alerts,
    alertList,
    bucketAlertsLimit: BUCKET_ALERT_LIMIT,

    allManualTags,
    loadAlertList,

    onTimeRangeUpdate,
    filterSummary,
    filters,
    onFilterUpdate,
    onSearchTermUpdate,
    search,

    serviceGroups,
    selectAlert,

    storeRCLinkURL,
    deriveReportLink,
    navigateToReport,

    findTerm,
    findTermProcessing,
    applyFindTerm,
    findMatches,

    listId: "incident-list",

    totalAlertCount,
    displayedAlertCount,

    history,
    addToHistory,
    historyBack,

    minBucketWidth,
    setMaxNumBuckets,

    ingestMode,

    alertLoadError,

    fetchIncidentsForTimeRange,
    spikeState,
    toggleSpikeExpansionState,
  };

  return (
    <IncidentListContext.Provider value={contextObj}>
      {props.children}
    </IncidentListContext.Provider>
  );
};

export function applyTextHighlights(etext, searchTermRe, findTermRe, findId) {
  let allMatches = [];

  if (searchTermRe) {
    const rgx = deriveRegExp(searchTermRe);
    const searchMatches = etext.matchAll(rgx);

    for (const match of searchMatches) {
      allMatches.push({
        start: match.index,
        end: match.index + match[0].length,
        text: match[0],
        type: "search",
      });
    }
  }

  if (findTermRe) {
    const rgx = deriveRegExp(findTermRe);
    const findMatches = etext.matchAll(rgx);
    for (const match of findMatches) {
      const m1 = {
        start: match.index,
        end: match.index + match[0].length,
        text: match[0],
        type: "find",
      };

      const overlapped =
        allMatches.findIndex(
          (m) =>
            (m1.start >= m.start && m1.start <= m.end) ||
            (m1.end >= m.start && m1.end <= m.end)
        ) > -1;

      if (!overlapped) {
        allMatches.push(m1);
      }
    }
  }

  if (allMatches.length) {
    let text = String(etext);
    let htmlText = [];
    let index = 0;

    allMatches.sort((a, b) => (a.start > b.start ? 1 : -1));

    allMatches.forEach((m, matchIndex) => {
      const id = index === 0 ? `find-${findId}` : null;
      const key = `${id}${matchIndex}`;
      const taggedText =
        m.type === "find" ? (
          <u key={key} id={id}>
            {m.text}
          </u>
        ) : (
          <b key={key}>{m.text}</b>
        );
      htmlText.push(text.substring(index, m.start));
      htmlText.push(taggedText);
      index = m.end;
    });

    htmlText.push(text.substring(index, index + text.length - 1));

    return <span>{htmlText}</span>;
  } else {
    return <span>{etext}</span>;
  }
}

function deriveRegExp(exp) {
  let rgx = new RegExp(exp, "gi");

  if (exp.indexOf("/") === 0 && exp.lastIndexOf("/") === exp.length - 1)
    rgx = new RegExp(exp.substring(1, exp.length - 1), "gi");

  return rgx;
}

import React, {useEffect, useState, useContext, useRef,forwardRef, useImperativeHandle} from 'react'

import {
    Field,
    Row,
    FieldTypes
} from "athena-next-ui-lib";


export const UserPrefItem=forwardRef((props, ref)=>{

    const [selectedValue, setSelectedValue] = useState(null);

    const fieldRef=useRef();

    const getFieldPropsByType = ()=>{
        let fldProps = {};
        switch (props.fieldType) {
            case FieldTypes.BUTTON_GROUP:
                fldProps = {...fldProps,  css : ["md", "dark"]};
        }
        return fldProps;
    }


    useEffect(()=>{
        switch (props.fieldType) {
            case FieldTypes.MULTI_SELECT:
                const selValArr = props.value.split(",");
                const selArr = props.values.filter(val=>selValArr.indexOf(val.value)>-1 );
                setSelectedValue([...selArr])
                break;
            case FieldTypes.SINGLE_SELECT:
                setSelectedValue([...props.values.filter(val=>val.value === props.value)])
                break;
            case FieldTypes.BUTTON_GROUP:
                //returning an object
                setSelectedValue(props.values.find(val=>val.value === props.value))
                break;
            default:
                setSelectedValue(props.value);
        }

    }, [props.value])


    const getValue=( data )=>{
        return  fieldRef.current.getValue(data);
    }

    const validDateField=()=>{
        return "";
    }

    useImperativeHandle(ref, ()=> ({
        getValue:getValue, validDateField:validDateField
    }));

    if( selectedValue == null ) {
        return null;
    } else {
        return  <Row height={"140px"}>
            <Field {...getFieldPropsByType()}
                    ref={fieldRef}
                   fieldWidth={props.fieldWidth}
                   type={props.fieldType}
                   isBoolean={props.isBoolean}
                   label={props.label}
                   name={props.fieldName}
                   labelAlign={"top"}
                   values={props.values}
                   value={selectedValue}/>
        </Row>;
    }

})

import React, { useContext } from "react";
import {
  IncidentListContext,
  IncidentTypeBanner,
  Timestamp,
  Tags,
} from "/components-biz";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import styles from "./IncidentList.module.scss";
import { CustomIcon, SignificanceIcon, Utilities } from "athena-next-ui-lib";
import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";
import { WordCloud, AIConfidence } from "/components-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Incident = ({ alert }) => {
  const ac = useContext(IncidentListContext);
  const alertRowStyle = alert.matched ? "matched" : alert.itype_state;

  const css = `${styles[alertRowStyle]} ${styles.row}`;
  const {
    itype_state,
    itype_id,
    inci_svc_grps,
    inci_sources,
    inci_logs,
    inci_itype_occ,
    inci_id,
    inci_words,
    inci_signal,
    itype_nlp_judgment: confidence,
  } = alert;
  const isCustomAlertRule = itype_state === "custom";
  const occurrenceCount =
    inci_itype_occ > 1 ? (
      <div className={styles.occurrencesCount}>
        {Utilities.Numbers.nthNumber(inci_itype_occ)}&nbsp;OCCURRENCE
      </div>
    ) : null;

  const logs = (
    <span className={styles.logs} title={inci_logs}>
      {inci_logs.length > 40 ? inci_logs.substring(0, 40) + "..." : inci_logs}
    </span>
  );
  const sources = (
    <span className={styles.hosts} title={inci_sources}>
      {inci_sources.length > 60
        ? inci_sources.substring(0, 60) + "..."
        : inci_sources}
    </span>
  );

  let svc_grps = (inci_svc_grps || "").split(",").join(", ");
  svc_grps =
    svc_grps.length > 40 ? svc_grps.substring(0, 40) + "..." : svc_grps;
  const service_groups = svc_grps.length > 0 && (
    <span className={styles.serviceGroups} title={"Service Groups"}>
      {svc_grps}
    </span>
  );

  const significance = (
    <SignificanceIcon
      showTooltip={true}
      size={"sm"}
      inci_significance={alert.inci_significance}
    />
  );

  const noSignal = "                                    ";
  const signal =
    inci_signal !== noSignal ? (
      <span className={styles.signalIndicator}>
        <FontAwesomeIcon icon={faBolt} />
        Result of RC Scan
      </span>
    ) : null;

  const reportURL = ac.deriveReportLink(itype_id, inci_id);
  return (
    <a href={reportURL} className={css} id={`inci-${inci_id}`}>
      <div onMouseDown={() => ac.storeRCLinkURL(inci_id)}>
        <div className={styles.innerRow}>
          <Timestamp alert={alert} />
          <IncidentTypeBanner alert={alert} />
          {signal}
          {occurrenceCount}
          <Tags alert={alert} />
          <div className={styles.title}>
            <strong>{alert.itype_title}</strong>
          </div>
        </div>

        <div className={styles.alertBody}>
          {isCustomAlertRule === false && (
            <WordCloud
              listView={true}
              words={inci_words}
              wordURL={reportURL}
              minWidth={"350px"}
              style={{
                flex: "1 0 350px",
                maxWidth: "350px",
                marginRight: "5px",
              }}
            />
          )}

          <div className={styles.innerColumn}>
            <div className={styles.innerRow}>
              {significance}
              <AIConfidence confidence={confidence} />
              {service_groups}
              {sources}
              {logs}
            </div>
            <div className={styles.coreEvents}>
              {alert.inci_events.map((event, index) => {
                let icon = (
                  <CustomIcon
                    name={"none"}
                    options={{ wrapInSVG: true, size: 14, rotate: 30 }}
                  />
                );
                if (itype_state !== "custom" && event.ievt_level === 0) {
                  icon = (
                    <CustomIcon
                      name={"key"}
                      options={{
                        wrapInSVG: true,
                        size: 14,
                        rotate: 30,
                        tooltip: "Log line is an Alert Key",
                      }}
                    />
                  );
                }

                return (
                  <div key={event.ievt_id} className={styles.event}>
                    <div className={styles.icon}>{icon}</div>
                    <div className={styles.eText}>{event.event_text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

import React, { useRef, useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import {
  tooltipStyles,
  Utilities,
  Button,
  useModalDialog,
  Form,
  Field,
  DataServiceContext,
  PageContext,
  CustomIcon,
  LoadingAnim,
} from "athena-next-ui-lib";
import {
  faArrowUp,
  faArrowDown,
  faEye,
  faEyeSlash,
  faCaretRight as submenuIcon,
  faCommentAltLines,
  faCommentAltLines as annotateIcon,
  faTimesCircle,
  faBell as customAlertIcon,
  faPlusCircle as includeIcon,
  faBan as excludeIcon,
  faChartNetwork,
  faChartNetwork as relatedAlertIcon,
  faEllipsisVertical as eventMenuIcon 
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListModes } from "/components-ui";
import EventStyles from "/components-ui/list/events/EventList.module.scss";
import commonStyles from "/components-biz/common/common.module.scss";
import {
  ReportContext,
  INCIDENTS_LIMIT,
  DialogAlertRule,
} from "/components-biz";
import { VIEW_TYPES, VIEW_DEFS } from "../../../pages/alert-rules";
import { TAG_TYPE_LABELS } from "./EventListConstants";

export const Event = (props) => {
  const pageContext = useContext(PageContext);
  const reportContext = useContext(ReportContext) || {};
  const {
    events,
    listMode,
    selectEvent,
  } = reportContext;

  let disableMetadata = false;
  let disableEventMenu = false;

  if (props?.alertKeyEvent === true) {
    disableMetadata = true;
    disableEventMenu = true;
  }

  //hideEventMenu is true when shown in a dialog
  if (props?.hideEventMenu) {
    disableEventMenu = true; //not to show actions menu
  }

  const [metadataVisible, setMetadataVisible] = useState(false);

  const {
    ievt_id,
    ievt_etroot,
    text,
    ievt_tags,
    ievt_log,
    ievt_sev,
    ievt_rare_idx,
    ievt_sevno,
    ievt_ts,
    ievt_level,
    ievt_meta_gen,
    ievt_meta_cfgs,
    ievt_meta_tags,
    alertRuleOnlyMatch,
  } = props;

  const mode = alertRuleOnlyMatch === true ? "alertRuleOnly" : listMode;

  let eventType;
  let options = { wrapInSVG: true, size: 18 };
  let eventTypeIconCSS = EventStyles.eventTypeIcon;

  let derivedEventLevel = ievt_level;

  if (mode === ListModes.edit) {
    //if we are in edit mode then we are in a temporal state where
    //the ievt_level is either key (0) or not key (""), as determined by
    //whether the event can be found in the alertContext.alertKeys set
    derivedEventLevel =
      reportContext.alertKeys.findIndex((e) => e.ievt_id === ievt_id) > -1
        ? 0
        : "";
  }

  switch (derivedEventLevel + mode) {
    case "0alertRuleOnly":
      eventType = "alertRuleMatch";
      options.tooltip = <span>Log line matches a Custom Alert rule</span>;
      break;
    case "0read":
    case "0peek":
      eventType = "key";
      options.rotate = 30;
      options.tooltip = (
        <span>
          <strong>Alert Key</strong>
          <br />
          <span>{ievt_etroot}</span>
        </span>
      );
      break;
    case "0edit":
      eventType = "key";
      options.rotate = 30;
      options.tooltip = (
        <span>
          <strong>
            Selected Alert Key <i>(click to Remove)</i>
          </strong>
          <br />
          <span>{ievt_etroot}</span>
        </span>
      );
      break;
    case "1alertRuleOnly":
    case "1read":
    case "1peek":
      eventType = "hand";
      options.tooltip = <span>Log line of interest</span>;
      options.rotate = 30;
      break;
    case "1edit":
    case "2edit":
    case "3edit":
    case "4edit":
    case "5edit":
    case "6edit": //peek mode
    case "edit":
      eventType = "key";
      options.rotate = 0;
      options.tooltip = (
        <span>
          <strong>Click to add as Alert Key</strong>
          <br />
          <span>{ievt_etroot}</span>
        </span>
      );
      eventTypeIconCSS = `${EventStyles.eventTypeIcon} ${EventStyles.selectableKey}`;
      break;
    default:
      eventType = "none";
      break;
  }

  const selectKeyEvent = (event) => {
    const eventIsCurrentlySelected =
      reportContext.alertKeys.findIndex((e) => e.ievt_id === event.ievt_id) >
      -1;
    let newState = 0;

    if (eventIsCurrentlySelected) {
      reportContext.removeAlertKey(event);
      newState = event.ievt_level_last || 2;
    } else {
      reportContext.addAlertKey(event);
      newState = 0;
    }

    // if we find event in the current event list (if we are on the same level it originally came from),
    // update the selectd key indicator for this event
    const allEvents = [...events.data];
    const eventToUpdate = allEvents.find((e) => e.ievt_id === event.ievt_id);

    if (eventToUpdate) {
      eventToUpdate.ievt_level = newState;
    }
  };

  let eventTypeIcon = (
    <span
      className={eventTypeIconCSS}
      onClick={
        mode === ListModes.edit
          ? (evt) => {
              evt.stopPropagation();
              selectKeyEvent(props);
            }
          : null
      }
    >
      <CustomIcon name={eventType} options={options} />
    </span>
  );

  const datetime = Utilities.TZ.formatTS(
    ievt_ts,
    pageContext.timeZone,
    "HH:mm:ss.SSS"
  );
  const timestamp = <span className={EventStyles.timestamp}>{datetime}</span>;
  const rareLabel = (
    <span className={EventStyles[`rare-label-${ievt_rare_idx}`]}>
      {RARE_LEVEL[ievt_rare_idx]}
    </span>
  );

  const alert_rule_tags = ievt_tags?.map((tag) => {
    return (
      <span
        className={`${commonStyles.alertRuleTag} ${
          commonStyles[tag.tag_type]
        } ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouth}`}
      >
        {tag.tag_name}
        <div key={tag.tag_id} className={tooltipStyles.cssTooltipText}>
          {TAG_TYPE_LABELS[tag.tag_type]}
        </div>
      </span>
    );
  });

  const sevCSS =
    EventStyles[Utilities.Strings.normalizeSeverityNumber(ievt_sevno)];

  const etext = <span className={EventStyles.eventText}>{text}</span>;

  let logs, severity, columnLeft, columnMid, columnRight;

  const sev = Utilities.Strings.normalizeSeverityLabel(ievt_sev).toUpperCase();
  severity = (
    <span className={sevCSS}>
      {sev}
      {Array.from({ length: 5 - sev.length }).map((i) => {
        return <>&nbsp;</>;
      })}
    </span>
  );

  if (props.wrapEvents === false) {
    columnLeft = EventStyles.columnLeft;
    columnMid = `${EventStyles.columnMid} ${EventStyles.eventColumn}`;
    columnRight = `${EventStyles.columnRight} ${EventStyles.annotationColumn}`;
    logs = Utilities.Strings.middleTruncateString(ievt_log, 12);
    logs = (
      <span className={EventStyles.logs} title={ievt_log}>
        {logs}
        {Array.from({ length: 12 - logs.length }).map((i) => {
          return <>&nbsp;</>;
        })}
      </span>
    );
  } else {
    columnLeft = null;
    columnMid = `${EventStyles.eventColumn}`;
    columnRight = `${EventStyles.annotationColumn}`;
    logs = <span className={EventStyles.logs}>{ievt_log}</span>;
  }

  let metadata;
  let metadataIcon;
  if (metadataVisible) {
    let allMetadataTags = {
      ...ievt_meta_gen,
      ...ievt_meta_cfgs,
      ...ievt_meta_tags,
    };
    let metadataStyle = { width: props.width };
    if (props.metadataMaxHeight) {
      metadataStyle = {
        ...metadataStyle,
        maxHeight: props.metadataMaxHeight,
        overflowY: "scroll",
      };
    }

    const metadataTags = Object.keys(allMetadataTags).map((key, index) => {
      return (
        <div key={key} className={EventStyles.tag}>
          <span className={EventStyles.tagKey}>{key}</span>
          <span className={EventStyles.tagValue}>{allMetadataTags[key]}</span>
        </div>
      );
    });
    metadata = (
      <div className={EventStyles.allTags} style={metadataStyle}>
        {metadataTags}
      </div>
    );
    metadataIcon = faArrowUp;
  } else {
    metadata = null;
    metadataIcon = faArrowDown;
  }

  const metadataToggler = disableMetadata ? null : (
    <div
      className={EventStyles.metadataToggle}
      onClick={() => setMetadataVisible(!metadataVisible)}
      style={{ width: props.width }}
    >
      <div className={EventStyles.metadataToggleBtn}>
        <FontAwesomeIcon icon={metadataIcon} size={"lg"} />
      </div>
    </div>
  );

  const eventDataDisplay = (
    <div
      className={EventStyles.event}
      onContextMenu={
        disableEventMenu
          ? null
          : (evt) => {
              props.showEventMenu(evt, null, props);
            }
      }
    >
      {!props.hideEventMenu && (
        <div className={columnLeft}>{eventTypeIcon}</div>
      )}
      <div className={columnMid}>
        {timestamp}
        {severity}
        {logs}
        {rareLabel}
        {alert_rule_tags}
        {etext}
      </div>
      <div className={columnRight}>
        {!props.hideEventMenu && <EventAnnotationIcon {...props} />}
        {!disableEventMenu && <EventMenuActionsButton {...props} />}
      </div>
    </div>
  );

  let eventCSS =
    props.ievt_id === props.selectedRow
      ? `${EventStyles.eventRow} ${EventStyles.selected}`
      : `${EventStyles.eventRow}`;
  eventCSS = props?.alertKeyEvent
    ? `${eventCSS} ${EventStyles.alertKeyEvent}`
    : eventCSS;

  return (
    <div
      style={{ zIndex: props.zIndex }}
      className={eventCSS}
      id={`evt-${ievt_id}${props?.prefix || ""}`}
      onClick={() => {
        if (!props?.alertKeyEvent && selectEvent) {
          selectEvent(props);
        }
      }}
    >
      {eventDataDisplay}
      {metadata}
      {metadataToggler}
    </div>
  );
};

const RARE_LEVEL = {
  0: "",
  1: "Rare by Generator",
  2: "Rare Overall",
};

export function EventPeek(props) {
  const alertContext = useContext(ReportContext);
  const {
    getQueryState,
  } = alertContext;
  const [loading, setLoading] = useState(false);

  const enterPeekMode = (event) => {
    alertContext.peek(event.ievt_id, event.ievt_gen);
  };

  const exitPeekMode = (event) => {
    alertContext.unpeek();
  };
  let peekLabel = null,
    peekIcon = null,
    peekAction = null;

  const PEEK_ZOOM_LEVEL = 6;

  let query = getQueryState();
  const zoomLevel = parseInt(query.ievt_level || 2); //query.ievt_level can arrive as a number in string format

  if (zoomLevel === PEEK_ZOOM_LEVEL) {
    peekLabel = "Unpeek";
    peekIcon = faEyeSlash;
    peekAction = exitPeekMode;
  } else {
    peekLabel = "Peek";
    peekIcon = faEye;
    peekAction = enterPeekMode;
  }

  const loadingCss = loading
    ? { visibility: "visible" }
    : { visibility: "hidden" };
  return (
    <div
      className={EventStyles.eventMenuItem}
      onClick={(evt) => {
        evt.stopPropagation();
        return Promise.resolve()
          .then(() => setLoading(true))
          .then(() => peekAction(props.event))
          .then(() => props.hideEventMenu())
          .finally(() => setLoading(false));
      }}
    >
      <span className={EventStyles.eventMenuIcon}>
        <FontAwesomeIcon icon={peekIcon} fixedWidth={true} />
      </span>
      <span>&nbsp;{peekLabel}&nbsp;</span>
      <span style={loadingCss} className={EventStyles.eventMenuLoading}>
        <LoadingAnim />
      </span>
    </div>
  );
}

export function RelatedIncidents(props) {
  const pageContext = useContext(PageContext);
  const { openModalDialog } = useModalDialog();
  const dataService = useContext(DataServiceContext);
  const [loading, setLoading] = useState(false);

  const loadRelatedIncidents = () => {
    const payload = {
      ievt_event: props.event.ievt_event,
      ievt_id: props.event.ievt_id,
      ievt_etroot: props.event.ievt_etroot,
      inci_code: props.event.inci_code,
    };

    setLoading(true);

    return Promise.resolve().then(() =>
      dataService.fetch("incidentevent/read/otherincidents", payload)
    );
  };

  const viewRelatedIncidents = (
    inci_codes,
    targetInciCode,
    ievt_id,
    inci_id
  ) => {
    const { env } = pageContext;
    const user = dataService.getUserProfile();

    let link = `/root-cause/list-related-incidents?deployment_id=${user.deployment_id}&inci_code=${inci_codes}&current=${targetInciCode}&ievt_id=${ievt_id}&inci_id=${inci_id}`;

    link = Utilities.Router.createAsPath(link, env);

    window.open(link);
  };

  const showRelatedIncidentsDialog = () => {
    return Promise.resolve()
      .then(() => loadRelatedIncidents())
      .then((relatedIncidentsCall) => {
        let submitLabel,
          closeOnSubmit,
          dialogType,
          onSubmit,
          content,
          title,
          svc_grps,
          otherErrorMessage;
        const list = relatedIncidentsCall?.data?.[0]?.incidents;
        const numIncidents = list ? list.length : 0;
        const exceededLimit = numIncidents > INCIDENTS_LIMIT;

        if (relatedIncidentsCall.response.code > 200) {
          submitLabel = "Close";
          closeOnSubmit = true;
          dialogType = "warn";
          title = <span>Failed to load related incidents</span>;
          onSubmit = () => {};
          otherErrorMessage = relatedIncidentsCall.response.message;
        } else if (numIncidents > 0) {
          let inci_codes = list.map((item) => item.inci_code);
          inci_codes.push(props.event.inci_code);
          inci_codes = inci_codes.join(",");
          submitLabel = "View Related Incidents";
          closeOnSubmit = true;
          dialogType = "modal";
          title = (
            <span>
              <span className={EventStyles.titleCount}>
                {numIncidents.toLocaleString()}
              </span>{" "}
              other {Utilities.Strings.pluralize("incident", numIncidents)}{" "}
              found that include this event
            </span>
          );
          onSubmit = () =>
            viewRelatedIncidents(
              inci_codes,
              props.event.inci_code,
              props.event.ievt_id,
              props.event.inci_id
            );

          let svc_grp_hash = {};
          list.forEach((incident) => {
            incident.inci_svc_grps
              .split(",")
              .forEach(
                (name) =>
                  (svc_grp_hash[name] = svc_grp_hash[name]
                    ? svc_grp_hash[name] + 1
                    : 1)
              );
          });

          svc_grps = (
            <div className={EventStyles.svc_grp_list_container}>
              <div>
                <strong>Found in these service groups:&nbsp;</strong>
              </div>
              <div className={EventStyles.svc_grp_list}>
                {Object.keys(svc_grp_hash).map((name) => (
                  <span key={name}>
                    {name}({svc_grp_hash[name].toLocaleString()})
                  </span>
                ))}
              </div>
            </div>
          );
        } else {
          submitLabel = "Close";
          closeOnSubmit = true;
          dialogType = "warn";
          title = (
            <span>
              <span
                className={`${EventStyles.titleCount} ${EventStyles.disabled}`}
              >
                No
              </span>{" "}
              other incidents found that include this event
            </span>
          );
          onSubmit = () => {};
        }

        if (relatedIncidentsCall.response.code === 200) {
          content = (
            <>
              <div className={EventStyles.etext}>{props.event.ievt_etext}</div>
              <div>{svc_grps}</div>
            </>
          );
        }

        return {
          title: title,
          type: dialogType,
          icon: faChartNetwork,
          submitLabel: submitLabel,
          cancelLabel: "Close",
          closeOnSubmit: closeOnSubmit,
          minHeight: "20vh",
          maxHeight: "40vh",
          minWidth: "20vw",
          maxWidth: "50vw",
          submit: onSubmit,
          content: content,
          toolbarMessage: exceededLimit
            ? `Incidents exceeded ${INCIDENTS_LIMIT} limit.  Cannot show related incidents.`
            : null,
          canSubmit: !exceededLimit,
          otherErrorMessage: otherErrorMessage,
        };
      })
      .then((modalDialogSettings) => openModalDialog(modalDialogSettings))
      .then(() => setLoading(false))
      .then(() => props.hideEventMenu())
      .catch((e) => {
        setLoading(false);
      });
  };

  const loadingCss = loading
    ? { visibility: "visible" }
    : { visibility: "hidden" };
  return (
    <div
      className={EventStyles.eventMenuItem}
      onClick={showRelatedIncidentsDialog}
    >
      <span className={EventStyles.eventMenuIcon}>
        <FontAwesomeIcon icon={relatedAlertIcon} fixedWidth={true} />
      </span>
      <span>&nbsp;{"Related Incidents"}&nbsp;</span>
      <span style={loadingCss} className={EventStyles.eventMenuLoading}>
        <LoadingAnim />
      </span>
    </div>
  );
}

export function EventAnnotationIcon(props) {
  const [count, setCount] = useState(props.ievta_cnt);

  useEffect(() => {
    setCount(props.ievta_cnt);
  }, [props.ievta_cnt]);

  if (count === 0) {
    const icon = faCommentAltLines;
    const css = EventStyles.hasNoAnnotation;
    return (
      <div
        className={`${css} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouthWest}`}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  } else {
    const icon = faCommentAltLines;
    const css =
      count >= 1
        ? `${EventStyles.hasAnnotation} ${EventStyles.showCount}`
        : EventStyles.hasAnnotation;

    return (
      <div
        data-count={count}
        className={`${css} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouthWest}`}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  }
}

export function EventAnnotation(props) {
  const { openModalDialog, closeModalDialog } = useModalDialog();
  const alertContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);
  const canCreate = dataService.AccessManifest("canCreate", "event-annotation");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(props.event.ievta_cnt);

  useEffect(() => {
    setCount(props.event.ievta_cnt);
  }, [props.event.ievta_cnt]);

  const formRef = useRef();
  const fMessage = useRef();

  const closeAnnotationDialog = () => {
    alertContext.refreshEventList();
    closeModalDialog();
  };

  const addAnnotation = (keyCode) => {
    const values = formRef.current.getValues();

    const payload = {
      inci_code: props.event.inci_code,
      ievt_id: props.event.ievt_id,
      ...values,
    };

    return Promise.resolve()
      .then(() => dataService.fetch("eventannotation/create", payload))
      .then(() => {
        return fMessage.current.updateValue("");
      })
      .then(() => showAnnotationDialog());
  };

  const deleteAnnotation = (item) => {
    const payload = {
      ...item,
    };

    return Promise.resolve()
      .then(() => dataService.fetch("eventannotation/delete", payload))
      .then(() => showAnnotationDialog());
  };

  const loadAnnotations = () => {
    const canCreate = dataService.AccessManifest(
      "canCreate",
      "event-annotation"
    );

    const payload = {
      filter: [
        `inci_code=${props.event.inci_code}`,
        `ievt_id=${props.event.ievt_id}`,
      ],
      sort: ["ts"],
    };

    setLoading(true);

    return Promise.resolve()
      .then(() => dataService.fetch("eventannotation/read", payload))
      .then((resp) => {
        setLoading(false);

        if (resp.response.code === 200) {
          setCount(resp.data.length);
          const messages = resp.data;
          const dialogContent = (
            <>
              <div className={EventStyles.etext}>{props.event.text}</div>
              <EventAnnotationChatList
                messages={messages}
                deleteAnnotation={(item) => deleteAnnotation(item)}
              />
              {canCreate && (
                <Form ref={formRef} refs={[fMessage]}>
                  <Field
                    ref={fMessage}
                    name={"ievta_desc"}
                    type={"textarea"}
                    placeholder={"enter note"}
                  />
                </Form>
              )}
            </>
          );

          return dialogContent;
        } else {
          return <div>{resp.response.message}</div>;
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const showAnnotationDialog = () => {
    let submitLabel, closeOnSubmit, dialogType, onSubmit, cancelCallback;
    if (canCreate) {
      submitLabel = "Add Note";
      closeOnSubmit = false;
      dialogType = "modal";
      cancelCallback = closeAnnotationDialog;
      onSubmit = () => addAnnotation();
    } else {
      submitLabel = "Close";
      closeOnSubmit = true;
      dialogType = "warn";
      onSubmit = () => {};
    }

    return Promise.resolve()
      .then(() => loadAnnotations())
      .then((dialogContent) => {
        return {
          title: "Annotations",
          type: dialogType,
          icon: null,
          submitLabel: submitLabel,
          cancelLabel: "Close",
          cancelCallback: cancelCallback,
          closeOnSubmit: closeOnSubmit,

          maxHeight: "80vh",
          minWidth: "40vw",
          submit: onSubmit,
          content: dialogContent,
        };
      })
      .then((modalDialogSettings) => openModalDialog(modalDialogSettings))
      .then(() => props.hideEventMenu());
  };

  if (count === 0 && canCreate === false) {
    return null;
  } else if (loading === true) {
    return (
      <div className={EventStyles.eventMenuItem}>
        <span className={EventStyles.eventMenuIcon}>
          <FontAwesomeIcon icon={annotateIcon} fixedWidth={true} />
        </span>
        <span>Annotations</span>
        <span className={EventStyles.eventMenuLoading}>
          <LoadingAnim />
        </span>
      </div>
    );
  } else {
    return (
      <div className={EventStyles.eventMenuItem} onClick={showAnnotationDialog}>
        <span className={EventStyles.eventMenuIcon}>
          <FontAwesomeIcon icon={annotateIcon} fixedWidth={true} />
        </span>
        <span>Annotations</span>
        {count > 0 && <span className={EventStyles.badge}>{count}</span>}
      </div>
    );
  }
}

export function EventAnnotationChatList(props) {
  const pageContext = useContext(PageContext);
  const [messages, setMessages] = useState(props.messages);
  const messagesCount = useRef(0);
  const list = useRef();

  const dataService = useContext(DataServiceContext);
  const { name: currentUser } = dataService.getUserProfile();
  const canDeleteAny = dataService.AccessManifest(
    "canDelete",
    "event-annotation-delete-any"
  );

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages]);

  useEffect(() => {
    if (messagesCount.current !== messages.length) {
      list.current.scrollTop = list.current.scrollHeight;
    }

    messagesCount.current = messages.length;
  }, [messages]);

  const deleteAnnotation = (msg) => {
    const _messages = [...messages];

    const target = _messages.find((m) => m.id === msg.id);

    target.markedForDelete = true;

    setMessages(_messages);

    props.deleteAnnotation(msg);
  };

  return (
    <div className={EventStyles.annotationListChatStyle} ref={list}>
      {messages.map((m) => {
        const isMyMessage = m.modify_user_name === currentUser;
        const mineCSS = isMyMessage ? EventStyles.mine : null;
        const canDelete = canDeleteAny || isMyMessage;
        const isBeingDeleted =
          m.markedForDelete ||
          (m.markedForDelete && m.markedForDelete === true);
        const css =
          isBeingDeleted === true
            ? `${EventStyles.annotationListItem} ${EventStyles.markedForDelete} ${mineCSS}`
            : `${EventStyles.annotationListItem} ${mineCSS}`;
        return (
          <div className={css} key={m.id}>
            <div className={EventStyles.messageWrapper}>
              <pre className={EventStyles.message}>{m.ievta_desc}</pre>
              {canDelete && (
                <div className={EventStyles.deleteMsg}>
                  <div onClick={() => deleteAnnotation(m)}>
                    <FontAwesomeIcon icon={faTimesCircle} size={"lg"} />
                  </div>
                </div>
              )}
            </div>
            <div className={`${EventStyles.attribution} ${EventStyles.mine}`}>
              <span className={EventStyles.arrowDown}></span>
              <span className={EventStyles.username}>{m.modify_user_name}</span>
              &nbsp;
              <span className={EventStyles.timestamp}>
                {Utilities.TZ.formatTS(
                  m.ts,
                  pageContext.timeZone,
                  "YYYY-MM-DD HH:mm"
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const EventMenu = (props) => {
  const alertContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);

  const menuItemToFeatureMap = {
    peek: "alert-types",
    annotations: "event-annotation",
    "related-incidents": "alert-types",
    include: "alert-rules",
    exclude: "alert-rules",
    custom: "alert-rules",
    "event-type": "alert-rules",
    advanced: "alert-rules",
  };

  const itypeData = alertContext?.itypeData || {};
  const itypeState = itypeData?.itype_state || "";
  const menuItemToItypeStateMap = {
    peek: true,//itypeState !== "custom",
    annotations: ["accepted", "rejected"].includes(itypeState),
    "related-incidents": itypeState !== "custom",
    include: ["accepted", "suggested"].includes(itypeState),
    exclude: ["accepted", "suggested"].includes(itypeState),
    custom: ["accepted", "suggested"].includes(itypeState),
    "event-type": ["accepted", "suggested"].includes(itypeState),
    advanced: ["accepted", "suggested"].includes(itypeState),
  };

  //rights: canUpdate, canRead
  const canAccess = (feature, rights) => {
    return dataService.AccessManifest(rights, feature);
  };

  if (!props.currentEventMenu) {
    return null;
  } else {
    const baseProps = {
      event: props.currentEventMenu.event,
      hideEventMenu: props.hideEventMenu,
    };

    const menu = (
      <div
        className={EventStyles.eventMenuShield}
        onClick={props.hideEventMenu}
        onContextMenu={(evt) => {
          evt.preventDefault();
        }}
      >
        <div
          className={EventStyles.eventMenu}
          style={props.currentEventMenu.position}
          onClick={(evt) => evt.stopPropagation()}
        >
          {canAccess(menuItemToFeatureMap["peek"], "canRead") &&
            menuItemToItypeStateMap.peek && <EventPeek {...baseProps} />}

          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["annotations"], "canRead") &&
            menuItemToItypeStateMap.annotations && (
              <EventAnnotation {...baseProps} />
            )}
          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["related-incidents"], "canRead") &&
            menuItemToItypeStateMap["related-incidents"] && (
              <RelatedIncidents {...baseProps} />
            )}
          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["include"], "canUpdate") &&
            menuItemToItypeStateMap.include && (
              <DialogAlertRule
                {...baseProps}
                menuLabel={"Include this event type in future alerts"}
                icon={includeIcon}
                action={"ADD"}
                tagType={VIEW_TYPES.INTEREST}
                dialogDef={VIEW_DEFS[VIEW_TYPES.INTEREST]}
              ></DialogAlertRule>
            )}
          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["exclude"], "canUpdate") &&
            menuItemToItypeStateMap.exclude && (
              <DialogAlertRule
                {...baseProps}
                menuLabel={"Exclude this event type in future alerts"}
                icon={excludeIcon}
                action={"ADD"}
                tagType={VIEW_TYPES.EXCLUDE}
                dialogDef={VIEW_DEFS[VIEW_TYPES.EXCLUDE]}
              ></DialogAlertRule>
            )}
          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["event-type"], "canUpdate") &&
            menuItemToItypeStateMap["event-type"] && (
              <DialogAlertRule
                {...baseProps}
                menuLabel={"Create a custom alert rule using this event type"}
                icon={customAlertIcon}
                action={"ADD"}
                tagType={VIEW_TYPES.ET_ROOT}
                dialogDef={VIEW_DEFS[VIEW_TYPES.ET_ROOT]}
              ></DialogAlertRule>
            )}

          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["advanced"], "canUpdate") &&
            menuItemToItypeStateMap["advanced"] && (
              <div className={EventStyles.eventMenuItem} click={() => {}}>
                <div className={EventStyles.eventMenuItemDivider} />
              </div>
            )}

          {alertContext.listMode !== ListModes.edit &&
            canAccess(menuItemToFeatureMap["advanced"], "canUpdate") &&
            menuItemToItypeStateMap["advanced"] && (
              <div className={EventStyles.eventMenuItem} click={() => {}}>
                <span className={EventStyles.eventMenuIcon}>
                  <FontAwesomeIcon icon={null} fixedWidth={true} />
                </span>
                Advanced
                <span className={EventStyles.eventSubMenuIcon}>
                  <FontAwesomeIcon icon={submenuIcon} fixedWidth={true} />
                </span>
                <div className={EventStyles.eventSubMenu}>
                  <DialogAlertRule
                    {...baseProps}
                    menuLabel={"Custom Regex Alert"}
                    useEventType={false}
                    icon={customAlertIcon}
                    action={"ADD"}
                    tagType={VIEW_TYPES.USER_DRIVEN}
                    dialogDef={VIEW_DEFS[VIEW_TYPES.USER_DRIVEN]}
                  ></DialogAlertRule>

                  <DialogAlertRule
                    {...baseProps}
                    useEventType={false}
                    menuLabel={"Include Regex Rule"}
                    icon={includeIcon}
                    action={"ADD"}
                    tagType={VIEW_TYPES.INTEREST}
                    dialogDef={VIEW_DEFS[VIEW_TYPES.INTEREST]}
                  ></DialogAlertRule>

                  <DialogAlertRule
                    {...baseProps}
                    useEventType={false}
                    menuLabel={"Exclude Regex Rule"}
                    icon={excludeIcon}
                    action={"ADD"}
                    tagType={VIEW_TYPES.EXCLUDE}
                    dialogDef={VIEW_DEFS[VIEW_TYPES.EXCLUDE]}
                  ></DialogAlertRule>
                </div>
              </div>
            )}
        </div>
      </div>
    );

    return ReactDOM.createPortal(menu, document.getElementById("__next"));
  }
};

export const EventMenuActionsButton = (props) => {
  const eventMenuId = `event-menu-${props.ievt_id}`;
  return (
    <div id={eventMenuId} className={EventStyles.eventMenuIconWrapper}>
      <div className={EventStyles.eventMenuIcon}>
        <Button
          type={"secondary"}
          onClick={(evt) => props.showEventMenu(evt, eventMenuId, props)}
        >
          <FontAwesomeIcon icon={eventMenuIcon} />
          &nbsp; Actions
        </Button>
      </div>
    </div>
  );
};

import { reportHeaderCSS } from "./css";
import styled from "styled-components";
import { tooltipStyles, Utilities } from "athena-next-ui-lib";

export const StyledTitleDiv = styled("div")`
  margin: 0 20px 0 0;
  font-weight: bold;
  font-size: ${(props) => props.$size || "14px"};
  color: ${(props) => props.$color || "#ffffff"};
  word-break: break-word;
  cursor: default;

  .lightTheme & {
    color: rgb(0, 0, 0);
    filter: brightness(80%);
    color: rgb(0,0,0);
  }
`;

const TITLE_MAX_LEN = 150;

export const Title = (props) => {
  const title = Utilities.Strings.middleTruncateString(
    props?.title,
    TITLE_MAX_LEN
  );

  const isTitleTruncated = props?.title !== title;
  const state = props?.state || "suggested";
  const size = (title || "").length > 70 ? "13px" : "16px";

  const tooltip = {
    width: "500px",
    whiteSpace: "normal",
    wordBreak: "break-word",
  };
  return (
    <StyledTitleDiv
      className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouth}`}
      $color={reportHeaderCSS[state].title.color}
      $size={size}
    >
      {title}
      {isTitleTruncated && (
        <div className={`${tooltipStyles.cssTooltipText}`} style={tooltip}>
          {props?.title}
        </div>
      )}
    </StyledTitleDiv>
  );
};

import { SearchField } from "athena-next-ui-lib";
import React, { useContext } from "react";
import { ReportContext } from "/components-biz";

/*
 *  SearchField used on both report detail and list view, only report is relying on alert context
 *   this is a wrapper for detail view
 */
export const SearchFieldWrapper = (props) => {
  const reportContext = useContext(ReportContext);
  const { applySearchTerm } = reportContext;

  if (reportContext == null) return null;

  const qs = reportContext.getQueryState();

  return (
    <SearchField
      {...props}
      searchScope={qs.ievt_level.toString() || "2"}
      applySearchTerm={applySearchTerm}
    />
  );
};

import React, { useContext, useState, useEffect, useRef } from "react";
import Moment from "moment";
import {
  Content,
  Panel,
  Toolbar,
  Utilities,
  Tooltip,
  PageContext,
} from "athena-next-ui-lib";

import styles from "./PanelEventsGraph.module.scss";
import { ReportContext } from "/components-biz";

const logtypeLabelWidth = 100;
const nodeRadius = 4;
const nodeDiameter = 2 * nodeRadius;
const nodeOverlapDiameter = nodeDiameter * 1.2;
const rowHeight = 20;
const rowHeaderHeight = 20;
const maxNumLabelCharacters = 50;
const marginX = 20;
const marginY = 0;
const numHistogramIntervals = 30;
const histogramHeight = 50;
const scrollbarWidth = 18;

export const PanelEventsGraph = (props) => {
  const pageContext = useContext(PageContext);
  const alertContext = useContext(ReportContext);
  const { events, selectEvent, getQueryState } = alertContext;
  const { ievt_id } = getQueryState();

  const [graphData, setGraphData] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");

  const DOM_graph = useRef();
  const DOM_histogram = useRef();
  const count = graphData?.events?.length ? graphData?.events?.length : null;

  let tooltipTimer = null;
  let tooltipCloseTimer = null;

  useEffect(() => {
    if (events.response.code === 0) return;
    deriveGraph(events.data);
  }, [events.response.timestamp, pageContext?.timeZone]);

  useEffect(() => {
    if (showTooltip && tooltipPosition && tooltipContent) {
      tooltipCloseTimer = setTimeout(() => setShowTooltip(false), 10000);
    }
  }, [showTooltip, tooltipContent, tooltipPosition]);

  const deriveGraph = (data) => {
    if (data.length === 0) return;

    let events = [];

    const filteredEvents = [...data];

    filteredEvents.forEach((evt, _index) => {
      events.push({
        ...evt,
        x: 0,
        y: 0,
        len: 0,
      });
    });

    const width = props.width - marginX * 2;
    const graphWidth =
      width - logtypeLabelWidth - nodeDiameter * 3 - scrollbarWidth;
    const graphX = logtypeLabelWidth + nodeDiameter;

    let hosts = {};

    const start = filteredEvents[0].ievt_ts;
    const end = filteredEvents[filteredEvents.length - 1].ievt_ts;

    const mStart = Moment(start);
    const mEnd = Moment(end);
    let fullDiff = mEnd.diff(mStart, "ms");
    fullDiff = fullDiff === 0 ? 1 : fullDiff;

    let rowCount = 0;
    let y = rowHeight;

    let histogram = Array.from({ length: numHistogramIntervals }, () => 0);

    events.forEach((evt) => {
      const host = evt.ievt_source; 
      const logtype = evt.ievt_log;

      if (!hosts.hasOwnProperty(host)) {
        hosts[host] = {
          host: host,
          x: 0,
          y: y,
          width: width,
          height: rowHeaderHeight,
          logtypes: [],
        };

        y += rowHeaderHeight;
        rowCount++;
      }

      if (!hosts[host].logtypes.find((lt) => lt.logtype === logtype)) {
        hosts[host].logtypes.push({
          logtype: logtype,
          fullLabel: logtype,
          displayLabel: Utilities.Strings.middleTruncateString(
            logtype,
            maxNumLabelCharacters
          ),
          x: 0,
          y: y + rowHeight,
          //labelY: y,
          width: logtypeLabelWidth,
          height: rowHeight,
          axisXstart: graphX,
          axisXend: graphX + graphWidth,
          events: [],
        });
        y += rowHeight;
        rowCount++;
      }

      const hostAndLogtype = hosts[host].logtypes.find(
        (lt) => lt.logtype === logtype
      );
      const mNow = Moment(evt.ievt_ts);
      const diff = mNow.diff(mStart, "ms");
      evt.value = diff / fullDiff;
      evt.x = graphX + (diff / fullDiff) * graphWidth;
      evt.y = hostAndLogtype.y;
      evt.r = nodeRadius;
      evt.localTimestamp = evt.ievt_ts;
      evt.tooltip = (
        <span>
          <b>{Utilities.TZ.formatTS(mNow, pageContext.timeZone, "HH:mm:ss")}</b>
          &nbsp;{evt.ievt_log}&nbsp;{evt.ievt_sev}&nbsp;{evt.ievt_etext}
        </span>
      );

      evt.hour = Moment(evt.ievt_ts).format("HH");
      evt.minute = Moment(evt.ievt_ts).format("mm");
      evt.second = Moment(evt.ievt_ts).format("ss");
      evt.ms = Moment(evt.ievt_ts).format("SSS");

      let timeOfDay = [];
      timeOfDay.push(<span className={styles.timeBright}>{evt.hour}</span>);
      timeOfDay.push(<span className={styles.timeDim}>:</span>);
      timeOfDay.push(<span className={styles.timeBright}>{evt.minute}</span>);
      timeOfDay.push(<span className={styles.timeDim}>:</span>);
      timeOfDay.push(<span className={styles.timeBright}>{evt.second}</span>);
      timeOfDay.push(<span className={styles.timeDim}>.</span>);
      timeOfDay.push(<span className={styles.timeBright}>{evt.ms}</span>);

      evt.timeOfDay = timeOfDay;

      const interval = Math.round(
        ((mNow - mStart) / fullDiff) * (numHistogramIntervals - 1)
      );
      histogram[interval] = histogram[interval] + 1;

      hostAndLogtype.events.push(evt);
    });

    y += rowHeight;

    //disambiguate overlapping events
    let overlaps = {};
    const tolerance = graphWidth / (nodeDiameter * 4);
    events.forEach((event) => {
      const group =
        event.ievt_source +
        "-" +
        event.ievt_log +
        "-" +
        Math.round(event.value * tolerance);
      if (!overlaps[group]) {
        overlaps[group] = [];
      }

      overlaps[group].push(event);
    });

    Object.keys(overlaps).forEach((group) => {
      const g = overlaps[group];
      const offsetIndex = g.length > 1 ? -(g.length / 2) + 0.5 : 0;
      g.forEach((event, index) => {
        event.offsetY = (offsetIndex + index) * nodeOverlapDiameter;
      });
    });

    /* calculate y, varying logtypes row height based on number of overlaps
              1. establish required row height for each host/logtype pair
              2. walk host/logtype pairs, assigning y values
          */
    y = 0;
    Object.keys(hosts).forEach((hostName) => {
      hosts[hostName].y = y + hosts[hostName].height * 0.5;
      y += hosts[hostName].height + rowHeight;

      hosts[hostName].logtypes.forEach((logtype) => {
        const hostLogtypeKey = hostName + "-" + logtype.logtype;

        const allOverlappedEventsWithKey = Object.keys(overlaps).filter(
          (key) => key.indexOf(hostLogtypeKey) === 0
        );

        const maxOverlappedEvents = Math.max(
          ...allOverlappedEventsWithKey.map((key) => overlaps[key].length)
        );

        logtype.height = rowHeight + maxOverlappedEvents * nodeOverlapDiameter;
        logtype.labelY = y;
        logtype.y = y + logtype.height * 0.5;
        y += logtype.height;

        logtype.events.forEach((event) => {
          event.y = logtype.y + event.offsetY;
        });
      });
    });

    const graphHeight = y + rowHeight;

    const labelStart = Moment(start).format("hh:mm:SS");
    const labelEnd = Moment(end).format("hh:mm:SS");

    const axes = {
      start: {
        label: labelStart,
        x1: graphX,
        x2: graphX,
        y1: rowHeight,
        y2: graphHeight,
        labelY: rowHeight,
      },
      end: {
        label: labelEnd,
        x1: graphX + graphWidth,
        x2: graphX + graphWidth,
        y1: rowHeight,
        y2: graphHeight,
        labelY: graphHeight - rowHeight,
      },
    };

    const cb = getGraphBounds();

    const max = Math.max(...histogram);
    const h = histogramHeight;
    const graphW =
      props.width -
      logtypeLabelWidth -
      marginX * 2 -
      nodeDiameter * 2 -
      scrollbarWidth;
    const barW = graphW / numHistogramIntervals;
    const barH = h - 12;
    histogram = histogram.map((item, index) => {
      const percent = item / max;
      const x = barW * index + 1;
      const y = barH - barH * percent;
      const width = barW - 2;
      const height = barH * percent;

      return {
        count: item,
        percent: percent,
        x: x,
        y: y,
        width: width,
        height: height,
      };
    });

    setGraphData({
      events: events,
      histogram: histogram,
      mStart: mStart,
      mEnd: mEnd,
      graphHeight: graphHeight,
      hosts: hosts,
      hostsAndLogsCount: rowCount,
      axes: axes,
      graphBounds: cb,
    });
  };

  const getGraphBounds = () => {
    const graph = DOM_graph.current;
    let scrollLeft = 0;
    let scrollTop = 0;
    let graphBounds = { left: 0, top: 0 };

    if (graph) {
      graphBounds = graph.getBoundingClientRect();
      scrollLeft = graph.scrollLeft;
      scrollTop = graph.scrollTop;
    }
    return {
      x: graphBounds.left,
      y: graphBounds.top,
      width: graphBounds.width,
      height: graphBounds.height,
      scrollX: scrollLeft,
      scrollY: scrollTop,
    };
  };

  const onMouseMove = (evt) => {
    const { pageX, pageY } = evt;

    clearTimeout(tooltipTimer);

    tooltipTimer = setTimeout(() => _onMouseMove(pageX, pageY), 100);
  };

  const _onMouseMove = (pageX, pageY) => {
    clearTimeout(tooltipCloseTimer);
    const bounds = getGraphBounds();
    const x = pageX - bounds.x + bounds.scrollX - marginX - nodeRadius;
    const y =
      pageY -
      bounds.y +
      bounds.scrollY -
      marginY -
      histogramHeight -
      nodeRadius * 2;

    const match = graphData?.events?.find((event) => {
      return (
        event.x >= x - event.r &&
        event.x <= x + event.r &&
        event.y >= y - event.r &&
        event.y <= y + event.r
      );
    });
    if (match) {
      const ttTop =  pageY + 4 ;
      const ttLeft = (bounds.width-x)>bounds.width/2 ? pageX + 4 : bounds.x + 80; //to prevent events count from wrapping

      setShowTooltip(true);
      setTooltipPosition({ top: ttTop + "px", left: ttLeft + "px" });
      setTooltipContent(match.tooltip);
    } else {
      setShowTooltip(false);
    }
  };

  const onMouseMoveHisto = (evt) => {
    const { pageX, pageY } = evt;

    clearTimeout(tooltipTimer);

    tooltipTimer = setTimeout(() => _onMouseMoveHistogram(pageX, pageY), 100);
  };
  const _onMouseMoveHistogram = (pageX, pageY) => {
    clearTimeout(tooltipCloseTimer);
    let bounds = { left: 0, top: 0 };

    const graph = DOM_histogram.current;
    if (graph) {
      bounds = graph.getBoundingClientRect();
    }
    const x = pageX - bounds.x - marginX - logtypeLabelWidth - nodeDiameter;
    const y = pageY - bounds.y - marginY;

    const match = graphData?.histogram?.find((bar) => {
      return (
        bar.count > 0 &&
        x >= bar.x &&
        x <= bar.x + bar.width &&
        y >= bar.y &&
        y <= bar.y + bar.height
      );
    });
    if (match) {
      const ttLeft = (bounds.width-x)>bounds.width/2 ? pageX + 4 : pageX - 20;  
      setShowTooltip(true);
      setTooltipPosition({ top: pageY + 4 + "px", left: ttLeft + "px" });
      setTooltipContent(
        match.count + Utilities.Strings.pluralize(" Event", match.count)
      );
    } else {
      setShowTooltip(false);
    }
  };

  const drawTooltip = () => {
    //reusable tooltip

    return (
      <Tooltip
        cssClass={styles.tooltip}
        position={tooltipPosition}
        marginH={1}
        marginW={1}
        visible={showTooltip}
        content={tooltipContent}
      />
    );
  };

  const drawHistogram = () => {
    if (!graphData) return;
    const { mStart, mEnd, histogram } = graphData;

    if (!mStart || !mEnd) return null;

    const h = histogramHeight;
    const graphW =
      props.width -
      logtypeLabelWidth -
      marginX * 2 -
      nodeDiameter * 2 -
      scrollbarWidth;
    const barH = h - 12;

    return (
      <div ref={DOM_histogram}>
        <svg
          className={styles.histogram}
          width={props.width - scrollbarWidth}
          height={histogramHeight}
          onMouseMove={onMouseMoveHisto}
        >
          <g transform={`translate(${marginX} ${marginY})`}>
            <g transform={`translate(${logtypeLabelWidth + nodeDiameter})`}>
              {histogram.map((bar, index) => (
                <rect
                  key={index}
                  className={styles.histogramBar}
                  x={bar.x}
                  y={bar.y}
                  width={bar.width}
                  height={bar.height}
                ></rect>
              ))}

              <line
                className={styles.histogramAxis}
                x1={0}
                x2={graphW}
                y1={barH}
                y2={38}
              />
              <text className={styles.histogramLabelStart} x={0} y={h}>
                {Utilities.TZ.formatTS(
                  mStart,
                  pageContext.timeZone,
                  "HH:mm:ss"
                )}
              </text>
              <text className={styles.histogramLabelEnd} x={graphW} y={h}>
                {Utilities.TZ.formatTS(mEnd, pageContext.timeZone, "HH:mm:ss")}
              </text>
            </g>
          </g>
        </svg>
      </div>
    );
  };

  const drawGraph = () => {
    if (!graphData) return;
    const { events, graphHeight, axes, hosts } = graphData;

    if (!events || events.length === 0) {
      return null;
    }

    let highlighted = null;
    const graph = Object.keys(graphData.hosts).map((hostName, index1) => {
      const host = hosts[hostName];
      const hostLabel = Utilities.Strings.middleTruncateString(host.host, 75);
      const logtypes = host.logtypes;
      return (
        <g key={`h-${host.host}`}>
          <foreignObject
            x={host.x}
            y={host.y}
            width={host.width}
            height={host.height}
          >
            <div className={styles.eventGraphMajorLabel} title={host.host}>
              {hostLabel}
            </div>
          </foreignObject>
          <g>
            {logtypes.map((logtype, index2) => {
              const logtypeLabel = Utilities.Strings.middleTruncateString(
                logtype.displayLabel,
                50
              );
              return (
                <g key={`lt-${logtype.logtype}`} className={styles.eventGraphRow}>
                  <foreignObject
                    x={logtype.x}
                    y={logtype.labelY}
                    width={logtype.width}
                    height={logtype.height}
                  >
                    <div
                      className={styles.eventGraphMinorLabel}
                      title={logtype.fullLabel}
                    >
                      {logtypeLabel}
                    </div>
                  </foreignObject>
                  <g className={styles.eventTimeline}>
                    <line
                      className={styles.eventTimelineAxis}
                      x1={logtype.axisXstart}
                      x2={logtype.axisXend}
                      y1={logtype.y}
                      y2={logtype.y}
                    />
                    {logtype.events.map((event, index3) => {
                      let eventCSS = `${styles.eventTimelineNode} `;

                      eventCSS +=
                        event.ievt_level === 0 ? ` ${styles.key} ` : ``;
                      eventCSS +=
                        event.ievt_level === 1 ? ` ${styles.hallmark} ` : ``;
                      eventCSS += ` ${
                        styles[
                          Utilities.Strings.normalizeSeverityNumber(
                            event.ievt_sevno
                          )
                        ]
                      } `;

                      let c1 = (
                        <circle
                          key={`cir-${event.ievt_id}`}
                          id={`evt-dot-${event.ievt_id}`}
                          className={eventCSS}
                          r={event.r}
                          cx={event.x}
                          cy={event.y}
                          onClick={() =>
                            selectEvent(event, {
                              scrollToEvent: true,
                              blink: true,
                            })
                          }
                        />
                      );

                      if (ievt_id === event.ievt_id) {
                        c1 = (
                          <g  key={`cir-${event.ievt_id}`}>
                            <circle
                              id={`evt-dot-${event.ievt_id}`}
                              fill={"black"}
                              stroke={"white"}
                              strokeWidth={2}
                              strokeDashArray={0}
                              r={event.r * 2}
                              cx={event.x}
                              cy={event.y}
                            >
                              <animate
                                attributeName="r"
                                values={`${0};${event.r + 4};0`}
                                dur="2s"
                                repeatCount="indefinite"
                              />
                              <animate
                                attributeName="strokeDashArray"
                                values={`0;3;0`}
                                dur="2s"
                                repeatCount="indefinite"
                              />
                              <animate
                                attributeName="stroke"
                                values={`#fff;#fff;#fff`}
                                dur="2s"
                                repeatCount="indefinite"
                              />
                            </circle>
                            {c1}
                          </g>
                        );
                      }

                      // let c1 = null;
                      // const selected = this.props.ievt_id === event.ievt_id;
                      //
                      //
                      // if( event.ievt_level === 0 ){
                      //     c1 = <CustomIcon name={"key"} options={{size:12, rotate:30, animate:selected}}/>
                      // } else if( event.ievt_level === 1 ){
                      //     c1 = <CustomIcon name={"hand"} options={{size:12, rotate:30, animate:selected}}/>
                      // } else {
                      //     c1 = <CustomIcon name={"dot"} options={{size:12, rotate:30, animate:selected}}/>
                      //
                      // }

                      //return <g transform={`translate(${event.x} ${event.y})`}>{c1}</g>;

                      return c1;
                    })}
                  </g>
                </g>
              );
            })}
          </g>
          {highlighted}
        </g>
      );
    });

    let startAxis = null,
      endAxis = null;

    if (axes) {
      startAxis = (
        <g>
          <line
            className={styles.eventTimelineAxisY}
            x1={axes.start.x1}
            y1={axes.start.y1}
            x2={axes.start.x2}
            y2={axes.start.y2}
          />
        </g>
      );
      endAxis = (
        <g>
          <line
            className={styles.eventTimelineAxisY}
            x1={axes.end.x1}
            y1={axes.end.y1}
            x2={axes.end.x2}
            y2={axes.end.y2}
          />
        </g>
      );
    }

    return (
      <div className={styles.eventGraph}>
        <svg
          width={props.width - scrollbarWidth}
          height={graphHeight}
          onMouseMove={onMouseMove}
        >
          <g transform={`translate(${marginX + nodeRadius} ${marginY})`}>
            {startAxis}
            {endAxis}
            {graph}
          </g>
        </svg>
      </div>
    );
  };

  return (
    <Panel width={props.width} flex={"1 0 auto"}>
      <Toolbar
        {...props}
        response={events.response}
        title={"Event"}
        count={count}
      />
      <Content
        {...props}
        response={events.response}
        maxHeight={"50vh"}
        width={props.width - scrollbarWidth}
      >
        <div ref={DOM_graph}>
          {drawHistogram()}
          {drawGraph()}
        </div>
      </Content>
      {drawTooltip()}
    </Panel>
  );
};
PanelEventsGraph.defaultProps = {
  ievt_id: null,
};

import React from "react";
import { MakeSymbol } from "/components-biz/incidents";
import styles from "./IncidentList.module.scss";
import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";

export const IncidentTypeBanner = (props) => {
  const { alert, hideSymbol } = props;

  const alertSymbol = alert.matched ? "matched" : alert.itype_state;

  let bannerLabel;
  switch (alert.itype_state) {
    case "custom":
      bannerLabel = "Custom Alert";
      break;
    case "accepted":
      bannerLabel = "Accepted Alert";
      break;
    case "suggested":
      bannerLabel = "Suggestion";
      break;
    case "rejected":
      bannerLabel = "Rejected Alert";
      break;
  }

  return (
    <div className={styles.bannerWrapper}>
      {!hideSymbol && (
        <svg width={16} height={16}>
          <MakeSymbol symbolName={alertSymbol} cX={8} cY={8} r={14} />
        </svg>
      )}
      <div className={`${styles[alert.itype_state]} ${styles.banner}`}>
        {bannerLabel}
      </div>
    </div>
  );
};

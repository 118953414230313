const MODIFIERS = "gi"; //always use gi as modifiers

const getRegExpFromStringInput = (stringInput) => {
  let rgx;

  if (stringInput?.trim().startsWith("/")) {
    const startIndx = stringInput.indexOf("/");
    const endIndx = stringInput.lastIndexOf("/");

    if (endIndx < 0 || startIndx === endIndx) return null;

    //regex expression detected
    const pattern = stringInput.substring(startIndx + 1, endIndx);
    //extracting modifiers:  stringInput.substring(endIndx+1)

    try {
      rgx = new RegExp(pattern, MODIFIERS);
    } catch (e) {
      rgx = null;
    }
  } else {
    //plain string detected
    const escapedString = stringInput.replace(/([\.\^\$\(\)])/gi, "\\$1"); //escape regex special characters
    rgx = new RegExp(escapedString, MODIFIERS);
  }

  return rgx;
};

export const EventUtils = {
  getRegExpFromStringInput,
  debounce: (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  },

  applyEtextRegexTextHighlights: (etext, findTerm) => {
    let findIndex = 0;
    let allMatches = [];

    const findTermRe = getRegExpFromStringInput(findTerm);
    let matchIndex = 0;
    if (findTermRe !== null && findTerm && findTerm.length) {
      const findMatches = etext.matchAll(findTermRe);

      for (const match of findMatches) {
        if (matchIndex++ === 0) findIndex += 1;

        const m1 = {
          start: match.index,
          end: match.index + match[0].length,
          text: match[0],
          type: "find",
        };

        const overlapped =
          allMatches.findIndex(
            (m) =>
              (m1.start >= m.start && m1.start <= m.end) ||
              (m1.end >= m.start && m1.end <= m.end)
          ) > -1;

        if (!overlapped) {
          allMatches.push(m1);
        }
      }
    }

    if (allMatches.length) {
      let text = String(etext);
      let htmlText = [];
      let index = 0;

      allMatches.sort((a, b) => (a.start > b.start ? 1 : -1));

      allMatches.forEach((m, matchIndex) => {
        const id = index === 0 ? `find-${findIndex}` : null;
        const taggedText =
          m.type === "find" ? (
            <u key={matchIndex} id={id}>
              {m.text}
            </u>
          ) : (
            <b key={matchIndex}>{m.text}</b>
          );
        htmlText.push(text.substring(index, m.start));
        htmlText.push(taggedText);
        index = m.end;
      });

      htmlText.push(text.substr(index, text.length));

      return <span>{htmlText}</span>;
    } else {
      return <span>{etext}</span>;
    }
  },
};

import { useContext, useEffect, useState } from "react";
import { ReportContext } from "/components-biz";
import styled, { css } from "styled-components";
import { tooltipStyles } from "athena-next-ui-lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowSquareLeft,
  faArrowSquareRight,
} from "@fortawesome/pro-solid-svg-icons";

const StyledSplitterDiv = styled("div")`
  position: relative;
  z-index: 2001;
  display: flex;
  align-self: stretch;
  align-items: center;
  cursor: pointer;
  margin: 0 1px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  ${(props) => {
    if (props.$state === "expanded") {
      return css`
        border-left: 2px solid rgba(255, 255, 255, 0.1);
      `;
    } else {
      return css`
        border-right: 2px solid rgba(255, 255, 255, 0.1);
      `;
    }
  }}

  ${(props) => {
    if (props.$state === "collapsed") {
      return css`
        margin-left: -30px;
      `;
    }
  }}
  &:hover {
    color: steelblue;

    ${(props) => {
      if (props.$state === "expanded") {
        return css`
          border-left: 2px solid steelblue;
        `;
      } else {
        return css`
          border-right: 2px solid steelblue;
        `;
      }
    }}
  }

  .lightTheme & {
    color: rgba(1, 1, 1, 0.8);

    ${(props) => {
      if (props.$state === "expanded") {
        return css`
          border-left: 2px solid rgba(0, 0, 0, 0.1);
          border-right: 2px solid transparent;
        `;
      } else {
        return css`
          border-right: 2px solid rgba(0, 0, 0, 0.2);
          border-left: 2px solid transparent;
        `;
      }
    }}
  }
`;

export const SplitterBar = () => {
  const reportContext = useContext(ReportContext);
  const { toggleSidePane, isSidePaneCollapsed } = reportContext;
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [panelState, setPanelState] = useState(null);

  useEffect(() => {
    const isCollapsed = isSidePaneCollapsed();
    setIsPanelCollapsed(isCollapsed);
  }, []);

  useEffect(() => {
    if (isPanelCollapsed) {
      setPanelState("collapsed");
    } else {
      setPanelState("expanded");
    }
  }, [isPanelCollapsed]);

  const togglePanel = () => {
    toggleSidePane();
    setIsPanelCollapsed(!isPanelCollapsed);
  };

  return (
    <StyledSplitterDiv onClick={togglePanel} $state={panelState}>
      <span>
        {isSidePaneCollapsed() ? (
          <div
            className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipWest}`}
          >
            <FontAwesomeIcon icon={faArrowSquareLeft} />
            <div className={`${tooltipStyles.cssTooltipText}`}>
              Expand Side Panel
            </div>
          </div>
        ) : (
          <div
            className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipWest}`}
          >
            <FontAwesomeIcon icon={faArrowSquareRight} />
            <div className={`${tooltipStyles.cssTooltipText}`}>
              Collapse Side Panel
            </div>
          </div>
        )}
      </span>
    </StyledSplitterDiv>
  );
};

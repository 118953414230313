import React, { useMemo, useRef, useState, useEffect, useContext } from "react";
import { Utilities, PageContext } from "athena-next-ui-lib";
import { FindBar } from "/components-ui";
import EventStyles from "/components-ui/list/events/EventList.module.scss";
import { ReportContext } from "/components-biz";
import { EventUtils } from "/_utilities";
import { EventMenu, Event } from "./Event";

let findIndex = 0;
export function EventList(props) {
  const pageContext = useContext(PageContext);
  const reportContext = useContext(ReportContext);
  const {
    events,
    itypeData,
    listMode,
    findTerm,
    setFindTerm,
    applyFindTerm,
    searchTerm,
    getQueryState,
    selectEvent,
    alertKeys,
  } = reportContext;
  const { data, response } = events;
  const [currentEventMenu, setCurrentEventMenu] = useState(null);
  const eventMenuRef = useRef();

  const [findMatchCount, setFindMatchCount] = useState(0);

  const { ievt_id: selectedRow } = getQueryState();

  const scrollFindMatchIntoView = Utilities.JS.debounce(() => {
    const element = document.getElementById("find-0");
    if (element) element.scrollIntoView(true);
  }, 50);

  useEffect(() => {
    if (findTerm?.length > 0) scrollFindMatchIntoView();
  }, [data, findTerm]);

  const showEventMenu = (evt, eventMenuIconId, event) => {
    let x, y;

    const menu_bbox = eventMenuRef?.current?.getBoundingClientRect();
    const eventMenuIcon = document.getElementById(eventMenuIconId);
    const maxMenuWidth = 350;
    if (eventMenuIcon) {
      const bbox = eventMenuIcon.getBoundingClientRect();
      x = bbox.left - (menu_bbox?.width || 200);
      y = bbox.top + bbox.height;
    } else {
      x = evt.clientX - (menu_bbox?.width || 125);
      y = evt.clientY;
    }

    x = Math.min(window.innerWidth - maxMenuWidth - 20, x);

    setCurrentEventMenu({ position: { top: y, left: x }, event: event });

    evt.stopPropagation();
    evt.preventDefault();
  };

  const hideEventMenu = () => {
    setCurrentEventMenu(null);
  };

  const list = useMemo(() => {
    findIndex = 0;

    const applyHighlights = searchTerm?.length > 0 || findTerm?.length > 0;
    let list = null;

    if (!response || response?.code !== 200) {
      return null;
    } else if (props.groupByDay) {
      let rowGroups = {};

      findIndex = 0;
      (data || []).forEach((item, index) => {
        const ts = Utilities.TZ.getFixedTimeStampByTimeZone(
          item.inci_ts,
          pageContext.timeZone
        ); //Moment(item.inci_ts);
        item.month = ts.format("MMM");
        item.day = ts.format("D");
        item.key = ts
          .set({ hour: 0, minute: 0, second: 0, millisecond: 1 })
          .unix();

        if (!rowGroups.hasOwnProperty(item.key)) {
          rowGroups[item.key] = { month: item.month, day: item.day, rows: [] };
        }

        item.text = applyHighlights
          ? applySearchFindHighlights(item.ievt_etext, searchTerm, findTerm)
          : item.ievt_etext;

        rowGroups[item.key].rows.push(item);
      });

      list = Object.keys(rowGroups)
        //.reverse()
        .map((key, index) => {
          const group = rowGroups[key];

          return (
            <div key={"rg-" + index} className={EventStyles.rowGroup}>
              <div className={EventStyles.dateTime}>
                <div className={EventStyles.date}>
                  {group.month}&nbsp;{group.day}
                </div>
              </div>
              <div className={EventStyles.rowGroupRows}>
                {group.rows.map((item, index) => {
                  return (
                    <Event
                      {...item}
                      key={"r-" + index}
                      zIndex={group?.rows?.length - index}
                      alertRuleOnlyMatch={itypeData?.inci_itype === "custom"}
                      wrapEvents={props.wrapEvents}
                      width={props.width}
                      // mode={listMode}
                      // enterPeekMode={props.enterPeekMode}
                      // exitPeekMode={props.exitPeekMode}
                      searchTerm={searchTerm}
                      selectedRow={selectedRow}
                      showEventMenu={showEventMenu}
                      // selectEvent={selectEvent}
                      // selectKey={props.selectKey}
                    />
                  );
                })}
              </div>
            </div>
          );
        });
    } else {
      findIndex = 0;
      list = (data || []).map((item, index) => {
        item.text = applyHighlights
          ? applySearchFindHighlights(item.ievt_etext, searchTerm, findTerm)
          : item.ievt_etext;

        return (
          <Event
            {...item}
            key={"r-" + index}
            alertRuleOnlyMatch={itypeData?.inci_itype}
            wrapEvents={props.wrapEvents}
            width={props.width}
            // mode={listMode}
            // enterPeekMode={props.enterPeekMode}
            // exitPeekMode={props.exitPeekMode}
            searchTerm={searchTerm}
            selectedRow={selectedRow}
            showEventMenu={showEventMenu}
            // selectKey={props.selectKey}
            selectEvent={selectEvent}
          />
        );
      });
    }

    setFindMatchCount(findIndex);

    return list;
  }, [
    data,
    props.groupByDay,
    selectedRow,
    searchTerm,
    findTerm,
    listMode,
    props.wrapEvents,
    itypeData?.inci_itype === "custom",
    alertKeys,
  ]);

  let eventListCSS = props.groupByDay
    ? `${EventStyles.eventList}`
    : `${EventStyles.eventList} ${EventStyles.notGroupedByDay}`;
  eventListCSS =
    props.wrapEvents === false
      ? `${eventListCSS} ${EventStyles.nowrap}`
      : `${eventListCSS} ${EventStyles.wrap}`;

  return (
    <>
      <div id={props.listId} className={EventStyles.scrollContainer}>
        <div className={eventListCSS} style={{ width: props.width }}>
          {list}
        </div>
      </div>

      <EventMenu
        ref={eventMenuRef}
        hideEventMenu={hideEventMenu}
        currentEventMenu={currentEventMenu}
      />

      <FindBar
        response={response}
        data={data}
        mode={listMode}
        findMatchCount={findMatchCount}
        listId={props.listId}
        scrollContainer={props.scrollContainer}
        objectName={"Event"}
        findTerm={findTerm}
        setFindTerm={setFindTerm}
        applyFindTerm={applyFindTerm}
        displayFindBar={true}
        // filterSummary={props.filterSummary}
      />
    </>
  );
}

//todo: promote this function to a common area so that event-list and incident-list can both reference it
export function applySearchFindHighlights(etext, searchTerm, findTerm) {
  let allMatches = [];

  const searchTermRe = EventUtils.getRegExpFromStringInput(searchTerm);
  const findTermRe = EventUtils.getRegExpFromStringInput(findTerm);

  if (searchTerm && searchTerm.length) {
    const searchMatches = etext.matchAll(searchTermRe);

    for (const match of searchMatches) {
      allMatches.push({
        start: match.index,
        end: match.index + match[0].length,
        text: match[0],
        type: "search",
      });
    }
  }

  let matchIndex = 0;
  if (findTerm && findTerm.length) {
    const findMatches = etext.matchAll(findTermRe);

    if (findMatches.length) matchIndex = 0;
    for (const match of findMatches) {
      if (matchIndex++ === 0) findIndex += 1;

      const m1 = {
        start: match.index,
        end: match.index + match[0].length,
        text: match[0],
        type: "find",
      };

      const overlapped =
        allMatches.findIndex(
          (m) =>
            (m1.start >= m.start && m1.start <= m.end) ||
            (m1.end >= m.start && m1.end <= m.end)
        ) > -1;

      if (!overlapped) {
        allMatches.push(m1);
      }
    }
  }

  if (allMatches.length) {
    let text = String(etext);
    let htmlText = [];
    let index = 0;

    allMatches.sort((a, b) => (a.start > b.start ? 1 : -1));

    allMatches.forEach((m, matchIndex) => {
      const id = index === 0 ? `find-${findIndex}` : null;
      const taggedText =
        m.type === "find" ? (
          <u key={matchIndex} id={id}>
            {m.text}
          </u>
        ) : (
          <b key={matchIndex}>{m.text}</b>
        );
      htmlText.push(text.substring(index, m.start));
      htmlText.push(taggedText);
      index = m.end;
    });

    htmlText.push(text.substr(index, text.length));

    return <span>{htmlText}</span>;
  } else {
    return <span>{etext}</span>;
  }
}

import styled from "styled-components";

const ConfidenceDiv = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 2px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 11px;
  .value {
    margin-left: 4px;
    font-weight: bold;
    color: ${(props) => (props.confidence === "Y" ? "#fa9e9e" : "#9bc2e7")};
  }

  .lightTheme & {
    color: #000;
  }
`;

export const AIConfidence = (props) => {
  const { confidence } = props;

  if (!confidence || confidence === "") return null;

  let label;
  if (confidence === "Y") {
    label = "Your Attention Needed";
  } else {
    label = "No Attention Needed";
  }

  return (
    <ConfidenceDiv confidence={confidence}>
      <span>AI Assessment:</span>
      <span className={"value"}>{label}</span>
    </ConfidenceDiv>
  );
};

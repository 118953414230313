import React, { useMemo } from "react";
import styled from "styled-components";
import { Utilities } from "athena-next-ui-lib";
import { StyledScrollableDiv } from "./css";

const StyledFieldsDiv = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  div {
    display: inline-block;
    gap: 8px;
    flex-wrap: wrap;
    white-space: normal;
    label {
      font-weight: bold;
      color: rgba(255, 255, 255, 0.4);
      margin-right: 4px;
    }
    span {
      a {
        color: #8ecdff;
      }
    }
  }

  .lightTheme & {
    div {
      label {
        color: rgba(0, 0, 0, 0.3);
      }
      span {
        a {
          color: rgb(21, 101, 192);
        }
      }
    }
  }
`;

export const AlertSummary = (props) => {
  const { valuesMap } = props;

  const getBasicField = (fld) => {
    const displayValue = valuesMap[fld.property];
    return (
      <div>
        <label>{fld.label}</label>
        <span>{displayValue}</span>
      </div>
    );
  };

  const simpleField = (fld) => {
    return getBasicField(fld);
  };

  const paragraphField = (fld) => {
    return getBasicField(fld);
  };

  const urlField = (fld) => {
    const value = valuesMap[fld.property];
    const displayValue = Utilities.Strings.middleTruncateString(value, 60);

    return (
      <div>
        <label>{fld.label}</label>
        <span>
          <a href={value} target={"_blank"}>
            {displayValue}
          </a>
        </span>
      </div>
    );
  };

  const lookupField = (fld) => {
    const rawValue = valuesMap[fld.property];
    const lookup = fld.lookup.find((item) => item.value === rawValue);
    const displayValue = lookup?.label;

    if (!displayValue) return null;

    return (
      <div>
        <label>{fld.label}</label>
        <span>{displayValue}</span>
      </div>
    );
  };

  const listField = (fld) => {
    const displayValue = valuesMap[fld.property].join(", ");

    return (
      <div>
        <label>{fld.label}</label>
        <span>{displayValue}</span>
      </div>
    );
  };

  const fields = useMemo(() => {
    if (!valuesMap) return null;

    const fields = props?.fields.map((fld) => {
      //don't display this field if it or its required field is not defined
      if (
        !valuesMap.hasOwnProperty(fld.property) ||
        valuesMap[fld.property].length === 0 ||
        (fld.requires && valuesMap[fld.requires].length === 0)
      ) {
        return null;
      }

      switch (fld.type) {
        case "paragraph":
          return paragraphField(fld);
        case "url":
          return urlField(fld);
        case "list":
          return listField(fld);
        case "lookup":
          return lookupField(fld);
        case "string":
        default:
          return simpleField(fld);
      }
    });

    if (fields.filter((item) => item !== null).length === 0) {
      return (
        <div>
          <h3>This alert is missing important information</h3>
          <p>
            Visit the 'Actions' menu to update alert rule info and set
            notifications.
          </p>
        </div>
      );
    }

    return fields;
  }, [props.valuesMap, props.fields]);

  return (
    <StyledScrollableDiv $height="75px">
      <StyledFieldsDiv>{fields}</StyledFieldsDiv>
    </StyledScrollableDiv>
  );
};

import React from "react";
import Head from "next/head";
import getConfig from "next/config";

export class CustomFonts extends React.PureComponent {
  render() {
    const { publicRuntimeConfig } = getConfig();
    const prefix = publicRuntimeConfig.INGRESS_PREFIX;

    return (
      <Head>
        <link rel="shortcut icon" href={`../images/favicon.png`} />
        <style>
          {`
                /* poppins-300 - latin-ext_latin_devanagari */
                @font-face {
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 300;
                  font-display: block;
                  src: url('${prefix}/fonts/poppins-v15-latin-ext_latin_devanagari-300.woff2') format('woff2');/* Super Modern Browsers */
                }
                /* poppins-700 - latin-ext_latin_devanagari */
                @font-face {
                  font-family: 'Poppins';
                  font-style: normal;
                  font-display: block;
                  font-weight: 700;
                  src: url('${prefix}/fonts/poppins-v15-latin-ext_latin_devanagari-700.woff2') format('woff2'); /* Super Modern Browsers */
                }
                
                /* roboto-mono-300 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
                @font-face {
                  font-family: 'Roboto Mono';
                  font-style: normal;
                  font-weight: 300;
                  font-display: block;
                  src: url('${prefix}/fonts/roboto-mono-v13-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'); /* Super Modern Browsers */
                }
                
                /* roboto-mono-700 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
                @font-face {
                  font-family: 'Roboto Mono';
                  font-style: normal;
                  font-weight: 700;
                  font-display: block;
                  src: url('${prefix}/fonts/roboto-mono-v13-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'); /* Super Modern Browsers */
                }
                
                @font-face {
                  font-family: 'Passion One';
                  font-style: normal;
                  font-weight: 400;
                  src: url(${prefix}/fonts/passion-one-regular.ttf) format('truetype');
                }
                
                 @font-face {
                  font-family: 'Dela Gothic One';
                  font-style: normal;
                  font-weight: 400;
                  src: url(${prefix}/fonts/dela-gothic-one-regular.ttf) format('truetype');
                }
                
                `}
        </style>
      </Head>
    );
  }
}

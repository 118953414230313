import styles from "./ZoomBar.module.scss";
import React, { useContext } from "react";
import { ListModes, ZoomLevels } from "/components-ui";
import {
  Button,
  DataServiceContext,
  useModalDialog,
  ButtonGroup,
  CustomIcon,
  tooltipStyles,
} from "athena-next-ui-lib";
import { faEyeSlash, faCopy } from "@fortawesome/pro-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ActionModes, ReportContext, userPrefsMapping } from "/components-biz";

export function ZoomBar(props) {
  const alertContext = useContext(ReportContext);
  const { events, setActionMode, listMode, setZoomLevel, unpeek } =
    alertContext;
  const { data, response } = events;

  const { openModalDialog } = useModalDialog();
  const dataServiceContext = useContext(DataServiceContext);
  const canFullyEdit = dataServiceContext.AccessManifest(
    "canFullyEditFeature",
    "define-custom-alert-types"
  );
  const queryState = alertContext.getQueryState();
  const zoomLevel = parseInt(queryState.ievt_level || 2);
  const PEEK_ZOOM_LEVEL = 6;
  const isPeekLevel = zoomLevel === PEEK_ZOOM_LEVEL;
  const isNotPeekLevel = zoomLevel !== PEEK_ZOOM_LEVEL;
  let title = dataServiceContext.isUIAlertCentric() ? null : (
    <span>{ZoomLevels[zoomLevel].title}</span>
  );
  let tip = dataServiceContext.isUIAlertCentric() ? null : (
    <div
      className={`${styles.tip} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouthEast}`}
    >
      <FontAwesomeIcon icon={faInfoCircle} size={"2x"} />
      <div
        className={tooltipStyles.cssTooltipText}
        style={{
          width: "300px",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        <strong>{title}</strong>
        <div>{ZoomLevels[zoomLevel].help}</div>
      </div>
    </div>
  );
  let help = null;
  let peekModeDesc = null;
  let rawEventTextButton = null;
  let buttons = null;

  const zoomValues = [
    { label: "Core Events", value: 2 },
    { label: "All Events", value: 5 },
  ];
  let eventZoom = (
    <div style={{ marginRight: "8px" }}>
      <ButtonGroup
        values={zoomValues}
        default={{ value: zoomLevel }}
        size={"md"}
        onChange={(selection) => setZoomLevel(selection.value)}
      />
    </div>
  );

  const setEventWrapPreference = (value) => {
    props.setWrapEvents(!props.wrapEvents);

    const payload = {
      [userPrefsMapping.EVENT_LINE_WRAP]: value,
    };

    return Promise.resolve().then(() =>
      dataServiceContext.setUserPreference(payload)
    );
  };

  if (dataServiceContext.isUIReportCentric() && listMode === ListModes.read) {
    const disabled = zoomLevel > 2;

    buttons = canFullyEdit ? (
      <Button
        onClick={() => {
          return Promise.resolve()
            .then(() => alertContext.initAlertKeys())
            .then(() => setZoomLevel(2))
            .then(() => setActionMode(ActionModes.enterEditMode));
        }}
        disabled={disabled}
      >
        Define Alert Keys
      </Button>
    ) : null;

    if (disabled) {
      buttons = (
        <span
          className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipWest}`}
        >
          {buttons}
          <span className={tooltipStyles.cssTooltipText}>
            In order to edit alert keys, you must have zoom level set to Core or
            lower.
          </span>
        </span>
      );
    }
  } else if (isPeekLevel) {
    if (data?.length) {
      const event = data[0];
      const tags = event.ievt_meta_cfgs;
      const label =
         tags?.container_name || event?.ievt_host;
      peekModeDesc = (
        <div className={styles.peekModeDesc}>
          <span>Peeking at logtype </span>
          <b>{event.ievt_log}</b>
          <span>on</span>
          <b>{label}</b>
        </div>
      );
    } else {
      title = "Peek";
      tip = null;
    }

    buttons = (
      <Button type={"peek"} onClick={() => unpeek()}>
        <span>UNPEEK</span>
        <FontAwesomeIcon icon={faEyeSlash} />
      </Button>
    );
  }

  const wrapValues = [
    {
      label: "Wrap",
      value: true,
    },
    {
      label: "No Wrap",
      value: false,
    },
  ];
  const selectedWrapValue = wrapValues.find(
    (btn) => btn.value === props.wrapEvents
  );

  let wrap = (
    <div style={{ marginRight: "8px" }}>
      <ButtonGroup
        values={wrapValues}
        default={selectedWrapValue}
        size={"md"}
        onChange={(selection) => setEventWrapPreference(selection.value)}
      />
    </div>
  );

  title = (
    <div className={styles.zoomTitle}>
      {tip}
      <div>
        <span className={styles.row}>
          {title}
          {title && <span>&nbsp;&nbsp;&nbsp;</span>}
        </span>
        {help}
      </div>
    </div>
  );

  const copyRawEventText = () => {
    const content = (
      <div className={styles.rawEventText}>
        {data.map((event, index) => {
          return (
            <div
              id={`raw-${event.ievt_id}`}
              key={event.ievt_id}
              className={styles.rawEventTextRow}
            >
              {event.ievt_etext}
            </div>
          );
        })}
      </div>
    );

    setTimeout(() => {
      const element = document.getElementById(`raw-${queryState.ievt_id}`);
      if (element) element.scrollIntoView();
    }, 250);

    openModalDialog({
      icon: faCopy,
      type: "warn",
      maxHeight: "80vh",
      title: "Raw Event Text",
      content: content,
    });
  };

  if (listMode === ListModes.read) {
    rawEventTextButton = (
      <>
        <Button type={"secondary"} size={"md"} onClick={copyRawEventText}>
          Raw Event Text
        </Button>
        &nbsp;&nbsp;
      </>
    );
  } else if (zoomLevel === PEEK_ZOOM_LEVEL) {
    rawEventTextButton = (
      <>
        <Button type={"peek"} size={"md"} onClick={copyRawEventText}>
          Raw Event Text
        </Button>
        &nbsp;&nbsp;
      </>
    );
  }

  if (response?.code !== 200) {
    buttons = null;
    wrap = null;
    eventZoom = null;
    rawEventTextButton = null;
  }

  const listStyle = zoomLevel === PEEK_ZOOM_LEVEL ? ListModes.peek : listMode;

  const css = `${styles.zoomBar} ${styles[listStyle]}`;

  return (
    <div className={css}>
      {listMode !== ListModes.edit && title}
      {isNotPeekLevel && eventZoom}
      {isPeekLevel && peekModeDesc}
      <div className={styles.forceRight} />
      {isNotPeekLevel && wrap}
      {isNotPeekLevel && rawEventTextButton}
      {buttons}
    </div>
  );
}

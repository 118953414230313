import React, { useContext } from "react";
import { Row, Column } from "athena-next-ui-lib";
import { StyledBannerRowDiv, StyledTitleRowDiv } from "../css";
import {
  ReportContext,
  DialogAlertRuleMetadataEdit,
  Title,
  Banner,
  OccurrencesGraph,
  getReportHeaderHeight,
} from "/components-biz";
import { AIConfidence } from "/components-ui";

export const Content = (props) => {
  const alertContext = useContext(ReportContext);

  const { itypeData } = alertContext;
  const {
    itype_state,
    itype_title,
    itype_nlp_judgment: confidence,
  } = itypeData || {};
  const title = itype_title;

  return (
    <Row width="*" css={{ margin: "8px 8px" }}>
      <Column width="*" css={{ margin: "0 0" }}>
        <Row align="center">
          <StyledTitleRowDiv>
            <StyledBannerRowDiv>
              <AIConfidence confidence={confidence} />
              <Banner state={itype_state} />
            </StyledBannerRowDiv>

            <Title state="rejected" title={title} />
          </StyledTitleRowDiv>
        </Row>

        <Row>
          <Column>
            <h3>Changed your mind? Is this suggestion useful?</h3>
            <p>Change what happens if this occurs again.</p>

            <Row css={{ gap: "8px", width: "auto" }}>
              <Column>
                {/* <DialogAlertRestore label="Accept" /> */}
                <Column>
                  {/* <DialogAlertAccept label={"Accept"} /> */}
                  <DialogAlertRuleMetadataEdit
                    type={"edit-note"}
                    data={props}
                    renderType="restoreButton"
                    label="Restore & Accept"
                  />
                </Column>
              </Column>
            </Row>
          </Column>
        </Row>
      </Column>

      <Column width="400px" css={{ margin: "0 24px 0 0" }}>
        <Row>
          <OccurrencesGraph
            width={400}
            height={getReportHeaderHeight(itype_state) - 75}
          />
        </Row>
      </Column>
    </Row>
  );
};

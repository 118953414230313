
const IncidentTypeUtils = {

    extractEventKeys:(eventData)=> {
        const etRootsMarkedAsKeys = (eventData||[]).filter(e => e.ievt_level === 0).map(event => event.ievt_etroot);
        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        }

        return etRootsMarkedAsKeys.filter(onlyUnique).join(",");
    },
    volumesDef: (ttPosition)=>{
        const tooltipPos = ttPosition ? ttPosition : "southEast";
        return  [
            {label: "Day", tooltip: "Alert at most once per log stream per day", tooltipPosition: tooltipPos, value: "day"},
            {label: "Hour", tooltip: "Alert at most once per log stream per hour", tooltipPosition: tooltipPos, value: "hour"},
            {label: "Minute", tooltip: `Alert at most once per log stream per minute`, tooltipPosition: tooltipPos, value: "minute"}
        ];
    },
    priorityDef: ()=>{
        //P3 is default
        return {
            response: {code: 200, timestamp: 111},
            data: [
                {label: "P1", value: "P1"},
                {label: "P2", value: "P2"},
                {label: "P3", value: "P3"},
                {label: "P4", value: "P4"},
                {label: "P5", value: "P5"}
            ]
        }
    }
}

export default IncidentTypeUtils

import React, {
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Column,
  Form,
  Row,
  Field,
  DataServiceContext,
  useModalDialog,
  getErrorMessageDisplay,
} from "athena-next-ui-lib";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DialogTag = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();

  const processing = false;

  const FormRef = useRef();
  const name = useRef();

  const isAdd = props.action === "ADD";

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const showDialog = (tag) => {
    const title = props.action === "ADD" ? "Add Tag" : "Edit Tag";
    const buttonLabel = props.action === "ADD" ? "Add" : "Update";

    const addOrUpdateTag = () => {
      let payload = FormRef.current.getValues();

      if (payload.errors.length > 0) {
        //form contains error(s), throw error
        const formErr = { message: getErrorMessageDisplay(payload.errors) };
        throw formErr;
      }
      delete payload.errors;

      let apiUrl = "tag/create";
      if (isAdd) {
        payload.tag_type = "manual";
      } else {
        payload = { ...tag, ...payload };
      }

      return Promise.resolve()
        .then(() => dataServiceContext.fetch(apiUrl, payload))
        .then((tagCall) => {
          if (tagCall.response.code === 200) {
            props.updateCallback && props.updateCallback();
          } else {
            throw tagCall.response;
          }
        });
    };

    const modalDialogSettings = {
      title: title,
      icon: null,
      submitLabel: buttonLabel,
      submit: addOrUpdateTag,
      content: (
        <Form ref={FormRef} refs={[name]}>
          <Row>
            <Column width={"400px"}>
              <Field
                label="Name"
                ref={name}
                required={isAdd}
                type={"text"}
                name={"tag_name"}
                width={"200px"}
                disabled={processing}
                value={tag ? tag.tag_name : ""}
              />
            </Column>
          </Row>
        </Form>
      ),
    };

    return Promise.resolve().then(() => openModalDialog(modalDialogSettings));
  };

  if (isAdd) {
    return (
      <Button onClick={() => showDialog({})} processing={processing}>
        <FontAwesomeIcon icon={faTags} size={"lg"} />
        <span>Add Tag</span>
      </Button>
    );
  } else {
    return null;
  }
});

import React, { useState, useContext, useEffect } from "react";

import { Field, SignificanceIcon, tooltipStyles } from "athena-next-ui-lib";

import { Panel, Row } from "athena-next-ui-lib";
import fieldStyles from "/components-ui/IncidentList.module.scss";
import commonStyles from "/components-biz/common/common.module.scss";

import { ReportContext } from "/components-biz";
import { TAG_TYPE_LABELS } from "/components-ui/list/events/EventListConstants";

import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PanelIncidentInfo = (props) => {
  const alertContext = useContext(ReportContext);
  const { itypeData } = alertContext;

  const [dataFieldsDisplay, setDataFieldsDisplay] = useState(null);

  const [tagDisplay, setTagDisplay] = useState(null);
  const [serviceGroupDisplay, setServiceGroupDisplay] = useState(null);
  const [significanceDisplay, setSignificanceDisplay] = useState(null);

  const { width } = props;

  useEffect(() => {
    if (!itypeData) return;

    const { itype_id } = itypeData;
    if (!itype_id) return null;
  }, [itypeData?.itype_id]);

  useEffect(() => {
    if (itypeData == null) return;

    if (Object.keys(itypeData).length > 0) {
      const allTagData = itypeData.inci_tags.concat(itypeData.itype_tags);

      let tags = allTagData.map((tag) => {
        return (
          <span
            className={`${commonStyles.alertRuleTag} ${
              commonStyles[tag.tag_type]
            } ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouth}`}
          >
            {tag.tag_name}
            <div className={tooltipStyles.cssTooltipText}>
              {TAG_TYPE_LABELS[tag.tag_type]}
            </div>
          </span>
        );
      });

      //set tags display
      setTagDisplay(
        allTagData.length === 0 ? null : (
          <div style={{ lineHeight: "1.8" }}>{tags}</div>
        )
      );

      //set significance display
      const inci_significance = itypeData.inci_significance;

      const significance = (
        <SignificanceIcon
          showTooltip={false}
          showLabel={true}
          inci_significance={inci_significance}
        />
      );
      setSignificanceDisplay(significance);

      //set service groups display
      setServiceGroupDisplay(
        <span className={fieldStyles.serviceGroups} title={"Service Groups"}>
          {itypeData.inci_svc_grps.split(",").join(", ")}
        </span>
      );
    }
  }, [itypeData]);

  useEffect(() => {
    const lblWidth = "105px";
    const fldWidth = "250px";

    const noSignal = "                                    ";
    const signalDisplay =
      itypeData?.inci_signal !== noSignal ? (
        <span>
          <FontAwesomeIcon icon={faBolt} /> &nbsp; Result of RC Scan
        </span>
      ) : null;

    setDataFieldsDisplay(
      <>
        {signalDisplay && (
          <Row>
            <Field
              type={"display-text"}
              value={signalDisplay}
              labelWidth={lblWidth}
              fieldWidth={fldWidth}
              label={" "}
            />
          </Row>
        )}

        {tagDisplay && (
          <Row>
            <Field
              type={"display-text"}
              value={tagDisplay}
              labelWidth={lblWidth}
              fieldWidth={fldWidth}
              label={"Matches On"}
            />
          </Row>
        )}
        <Row>
          <Field
            type={"display-text"}
            value={significanceDisplay}
            labelWidth={lblWidth}
            fieldWidth={fldWidth}
            label={"Significance"}
          />
        </Row>
        <Row>
          <Field
            type={"display-text"}
            value={serviceGroupDisplay}
            labelWidth={lblWidth}
            fieldWidth={fldWidth}
            label={"Service Groups"}
          />
        </Row>
      </>
    );
  }, [tagDisplay, significanceDisplay, serviceGroupDisplay]);

  if (!itypeData) return null;

  return (
    <Panel width={width} flex={`0 1 ${props.height}`}>
      {dataFieldsDisplay}
    </Panel>
  );
};
PanelIncidentInfo.defaultProps = {
  graphData: { occurrences: { response: { code: 0 } } },
  response: { code: 0 },
  data: [],
  marginX: 10,
  marginY: 0,
  width: 200,
  height: 40,
};

import React, { useContext } from "react";
import { IncidentListContext } from "/components-biz/incidents";
import styles from "./IncidentList.module.scss";
import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";
import { WORD_CLOUD_COLOR_RAMP as commonWordColors } from "/components-ui/";

const commonWordSizes = [10, 12, 13, 14, 15, 16, 17, 20];

export const CommonWords = (props) => {
  const ac = useContext(IncidentListContext);
  const { alert } = props;

  const navigateToReportWithFindTerm = (evt, findTerm) => {
    evt.stopPropagation();
    ac.navigateToReport(alert.itype_id, alert.inci_id, findTerm);
  };

  return alert.itype_state !== "custom" ? (
    <div className={styles.commonWords}>
      {alert.inci_words.map((word, index) => {
        return (
          <span
            key={word.w}
            onMouseDown={(evt) => navigateToReportWithFindTerm(evt, word.w)}
            style={{
              color: commonWordColors[word.b - 1],
              fontSize: commonWordSizes[word.s - 1],
            }}
          >
            {word.w}
          </span>
        );
      })}
    </div>
  ) : null;
};

import React, {useContext, useEffect, useState,useRef} from "react";
import styles from "./Bookmarks.module.scss";
import {Button,PageContext,Utilities,useModalDialog,Field,DataServiceContext} from 'athena-next-ui-lib'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";

export function Bookmarks(props){
    const pageContext = useContext(PageContext);
    const dataService = useContext(DataServiceContext);
    const {openModalDialog,openConfirmationDialog} = useModalDialog();
    const newBookmarkName = useRef();

    const [allBookmarks,setAllBookmarks] = useState([]);
    const canEditBookmarks =  !!dataService?.AccessManifest?.( "canFullyEditFeature", "define-custom-alert-types");

    useEffect(()=>{
         dataService?.fetch && loadBookmarks();
    },[]);

    const loadBookmarks = () => {
        return Promise.resolve()
            .then( ()=>dataService.fetch("savedfilter/read",{}) )
            .then( (readCall)=>{
                if(readCall.response.code === 200){
                    setAllBookmarks( readCall.data || []);
                } else {
                    throw(readCall.response);
                }
            });

    }

    const saveBookmark = () => {
        const {router,env} = pageContext;

        const qs = new URLSearchParams( window.location.search);
        //qs.delete("deployment_id"); //never store deployment id
        qs.delete("inci_code"); //never store inci code selections
        qs.delete("current"); //never store current


        let url = window.location.pathname + "?" + qs.toString();
        url = url.replace( new RegExp(`${env.INGRESS_PREFIX}`), "");

        const type = router.pathname.replace(new RegExp(`${env.INGRESS_PREFIX}`), "");
        const value = newBookmarkName.current.getValue();

        if(value.name.length === 0){
            return {response:{code:999, message:"Name is required"}}
        }

        const payload = {
            name: value.name,
            type:type,
            url: url
        }

        return Promise.resolve()
            .then( ()=>dataService.fetch("savedfilter/create",payload) )
            .then( (createCall)=>{
                if(createCall.response.code === 200){
                    return true;
                } else {
                    throw(createCall.response);
                }
            })
            .then( ()=> loadBookmarks() );

    };

    const showCreateBookmarkDialog = () => {
        const {env} = pageContext;


        const qs = new URLSearchParams( window.location.search);
        //qs.delete("deployment_id"); //never store deployment id
        qs.delete("inci_code"); //never store inci code selections
        qs.delete("current"); //never store current

        let kvs = [];
        qs.forEach( (v,k) => kvs.push({key:k,value:v}) );


        let url = window.location.pathname;

        url = url.replace( new RegExp(`${env.INGRESS_PREFIX}`), "");

        const content = <div style={{maxWidth:"400px"}}>
            <Field ref={newBookmarkName} name="name" type={"text"} label={"Display Name"} />
            <p style={{wordBreak:"break-all"}}>
                {url}
                <ul>
                    {kvs.map( kv => <li key={kv.key}>{kv.key}&nbsp;=&nbsp;{kv.value}</li>)}
                </ul>
            </p>
            {/*<Field type={"description"} label={"URL"} value={url}/>*/}
        </div>

        const settings = {
            title:"Add View",
            icon:faBookmark,
            submitLabel: "Save",
            submit:saveBookmark,
            content: content
        };

        openModalDialog( settings )

    }

    const removeBookmark = (evt,item) => {
        evt.stopPropagation();
        const payload = { id:item.id };

        openConfirmationDialog({
            title:`Confirm View Delete: '${item.name}'`,
            icon: faTrash,
            submit: ()=> _removeBookmark(payload),
            content:<p>Are you sure you want to delete this View?</p>});



    }

    const _removeBookmark = (payload) => {

        return Promise.resolve()
            .then( ()=>dataService.fetch("savedfilter/delete",payload) )
            .then( (deleteCall)=>{
                if(deleteCall.response.code === 200){
                    return true;
                } else {
                    throw(deleteCall.response);
                }
            })
            .then( ()=> loadBookmarks() );
    }

    const launchBookmark = (evt, item) => {
        evt.stopPropagation();
        const {env} = pageContext;
        window.location = Utilities.Router.createAsPath(item.url, env);
    }


    const stopPropagation = (evt) => {evt.stopPropagation()}

    let content = null;

    if( canEditBookmarks === true || allBookmarks.length > 0 ) {
        //if we have bookmarks, paint control
        //if we are admin or above, paint control and show create bookmark and delete bookmark controls

        content = <div className={styles.Bookmarks} onClick={stopPropagation} onMouseDown={stopPropagation}>
            <div className={styles.BookmarksField}>
                <FontAwesomeIcon icon={faBookmark}/>
            </div>
            <div className={`${styles.BookmarksMenu}`}>
                <h3>Views</h3>
                <div className={styles.list}>
                    {allBookmarks.map( (item,index) => {

                        return <div key={item.id} className={styles.BookmarksMenuItem} onClick={(evt)=>launchBookmark(evt,item)}>
                            {item.name}
                            {canEditBookmarks === true && <div className={styles.itemControls}>
                                <div onClick={(evt)=>removeBookmark(evt,item)}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </div>
                            </div>}
                        </div>;
                    } )}
                </div>

                {canEditBookmarks === true && <div className={styles.BookmarksMenuItem}>
                    <Button type={"secondary"} onClick={showCreateBookmarkDialog}>
                        <FontAwesomeIcon icon={faPlus}/>&nbsp;Add View
                    </Button>
                </div>}
            </div>
        </div>
    }

    return content;
}
import React, { useState, useContext, useRef, useEffect } from "react";

import styles from "./alert-rules.module.scss";

import {
  Column,
  NavBarButton,
  Panel,
  Row,
  TabBar,
  PageContext,
  PageTemplate,
} from "athena-next-ui-lib";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import {
  faCirclePlus as IncludeRuleIcon,
  faBan as ExcludeRuleIcon,
  faRouteHighway as RouteRuleIcon,
  faClipboardCheck as AlertRuleIcon,
} from "@fortawesome/pro-regular-svg-icons";
import { AlertRuleContextProvider, AlertRulesTable, DialogAlertRule } from "/components-biz";

export const VIEW_TYPES = {
  USER_DRIVEN: "user_driven",
  SUGGESTED: "suggested",
  INTEREST: "evts_int",
  ROUTING: "routing",
  EXCLUDE: "exclude",
  ET_ROOT: "etroot",
};

const VIEW_NAMES = {
  [VIEW_TYPES.USER_DRIVEN]: "Custom Alert Rules",
  [VIEW_TYPES.SUGGESTED]: "Zebrium Suggested Alerts",
  [VIEW_TYPES.ROUTING]: "ML Routing Rules",
  [VIEW_TYPES.INTEREST]: "Include Rules",
  [VIEW_TYPES.EXCLUDE]: "Exclude Rules",
  [VIEW_TYPES.ET_ROOT]: "Event Type Alerts",
};

export const VIEW_DEFS = {
  [VIEW_TYPES.USER_DRIVEN]: {
    title: VIEW_NAMES[VIEW_TYPES.USER_DRIVEN],
    tagType: "user_driven",
    icon: AlertRuleIcon,
    desc: () =>
      "Custom alert rules deterministically create an alert and notify one or more channels when a log line matches the defined rule.",
    filter: ["tag_type=user_driven"],
    dialog: {
      addLabel: "Add Custom Alert Rule",
      createTitle: (etroot) => `Add Custom Alert Rule`,
      editTitle: (etroot) => `Edit Custom Alert Rule`,
      deleteTitle: "Delete Custom Alert Rule",
      deleteDesc: "Are you sure you want to delete this custom alert rule",
      createdTitle: "Custom Alert Rule Added",
      updatedTitle: "Custom Alert Rule Updated",
      createdDesc: (tag) => `Custom Alert Rule '${tag}' has been added.`,
      editedDesc: (tag) => `Custom Alert Rule '${tag}' has been updated.`,
    },
  },
  [VIEW_TYPES.SUGGESTED]: {
    title: (
      <span>
        <img
          src={"images/favicon.png"}
          width={14}
          style={{ filter: "grayscale(1)" }}
        />
        &nbsp;Zebrium Suggested Alerts
      </span>
    ),
    icon: null,
    desc: "",
  },
  [VIEW_TYPES.ROUTING]: {
    title: VIEW_NAMES[VIEW_TYPES.ROUTING],
    tagType: "routint",
    icon: RouteRuleIcon,
    desc: () =>
      "Routing rules allow you to tag a suggested alert and notify one or more channels when log lines in the suggested alert match the rule you define.",
    filter: ["tag_type=routing"],
    dialog: {
      addLabel: "Add Routing Rule",
      createTitle: (etroot) =>
        etroot ? (
          <span>
            Add Routing Rule for Event Type:&nbsp;<i>{etroot}</i>
          </span>
        ) : (
          `Add Routing Rule`
        ),
      editTitle: (etroot) =>
        etroot ? (
          <span>
            Edit Routing Rule for Event Type:&nbsp;<i>{etroot}</i>
          </span>
        ) : (
          `Edit Routing Rule`
        ),
      deleteTitle: "Delete Routing Rule",
      deleteDesc: "Are you sure you want to delete this routing rule",
      createdTitle: "Routing Rule Added",
      updatedTitle: "Routing Rule Updated",
      createdDesc: (tag) => `Routing Rule '${tag}' has been added.`,
      editedDesc: (tag) => `Routing Rule '${tag}' has been updated.`,
    },
  },
  [VIEW_TYPES.INTEREST]: {
    title: VIEW_NAMES[VIEW_TYPES.INTEREST],
    tagType: "evts_int",
    icon: IncludeRuleIcon,
    desc: (etroot) =>
      etroot
        ? "When a suggested alert is created, nearby log lines of this event type will also be included in the core of the suggested alert."
        : "When an ML alert is created, any nearby log lines that match the include rules below will also be included in the core of the ML alert.",
    filter: ["tag_type=evts_int"],
    dialog: {
      addLabel: "Add Include Rule",
      createTitle: (etroot) =>
        etroot ? (
          <span>
            Always include event type&nbsp;<i>{etroot}</i>&nbsp;in future
            suggested alerts
          </span>
        ) : (
          `Add Include Rule`
        ),
      editTitle: (etroot) =>
        etroot ? (
          <span>
            Always include event type&nbsp;<i>{etroot}</i>&nbsp;in future
            suggested alerts
          </span>
        ) : (
          `Edit Include Rule`
        ),
      deleteTitle: "Delete Include Rule",
      deleteDesc: "Are you sure you want to delete this include rule",
      createdTitle: "Include Rule Added",
      updatedTitle: "Include Rule Updated",
      createdDesc: (tag) => `Include Rule '${tag}' has been added.`,
      editedDesc: (tag) => `Include Rule '${tag}' has been updated.`,
    },
  },
  [VIEW_TYPES.EXCLUDE]: {
    title: VIEW_NAMES[VIEW_TYPES.EXCLUDE],
    tagType: "exclude",
    icon: ExcludeRuleIcon,
    desc: (etroot) =>
      etroot
        ? "Prevent this event type from ever being part of any future alert."
        : "Prevent log lines that match the exclude rules below from ever being part of any alert.",
    filter: ["tag_type=exclude"],
    dialog: {
      addLabel: "Add Exclude Rule",
      createTitle: (etroot) =>
        etroot ? (
          <span>
            Always exclude event type&nbsp;<i>{etroot}</i>&nbsp;from all future
            alerts
          </span>
        ) : (
          `Add Exclude Rule`
        ),
      editTitle: (etroot) =>
        etroot ? (
          <span>
            Always exclude event type&nbsp;<i>{etroot}</i>&nbsp;from all future
            alerts
          </span>
        ) : (
          `Edit Exclude Rule`
        ),
      deleteTitle: "Delete Exclude Rule",
      deleteDesc: "Are you sure you want to delete this exclude rule",
      createdTitle: "Exclude Rule Added",
      updatedTitle: "Exclude Rule Updated",
      createdDesc: (tag) => `Exclude Rule '${tag}' has been added.`,
      editedDesc: (tag) => `Exclude Rule '${tag}' has been updated.`,
    },
  },
  [VIEW_TYPES.ET_ROOT]: {
    title: VIEW_NAMES[VIEW_TYPES.ET_ROOT],
    tagType: "user_driven",
    icon: AlertRuleIcon,
    desc: () =>
      "Custom event type alert rules deterministically create an alert and notify one or more channels when a log line of this event type occurs.",
    filter: ["tag_type=user_driven", "tag_etroot!="],
    dialog: {
      addLabel: "Add Event Type Alert Rule",
      createTitle: (etroot) => (
        <span>
          Create custom event type alert rule for event type:&nbsp;
          <i>{etroot}</i>
        </span>
      ),
      editTitle: (etroot) => (
        <span>
          Edit custom event type alert rule for event type:&nbsp;<i>{etroot}</i>
        </span>
      ),
      deleteTitle: "Delete Custom Event Type Alert Rule",
      deleteDesc:
        "Are you sure you want to delete this custom event type alert rule",
      createdTitle: "Custom Event Type Alert Rule Added",
      updatedTitle: "Custom Event Type Alert Rule Updated",
      createdDesc: (tag) =>
        `Custom Event Type Alert Rule '${tag}' has been added.`,
      editedDesc: (tag) =>
        `Custom Event Type Alert Rule '${tag}' has been updated.`,
    },
  },
};

const AlertRules = (props) => {
  const pageContext = useContext(PageContext);
  const [viewType, setViewType] = useState(VIEW_TYPES.USER_DRIVEN); //custom alerts is the default tab
  const [tabContent, setTabContent] = useState(null);
  const currentTabIndex = 0; //default to custom alerts tab

  const [pageContent, setPageContent] = useState(null);

  const alertRulesTable = useRef();
  const alertRuleDialog = useRef();

  useEffect(() => {
    if (viewType == null) return;

    setTabContent(drawAlertRulesTable(viewType));
  }, [viewType]);

  useEffect(() => {
    setPageContent(
      <AlertRuleContextProvider>
        <TabBar tabs={tabs} currentTab={currentTabIndex} />
        <div>{tabContent}</div>
      </AlertRuleContextProvider>
    );
  }, [tabContent]);

  const refreshList = () => {
    return alertRulesTable?.current?.refreshList();
  };

  const drawAlertRulesTable = (viewType) => {
    const addBtn =
      viewType === VIEW_TYPES.ET_ROOT ? null : (
        <>
          {
            <DialogAlertRule
              action={"ADD"}
              ref={alertRuleDialog}
              tagType={viewType}
              dialogDef={VIEW_DEFS[viewType]}
              updateCallback={refreshList}
            ></DialogAlertRule>
          }
        </>
      );

    return (
      <AlertRulesTable
        key={viewType}
        ref={alertRulesTable}
        tagType={viewType}
        desc={VIEW_DEFS[viewType].desc}
        filter={VIEW_DEFS[viewType].filter}
        addButton={addBtn}
        dialogDef={VIEW_DEFS[viewType]?.dialog}
      ></AlertRulesTable>
    );
  };

  const showTableOfType = (tab) => {
    setViewType(tab.value);
  };

  //  const tabs = [ VIEW_TYPES.USER_DRIVEN, VIEW_TYPES.SUGGESTED, VIEW_TYPES.ROUTING, VIEW_TYPES.INTEREST,  VIEW_TYPES.EXCLUDE ].map(tabName=>{
  const tabs = [
    VIEW_TYPES.USER_DRIVEN,
    VIEW_TYPES.INTEREST,
    VIEW_TYPES.EXCLUDE,
    VIEW_TYPES.ROUTING,
  ].map((tabName) => {
    return {
      label: VIEW_DEFS[tabName].title,
      icon: VIEW_DEFS[tabName].icon,
      value: tabName,
      callback: (tab) => showTableOfType(tab),
    };
  });

  const getPageContent = () => {
    if (!pageContext.isReady) return null;

    return (
      <>
        <Row width={"*"}>
          <Column width={"*"}>
            <Row
              height={"calc(100vh - 49px)"}
              width="*"
              overflowY={"hidden"}
              overflowX={"hidden"}
            >
              <div className={styles.page}>
                <Panel width={"auto"} style={"clear"}>
                  {pageContent}
                </Panel>
              </div>
            </Row>
          </Column>
        </Row>
      </>
    );
  };

  const navBarContent = () => {
    return (
      <NavBarButton
        icon={faArrowLeft}
        iconSize={"lg"}
        label={"Back"}
        action={() => pageContext.router.back()}
      />
    );
  };

  return (
    <PageTemplate
      title={"Alert Rules & Settings"}
      pageBar={null}
      navBar={navBarContent()}
      pageContent={getPageContent()}
    />
  );
};
AlertRules.getInitialProps = async (ctx) => {
  return {};
};

export default AlertRules;

import React, { useContext } from "react";
import {
  IncidentListContext,
  IncidentTypeBanner,
  Timestamp,
  Tags,
} from "/components-biz";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import styles from "./PanelRelatedSuggestion.module.scss";
import {
  CustomIcon,
  SignificanceIcon,
  Utilities,
  DataServiceContext,
  PageContext,
} from "athena-next-ui-lib";


export const RelatedSuggestion = ({ alert }) => {

  const alertRowStyle = alert.itype_state;
  const dataService = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);

  const css = `${styles[alertRowStyle]} ${styles.row}`;
  const {
    itype_id,
    inci_id
  } = alert;

  
  const deriveReportLink = () => {
    const { env } = pageContext;
    const user = dataService.getUserProfile();

    let reportLink = `/root-cause/report?deployment_id=${user.deployment_id}&itype_id=${itype_id}&inci_id=${inci_id}&ievt_level=2`;

    return Utilities.Router.createAsPath(reportLink, env);
  };

  const reportURL = deriveReportLink();
  return (
    <a href={reportURL} className={css} id={`inci-${inci_id}`} target="_blank">
     
        <div className={styles.innerRow}>
          <Timestamp alert={alert} />
          <IncidentTypeBanner alert={alert} hideSymbol={true} />

          <Tags alert={alert} />

          <div className={styles.title} style={{wordBreak: "break-word"}}>
            <strong>{alert.itype_title || alert.itype_nlp_title || alert.inci_title}</strong>
          </div>
        </div>
    </a>
  );
};

import {FieldTypes} from "athena-next-ui-lib"

export const userPrefsMapping = {
    LOAD_OCCURRENCE: "load_occurrence_behavior",
    EVENT_LINE_WRAP: "report_event_wrap_lines"
};



export const userPrefDefinitions = [
    {
        category: "occurrences",
        title: "This is the category title for occurrences:",
        items: [{
            fieldType: FieldTypes.RADIO,
            fieldName: userPrefsMapping.LOAD_OCCURRENCE,
            label: "When opening new occurrences",
            values: [{label: "Always open occurrences in a new browser tab", value: "new_tab"},
                {label: "Always open occurrences in this tab (replace this browser tab's content)", value: "replace"}],
            defaultValue: "new_tab"
        }],
    }, {
        category: "events",
        title: "This is the category title for events:",
        items: [{
            fieldType: FieldTypes.RADIO,
            isBoolean: true,
            label: "When viewing events within reports",
            values: [{label: "Always wrap event lines to fit your browser width", value: true},
                {label: "Never wrap event lines (may require you to scroll horizontally to see full event text)", value: false}],
            fieldName: userPrefsMapping.EVENT_LINE_WRAP,
            defaultValue: true
        }],
    }

];

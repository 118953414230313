import React, { useContext } from "react";
import { IncidentListContext, IncidentBucket } from "/components-biz/incidents";
import styles from "./IncidentList.module.scss";
import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";

export const IncidentList = (props) => {
  const ac = useContext(IncidentListContext);

  if (ac.isLoadingAlertData) {
    return null;
  } else if (ac?.alertList?.some((item) => item.alerts !== null)) {
    return (
      <div
        className={styles.listContainer}
        style={{ flexBasis: props.height }}
        id={ac.listId}
      >
        <div className={styles.list}>
          <div className={styles.shadow} />
          {ac.alertList.map((bucket, index) => {
            const key = `${bucket.time_from}${index}`;
            return <IncidentBucket key={key} bucket={bucket} />;
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const occurrencesFilterList = [
  {
    label: "All alert occurrences",
    name: "All alert occurrences",
    value: "all",
  },
  {
    label: "First alert occurrence only",
    name: "First alert occurrence only",
    value: "first",
  },
  {
    label: "Most recent alert occurrence only",
    name: "Most recent alert occurrence only",
    value: "last",
  },
  {
    label: "Alerts with at least 10 occurrences",
    name: "Alerts with at least 10 occurrences",
    value: ">=10",
  },
  {
    label: "Alerts with at least 100 occurrences",
    name: "Alerts with at least 100 occurrences",
    value: ">=100",
  },
  {
    label: "Alerts with at most 10 occurrences",
    name: "Alerts with at most 10 occurrences",
    value: "<=10",
  },
  {
    label: "Alerts with 1 occurrence",
    name: "Alerts with 1 occurrence",
    value: "=1",
  },
];

export const confidence = [
  { label: "High", value: "Y" },
  { label: "Low", value: "N" },
];

export const timeRanges = [
  {
    label: "Today",
    name: "Today",
    value: "today",
  },
  {
    label: "Last 24 Hours",
    name: "Last 24 Hours",
    value: "last-24-hours",
  },
  {
    label: "Last 7 Days",
    name: "Last 7 Days",
    value: "last-7-days",
  },
  {
    label: "Week to Date",
    name: "Week to Date",
    value: "week-to-date",
  },
  {
    label: "Last Week",
    name: "Last Week",
    value: "last-week",
  },
  {
    label: "Month to Date",
    name: "Month to Date",
    value: "month-to-date",
  },
  {
    label: "Last Month",
    name: "Last Month",
    value: "last-month",
  },
  {
    label: "Custom",
    name: "Custom",
    value: "custom",
  },
  {
    label: "All Time",
    name: "All Time",
    value: "all-time",
  },
];

export const significance = [
  {
    label: "Low or greater",
    name: "Low",
    value: "low",
    tooltip: "Show Root Cause Reports with Low, Medium or High Significance",
  },
  {
    label: "Medium or greater",
    name: "Medium",
    value: "medium",
    tooltip: "Show Root Cause Reports with Medium or High Significance",
  },
  {
    label: "High Only",
    name: "High",
    value: "high",
    tooltip: "Show Root Cause Reports with High Significance",
  },
];

export const Occurrences_Sortables = [
  { name: "Most Frequent First", value: "most", selected: false },
  { name: "Least Frequent First", value: "least", selected: false },
];

export const SEQ_BASE = 0;

import React from "react";
import { Row, Column, LoadingAnim } from "athena-next-ui-lib";

export const Content = (props) => {
  return (
    <Row width="*" align="center">
      <Column align="center">
        <LoadingAnim />
      </Column>
    </Row>
  );
};

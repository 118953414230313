import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./IncidentList.module.scss";
import {
  tooltipStyles,
  Utilities,
  useModalDialog,
  CustomIcon,
  SignificanceIcon,
} from "athena-next-ui-lib";

import {
  faBolt,
  faTags,
  faCheck,
  faPlus,
  faBars,
} from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import {
  faRouteHighway as RouteRuleIcon,
  faClipboardListCheck as AlertRuleIcon,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import { WordCloud } from "/components-ui";

let findIndex = 0;
export function IncidentList(props) {
  const { openModalDialog } = useModalDialog();

  const tagSelectorDialog = useRef();

  const showTagSelector = (evt, incident) => {
    evt.stopPropagation();

    const settings = {
      icon: faTags,
      submitLabel: "Update Tags",
      title: "Tagging",
      content: (
        <TagSelectorDialogContent
          ref={tagSelectorDialog}
          allManualTags={props.allManualTags}
          incident={incident}
        />
      ),
      closeOnSubmit: true,
      submit: () => {
        const tagIds = tagSelectorDialog.current.getTags();
        return props.saveTags({
          itype_id: incident.itype_id,
          inci_id: incident.inci_id,
          inci_code: incident.inci_code,
          itype_tag_ids: tagIds,
        });
      },
    };

    openModalDialog(settings);
  };

  const list = useMemo(() => {
    const IncidentRenderStyle = IncidentAlertCentric;
    findIndex = 0;

    if (!props.response || props.response.code !== 200) {
      return null;
    } else if (props.groupByDay) {
      let rowGroups = {};

      (props.data || []).forEach((item) => {
        const ts = Moment(item.inci_ts);
        item.month = ts.format("MMM");
        item.day = ts.format("D");
        item.key = ts
          .set({ hour: 0, minute: 0, second: 0, millisecond: 1 })
          .unix();

        if (!rowGroups.hasOwnProperty(item.key)) {
          rowGroups[item.key] = { month: item.month, day: item.day, rows: [] };
        }

        rowGroups[item.key].rows.push(item);
      });

      return Object.keys(rowGroups)
        .reverse()
        .map((key, index) => {
          const group = rowGroups[key];
          const rowKey = key;

          return (
            <div key={"rg-" + rowKey} className={styles.rowGroup}>
              <div className={styles.dateTime}>
                <div className={styles.date}>
                  {group.month}&nbsp;{group.day}
                </div>
              </div>
              <div className={styles.rowGroupRows}>
                {group.rows.map((item, index) => (
                  <IncidentRenderStyle
                    {...item}
                    key={`r-${item.inci_id}`}
                    alertCentric={props.alertCentric}
                    showTagSelector={showTagSelector}
                    searchTerm={props.searchTerm}
                    findTerm={props.findTerm}
                    findMatches={props.findMatches}
                    groupByDay={props.groupByDay}
                    reportLink={props.reportLink}
                    reportLinkForWordCloud={props.reportLinkForWordCloud}
                    storeRCListURL={props.storeRCLinkURL}
                    categoryId2LabelMap={props.categoryId2LabelMap}
                    nlp_label={props.nlpLabel}
                  />
                ))}
              </div>
            </div>
          );
        });
    } else {
      return (props.data || []).map((item, index) => (
        <IncidentRenderStyle
          {...item}
          key={`r${item.inci_id}`}
          alertCentric={props.alertCentric}
          showTagSelector={showTagSelector}
          searchTerm={props.searchTerm}
          findTerm={props.findTerm}
          findMatches={props.findMatches}
          groupByDay={props.groupByDay}
          reportLink={props.reportLink}
          reportLinkForWordCloud={props.reportLinkForWordCloud}
          storeRCListURL={props.storeRCLinkURL}
          categoryId2LabelMap={props.categoryId2LabelMap}
          nlp_label={props.nlpLabel}
        />
      ));
    }
  }, [props.data, props.groupByDay, props.alertCentric]);
  const incidentListCSS = props.groupByDay
    ? `${styles.incidentList}`
    : `${styles.incidentList} ${styles.notGroupedByDay}`;

  return (
    <div id={props.listId} className={styles.scrollContainer}>
      <div className={incidentListCSS}>{list}</div>
    </div>
  );
}

function IncidentAlertCentric(props) {
  const {
    inci_id,
    inci_logs,
    inci_ts,
    inci_itype_occ,
    inci_has_signal,
    itype_summary,
    itype_id,
    itype_title,
    itype_state,
    inci_svc_grps,
    inci_events,
    nlp_label,
    inci_words,
    inci_code,
    inci_tags,
    itype_tags,
    findMatches,
    searchTerm,
    inci_sources,
    findTerm,
    inci_significance,
    alertCentric,
  } = props;

  const searchTermRe = Utilities.Strings.formRegularExpression(
    searchTerm || ""
  );
  const findTermRe = Utilities.Strings.formRegularExpression(findTerm || "");
  const applyHighlights =
      searchTerm?.length || findTerm?.length;

  let findId = null;
  if (
    props.findMatches &&
    props.findMatches.length > 0 &&
    props.findMatches.findIndex((i) => i.inci_code === inci_code) > -1
  ) {
    findId = `find-${findIndex++}`;
  }

  const resultOfScan = inci_has_signal ? (
    <div
      className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipEast}`}
    >
      <div className={`fa-layers fa-fw ${styles.resultOfScan}`}>
        <FontAwesomeIcon className={styles.resultOfScanBase} icon={faCircle} />
        <FontAwesomeIcon className={styles.resultOfScanSymbol} icon={faBolt} />
      </div>
      <div className={tooltipStyles.cssTooltipText}>Result of Scan</div>
    </div>
  ) : null;

  let rowCSS = styles.incidentRow;

  const alertRuleMatches = inci_tags || [];
  let alertRuleMatchesDisplay = null;
  if (alertRuleMatches?.length) {
    const label =
      itype_state === "custom" ? (
        <span className={styles.matchesLabel}>Matches Alert Rule:&nbsp;</span>
      ) : (
        <span className={styles.matchesLabel}>&nbsp;also matches:&nbsp;</span>
      );
    alertRuleMatchesDisplay = (
      <>
        {label}
        {alertRuleMatches.map((rule, index) => {
          let css = null;
          let icon = null;
          switch (rule.tag_type) {
            case "routing":
              css = styles.routing;
              icon = <FontAwesomeIcon icon={RouteRuleIcon} />;
              break;
            case "user_driven":
            default:
              css = styles.user_driven;
              icon = <FontAwesomeIcon icon={AlertRuleIcon} />;
              break;
          }
          return (
            <span key={rule.tag_id} className={`${styles.tagType} ${css}`}>
              {icon}&nbsp;{rule.tag_name}
            </span>
          );
        })}
      </>
    );
  }

  let title = null;

  if (itype_state === "custom") {
    title = (
      <>
        <div className={styles.title}>
          {itype_title}
          {alertRuleMatchesDisplay}
        </div>
      </>
    );
  } else if (itype_state === "suggested") {
    rowCSS = `${rowCSS} ${styles.incidentRowSuggested}`;
    title = (
      <>
        <div
          className={`${styles.suggested} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipEast}`}
        >
          <img src={"../images/favicon.png"} width={13} />
          &nbsp;Suggested Alert
          <div className={tooltipStyles.cssTooltipText}>
            This alert has been suggested by Zebrium ML
          </div>
        </div>
        <div className={styles.title}>
          {itype_title}
          {alertRuleMatchesDisplay}
        </div>
      </>
    );
  } else if (itype_state === "accepted") {
    rowCSS = `${rowCSS} ${styles.incidentRowAccepted}`;
    title = (
      <>
        <div
          className={`${styles.accepted} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipEast}`}
        >
          <FontAwesomeIcon icon={faCheck} />
          &nbsp;Accepted Alert
          <div className={tooltipStyles.cssTooltipText}>
            You are seeing this alert because it was suggested by Zebrium ML and
            it was accepted as valid
          </div>
        </div>
        <div className={styles.title}>
          {itype_title}
          {alertRuleMatchesDisplay}
        </div>
      </>
    );
  } else if (itype_state === "rejected") {
    rowCSS = `${rowCSS} ${styles.incidentRowRejected}`;
    title = (
      <>
        <div
          className={`${styles.rejected} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipEast}`}
        >
          Rejected Alert
          <div className={tooltipStyles.cssTooltipText}>
            This alert has been rejected. No new alerts of its kind will be
            issued.
          </div>
        </div>
        <div className={styles.title}>
          {itype_title}
          {alertRuleMatchesDisplay}
        </div>
      </>
    );
  } else if (
    itype_title.length &&
    itype_summary.length &&
    itype_summary === itype_title
  ) {
    title = (
      <>
        <div
          className={`${styles.experimental} ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipEast}`}
        >
          {nlp_label}
          <div className={tooltipStyles.cssTooltipText}>
            This is an experimental plain language summary of the root cause
            report
          </div>
        </div>
        <div className={styles.title}>
          {itype_title}
          {alertRuleMatchesDisplay}
        </div>
      </>
    );
  } else {
    title = (
      <div className={styles.title}>
        {title}
        {itype_title}
        {alertRuleMatchesDisplay}
      </div>
    );
  }

  const occurrences =
    inci_itype_occ > 1 ? (
      <label className={styles.occurrenceCount}>
        {inci_itype_occ.toLocaleString()} Occurrences
      </label>
    ) : null;

  let itypeIcon = null;

  switch (true) {
    case props.findTerm &&
      findMatches &&
      findMatches.findIndex((i) => i.inci_code === inci_code) > -1:
      itypeIcon = (
        <CustomIcon
          name={"itypeFindMatch"}
          options={{
            wrapInSVG: true,
            size: 16,
            tooltip: `Some lines in this report match the term '${props.findTerm}'`,
          }}
        />
      );
      break;
    case itype_state === "custom":
      itypeIcon = (
        <CustomIcon
          name={"itypeAccepted"}
          options={{
            wrapInSVG: true,
            size: 16,
            tooltip: "Matches a custom alert",
          }}
        />
      );
      break;
    case alertCentric && itype_state === "suggested":
      itypeIcon = (
        <CustomIcon
          name={"itypeSuggested"}
          options={{
            wrapInSVG: true,
            size: 16,
            tooltip: "Zebrium suggests this alert",
          }}
        />
      );
      break;
    case alertCentric && itype_state === "rejected":
      itypeIcon = (
        <CustomIcon
          name={"itypeRejected"}
          options={{
            wrapInSVG: true,
            size: 16,
            tooltip: "This alert has been rejected",
          }}
        />
      );
      break;
    case alertCentric && itype_state === "accepted":
    default:
      itypeIcon = (
        <CustomIcon
          name={"itypeAccepted"}
          options={{
            wrapInSVG: true,
            size: 16,
            tooltip: "This alert has been accepted",
          }}
        />
      );
      break;
  }

  const time = Moment(inci_ts).format("HH:mm:ss.SSS").replace(/\.000/, "");
  const date = Moment(inci_ts).format("MMM DD");
  const datestamp = props.groupByDay ? null : (
    <span className={styles.datestamp}>{date}</span>
  );
  const timestamp = <span className={styles.timestamp}>{time}</span>;
  const logs = (
    <span className={styles.logs} title={inci_logs}>
      {inci_logs.length > 40 ? inci_logs.substring(0, 40) + "..." : inci_logs}
    </span>
  );
  const hosts = (
    <span className={styles.hosts} title={inci_sources}>
      {inci_sources.length > 60
        ? inci_sources.substring(0, 60) + "..."
        : inci_sources}
    </span>
  );
  let svc_grps = (inci_svc_grps || "").split(",").join(", ");
  svc_grps =
    svc_grps.length > 40 ? svc_grps.substring(0, 40) + "..." : svc_grps;

  const manualTags = itype_tags || [];
  let tagDisplay = manualTags.map((t, index) => (
    <span
      key={t.tag_id}
      className={`${styles.tagType} ${styles.manual}`}
      onMouseDown={(evt) => props.showTagSelector(evt, props)}
    >
      {t.tag_name}
    </span>
  ));
  if (itype_state === "custom") {
    tagDisplay = null;
  } else if (!tagDisplay || tagDisplay.length === 0) {
    tagDisplay = (
      <span
        className={`${styles.tagType} ${styles.addTag}`}
        onMouseDown={(evt) => props.showTagSelector(evt, props)}
      >
        <FontAwesomeIcon icon={faPlus} className={styles.plus} />
        <FontAwesomeIcon icon={faTags} className={styles.tag} />
      </span>
    );
  }

  const service_groups = svc_grps.length > 0 && (
    <span className={styles.serviceGroups} title={"Service Groups"}>
      {svc_grps}
    </span>
  );

  const events = (inci_events || []).map((event, index) => {
    event.text = applyHighlights
      ? applyTextHighlights(event.ievt_etext, searchTermRe, findTermRe)
      : event.ievt_etext;

    let eventType = null;
    let options = { wrapInSVG: true, size: 20, rotate: 30 };

    if (itype_state === "custom" && event.ievt_level === 0) {
      eventType = "alertRuleMatch";
      options.tooltip = "Log line matches Alert rule";
      options.rotate = 0;
    } else {
      eventType = "hand";
      options.tooltip = "Log line of interest";
    }

    return (
      <div key={event.ievt_id} className={styles.incidentEvent}>
        <div className={styles.icon}>
          <CustomIcon name={eventType} options={options} />
        </div>
        <div>{event.text}</div>
      </div>
    );
  });

  const resolvedReportLink = props.reportLink
    .replace(/>>ITYPE_ID<</, itype_id)
    .replace(/>>INCI_ID<</, inci_id);
  const resolvedReportLinkForWordCloud = props.reportLinkForWordCloud
    .replace(/>>ITYPE_ID<</, itype_id)
    .replace(/>>INCI_ID<</, inci_id);

  let wordCloud =
    itype_state !== "custom" ? (
      <WordCloud
        listView={true}
        words={inci_words}
        wordURL={resolvedReportLinkForWordCloud}
        minWidth={"400px"}
        style={{ flex: "1 0 400px" }}
      />
    ) : null;

  const significance = (
    <SignificanceIcon
      showTooltip={true}
      size={"lg"}
      inci_significance={inci_significance}
    />
  );

  return (
    <div
      className={rowCSS}
      key={inci_code}
      onMouseDown={() => props.storeRCListURL(itype_id, inci_id, inci_code)}
      id={`inci-${inci_code}`}
    >
      <div className={styles.flexRow}>
        <div className={styles.flexColumn}>
          <a id={findId} href={resolvedReportLink} className={styles.eventList}>
            <div className={styles.body}>
              <div className={styles.incidentIndicators}>
                {significance}
                {resultOfScan}
              </div>

              <div style={{ flex: "1 1 100%", marginLeft: "25px" }}>
                <div className={styles.titlebar}>{title}</div>

                <div className={styles.subtitle}>
                  {itypeIcon}
                  {datestamp}
                  {timestamp}
                  {occurrences}

                  {tagDisplay}
                  {service_groups}
                  {logs}
                  {hosts}
                </div>

                <div className={`${styles.events}`}>{events}</div>
              </div>

              {wordCloud}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

//todo: promote this function to a common area so that event-list and incident-list can both reference it
function applyTextHighlights(etext, searchTermRe, findTermRe) {
  let allMatches = [];

  if (searchTermRe) {
    const searchMatches = etext.matchAll(searchTermRe);

    for (const match of searchMatches) {
      allMatches.push({
        start: match.index,
        end: match.index + match[0].length,
        text: match[0],
        type: "search",
      });
    }
  }

  if (findTermRe) {
    const findMatches = etext.matchAll(findTermRe);
    for (const match of findMatches) {
      const m1 = {
        start: match.index,
        end: match.index + match[0].length,
        text: match[0],
        type: "find",
      };

      const overlapped =
        allMatches.findIndex(
          (m) =>
            (m1.start >= m.start && m1.start <= m.end) ||
            (m1.end >= m.start && m1.end <= m.end)
        ) > -1;

      if (!overlapped) {
        allMatches.push(m1);
      }
    }
  }

  if (allMatches.length) {
    let text = String(etext);
    let htmlText = [];
    let index = 0;

    allMatches.sort((a, b) => (a.start > b.start ? 1 : -1));

    allMatches.forEach((m, matchIndex) => {
      const id = index === 0 ? `find-${findIndex}` : null;
      const tsKey = `f-${new Date().getTime()}${matchIndex}`;
      const taggedText =
        m.type === "find" ? (
          <u key={tsKey} id={id}>
            {m.text}
          </u>
        ) : (
          <b key={tsKey}>{m.text}</b>
        );
      htmlText.push(text.substring(index, m.start));
      htmlText.push(taggedText);
      index = m.end;
    });

    findIndex += 1;

    htmlText.push(text.substring(index));

    return <span>{htmlText}</span>;
  } else {
    return <span>{etext}</span>;
  }
}

const TagSelectorDialogContent = forwardRef((props, ref) => {
  const { inci_tag_names, itype_tags } = props.incident;

  const autoTags = inci_tag_names;
  const manualTags = (itype_tags || []).map((t) => t.tag_name);

  const [manualTagSelections, setManualTagSelections] = useState([]);

  useEffect(() => {
    const selections = props.allManualTags.map((t) => {
      const isChecked = manualTags.includes(t.tag_name);
      return {
        tag_name: t.tag_name,
        tag_id: t.tag_id,
        selected: isChecked,
        css:
          isChecked === false
            ? `${styles.tagCheckbox} ${styles.disabled}`
            : styles.tagCheckbox,
      };
    });

    setManualTagSelections(selections);
  }, [props.incident]);

  const update = (t) => {
    let selections = [...manualTagSelections];
    const index = selections.findIndex((_t) => _t.tag_id === t.tag_id);
    if (index > -1) {
      let tag = { ...selections[index] };
      tag.selected = !tag.selected;
      tag.css =
        tag.selected === false
          ? `${styles.tagCheckbox} ${styles.disabled}`
          : styles.tagCheckbox;
      selections.splice(index, 1, tag);
      setManualTagSelections(selections);
    }
  };

  const getTags = () => {
    return manualTagSelections
      .filter((s) => s.selected === true)
      .map((s) => s.tag_id)
      .join(",");
  };

  useImperativeHandle(ref, () => ({
    getTags: getTags,
  }));

  return (
    <div className={styles.tagSelector}>
      {autoTags && (
        <div className={styles.tagRow}>
          <p>
            This <b>Occurrence</b> is <b>Auto-tagged</b>:
          </p>
          <div>
            {autoTags.map((t, index) => (
              <span key={`${t}`} className={`${styles.tagType} ${styles.auto}`}>
                {t}
              </span>
            ))}
          </div>
        </div>
      )}
      {manualTagSelections && manualTagSelections.length > 0 && (
        <div className={styles.tagRow}>
          <p>
            This <b>Alert Type</b> is <b>Manually</b> tagged:
          </p>
          <div>
            {manualTagSelections.map((t, index) => {
              return (
                <label key={t.tag_id} className={t.css} htmlFor={t.tag_id}>
                  <input
                    id={t.tag_id}
                    type={"checkbox"}
                    checked={t.selected}
                    onChange={() => update(t)}
                  />
                  <span className={`${styles.tagType} ${styles.manual}`}>
                    {t.tag_name}
                  </span>
                </label>
              );
            })}
          </div>
        </div>
      )}
      {(!manualTagSelections || manualTagSelections.length === 0) && (
        <div className={styles.tagRow}>
          <i>
            No tags have been created for this deployment. To get started, visit
            the main menu in the upper right of the UI&nbsp;&nbsp;
            <FontAwesomeIcon icon={faBars} size={"lg"} />
            &nbsp;&nbsp;and click Manage Tags
          </i>
        </div>
      )}
    </div>
  );
});

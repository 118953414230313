import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Row, useModalDialog, Column } from "athena-next-ui-lib";
import styled from "styled-components";

import { RelatedSuggestion } from "/components-biz";

const StyledNoDataOrErrorDiv = styled("div")`
  padding: 20px 10px;
  color: goldenrod;
  font-weight: bold;
  font-size: "14px";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledListContainer = styled("div")`
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
`;

export const DialogRelatedSuggestions = forwardRef((props, ref) => {
  const { openModalDialog } = useModalDialog();

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const getSuggestedList = (alertList) => {
    return alertList.map((alert, index) => {
      return (
        <Row>
          <RelatedSuggestion alert={alert} />
        </Row>
      );
    });
  };

  const getContent = (alertList, alertListResponse) => {
    let dialogContent = null;
    if (alertListResponse) {
      if (alertListResponse.code === 200) {
        //successful call
        if (alertList?.length > 0) {
          dialogContent = (
            <StyledListContainer>
              {getSuggestedList(alertList)}
            </StyledListContainer>
          );
        } else {
          dialogContent = (
            <StyledNoDataOrErrorDiv>
              <strong>
                No related alerts or suggestions found in this time range.
              </strong>
            </StyledNoDataOrErrorDiv>
          );
        }
      } else {
        //api call failed
        dialogContent = (
          <StyledNoDataOrErrorDiv>
            ERROR: {alertListResponse.message}
          </StyledNoDataOrErrorDiv>
        );
      }
    }
    return dialogContent;
  };

  const showDialog = (dialogData) => {
    const title = "Related Alerts & Suggestions";
    const buttonLabel = "OK";

    const { alertListData, alertListResponse, searchTimeRange } = dialogData;

    const subTitle = searchTimeRange && (
      <div
        style={{ margin: "5px 0px", fontSize: "12px", fontWeight: "normal" }}
      >
        {`Searching a 4 hour window:  ${searchTimeRange.from.displayTS}`}{" "}
        &mdash; {`${searchTimeRange.to.displayTS}`}
      </div>
    );

    const modalDialogSettings = {
      title: (
        <Column>
          <div>{title}</div>
          {subTitle}
        </Column>
      ),
      icon: null,
      minWidth: "800px",
      minHeight: "10vh",
      submitLabel: buttonLabel,
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
            overflowY: "auto",
            paddingRight: "4px",
          }}
        >
          {getContent(alertListData, alertListResponse)}
        </div>
      ),
    };

    openModalDialog(modalDialogSettings);
  };

  return null;
});

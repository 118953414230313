import React, { useEffect, useState } from "react";
import { Field, FieldTypes } from "athena-next-ui-lib";

const PRIORITY_LIST = [
  { label: "P1", value: "P1" },
  { label: "P2", value: "P2" },
  { label: "P3", value: "P3" }, //P3 is default
  { label: "P4", value: "P4" },
  { label: "P5", value: "P5" },
];

export function FieldPrioritySelector(props) {
  const { fieldRef, selectedPriorityString, label, labelAlign } = props;
  const [selPriorityArr, setSelPriorityArr] = useState([]);

  useEffect(() => {
    if (selectedPriorityString) {
      setSelPriorityArr(deriveSelPriorityByString(selectedPriorityString));
    }
  }, [selectedPriorityString]);

  const fieldWidth = "200px";

  const deriveSelPriorityByString = (priorityString) => {
    const searchPriority = priorityString === "" ? "P3" : priorityString;

    const matchingPriority = PRIORITY_LIST.filter(
      (pri) => pri.value === searchPriority
    );
    return matchingPriority;
  };

  const defaultContentProps = {
    label: label,
    type: FieldTypes.SINGLE_SELECT,
    ref: fieldRef,
    fieldWidth: fieldWidth,
    labelAlign: labelAlign || "left",
    placeholder: "Select...",
    values: PRIORITY_LIST,
    name: "itype_outbound_priority",
    ...props,
  };

  return (
    <>
      <Field {...defaultContentProps} value={selPriorityArr} />
    </>
  );
}

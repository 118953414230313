import React from "react";
import styled from "styled-components";
import { reportHeaderCSS } from "./css";

const StyledBannerDiv = styled("div")`
  vertical-align: baseline;
  padding: 2px 4px;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  i {
    font-size: 10px;
    color: inherit;
    opacity: 0.8;
  }
  span {
  }

  i + span {
    margin-left: 4px;
  }

  color: ${(props) => props.$css.banner.color};
  border-color: ${(props) => props.$css.banner.color};
  background: ${(props) => props.$css.banner.background};

  .lightTheme & {
    filter: brightness(80%);
  }
`;

export const Banner = (props) => {
  let label = "";
  const { state } = props;

  if (!state) return null;

  const css = reportHeaderCSS[state];

  switch (state) {
    case "custom":
      label = (
        <>
          <i>based on</i>
          <span>Custom Alert Rule</span>
        </>
      );
      break;
    case "suggested":
      label = "Suggested by AI/ML";
      break;
    case "rejected":
      label = "Rejected";
      break;
    case "accepted":
      label = (
        <>
          <i>based on</i>
          <span>Accepted Alert Rule</span>
        </>
      );
      break;
  }

  return <StyledBannerDiv $css={css}>{label}</StyledBannerDiv>;
};

import { Utilities, LoadingAnim } from "athena-next-ui-lib";
import styles from "/components-ui/FindBar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { IncidentListContext } from "/components-biz/incidents";

let keyInputTimer = null;

export function IncidentFindBar(props) {
  const ac = useContext(IncidentListContext);
  const [findTerm, setFindTerm] = useState(ac.findTerm || "");
  const [searchTerm, setSearchTerm] = useState("");
  const [findIndex, setFindIndex] = useState(0);
  const findMatchCount =
    ac.findMatches && ac.findMatches.length
      ? ac.findMatches.reduce((count, bucket) => {
          return count + bucket.alerts_count;
        }, 0)
      : 0;
  const objectName = "Alert";

  useEffect(() => {
    if (
      (ac.findTerm && ac.findTerm !== findTerm) ||
      (ac.search && ac.search !== searchTerm)
    ) {
      setFindIndex(0);
      setSearchTerm(ac.search);
      setFindTerm(ac.findTerm);
      sendQuery(ac.findTerm);
    }
  }, [ac.findTerm, ac.search]);

  const clearFindTerm = (evt) => {
    evt.stopPropagation();
    setFindTerm("");
    setFindIndex(0);
    delayedQuery("");
  };

  const updateFindTerm = (evt) => {
    evt.stopPropagation();
    const term = evt.target.value;
    setFindTerm(term);
    delayedQuery(term);
  };

  const sendQuery = (find) => {
    ac.applyFindTerm && ac.applyFindTerm(find);
  };

  const delayedQuery = useCallback(
    debounce((find) => sendQuery(find), 2000),
    []
  );

  const find = (dir) => {
    let newIndex = Math.max(0, findIndex + dir);

    newIndex = Math.min(newIndex, findMatchCount - 1);

    newIndex = findMatchCount === 1 ? 0 : newIndex;

    const element = document.getElementById("find-" + newIndex);

    if (element) {
      element.scrollIntoView(true);
      setFindIndex(newIndex);
    }
  };

  const css = `${styles.findBar} ${styles.read}`;

  const findTermExists = findTerm && findTerm.length > 0;

  let resultCount = null;

  if (
    ac.displayedAlertCount !== 0 &&
    ac.displayedAlertCount !== ac.totalAlertCount
  ) {
    resultCount = (
      <span>
        Displaying <b>{ac?.displayedAlertCount?.toLocaleString()}</b>
        &nbsp;of&nbsp;<b>{ac?.totalAlertCount?.toLocaleString()}</b>{" "}
        {Utilities.Strings.pluralize(objectName, ac?.totalAlertCount)}
      </span>
    );
  } else {
    resultCount = (
      <span>
        Displaying <b>{ac?.displayedAlertCount?.toLocaleString()} </b>{" "}
        {Utilities.Strings.pluralize(objectName, ac?.displayedAlertCount)}
      </span>
    );
  }

  let matchCount = null;

  if (findTermExists) {
    const count =
      ac.findTermProcessing === true ? (
        <LoadingAnim />
      ) : (
        findMatchCount.toLocaleString()
      );
    matchCount = (
      <div className={styles.resultCount}>
        <span>
          Matched on <b>{count}</b>{" "}
          {Utilities.Strings.pluralize(objectName, findMatchCount)}
        </span>
      </div>
    );
  } else {
    matchCount = (
      <label>
        Highlight {Utilities.Strings.pluralize(objectName, 2)} that Match:
      </label>
    );
  }
  let numberOfResults = <div className={styles.resultCount}>{resultCount}</div>;

  const inputCSS = ac.findTerm && ac.findTerm.length ? styles.notEmpty : "";

  let findControls = (
    <div className={styles.controls}>
      {matchCount}
      <div className={styles.field}>
        <input
          type={"text"}
          className={inputCSS}
          placeholder={"Enter text or /regex/"}
          value={findTerm}
          spellCheck={false}
          onChange={(evt) => updateFindTerm(evt)}
        />
        {findTermExists && (
          <div
            className={styles.clearField}
            onClick={(evt) => clearFindTerm(evt)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>

      <button
        onClick={() => find(1)}
        disabled={!findTermExists || findIndex === findMatchCount - 1}
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </button>

      <button
        onClick={() => find(-1)}
        disabled={!findTermExists || findIndex === 0}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </div>
  );

  const baseProps = { "data-testid": "incident-findbar" };

  if (ac.isLoadingAlertData) {
    return null;
  } else if (ac?.alertList?.some((item) => item.alerts !== null)) {
    return (
      <div {...baseProps} className={css}>
        {numberOfResults}
        <span className={styles.forceRight}>&nbsp;</span>
        {findControls}
      </div>
    );
  } else {
    return null;
  }
}

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

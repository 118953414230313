export const loadTagFiltersForIncidents = (
  dataService,
  queryState,
  setters,
  SEQ_BASE
) => {
  return dataService
    .fetch("tag/read", {
      sort: ["tag_name"],
      args: { load_rules: false },
    })
    .then((apiResponse) => {
      return deriveTagFilters(apiResponse, queryState, setters, SEQ_BASE);
    });
};

export const loadTagFiltersForEvents = (
  dataService,
  queryState,
  inci_id,
  ievt_level,
  setters,
  SEQ_BASE
) => {
  return dataService
    .fetch("tag/read/tagids", {
      inci_id,
      ievt_level: parseInt(ievt_level),
      sort: ["tag_name"],
      args: { load_rules: false },
    })
    .then((apiResponse) => {
      return deriveTagFilters(apiResponse, queryState, setters, SEQ_BASE);
    });
};

export const deriveTagFilters = (
  apiResponse,
  queryState,
  setters,
  SEQ_BASE
) => {
  const {
    setCurrentFilter,
    setAllManualTags,
    setFilterableManualTags,
    setRoutingRules,
    setCustomRules,
    setManualTags,
  } = setters;

  return Promise.resolve().then(() => {
    const {
      manual_tag: manualTagsInQuery,
      custom_rules: customRulesInQuery,
      routing_rules: routingRulesInQuery,
    } = queryState;

    const selectedManualTags = manualTagsInQuery
      ? manualTagsInQuery.split(",")
      : [];
    const selectedCustomRules = customRulesInQuery
      ? customRulesInQuery.split(",")
      : [];
    const selectedRoutingRules = routingRulesInQuery
      ? routingRulesInQuery.split(",")
      : [];

    //----------auto tag filter-------------
    let manualTagFilterEnabled = false;
    let manualTags = (apiResponse?.data || []).filter(
      (tg) => tg.tag_type === "manual"
    );

    if (manualTags.length > 0) {
      manualTags.unshift({
        tag_name: "none",
        tag_id: "none",
        tag_type: "manual",
      });
      manualTags.map((item) => {
        item.name = item.tag_name;
        item.value = item.tag_name;
        item.id = item.tag_id;
      });
      manualTagFilterEnabled = true;
    }

    manualTagFilterEnabled &&
      setCurrentFilter &&
      setCurrentFilter({
        seq: SEQ_BASE + 15,
        expanded: false,
        category: "Manual Tag",
        items: manualTags.map((item) => {
          //matching tag name
          const findIndex = selectedManualTags.findIndex(
            (t) => t.toLowerCase() == item.tag_name.toLowerCase()
          );
          const isSelected = findIndex > -1;
          return {
            ...item,
            selected: isSelected, //this.props.router.query.category && this.props.router.query.category === item.value ? true : false
          };
        }),
        selectionStyle: "multiple",
        apiParam: "manual_tag",
      });

    //----------custom rule filter-------------
    let customRuleFilterEnabled = false;
    let customRules = (apiResponse?.data || []).filter(
      (tg) => tg.tag_type === "user_driven"
    );

    if (customRules.length > 0) {
      //customRules.unshift({tag_name: "none", tag_id: "none"}); hides this filter until we have Bake support
      customRules.map((item) => {
        item.name = item.tag_name;
        item.value = item.tag_name;
        item.id = item.tag_id;
      });
      customRuleFilterEnabled = true;
    }

    customRuleFilterEnabled &&
      setCurrentFilter &&
      setCurrentFilter({
        seq: SEQ_BASE + 13,
        expanded: false,
        category: "Custom Alert Rule",
        items: customRules.map((item) => {
          //matching tag name
          const findIndex = selectedCustomRules.findIndex(
            (t) => t.toLowerCase() == item.tag_name.toLowerCase()
          );
          const isSelected = findIndex > -1;
          return {
            ...item,
            selected: isSelected, //this.props.router.query.category && this.props.router.query.category === item.value ? true : false
          };
        }),
        selectionStyle: "multiple",
        apiParam: "custom_rules",
      });

    //----------routing rule filter-------------
    let routingRuleFilterEnabled = false;
    let routingRules = (apiResponse?.data || []).filter(
      (tg) => tg.tag_type === "routing"
    );

    if (routingRules.length > 0) {
      routingRules.map((item) => {
        item.name = item.tag_name;
        item.value = item.tag_name;
        item.id = item.tag_id;
      });
      routingRuleFilterEnabled = true;
    }

    routingRuleFilterEnabled &&
      setCurrentFilter &&
      setCurrentFilter({
        seq: SEQ_BASE + 14,
        expanded: false,
        category: "ML Routing Rule",
        items: routingRules.map((item) => {
          //matching tag name
          const findIndex = selectedRoutingRules.findIndex(
            (t) => t.toLowerCase() == item.tag_name.toLowerCase()
          );
          const isSelected = findIndex > -1;
          return {
            ...item,
            selected: isSelected, //this.props.router.query.category && this.props.router.query.category === item.value ? true : false
          };
        }),
        selectionStyle: "multiple",
        apiParam: "routing_rules",
      });

    const filterableManualTags = [...manualTags];
    let allManualTags = [...manualTags];
    allManualTags?.length > 0 && allManualTags.shift();

    if (setRoutingRules) setRoutingRules(routingRules);
    if (setCustomRules) setCustomRules(customRules);
    if (setAllManualTags) setAllManualTags(allManualTags);
    if (setManualTags) setManualTags(allManualTags);
    if (setFilterableManualTags) setFilterableManualTags(filterableManualTags);

    return true;
  });
};

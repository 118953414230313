import React, { useContext } from "react";
import {
  IncidentListContext,
  TimelineContext,
} from "/components-biz/incidents";

import styles from "./IncidentTimeline.module.scss";

export function SpikePlot() {
  const incidentListContext = useContext(IncidentListContext);
  const tc = useContext(TimelineContext);

  const { alertBuckets, bucketAlertsLimit, spikeState } = incidentListContext;
  const { bucketWidth, totalH, xAxisH } = tc;
  const height = totalH - xAxisH;

  const adjustedH = height - 5;
  const cX = bucketWidth * 0.5;
  const x1 = cX - 2;
  const x2 = cX + 2;
  const y0 = height;
  const d = 5;
  const dY = d * 1.5;

  const scrollToItem = (evt, bucketId) => {
    const { selectedBuckets } = tc;
    const { start, end } = selectedBuckets;
    if (start === end && end === bucketId) {
      evt.stopPropagation();
      const timeRange = document.getElementById(`range-${bucketId}`);
      if (timeRange) timeRange.scrollIntoView({ behavior: "smooth" });
    } else {
      //allow mouseUpEvent to bubble so that drag to zoom operations will be received on the parent SVG dom element
    }
  };

  let spikes = [];
  alertBuckets.forEach((item, index) => {
    const x0 = index * bucketWidth;
    const count = item["alerts_count"];
    if (count > bucketAlertsLimit) {
      const spikeH = y0 - adjustedH + 10;

      const spike = (
        <path
          className={styles.spike}
          d={`M ${x0 + x1} ${y0} L ${x0 + cX} ${spikeH} L ${x0 + x2} ${y0} L ${
            x0 + x1
          } ${y0} Z`}
        />
      );
      const mark = (
        <path
          className={styles.mark}
          d={`M ${x0 + cX} ${
            y0 - 10
          } l${-d} ${-dY} l${d} ${-dY} l${d} ${dY}  l${-d} ${dY} Z`}
        />
      );
      let css =
        item.findMatchCount > 0
          ? `${styles.kebabBucket} ${styles.matched}`
          : styles.kebabBucket;

      css =
        spikeState.findIndex((item) => item === index) > -1
          ? `${css} ${styles.expanded}`
          : css;

      const key = `${item.time_from}${index}`;

      spikes.push(
        <g
          key={key}
          className={css}
          onMouseUp={(evt) => scrollToItem(evt, index)}
        >
          {spike}
          {mark}
          <rect x={x0} y={0} width={bucketWidth} height={height} />
          <text
            className={styles.bucketCount}
            x={x0 + bucketWidth * 0.5}
            y={-12}
          >
            {count.toLocaleString()}
          </text>
        </g>
      );
    }
  });

  return <g className={styles.kebab}>{spikes}</g>;
}

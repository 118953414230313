import React, {
  useState,
  createContext,
  useRef,
  useEffect,
  useContext,
} from "react";
import IntegrationUtils from "../../_utilities/integrationUtils";
import { PageContext, DataServiceContext, Utilities } from "athena-next-ui-lib";
import { ListModes } from "/components-ui";
import { loadTagFiltersForEvents } from "/components-biz";
export const ReportContext = createContext();

const searchLimit = 2000;

export const ActionModes = {
  enterEditMode: "enterEditMode",
  exitEditMode: "exitEditMode",
  enterPeekMode: "enterPeekMode",
  exitPeekMode: "exitPeekMode",
};

export const listId = "event-list";

export function ReportContextProvider(props) {
  const dataServiceContext = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);

  const { env, router } = pageContext;
  const [readyToFetch, setReadyToFetch] = useState(false);
  const [readyToLoadData, setReadyToLoadData] = useState(false);

  const [manualTags, setManualTags] = useState(null);
  const [routingRules, setRoutingRules] = useState(null);
  const [customRules, setCustomRules] = useState(null);

  const [outboundChannels, setOutboundChannels] = useState(null);
  const [itypeData, setItypeData] = useState(null);
  const [itypeDataResponse, setItypeDataResponse] = useState({ code: 0 });

  const [actionMode, setActionMode] = useState(null);
  const [actionEvent, setActionEvent] = useState(null);
  const [eventLevelToRestore, setEventLevelToRestore] = useState(null);
  const [listMode, setListMode] = useState(null);

  const [currentInciId, setCurrentInciId] = useState(null);
  const [events, setEvents] = useState({ response: { code: 0 }, data: [] });

  const [findTerm, setFindTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSummary, setFilterSummary] = useState([]);

  const [originalAlertKeys, setOriginalAlertKeys] = useState([]);
  const [alertKeys, setAlertKeys] = useState([]);

  const [eventIdInPeekMode, setEventIdInPeekMode] = useState(null);

  const queryState = useRef({});

  useEffect(() => {
    //store the queryState once router reports it is initialized
    if (pageContext.isReady === true) {
      setReadyToFetch(true);
    }
  }, [pageContext.isReady]);

  useEffect(() => {
    if (readyToFetch) {
      Promise.resolve()
        .then(() => loadTagFilters())
        .then(() => setReadyToLoadData(true));
    }
  }, [readyToFetch]);

  useEffect(() => {
    if (readyToLoadData) {
      loadData();
    }
  }, [readyToLoadData]);

  useEffect(() => {
    if (currentInciId == null) return;

    let query = { ...getQueryState() };

    console.log("---- current inci id:" + currentInciId);

    delete query.ievt_id;
    delete query.ievt_etroot;
    query.inci_id = currentInciId;
    if (currentInciId) {
      Promise.resolve()
        .then(() => setQueryState(query))
        .then(() => loadTagFilters())
        .then(() =>
          Promise.all([
            loadIncidentTypeDataByInciId(currentInciId),
            loadEventsList(currentInciId),
          ])
        );
    }
  }, [currentInciId]);

  const applyFindTerm = (term) => {
    let query = { ...getQueryState() };
    query.find = term;

    return Promise.resolve()
      .then(() => setFindTerm(term))
      .then(() => {
        return setQueryState(query);
      });
  };

  const applySearchTerm = (term, scope) => {
    const query = { ...getQueryState() };

    if (term.length) {
      query.search = term;
      query.ievt_level = scope.value || 2;
    } else {
      delete query.search;
      query.ievt_level = 2;
    }

    return Promise.resolve()
      .then(() => {
        return setQueryState(query);
      })
      .then(() => loadEventsList())
      .then(() => {
        if (query.ievt_id && events) {
          const event = events.data.find(
            (event) => event.ievt_id === query.ievt_id
          );
          if (event) {
            selectEvent(event, {
              scrollToEvent: true,
              scrollToEventDot: true,
              blink: true,
              delay: true,
            });
          }
        }
      });
  };

  const scrollEventIntoView = (elementId, behavior, blink) => {
    const element = document.getElementById(elementId);

    if (element !== null) {
      element.scrollIntoView({
        behavior: behavior || "smooth",
        block: "center",
        inline: "nearest",
      });

      if (blink) {
        setTimeout(() => {
          if (element) element.classList.add("blink");
        }, 1000);
        setTimeout(() => {
          if (element) element.classList.remove("blink");
        }, 4000);
      }
    }
  };

  const selectEvent = (event, _options) => {
    let query = { ...getQueryState() };
    const options = {
      blink: false,
      scrollToEvent: false,
      scrollToEventDot: false,
      delay: false,
      ..._options,
    };

    let eventId;

    if (event) {
      query.ievt_id = event.ievt_id;
      query.ievt_etroot = event.ievt_etroot;
      eventId = event.ievt_id;
    }

    return Promise.resolve()
      .then(() => setQueryState(query))
      .then(() => {
        if (event) {
          if (options.scrollToEventDot && options.scrollToEvent) {
            scrollEventIntoView(`evt-${eventId}`, "auto", options.blink);
            scrollEventIntoView(`evt-dot-${eventId}`, "auto", options.blink);
          } else if (options.scrollToEvent)
            scrollEventIntoView(`evt-${eventId}`, "auto", options.blink);
          else if (options.scrollToEventDot)
            scrollEventIntoView(`evt-dot-${eventId}`, "auto", options.blink);
        }
      });
  };

  useEffect(() => {
    if (actionMode == null) return;

    if (actionMode === ActionModes.enterEditMode) {
      setListMode(ListModes.edit);
      return;
    } else if (actionMode === ActionModes.exitEditMode) {
      setListMode(ListModes.read);
      return;
    }

    if (actionEvent == null) return;
  }, [actionMode, actionEvent]);

  const setQueryState = (qs) => {
    queryState.current = qs;
    const url = `/root-cause/report?${Utilities.JS.generateQueryString(qs)}`;
    router.push(url, Utilities.Router.createAsPath(url, env));
  };

  const getQueryState = () => {
    const user = dataServiceContext.getUserProfile();

    const qs =
      Object.keys(queryState.current).length < 1
        ? { ...router.query }
        : { ...queryState.current };

    return { ...qs, deployment_id: user?.deployment_id };
  };

  const loadData = () => {
    const { itype_id, inci_id, ievt_gen } = getQueryState();

    if (!itype_id || !inci_id) return;

    const inPeekMode = ievt_gen && ievt_gen !== undefined;

    return Promise.all([
      setListMode(inPeekMode ? ListModes.peek : ListModes.read),
      loadOutboundChannel(),
    ])
      .then(() => loadIncidentTypeData())
      .then(() => loadEventsList());
  };

  const loadIncidentTypeDataByInciId = (inciId) => {
    return Promise.resolve()
      .then(() => setItypeDataResponse({ code: 0 }))
      .then(() =>
        dataServiceContext.fetch("incident/read/list", {
          repeating_incidents: "all",
          occurrences: "none",
          time_from: 1,
          time_to: 99999999999,
          inci_id: inciId,
        })
      )
      .then((fetchCall) => {
        setItypeDataResponse(fetchCall.response);
        if (
          fetchCall.response.code === 200 &&
          fetchCall.data &&
          fetchCall.data.length
        ) {
          setItypeData(fetchCall.data[0]);
        } else {
          throw fetchCall.response;
        }
        return;
      });
  };

  const loadIncidentTypeData = () => {
    const { inci_id } = getQueryState();
    return loadIncidentTypeDataByInciId(inci_id);
  };

  const loadOutboundChannel = () => {
    return Promise.resolve()
      .then(() =>
        IntegrationUtils.getOutboundChannels(pageContext, dataServiceContext)
      )
      .then((getOutboundCall) => {
        if (getOutboundCall.response.code == 200) {
          const dataOut = (getOutboundCall || {}).data || [];

          const derivedOutboundChannels = (dataOut || []).map((itm) => {
            return {
              label: itm.name,
              value: itm.siid,
              integration: itm.integration,
            };
          });

          setOutboundChannels([...derivedOutboundChannels]);
          return;
        } else {
          throw getOutboundCall.response;
        }
      });
  };

  const loadEventsList = (inciId) => {
    const {
      ievt_id,
      ievt_gen,
      itype_id,
      inci_id,
      ievt_level,
      ievt_log,
      ievt_host,
      ievt_source,
      ievt_svc_grp,
      ievt_sevno,
      ievt_rare_idx,
      search,
      find,
      routing_rules,
      custom_rules,
    } = getQueryState();

    let options = {
      ievt_level: ievt_level * 1,
      inci_id: inciId || inci_id,
      itype_id: itype_id,
      limit: searchLimit,
    };

    const inPeekMode = ievt_gen && ievt_gen !== undefined;

    let filterSummary = [];
    //temporary simplification: only apply filters and search when not in peek mode
    if (!inPeekMode) {
      let filters = [];

      if (routing_rules || custom_rules) {
        let ievt_tag_ids = [];

        if (routing_rules && routingRules) {
          filterSummary.push(
            <span>
              Routing Rule: <b>{routing_rules.split(",").join(", ")}</b>
            </span>
          );

          routing_rules.split(",").forEach((tagName) => {
            const tag = routingRules.find((tag) => tag.name === tagName);
            if (tag?.id) ievt_tag_ids.push(tag.id);
          });
        }

        if (custom_rules && customRules) {
          filterSummary.push(
            <span>
              Custom Rule: <b>{custom_rules.split(",").join(", ")}</b>
            </span>
          );

          custom_rules.split(",").forEach((tagName) => {
            const tag = customRules.find((tag) => tag.name === tagName);
            if (tag?.id) ievt_tag_ids.push(tag.id);
          });
        }

        options.ievt_tag_ids = ievt_tag_ids;
      }

      if (custom_rules) {
        filterSummary.push(
          <span>
            Custom Rule: <b>{custom_rules.split(",").join(", ")}</b>
          </span>
        );
        filters.push(`custom_rules=${custom_rules}`);
      }

      if (ievt_log) {
        filterSummary.push(
          <span>
            Logtype: <b>{ievt_log.split(",").join(", ")}</b>
          </span>
        );
        filters.push(`ievt_log=${ievt_log}`);
      }

      if (ievt_host) {
        filterSummary.push(
          <span>
            Source: <b>{ievt_host.split(",").join(", ")}</b>
          </span>
        );
        filters.push(`ievt_host=${ievt_host}`);
      }

      if (ievt_source) {
        filterSummary.push(
          <span>
            Source: <b>{ievt_source.split(",").join(", ")}</b>
          </span>
        );
        filters.push(`ievt_source=${ievt_source}`);
      }

      if (ievt_svc_grp) {
        filterSummary.push(
          <span>
            Service Group: <b>{ievt_svc_grp.split(",").join(", ")}</b>
          </span>
        );
        filters.push(`ievt_svc_grp=${ievt_svc_grp}`);
      }

      if (ievt_sevno) {
        filterSummary.push(
          <span>
            Severity:{" "}
            <b>
              {ievt_sevno
                .split(",")
                .map((sevno) =>
                  Utilities.Strings.translateSeverityNumberToString(sevno)
                )
                .join(", ")}
            </b>
          </span>
        );
        filters.push(`ievt_sevno=${ievt_sevno}`);
      }

      if (ievt_rare_idx) {
        filterSummary.push(
          <span>
            Rareness:{" "}
            <b>
              {ievt_rare_idx
                .split(",")
                .map((rare_idx) =>
                  Utilities.Strings.translateRarenessNumberToString(rare_idx)
                )
                .join(", ")}
            </b>
          </span>
        );
        filters.push(`ievt_rare_idx=${ievt_rare_idx}`);
      }

      if (filters.length) {
        options.filter = filters;
      }

      if (search) {
        setSearchTerm(search);
        options.search = search;
      } else {
        delete options.search;
        setSearchTerm("");
      }

      if (find) {
        setFindTerm(find);
      } else {
        delete options.find;
        setFindTerm("");
      }
    }

    //generator used for peek
    if (inPeekMode) {
      filterSummary = []; //peek doesn't honor filters
      options.ievt_gen = ievt_gen;
    }

    //rid used for determining around what event to return results
    if (ievt_id) {
      options.ievt_id = ievt_id;
    }

    return Promise.resolve()
      .then(() => {
        setFilterSummary(filterSummary);
        setEvents({ response: { code: 0 }, data: null });
        return true;
      })
      .then(() => {
        return dataServiceContext.fetch("incidentevent/read/events", options);
      })
      .then((eventsCall) => {
        if (eventsCall.response.code === 200) {
          return setEvents(eventsCall);
        } else {
          //when error return, alert error message
          alert(
            "Unable to load: \n" +
              eventsCall.response.code +
              " - " +
              eventsCall.response.message
          );
          throw eventsCall.response;
        }
      });
  };

  const loadTagFilters = () => {
    const setters = {
      setManualTags,
      setRoutingRules,
      setCustomRules,
    };

    const qs = getQueryState();
    const { inci_id, ievt_level } = qs;

    return loadTagFiltersForEvents(
      dataServiceContext,
      qs,
      inci_id,
      ievt_level,
      setters,
      10
    );
  };

  const onFilterUpdate = (selectedFilters) => {
    let query = { ...getQueryState() };

    delete query.ievt_log;
    delete query.ievt_host;
    delete query.ievt_source;
    delete query.ievt_svc_grp;
    delete query.ievt_sevno;
    delete query.ievt_rare_idx;
    delete query.routing_rules;
    delete query.custom_rules;

    Object.keys(selectedFilters).forEach((prop) => {
      //query[prop]= selectedFilters[prop]
      const value =
        Array.isArray(selectedFilters[prop]) === true
          ? selectedFilters[prop].join(",")
          : selectedFilters[prop];
      query[prop] = value;
    });

    return Promise.resolve()
      .then(() => setQueryState(query))
      .then(() => loadEventsList());
  };

  const CORE_EVENTS_LEVEL = 2;
  // ALL_EVENTS_LEVEL is set at level 5;
  const PEEK_ZOOM_LEVEL = 6;

  const unpeek = () => {
    let query = { ...getQueryState() };
    query.ievt_id = eventIdInPeekMode;  //restore ievt_id set in peek mode
    query.ievt_level =
      eventLevelToRestore && eventLevelToRestore !== PEEK_ZOOM_LEVEL
        ? eventLevelToRestore
        : CORE_EVENTS_LEVEL;
    delete query.ievt_gen;

    return Promise.resolve()
      .then(() => {
        /* if the user has entered the page in peek mode (a peek mode URL was shared with them), 
        when they exit peek mode force it to read mode */
        if (listMode === ListModes.peek) {
          setListMode(ListModes.read);
        }
        return true;
      })
      .then(() => setEventLevelToRestore(query.ievt_level))
      .then(() => setQueryState(query))
      .then(() => loadTagFilters())
      .then(() => loadEventsList())
      .then(() => returnFocusToEvent(query.ievt_id));
  };

  const peek = (ievt_id, ievt_gen) => {
    setEventIdInPeekMode(ievt_id); //save for unpeek operation
    let query = { ...getQueryState() };
    const onUnpeekLevelToRestore = query.ievt_level;
    query.ievt_id = ievt_id;
    query.ievt_level = PEEK_ZOOM_LEVEL;
    query.ievt_gen = ievt_gen;

    return Promise.resolve()
      .then(() => setEventLevelToRestore(onUnpeekLevelToRestore))
      .then(() => setQueryState(query))
      .then(() => loadTagFilters())
      .then(() => loadEventsList())
      .then(() => returnFocusToEvent(query.ievt_id));
  };

  const setZoomLevel = (level) => {
    let query = { ...getQueryState() };

    query.ievt_level = level;
    const levelToRestore = level;

    delete query.ievt_gen; //unpeek when crossing zoom levels

    return Promise.resolve()
      .then(() => setEventLevelToRestore(levelToRestore))
      .then(() => setQueryState(query))
      .then(() => loadTagFilters())
      .then(() => loadEventsList())
      .then(() => returnFocusToEvent(query.ievt_id));
  };

  const returnFocusToEvent = (ievt_id) => {
    if (ievt_id && events?.data?.length) {
      const event = events.data.find((e) => e.ievt_id === ievt_id);
      if (event) {
        selectEvent(event, { scrollToEvent: true, scrollToEventDot: true });
      }
    }

    return true;
  };

  const navigateToList = () => {
    let query = { ...getQueryState() };
    const inci_id = query.inci_id;
    const key = `rcListURL${inci_id}`;
    const storedURLString = window.localStorage.getItem(key);
    window.localStorage.removeItem(key);

    const reportURL = new URL(window.location.href);
    const reportParams = new URLSearchParams(reportURL.search);

    const serviceGroups = itypeData.inci_svc_grps.split(",").join(", ");

    let targetURL;

    if (storedURLString) {
      const storedURL = new URL(
        storedURLString,
        dataServiceContext.getBaseUrl()
      );
      const storedParams = new URLSearchParams(storedURL.search);

      const storedDeploymentId = storedParams.get("deployment_id");
      const reportDeploymentId = reportParams.get("deployment_id");
      if (storedDeploymentId !== reportDeploymentId) {
        targetURL = dataServiceContext.deriveLandingPageURL(
          reportParams.get("deployment_id")
        );
        if (serviceGroups.length)
          targetURL = `${targetURL}&service_groups=${serviceGroups}`;
      } else {
        targetURL = storedURLString;
      }
    } else {
      targetURL = dataServiceContext.deriveLandingPageURL(
        reportParams.get("deployment_id")
      );
      if (serviceGroups.length)
        targetURL = `${targetURL}&service_groups=${serviceGroups}`;
    }
    router.push(targetURL, targetURL);
  };

  const copyEvent = (event) => {
    let eventCopy = {};
    Object.keys(event).forEach((key) => {
      if (key === "text") {
        eventCopy[key] = `${event["ievt_etext"]}`;
      } else {
        eventCopy[key] = event[key];
      }
    });
    return eventCopy;
  };

  const initAlertKeys = () => {
    const { itype_id, inci_id } = getQueryState();

    return Promise.resolve()
      .then(() => {
        // on first open of the edit key tray, we ask to compose the array of alert keys from the keys seen at level 0
        return dataServiceContext.fetch("incidentevent/read/events", {
          itype_id,
          inci_id,
          ievt_level: 0,
        });
      })
      .then((getAlertKeysCall) => {
        if (getAlertKeysCall.response.code === 200) {
          const keys = (getAlertKeysCall?.data || []).map((event) => {
            return { ...event, text: event.ievt_etext };
          });
          setOriginalAlertKeys(copyEvent(keys));
          setAlertKeys(keys);
        } else {
          setOriginalAlertKeys([]);
          setAlertKeys([]);
          alert("No Alert Keys found! Contact your system administrator.");
          throw Error(
            "No Alert Keys found! Contact your system administrator."
          );
        }
      })
      .then(() => setActionMode(ActionModes.enterEditMode));

    //we maintain this array on the ac context until the tray is closed/destroyed
  };

  const restoreOriginalAlertKeys = () => {
    const allEvents = [...events.data];

    // walk all events and restore the old key
    alertKeys.forEach((alertKey) => {
      const event = allEvents.find((e) => e.ievt_id === alertKey.ievt_id);
      event.ievt_level = event.ievt_level_last;
    });

    setEvents({ response: events.response, data: allEvents });

    setAlertKeys([...originalAlertKeys]);
  };

  const clearAlertKeys = () => {
    setAlertKeys([]);
  };

  const addAlertKey = (event) => {
    let keys = [...alertKeys];

    const index = keys.findIndex((e) => e.ievt_id === event?.ievt_id);

    if (index > -1) return;

    let eventCopy = copyEvent(event);
    eventCopy.ievt_level = 0;

    keys.push(eventCopy);

    keys.sort((a, b) => {
      return (
        a.ievt_ts.localeCompare(b.ievt_ts) ||
        a.ievt_ord - b.ievt_ord ||
        a.ievt_id.localeCompare(b.ievt_id)
      );
    });

    setAlertKeys(keys);
  };

  const removeAlertKey = (event) => {
    let keys = [...alertKeys];

    const index = keys.findIndex((e) => e.ievt_id === event?.ievt_id);

    if (index > -1) {
      keys.splice(index, 1);
      setAlertKeys(keys);
    }
  };

  const enterEditKeysMode = () => {
    return Promise.resolve()
      .then(() => collapseSidePane())
      .then(() => initAlertKeys())
      .then(() => setZoomLevel(2))
      .then(() => setActionMode(ActionModes.enterEditMode));
  };

  const exitEditKeysMode = () => {
    return Promise.resolve()
      .then(() => {
        setActionMode(ActionModes.exitEditMode);
      })
      .then(() => {
        setOriginalAlertKeys([]);
        setAlertKeys([]);
      })
      .then(() => expandSidePane());
  };

  const mainPaneWidth = "*";
  const sidePaneExpandedWidth = 400;
  const sidePaneCollapsedWidth = 0;
  const [sidePaneWidth, setSidePaneWidth] = useState(sidePaneExpandedWidth);

  const toggleSidePane = () => {
    setSidePaneWidth(
      sidePaneWidth === sidePaneExpandedWidth
        ? sidePaneCollapsedWidth
        : sidePaneExpandedWidth
    );
  };

  const expandSidePane = () => {
    setSidePaneWidth(sidePaneExpandedWidth);
  };

  const collapseSidePane = () => {
    setSidePaneWidth(sidePaneCollapsedWidth);
  };

  const isSidePaneCollapsed = () => {
    return sidePaneWidth === sidePaneCollapsedWidth;
  };

  const contextObj = {
    manualTags,
    routingRules,
    customRules,
    outboundChannels,
    itypeData,
    setItypeData,
    itypeDataResponse,
    setItypeDataResponse,
    setActionMode,
    setActionEvent,
    listMode,
    setListMode,
    currentInciId,
    setCurrentInciId,
    events,
    setEvents,
    applyFindTerm,
    findTerm,
    setFindTerm,
    applySearchTerm,
    searchTerm,
    filterSummary,
    onFilterUpdate,
    setZoomLevel,
    peek,
    unpeek,
    getQueryState,
    queryState,
    setQueryState,
    selectEvent,
    loadIncidentTypeData,
    navigateToList,
    refreshEventList: loadData,

    alertKeys,
    addAlertKey,
    removeAlertKey,
    initAlertKeys,
    restoreOriginalAlertKeys,
    clearAlertKeys,

    enterEditKeysMode,
    exitEditKeysMode,

    expandSidePane,
    collapseSidePane,
    toggleSidePane,
    isSidePaneCollapsed,
    sidePaneWidth,
    mainPaneWidth,
  };

  return (
    <ReportContext.Provider value={contextObj}>
      {props.children}
    </ReportContext.Provider>
  );
}

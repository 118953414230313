import React, { useMemo, useContext } from "react";
import {
  IncidentListContext,
  TimelineContext,
} from "/components-biz/incidents";

import styles from "./IncidentTimeline.module.scss";

export const HistogramTooltips = (props) => {
  const ac = useContext(IncidentListContext);
  const tc = useContext(TimelineContext);

  const setTooltip = (bucketNum) => {
    if (bucketNum) {
      const b = ac.alertBuckets[bucketNum];
      const tip = (
        <>
          <div className={styles.tooltipRow}>
            <div className={styles.histogramRow}>
              <div className={`${styles.logsLine} ${styles.swatch}`} />
              <div className={styles.label}>Log Lines</div>
              <div className={styles.num}>{numberFormatter(b.logs, 1)}</div>
            </div>
          </div>
          <div className={styles.tooltipRow}>
            <div className={styles.histogramRow}>
              <div className={`${styles.rareLine} ${styles.swatch}`} />
              <div className={styles.label}>Rare Events</div>
              <div className={styles.num}>{numberFormatter(b.rare, 1)}</div>
            </div>
          </div>
        </>
      );
      props.setTooltip(tip);
    } else {
      props.setTooltip(null);
    }
  };

  const buckets = useMemo(() => {
    return ac.alertBuckets.map((bucket, x) => {
      const key = `${bucket.id}${x}`;
      return (
        <g
          key={key}
          transform={`translate(${tc.bucketWidth * x} ${0})`}
          onMouseEnter={() => setTooltip(x)}
          onMouseLeave={() => setTooltip(null)}
        >
          <rect
            className={styles.histogramTooltipRect}
            x={0}
            y={0}
            width={tc.bucketWidth}
            height={tc.xAxisH}
          />
        </g>
      );
    });
  }, [
    ac.alertBuckets,
    tc.selectedBuckets,
    tc.numBuckets,
    tc.totalW,
    tc.totalH,
  ]);

  return buckets;
};

//todo: promote the following function to ui-lib
function numberFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

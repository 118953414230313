import React, { useMemo, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import { TimelineContext } from "/components-biz/incidents";
import styles from "./IncidentTimeline.module.scss";

export const IncidentTooltip = (props) => {
  const tc = useContext(TimelineContext);
  const DOM_tooltip = useRef();
  const { mouse } = tc;

  const tooltipContainer = useMemo(() => {
    if (props.tooltip && mouse) {
      const box = DOM_tooltip?.current?.getBoundingClientRect();
      const x =
        tc.mouse.rX > tc.totalW * 0.5 ? tc.mouse.x - box?.width : tc.mouse.x;
      const y = tc.mouse.y + 10;
      const pos = { top: y, left: x };

      const t = (
        <div ref={DOM_tooltip} className={styles.tooltipContainer} style={pos}>
          <div className={styles.tooltip}>{props.tooltip}</div>
        </div>
      );
      return ReactDOM.createPortal(t, document.getElementById("__next"));
    } else {
      return null;
    }
  }, [props.tooltip, mouse]);

  return tooltipContainer;
};

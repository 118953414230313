import React, { useMemo, useContext } from "react";
import {
  IncidentListContext,
  IncidentTypeBanner,
  CommonWords,
  Timestamp,
  Tags,
  TimelineContext,
} from "/components-biz/incidents";

import styles from "./IncidentTimeline.module.scss";

export const IncidentSymbolPlot = (props) => {
  const ac = useContext(IncidentListContext);
  const tc = useContext(TimelineContext);

  const { alertBuckets: buckets } = ac;
  const { graphH, bucketWidth, symbolWidth } = tc;

  const selectAlert = (evt, inci_id) => {
    if (tc.mouse.isDragging === false) {
      evt.stopPropagation();
      ac.selectAlert(inci_id);
    }
  };

  const setTooltip = (alert) => {
    if (alert) {
      if (alert.itype_state === "custom") {
        props.setTooltip(
          <>
            <div className={styles.tooltipRow}>
              <IncidentTypeBanner alert={alert} />
              <Timestamp alert={alert} />
            </div>
            <div className={styles.tooltipRow}>
              <Tags alert={alert} />
            </div>
          </>
        );
      } else {
        props.setTooltip(
          <>
            <div className={styles.tooltipRow}>
              <IncidentTypeBanner alert={alert} />
              <Timestamp alert={alert} />
            </div>

            <div className={styles.tooltipRow}>
              <div className={styles.tooltipTitle}>{alert.itype_title}</div>
            </div>
            <div className={styles.wordCloud}>
              <CommonWords alert={alert} />
            </div>
          </>
        );
      }
    } else {
      props.setTooltip(null);
    }
  };

  const plot = useMemo(() => {
    let items = [];
    buckets?.forEach((b, x) => {
      const cX = x * bucketWidth + bucketWidth * 0.5;
      b.alerts?.forEach((alert, y) => {
        const cY = graphH - symbolWidth - symbolWidth * y;
        const alertSymbol = alert.matched ? "matched" : alert.itype_state;
        const key = `${alert.inci_id}_${x}_${y}`;
        items.push(
          <g
            key={key}
            onMouseEnter={() => setTooltip(alert)}
            onMouseLeave={() => setTooltip(null)}
            onMouseUp={(evt) => selectAlert(evt, alert.inci_id)}
          >
            <MakeSymbol
              symbolName={alertSymbol}
              cX={cX}
              cY={cY}
              r={symbolWidth}
            />
          </g>
        );
      });
    });
    return items;
  }, [buckets, tc, ac.findMatches]);

  return (
    <g className={styles.alertSymbolPlot} transform={`translate(${0} ${0})`}>
      {plot}
    </g>
  );
};

export const MakeSymbol = (props) => {
  let pathD = null,
    dR,
    tR;
  const { symbolName, cX, cY, r } = props;

  switch (symbolName) {
    case "diamond":
    case "suggested":
      dR = r * 0.38;
      pathD = `M ${cX} ${
        cY - dR
      } l ${dR} ${dR}  l ${-dR} ${dR}  l ${-dR} ${-dR}  l ${dR} ${-dR} z`;
      break;
    case "triangle":
    case "rejected":
      dR = r * 0.35;
      tR = r * 0.3;
      pathD = `M ${cX - dR} ${cY - tR} l ${dR * 2} ${0}  l ${-dR} ${
        tR * 2
      }  l ${-dR} ${-tR * 2}  z`;
      break;
    case "badge":
    case "custom":
      dR = r * 0.39;
      tR = r * 0.3;
      pathD = `M ${cX - dR} ${cY + tR} l ${dR * 2} ${0}  l ${-dR} ${
        -tR * 2
      }  l ${-dR} ${tR * 2}  z`;
      break;
    case "dot":
    case "accepted":
    default:
      dR = r * 0.33;
      pathD = `M ${cX} ${cY}
                              m -${dR}, 0
                              a ${dR},${dR} 0 1,0 ${dR * 2},0
                              a ${dR},${dR} 0 1,0 -${dR * 2},0`;
      break;
  }

  return (
    <path className={`${styles.symbol} ${styles[symbolName]}`} d={pathD} />
  );
};

import React, { useEffect, useState } from "react";
import styles from "./ListWrapper.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { tooltipStyles, Utilities } from "athena-next-ui-lib";

export function ListWrapper(props) {
  const [promptToScroll, setPromptToScroll] = useState(false);

  const scrollPromptCSS = promptToScroll
    ? styles.promptToScrollButton
    : `${styles.promptToScrollButton} ${styles.hide}`;
  const promptToScrollButton = (
    <div
      className={scrollPromptCSS}
      onClick={(evt) => {
        evt.stopPropagation();
        setPromptToScroll(false);
        const listElement = document.getElementById(props.listId);
        if (listElement) listElement.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <FontAwesomeIcon icon={faArrowUp} />
      &nbsp;&nbsp;more {props.objectName || "reports"} above&nbsp;&nbsp;
      <FontAwesomeIcon icon={faArrowUp} />
    </div>
  );

  useEffect(() => {
    /*this effect...
     * determines if the target element (incident,event) is not in
     * the first position (by measuring element.offsetTop)
     *
     * if it is not in first position
     *   1. the element is scrolled into view
     *   2. a prompt to scroll (More Above) message is posted
     *   3. that message is set to hide when the user begins to scroll the viewport
     * */
    const scrollToDelay = 50;
    const hideOnScrollDelay = 500;

    const showPromptToScroll = Utilities.JS.debounce((scrollableParent) => {
      const listElement = document.getElementById(props.listId);
      setPromptToScroll(listElement && listElement.scrollTop > 20);
    }, scrollToDelay);

    const qs = props.getQueryState ? props.getQueryState() : props.router.query;

    const scrollToElement = Utilities.JS.debounce(() => {
      let element;
      if (qs.hasOwnProperty("current")) {
        element = document.getElementById("inci-" + qs.current);
      } else if (qs.hasOwnProperty("ievt_id")) {
        element = document.getElementById("evt-" + qs.ievt_id);
      } else if (qs.hasOwnProperty("find")) {
        element = document.getElementById("find-0");
      }

      if (element) {
        element.scrollIntoView({ behavior: "auto", block: "start" });
        showPromptToScroll();
      } else {
        setPromptToScroll(false);
      }
    }, scrollToDelay);

    const hidePromptAfterScroll = Utilities.JS.debounce(() => {
      const listElement = document.getElementById(props.listId);
      if (listElement) {
        const hidePromptToScroll = () => {
          setPromptToScroll(false);
          listElement.removeEventListener("scroll", hidePromptToScroll, false);
        };
        listElement.addEventListener("scroll", hidePromptToScroll, false);
      }
    }, hideOnScrollDelay);
    const scrollToTop = Utilities.JS.debounce(() => {
      const listElement = document.getElementById(props.listId);
      if (listElement?.scrollTo) listElement.scrollTo(0, 0);
    });

    if (
      qs &&
      (qs.hasOwnProperty("current") ||
        qs.hasOwnProperty("ievt_id") ||
        qs.hasOwnProperty("find"))
    ) {
      scrollToElement();
      hidePromptAfterScroll();
    } else {
      scrollToTop();
    }
  }, [props.response, props.data]);

  useEffect(() => {
    setPromptToScroll(false);
  }, [props.isLoading]);

  const css = `${styles.listWrapper} ${styles[props.mode]} ${
    styles[props.listId]
  }`;

  let banner = null;

  if (false && props.banner) {
    banner = (
      <div
        className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipWest}`}
      >
        <div className={`${styles.cornerRibbonOuter}`}>
          <div
            className={`${styles.cornerRibbon} ${styles.topRight} ${styles.sticky} ${styles.golden}`}
          >
            {props.banner}
          </div>
          <div
            className={tooltipStyles.cssTooltipText}
            style={{ margin: "16px" }}
          >
            This is an experimental feature.
            <br />
            Design and behavior are subject to change.
            <br />
            Please{" "}
            <a className={"link"} href={"mailto:support@zebrium.com"}>
              share your feedback
            </a>{" "}
            with us.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css}>
      {banner}
      {promptToScrollButton}
      {props.children}
    </div>
  );
}

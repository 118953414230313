import { NavBarButton } from "athena-next-ui-lib";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { ReportContext } from "/components-biz";

export const PageNavBar = (props) => {
  const reportContext = useContext(ReportContext);
  const { navigateToList } = reportContext;

  return (
    <NavBarButton
      icon={faArrowLeft}
      iconSize={"lg"}
      label={"Back"}
      action={navigateToList}
    />
  );
};

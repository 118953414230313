import "../styles/globals.scss";
import "athena-next-ui-lib/dist/bundle.css";

import React from "react";
import {
  AppWrapper,
  PromptToSendLogs,
  WebSocketManager,
  DeploymentChangeDetector,
} from "athena-next-ui-lib";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
// see https://github.com/FortAwesome/react-fontawesome/issues/134 for details
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
import getConfig from "next/config";
import { useRouter } from "next/router";
import Head from "next/head";
import { CustomFonts } from "/components-ui";

config.autoAddCss = false; /* eslint-disable import/first */

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const env = getConfig().publicRuntimeConfig;  
  const webSocketManager =  new WebSocketManager(env);

  const isSubFolder = ["/root-cause/", "/tags/"].some(
    (route) => router.pathname.toLowerCase().indexOf(route) > -1
  );
  const favicon = isSubFolder ? "../images/favicon.png" : "images/favicon.png";

  return (
    <AppWrapper env={env} router={router} webSocketManager={webSocketManager}>
      <CustomFonts />
      <Head>
        <link rel="shortcut icon" href={favicon} />
      </Head>
      <Component {...pageProps} />
      <PromptToSendLogs
        router={router}
        webSocketManager={webSocketManager}
        zapi_url={env.ZAPI_URL}
      />
      <DeploymentChangeDetector webSocketManager={webSocketManager} />
    </AppWrapper>
  );
};

MyApp.getInitialProps = async (ctx) => {
  return {};
};

export default MyApp;

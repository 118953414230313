import React, { useEffect, useState } from "react";

import { Field, FieldTypes } from "athena-next-ui-lib";

const VOLUMES_DEFINITION = (ttPosition) => {
  const tooltipPos = ttPosition ? ttPosition : "southEast";
  return [
    {
      label: "Day",
      tooltip: "Alert at most once per log stream per day",
      tooltipPosition: tooltipPos,
      value: "day",
    },
    {
      label: "Hour",
      tooltip: "Alert at most once per log stream per hour",
      tooltipPosition: tooltipPos,
      value: "hour",
    },
    {
      label: "Minute",
      tooltip: `Alert at most once per log stream per minute`,
      tooltipPosition: tooltipPos,
      value: "minute",
    },
  ];
};

export function FieldAlertVolumeSelector(props) {
  const { fieldRef, selectedAlertVolume, label, labelAlign } = props;
  const [selectedAlertVolumeObj, setSelectedAlertVolumeObj] = useState({});

  useEffect(() => {
    if (selectedAlertVolume) {
      setSelectedAlertVolumeObj(
        deriveAlertVolumeObjByValue(selectedAlertVolume)
      );
    }
  }, [selectedAlertVolume]);

  const fieldWidth = "200px";

  const volumeValues = VOLUMES_DEFINITION("northEast");

  const deriveAlertVolumeObjByValue = (value) => {
    const retObj =
      volumeValues.find((btn) => btn.value === value) || volumeValues[1]; //hour by default
    return retObj;
  };

  const defaultContentProps = {
    label: label,
    size: "sm",
    type: FieldTypes.BUTTON_GROUP,
    ref: fieldRef,
    fieldWidth: fieldWidth,
    labelAlign: labelAlign || "left",
    name: "itype_volume",
    ...props,
  };

  return (
    <>
      <Field
        {...defaultContentProps}
        values={volumeValues}
        value={selectedAlertVolumeObj}
        label={"Alert Volume"}
        size={"sm"}
      />
    </>
  );
}

import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  PageContext,
  DataServiceContext,
  TableCrud,
  Row,
  Utilities,
} from "athena-next-ui-lib";
import styles from "../../pages/tags/tags-list.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AlertRuleContext } from "./AlertRuleContextProvider";
import { DialogAlertRule } from "./DialogAlertRule";
import { VIEW_DEFS } from "../../pages/alert-rules";

const displayTagNotifyChannels = (row) => {
  if (!row.channelDisplayNames || row.channelDisplayNames.length === 0) {
    return null;
  } else {
    return (
      <span className={styles.channel}>
        <b>alert:</b>&nbsp;{row.channelDisplayNames.join(", ")}
      </span>
    );
  }
};

export const displayTagRuleBlock = (row) => {
  let rules = displayTagRules(row.tag_rules);

  const ruleNameDisplay = row.tag_etroot ? (
    <>
      <div className={styles.tagEtRootLabel}>on Event Type </div>
      <div className={styles.tagEtRootValue}>{row.tag_etroot}</div>
    </>
  ) : (
    <>
      <div className={styles.tagEtRootLabel}>on Event Text:</div>
    </>
  );

  return (
    <>
      <div className={styles.tagTitle}>
        <div className={styles.tagName}>{row.tag_name}</div>
        {ruleNameDisplay}
        <div> {displayTagNotifyChannels(row)}</div>
      </div>
      <div>{rules}</div>
    </>
  );
};

export const displayTagRules = (rules) => {
  return (rules || []).map((r, num) => {
    let content = [<span className={styles.ruleNum}>{num + 1}:</span>];
    drawKeyValue(content, r, "tagr_svc_grps");
    drawKeyValue(content, r, "tagr_labels_regex");
    drawKeyValue(content, r, "tagr_etext_regex");

    return (
      <div key={r.tagr_idx} className={styles.ruleSummary}>
        {content}
      </div>
    );
  });
};

const drawKeyValue = (content, r, key) => {
  const keysLookup = {
    tagr_svc_grps: "svcgrp",
    tagr_labels_regex: "labels",
    tagr_etext_regex: "text",
  };

  if (!r[key]) {
    return null;
  } else {
    content.push(
      <>
        {content.length > 1 && (
          <div className={styles.ruleAnd}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
        <div className={styles.rulePart}>
          <span className={styles.ruleKey}>{keysLookup[key]}</span>
          <span className={styles.ruleValue}>{r[key]}</span>
        </div>
      </>
    );
  }
};

export const AlertRulesTable = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);
  const alertRuleContext = useContext(AlertRuleContext);

  const [listData, setListData] = useState(null);
  const [listCallResponse, setListCallResponse] = useState({ code: 200 });
  const [tagType, setTagType] = useState(null);
  const [viewType, setViewType] = useState(null);

  const canFullyEdit = dataServiceContext.AccessManifest(
    "canFullyEditFeature",
    "define-custom-alert-types"
  );
  const alertRuleDialog = useRef();
  const tableRef = useRef();

  useEffect(() => {
    setTagType(props.tagType);
  }, [props.tagType]);

  useImperativeHandle(ref, () => ({
    refreshList
  }));


  const refreshList = ()=>{
    tableRef.current.loadTableData();
  }

  const loadData = (sortCol) => {
     //default to -ts for sorting on internal column
     const sortColFld = sortCol ? sortCol.replace("-", "") : "-ts";
     const sortColObj = columnDefs.find((col) => col.fldName === sortColFld);
     const sortOn = sortColObj.internalSort ? "-ts" : sortCol;

    const channels = alertRuleContext?.channels;

    return Promise.resolve()
      .then(() => setListData(null))
      .then(() => {
        return dataServiceContext.fetch("tag/read", {
          sort: ["tag_type", sortOn],
          filter: props.filter,
          args: { load_rules: true },
        });
      })
      .then((tagsCall) => {
        if (tagsCall.response.code === 200) {
          const tagDefs = tagsCall.data || [];

          tagDefs.map((tagDef) => {
            let channelDisplayNames = [];
            tagDef.tag_outbound_integration_ids.forEach((id) => {
              const ch = channels?.find((c) => c.siid === id);
              if (ch) {
                channelDisplayNames.push(ch.name);
              }
            });

            tagDef.channelDisplayNames = channelDisplayNames;
          });

          setListData([...tagDefs]);
          return tagDefs;
        } else {
          setListCallResponse(tagsCall.response);
          throw tagsCall.response;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getDeleteConfirmObj = (obj) => {
    return {
      title: props.dialogDef.deleteTitle,
      desc: (
        <span>
          {props.dialogDef.deleteDesc}: <b>{obj.tag_name}</b>?
        </span>
      ),
    };
  };

  const editAlertRuleAction = (alertRule) => {
    return Promise.resolve()
      .then(() => setTagType(alertRule.tag_type))
      .then(() => {
        if (
          alertRule.tag_type === "user_driven" &&
          alertRule.hasOwnProperty("tag_etroot") &&
          alertRule.tag_etroot !== ""
        ) {
          setViewType("etroot");
        } else {
          setViewType(alertRule.tag_type);
        }
      })
      .then(() => alertRuleDialog.current.showDialog(alertRule));
  };

  const deleteAlertRuleHandler = (alertRule) => {
    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch("tag/delete", { tag_id: alertRule.tag_id })
      )
      .then((response) => {
        try {
          if (response.response.code === 200) tableRef?.current?.loadTableData();
        } catch (err) {
          console.log("Err:" + err.message);
        }
        return response;
      });
  };

  const displayModifiedInfo = (row) => {
    return (
      <>
        <div>
          <b>{Utilities.TZ.timeAgo(row.ts, pageContext.timeZone)}</b>
        </div>
        <div style={{ opacity: 0.75 }}>by&nbsp;{row.modify_user_name}</div>
      </>
    );
  };
  const columnDefs = [
    {
      label: "Rule Name/Definition",
      renderer: displayTagRuleBlock,
      width: "70%",
      fldName: "tag_name",
      internalSort: true
    },
    { label: "Modified", renderer: displayModifiedInfo, width: "30%", fldName: "ts", sort: "desc" },
  ];

  const rowActions = canFullyEdit
    ? {
        edit: {
          handler: editAlertRuleAction,
        },
        delete: {
          handler: deleteAlertRuleHandler,
          confirm: getDeleteConfirmObj,
        },
      }
    : {};

  return (
    <>
      <Row width={"*"} align={"center"}>
        <div style={{ flex: "1" }}>
          {" "}
          <p>{props.desc()}</p>
        </div>
        {canFullyEdit && (
          <div style={{ marginLeft: "auto", paddingLeft: "20px" }}>
            {props.addButton}
          </div>
        )}
      </Row>
      <TableCrud
        ref={tableRef}
        loadData={loadData}
        listData={listData}
        listCallResponse={listCallResponse}
        columnDefs={columnDefs}
        rowActions={rowActions}
        maxHeight={"calc(100vh - 140px)"}
      />
      <DialogAlertRule
        action={"EDIT"}
        ref={alertRuleDialog}
        tagType={tagType}
        dialogDef={VIEW_DEFS[viewType]}
        updateCallback={tableRef?.current?.loadTableData}
      ></DialogAlertRule>
    </>
  );
});

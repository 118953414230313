import {
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { Field, FieldTypes, Button } from "athena-next-ui-lib";
import styles from "./PanelSection.module.scss";
import {
  faChevronDown as expandedStateIcon,
  faChevronRight as collapsedStateIcon,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PANEL_SECTION_TYPES = {
  COLLAPSING: 0,
  STATIC: 1,
  TITLE: 2,
};
export const PanelSection = forwardRef((props, ref) => {
  const {
    title,
    value: defaultValue,
    expanded,
    isEditing,
    onChange,
    disabled,
    required,
    originalValue,
    height,
    name,
  } = props;

  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const [value, setValue] = useState(defaultValue);

  const fldRef = useRef();

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const revertToOriginalValue = (evt) => {
    evt.stopPropagation();
    fldRef?.current?.updateValue(originalValue);
  };

  const valueChanged = (value) => {
    setValue(value);
    //onChange(value);
  };

  useImperativeHandle(ref, () => ({
    getValue,
  }));

  const getValue = (data) => {
    data[name] = value;
    return data;
  };

  const content = useMemo(() => {
    const hideRevertBtn =
      originalValue !== value && isEditing ? null : { visibility: "hidden" };

    const revertBtn = (
      <Button
        type="secondary"
        size="sm"
        onClick={(evt) => revertToOriginalValue(evt)}
        cssStyle={hideRevertBtn}
      >
        Revert
      </Button>
    );

    switch (props.type) {
      case PANEL_SECTION_TYPES.COLLAPSING:
        const twistie = isExpanded ? (
          <span
            data-testid={`${name}-twistie`}
            onClick={() => setIsExpanded(false)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FontAwesomeIcon
              icon={expandedStateIcon}
              style={{ marginRight: "4px" }}
            />
            {title}
            &nbsp; &nbsp;
            {revertBtn}
          </span>
        ) : (
          <span
            data-testid={`${name}::twistie`}
            onClick={() => setIsExpanded(true)}
          >
            <FontAwesomeIcon
              icon={collapsedStateIcon}
              style={{ marginRight: "4px" }}
            />
            {title}
          </span>
        );

        const content = isEditing ? (
          <div data-testid={`${name}::field`}>
            <Field
              ref={fldRef}
              type={FieldTypes.TEXT_AREA}
              required={required}
              width="100%"
              height={height}
              className={styles.taMd}
              value={props.value}
              disabled={disabled}
              spellCheck={false}
              onUpdateValue={(value) => valueChanged(value)}
            />
          </div>
        ) : value?.length > 0 ? (
          <pre className={styles.pre}>{value}</pre>
        ) : (
          "N/A"
        );

        return (
          <div className={styles.section}>
            <div
              className={
                isEditing
                  ? `${styles.sectionTitle} ${styles.isEditing}`
                  : styles.sectionTitle
              }
            >
              {twistie}
            </div>
            <div className={styles.value}>{isExpanded ? content : null}</div>
          </div>
        );
      case PANEL_SECTION_TYPES.STATIC:
        return (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {title}&nbsp;&nbsp;{revertBtn}
            </div>
            <div className={styles.value} data-testid={`${name}::field`}>
              {isEditing ? (
                <Field
                  ref={fldRef}
                  type={FieldTypes.TEXT_AREA}
                  width="100%"
                  className={styles.taMd}
                  value={props.value}
                  spellCheck={false}
                  onUpdateValue={(value) => valueChanged(value)}
                />
              ) : value?.length > 0 ? (
                <pre className={styles.pre}>{value}</pre>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        );
      case PANEL_SECTION_TYPES.TITLE:
      default:
        return (
          <div className={styles.section}>
            <div className={styles.title} data-testid={`${name}::field`}>
              {isEditing ? (
                <Field
                  ref={fldRef}
                  type={FieldTypes.TEXT_AREA}
                  width="100%"
                  className={styles.taMd}
                  value={props.value}
                  spellCheck={false}
                  onUpdateValue={onChange}
                />
              ) : value?.length > 0 ? (
                value
              ) : (
                "N/A"
              )}
            </div>
          </div>
        );
    }
  }, [title, value, isExpanded, isEditing, originalValue]);

  return <>{content}</>;
});

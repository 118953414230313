import React, { useState, useContext, useEffect } from "react";
import Moment from "moment";

import {
  Toolbar,
  Tooltip,
  DataServiceContext,
  Field,
  useModalDialog,
  Utilities,
  PageContext,
  SignificanceIcon,
  LoadingAnim,
  Row,
} from "athena-next-ui-lib";

import styles from "./OccurrencesGraph.module.scss";
import fieldStyles from "/components-ui/IncidentList.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/pro-solid-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import { UserPrefItem } from "../../userPrefs/user-pref-item";
import {
  userPrefDefinitions,
  userPrefsMapping,
} from "../../userPrefs/user-pref-definitions";
import { OCCURRENCE_LIMIT } from "../../constants/limit-constants";
import styled from "styled-components";

import { ReportContext } from "/components-biz";

const StyledPanelDiv = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 0;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width}px;
`;

const padX = 8,
  marginX = 0,
  marginY = 0;

export const OccurrencesGraph = (props) => {
  const alertContext = useContext(ReportContext);
  const { itypeData, setCurrentInciId, getQueryState } = alertContext;

  const { openModalDialog } = useModalDialog();
  const dataService = useContext(DataServiceContext);
  const router = useRouter();
  const pageContext = useContext(PageContext);
  const [panelContent, setPanelContent] = useState(null);
  const [response, setResponse] = useState({ code: 0, timestamp: 0 });
  const [data, setData] = useState(null);
  const [dataFieldsDisplay, setDataFieldsDisplay] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [graphData, setGraphData] = useState({
    calculatedWidth: 100,
    currentDotId: null,
    dots: null,
    occurrences: [],
    currentOccurrence: { inci_id: "" },
    background: null,
  });

  const [graphStage, setGraphStage] = useState("empty");

  const [tagDisplay, setTagDisplay] = useState(null);
  const [serviceGroupDisplay, setServiceGroupDisplay] = useState(null);
  const [significanceDisplay, setSignificanceDisplay] = useState(null);
  const [selectedOccurrenceValue, setSelectedOccurrenceValue] = useState(null);
  const [exceededLimit, setExceededLimit] = useState(false);

  const DOM_snake = React.useRef();
  const DOM_graph = React.useRef();
  const DOM_scroller = React.useRef();
  const DOM_dropdown = React.useRef();
  const DOM_loadOccurrenceBehavior = React.useRef();

  let tooltipTimer = null;
  let tooltipCloseTimer = null;

  const { width } = props;
  const queryState = getQueryState();

  useEffect(() => {
    if (!itypeData) return;

    const { itype_id } = itypeData;
    if (!itype_id) return null;
    return Promise.resolve()
      .then(() =>
        dataService.fetch("incident/read/occurrences", {
          itype_id: itype_id,
          limit: OCCURRENCE_LIMIT + 1,
        })
      )
      .then((occurrencesCall) => {
        setResponse(occurrencesCall.response);

        const outData = occurrencesCall.data || [];
        if (outData.length > OCCURRENCE_LIMIT) {
          setExceededLimit(true);
          outData.shift();
        }

        setData(outData);
        return;
      });
  }, [itypeData?.itype_id]);

  useEffect(() => {
    if (!data || !data.length) {
      return;
    } else {
      deriveGraphComponents([...data], props.width, props.height);

      setTimeout(() => {
        deriveGraphComponents([...data], props.width, props.height);
        setGraphStage("complete");
      }, 100);
    }
  }, [
    DOM_snake.current,
    queryState?.inci_id,
    data,
    graphStage,
    pageContext?.timeZone,
  ]);

  const loadOccurrencesByBehavior = (loadBehavior, inci_id) => {
    if (loadBehavior === "new_tab") {
      const query = router.query;
      let link = `/root-cause/report?deployment_id=${query.deployment_id}&itype_id=${query.itype_id}&inci_id=${inci_id}&ievt_level=${query.ievt_level}`;

      link = Utilities.Router.createAsPath(link, pageContext.env);

      //revert occurrence dropdown:
      restoreOccurrenceSelection();

      window.open(link, "_blank");
    } else if (loadBehavior === "replace") {
      setCurrentInciId(inci_id);
    }
  };

  const loadOccurrencesChangeHandler = (selectedInciId) => {
    const selectedBehaviorObj = DOM_loadOccurrenceBehavior.current.getValue({});
    const selectedBehavior =
      selectedBehaviorObj[userPrefsMapping.LOAD_OCCURRENCE];

    return Promise.resolve()
      .then(() => dataService.setUserPreference(selectedBehaviorObj))
      .then(() => loadOccurrencesByBehavior(selectedBehavior, selectedInciId));
  };

  const restoreOccurrenceSelection = () => {
    //revert occurrence dropdown:
    const selectedValue = graphData?.occurrences?.selectedOccurrenceItem
      ? [graphData?.occurrences?.selectedOccurrenceItem]
      : [];
    setSelectedOccurrenceValue(selectedValue);
  };

  const onUpdateOccurrenceDD = (selValue) => {
    //selValue is an array of obj

    if (selValue?.length == 1 && selValue[0] && selValue[0].value) {
      if (selValue[0].value !== queryState.inci_id) {
        const loadOccurrences = dataService.getUserPreference(
          userPrefsMapping.LOAD_OCCURRENCE
        );

        const upDef = userPrefDefinitions.find(
          (def) => def.category === "occurrences"
        );
        const userPref = upDef.items.find(
          (itm) => itm.fieldName === userPrefsMapping.LOAD_OCCURRENCE
        );
        delete userPref.label; //hide label
        const compProps = {
          ...userPref,
          ref: DOM_loadOccurrenceBehavior,
          value: loadOccurrences || userPref.defaultValue,
        };

        if (!loadOccurrences) {
          //user pref not set
          openModalDialog({
            title: "User preference when opening new occurrences",
            icon: faWrench,
            height: "400px",
            content: <UserPrefItem {...compProps} />,
            cancelLabel: "Cancel",
            submitLabel: "Proceed",
            submit: () => loadOccurrencesChangeHandler(selValue[0].value),
            cancelCallback: restoreOccurrenceSelection,
          });
          return;
        } else {
          //open new tab
          loadOccurrencesByBehavior(loadOccurrences, selValue[0].value);
        }
      }
    }
  };

  useEffect(() => {
    setPanelContent(getPanelContent());
  }, [showTooltip, graphData, dataFieldsDisplay, selectedOccurrenceValue]);

  useEffect(() => {
    if (showTooltip && tooltipPosition && tooltipContent) {
      tooltipCloseTimer = setTimeout(() => setShowTooltip(false), 5000);
    }
  }, [showTooltip, tooltipPosition, tooltipContent]);

  useEffect(() => {
    if (graphData) {
      const selectedValue = graphData?.occurrences?.selectedOccurrenceItem
        ? [graphData?.occurrences?.selectedOccurrenceItem]
        : [];
      setSelectedOccurrenceValue(selectedValue);
    }

    if (graphData.dots) {
      const callback = () =>
        setTimeout(() => {
          if (graphData.currentDotId) {
            const element = document.getElementById(graphData.currentDotId);
            if (element) {
              DOM_scroller.current.scrollLeft =
                (element.cx.baseVal.value || 10) - 50;
            }
          }
        }, 500);

      callback();
    }
  }, [graphData]);

  useEffect(() => {
    if (itypeData == null) return;

    if (Object.keys(itypeData).length > 0) {
      const arTags = itypeData.inci_tags || [];
      const manualTags = itypeData.itype_tags || [];

      const noTag = arTags.length + manualTags.length === 0;

      //auto tags
      let arString = arTags.map((t, index) => {
        const key = `${t.tag_id}${index}`;
        return (
          <span
            key={key}
            className={`${fieldStyles.tagType} ${fieldStyles.auto}`}
          >
            {t.tag_name}
          </span>
        );
      });

      //manual tags
      //hide manual tags when inci_user_rule_only_match is true
      let manualString =
        itypeData.inci_itype === "custom"
          ? []
          : manualTags.map((t, index) => {
              const key = `${t.tag_id}${index}`;
              return (
                <span
                  key={key}
                  className={`${fieldStyles.tagType} ${fieldStyles.manual}`}
                >
                  {t.tag_name}
                </span>
              );
            });

      //set tags display
      setTagDisplay(
        noTag ? null : (
          <div style={{ lineHeight: "1.8" }}>
            {arString}
            {manualString}
          </div>
        )
      );

      //set significance display
      const inci_significance = itypeData.inci_significance;

      const significance = (
        <SignificanceIcon
          showTooltip={false}
          showLabel={true}
          inci_significance={inci_significance}
        />
      );
      setSignificanceDisplay(significance);

      //set service groups display
      setServiceGroupDisplay(
        <span className={fieldStyles.serviceGroups} title={"Service Groups"}>
          {itypeData.inci_svc_grps?.split(",").join(", ")}
        </span>
      );
    }
  }, [itypeData]);

  useEffect(() => {
    const lblWidth = "105px";
    const fldWidth = "250px";
    setDataFieldsDisplay(
      <>
        {tagDisplay && (
          <Row>
            <Field
              type={"display-text"}
              value={tagDisplay}
              labelWidth={lblWidth}
              fieldWidth={fldWidth}
              label={"Matches On"}
            />
          </Row>
        )}
        <Row>
          <Field
            type={"display-text"}
            value={significanceDisplay}
            labelWidth={lblWidth}
            fieldWidth={fldWidth}
            label={"Significance"}
          />
        </Row>
        <Row>
          <Field
            type={"display-text"}
            value={serviceGroupDisplay}
            labelWidth={lblWidth}
            fieldWidth={fldWidth}
            label={"Service Groups"}
          />
        </Row>
      </>
    );
  }, [tagDisplay, significanceDisplay, serviceGroupDisplay]);

  const getPanelContent = () => {
    if (!itypeData) return <LoadingAnim />;

    const allValues = graphData?.occurrences?.data
      ? graphData?.occurrences?.data
      : [];

    let panelContent = null;

    let occurrencePosition = Utilities.Numbers.nthNumber(
      itypeData?.inci_itype_occ || 1
    );

    const exceedLimitMsg = exceededLimit ? (
      <div
        className={styles.exceedLimitMsg}
      >{`Occurrence limit exceeded.  Showing the most recent ${OCCURRENCE_LIMIT}.`}</div>
    ) : null;

    if (itypeData.inci_itype !== "custom") {
      panelContent = (
        <StyledPanelDiv width={width} flex={`0 1 ${props.height}`}>
          <Toolbar
            response={response}
            title={<>{occurrencePosition}&nbsp;Occurrence</>}
          >
            <Field
              type={"single-select"}
              ref={DOM_dropdown}
              fieldWidth={"180px"}
              response={graphData.response}
              values={allValues}
              value={selectedOccurrenceValue} //selected is in an array for single select
              onUpdateValue={onUpdateOccurrenceDD}
              multiselect={false}
            />
          </Toolbar>
          {data?.length > 0 && drawGraph()}
          {data?.length > 0 && drawTooltip()}
          {exceedLimitMsg}
        </StyledPanelDiv>
      );
    } else {
      panelContent = (
        <StyledPanelDiv width={width} flex={`0 1 ${props.height}`}>
          {dataFieldsDisplay}
        </StyledPanelDiv>
      );
    }
    return panelContent;
  };

  const navigateToOccurrenceByDot = (occurrence) => {
    onUpdateOccurrenceDD([{ value: occurrence.inci_id }]);
  };

  const deriveSnakePath = (width, height, retentionDays) => {
    const snakeWidth = width; // * 0.95;
    const snakeOffset = 0; //width * 0.025;
    const numPeaks = retentionDays;
    const frequency = snakeWidth / numPeaks;

    let x = snakeOffset;
    let minY = 0.15 * height;
    let maxY = 0.85 * height;
    let path = "";

    for (let index = 0; index < numPeaks; index++) {
      const ratio = 1 / 4;
      const c1 = x + frequency * ratio;
      const c2 = x + frequency * ratio * 2;
      const c3 = x + frequency * ratio * 3;
      const c4 = x + frequency * ratio * 4;

      if (index === 0) {
        path += `M ${x} ${minY}`;
      }
      path += `C ${c1} ${minY}, ${c1} ${maxY}, ${c2} ${maxY}`;
      path += `C ${c3} ${maxY}, ${c3} ${minY}, ${c4} ${minY}`;

      x = c4;
    }

    return path;
  };

  const deriveGraphComponents = (data, width, height) => {
    let snakePathLength = 0;
    let rows = data;
    if (rows.length === 0) return;
    let occurrences = [];
    let selectedOccurrence = "";
    let selectedOccurrenceIndex = -1;
    let snakePath;

    if (DOM_snake.current) {
      snakePathLength = DOM_snake.current.getTotalLength();
    } else {
      rows = [];
    }

    const end = Moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
    let start = Moment(data[0].inci_ts).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 1,
    });

    const numDaysVisibleAtOnce = 7;
    let numDaysInIncidentHistory = end.diff(start, "days");
    if (numDaysInIncidentHistory < numDaysVisibleAtOnce) {
      numDaysInIncidentHistory = numDaysVisibleAtOnce;
      start = end.subtract(numDaysVisibleAtOnce, "days").add(1, "minute");
    }

    const numMSInIncidentHistory = 86400000 * numDaysInIncidentHistory;
    const padX = 20;
    const dayWidth = (width - padX) / numDaysVisibleAtOnce; //show 7 days at a time

    const dayLabelInterval = numDaysVisibleAtOnce - 4;
    const numDayLabels =
      parseInt(numDaysInIncidentHistory / dayLabelInterval) + 1;
    const dayLabelIntervalWidth = dayWidth * dayLabelInterval;
    let dayLabels = Array.from({ length: numDayLabels }, (a, index) => {
      return index;
    }).map((a, index) => {
      const date = Utilities.TZ.formatTS(
        Moment(start).add(index * dayLabelInterval, "days"),
        pageContext.timeZone,
        "MM-DD"
      );
      const key = `${date}${index}`;
      const x = index * dayLabelIntervalWidth;
      return (
        <g key={key}>
          <line
            className={styles.dayLabelLine}
            x1={x}
            x2={x}
            y1={0}
            y2={height + 10}
          />
          <text className={styles.dayLabel} x={x + 2} y={height + 10}>
            {date}
          </text>
        </g>
      );
    });

    const w = numDaysInIncidentHistory * dayWidth;
    const h = height;

    let currentDotId = null;

    snakePath = deriveSnakePath(w, h, numDaysInIncidentHistory);

    const rowCount = (rows || []).length;

    let currentOccurrence = "";

    let svcGrpHash = {};
    rows.forEach((r) => {
      svcGrpHash[r.inci_svc_grps] = 1;
    });
    const applySecondaryLabel = Object.keys(svcGrpHash).length > 1;

    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      let row = rows[rowIndex];
      if (!row) {
        continue;
      }

      if (applySecondaryLabel) {
        occurrences.push({
          label: row.inci_svc_grps?.split(",").join(", "),
          secondaryLabel: Moment(row.inci_ts).format("YYYY-MM-DD HH:mm:ss"),
          value: row.inci_id,
        });
      } else {
        occurrences.push({
          label: Utilities.TZ.formatTS(
            row.inci_ts,
            pageContext.timeZone,
            "YYYY-MM-DD HH:mm:ss"
          ),
          value: row.inci_id,
        });
      }

      row.x = -10;
      row.y = h * 0.5;

      const rowTimeUnixMs = new Date(row.inci_ts).getTime();

      row.selected = false;
      row.dotId = `dot-id-${row.inci_id}`;

      if (row.inci_id === queryState.inci_id) {
        row.selected = true;
        currentDotId = row.dotId;
        selectedOccurrenceIndex = rowIndex;
        currentOccurrence = { ...row };
        selectedOccurrence = row.inci_id;
      }

      if (snakePathLength > 0) {
        const ratio =
          (rowTimeUnixMs - start.valueOf()) / numMSInIncidentHistory;

        const p = DOM_snake.current.getPointAtLength(ratio * snakePathLength);
        row.x = p.x;
        row.y = p.y;
        row.r = 4;
      }
    }

    if (currentOccurrence.length) {
      rows.push(currentOccurrence);
    }

    const dots = (rows || []).map((row, index) => {
      const key = `${row.inci_id}${index}`;
      return (
        <circle
          key={key}
          id={row.dotId}
          className={styles.snakeDot}
          r={row.r}
          cx={row.x}
          cy={row.y}
          onClick={() => navigateToOccurrenceByDot(row)}
        />
      );
    });

    const currentDot = (rows || [])
      .filter((row) => row.selected)
      .map((row, index) => {
        const key = `${row.inci_id}${index}`;
        return (
          <g key={key} transform={`translate( ${row.x}, ${row.y})`}>
            <g>
              <circle
                className={`${styles.snakeDot} ${styles.selected}`}
                r={row.r}
                cx={0}
                cy={0}
                onClick={() => navigateToOccurrenceByDot(row)}
              />
              ;
            </g>
          </g>
        );
      });

    const occurrencesList = {
      response: { timestamp: 1, code: 200 },
      data: occurrences,
      selectedOccurrence: selectedOccurrence,
      selectedOccurrenceItem: occurrences[selectedOccurrenceIndex],
    };

    const background = (
      <rect
        fill="url('#nightDayFill')"
        stroke="rgba(255,255,255,.25)"
        strokeWidth={1}
        x={0}
        y={0}
        width={w}
        height={h}
      />
    );

    setGraphData({
      calculatedWidth: w,
      occurrences: occurrencesList,
      snakePath: snakePath,
      dots: dots,
      currentDotId: currentDotId,
      currentDot: currentDot,
      currentOccurrence: currentOccurrence,
      dayLabels: dayLabels,
      background: background,
    });
  };

  const getGraphBounds = () => {
    const graph = DOM_graph.current;
    let scrollLeft = 0;
    let scrollTop = 0;
    let graphBounds = { left: 0, top: 0 };

    if (graph) {
      graphBounds = graph.getBoundingClientRect();
      scrollLeft = graph.scrollLeft;
      scrollTop = graph.scrollTop;
    }
    return {
      x: graphBounds.left,
      y: graphBounds.top,
      scrollX: scrollLeft,
      scrollY: scrollTop,
    };
  };

  const onMouseMove = (evt) => {
    const { pageX, pageY } = evt;

    clearTimeout(tooltipTimer);

    tooltipTimer = setTimeout(() => _onMouseMove(pageX, pageY), 100);
  };

  const _onMouseMove = (pageX, pageY) => {
    const bounds = getGraphBounds();
    clearTimeout(tooltipCloseTimer);
    const x = pageX - bounds.x + bounds.scrollX - marginX;
    const y = pageY - bounds.y + bounds.scrollY - marginY;

    const match = data.find((dot) => {
      return (
        dot.x >= x - dot.r &&
        dot.x <= x + dot.r &&
        dot.y >= y - dot.r &&
        dot.y <= y + dot.r
      );
    });

    if (match) {
      setShowTooltip(true);
      setTooltipPosition({ top: pageY + 4 + "px", left: pageX + 4 + "px" });
      setTooltipContent(
        Utilities.TZ.formatTS(
          match.inci_ts,
          pageContext.timeZone,
          "MMM DD HH:mm:ss"
        )
      );
    } else {
      setShowTooltip(false);
    }
  };

  const drawTooltip = () => {
    //reusable tooltip

    return (
      <Tooltip
        position={tooltipPosition}
        cssClass={styles.tooltip}
        marginH={1}
        marginW={1}
        visible={showTooltip}
        content={tooltipContent}
      />
    );
  };

  const drawGraph = () => {
    const { height } = props;
    const { calculatedWidth } = graphData;

    if ((response && response.code !== 200) || data.length === 0) return null;

    const style = { width: calculatedWidth, height: height + 10 };

    return (
      <div
        ref={DOM_scroller}
        className={styles.scrollable}
        style={{ height: style.height + 12 }}
      >
        <div className={styles.stickyLeft}>
          <span className={styles.top}>
            <FontAwesomeIcon icon={faMoon} size={"1x"} />
          </span>
          <span className={styles.btm}>
            <FontAwesomeIcon icon={faSun} size={"1x"} />
          </span>
        </div>
        <div className={styles.svgGraph} style={style}>
          <svg
            ref={DOM_graph}
            width={calculatedWidth}
            height={height + 10}
            onMouseMove={onMouseMove}
          >
            <defs>
              <linearGradient
                id="nightDayStroke"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="rgb(79,141,169)" />
                <stop offset="50%" stopColor="rgb(79,141,169)" />
                <stop offset="50%" stopColor="rgb(139,201,229)" />
                <stop offset="100%" stopColor="rgb(139,201,229)" />
              </linearGradient>
              <linearGradient
                id="nightDayFill"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="rgba(255,255,255,.2)" />
                <stop offset="50%" stopColor="rgba(255,255,255,.2)" />
                <stop offset="50%" stopColor="rgba(255,255,255,.4)" />
                <stop offset="100%" stopColor="rgba(255,255,255,.4)" />
              </linearGradient>
            </defs>

            <g>
              {graphData.background}
              <path
                ref={DOM_snake}
                className={styles.snake}
                d={graphData.snakePath}
                stroke="url('#nightDayStroke')"
              />

              {graphData.dayLabels}
              {graphData.dots}
              {graphData.currentDot}
            </g>
          </svg>
        </div>
      </div>
    );
  };

  return panelContent;
};
OccurrencesGraph.defaultProps = {
  graphData: { occurrences: { response: { code: 0 } } },
  response: { code: 0 },
  data: [],
  marginX: 10,
  marginY: 0,
  width: 200,
  height: 40,
};

import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Column,
  Form,
  Row,
  Field,
  FieldTypes,
  Button,
  PageContext,
  DataServiceContext,
  useModalDialog,
  StyledMenuItemDiv,
  getErrorMessageDisplay,
} from "athena-next-ui-lib";
import {
  faEdit,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { UICustomizer } from "/components-ui";
import {
  FieldOutboundIntegrationSelector,
  FieldPrioritySelector,
  FieldAlertVolumeSelector,
  FieldManualTagSelector,
  ReportContext,
  PANEL_SECTION_TYPES,
  PanelSection,
} from "/components-biz";

export const DialogAlertRuleMetadataEdit = forwardRef((props, ref) => {
  const alertContext = useContext(ReportContext);
  const { itypeData } = alertContext;
  const dataService = useContext(DataServiceContext);
  const { openModalDialog, closeModalDialog, openConfirmationDialog } =
    useModalDialog();
  const pageContext = useContext(PageContext);

  const [data, setData] = useState(itypeData || {});

  const IncidentTypeForm = useRef();

  const itype_volume = useRef();
  const itype_title = useRef();
  const itype_owner = useRef();
  const itype_tracking_url = useRef();
  const itype_feedback_incident = useRef();
  const itype_summary = useRef();
  const itype_detail = useRef();
  const itype_keys = useRef();
  const itype_force_rcr_from_keys = useRef();

  const itype_outbound_integration_ids = useRef();
  const itype_outbound_priority = useRef();
  const itype_tag_ids = useRef();

  const outboundIntegrationRef = useRef();

  let secondaryBtnProcessing = false;
  let dialogErrorMessage = null;

  let extensionFieldRefs = {};
  extensionFieldRefs[`itype_ext_f1`] = useRef();
  extensionFieldRefs[`itype_ext_f2`] = useRef();
  extensionFieldRefs[`itype_ext_f3`] = useRef();
  extensionFieldRefs[`itype_ext_f4`] = useRef();
  extensionFieldRefs[`itype_ext_f5`] = useRef();
  extensionFieldRefs[`itype_ext_f6`] = useRef();
  extensionFieldRefs[`itype_ext_f7`] = useRef();
  extensionFieldRefs[`itype_ext_f8`] = useRef();
  extensionFieldRefs[`itype_ext_f9`] = useRef();
  extensionFieldRefs[`itype_ext_f10`] = useRef();

  let fieldRefs = [
    itype_keys,
    itype_title,
    itype_owner,
    itype_tracking_url,
    itype_feedback_incident,
    itype_summary,
    itype_detail,
    itype_outbound_integration_ids,
    itype_outbound_priority,
    itype_tag_ids,
    itype_force_rcr_from_keys,
    itype_volume,
  ];

  fieldRefs = fieldRefs.concat(extensionFieldRefs); //combining out-of-box with custom defined fields
  Object.keys(extensionFieldRefs).forEach((name) =>
    fieldRefs.push(extensionFieldRefs[name])
  );

  const { env } = pageContext;
  const { processing } = false;
  const fieldWidth = "200px";
  const fieldProps = {
    fieldWidth: fieldWidth,
    labelWidth: "130px",
    labelAlign: "right",
  };

  useImperativeHandle(ref, () => ({
    showDialog,
    setData,
  }));

  useEffect(() => {
    const mergedData = { ...data, ...itypeData };
    setData({ ...mergedData });
  }, [itypeData]);

  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [detail, setDetail] = useState("");

  useEffect(() => {
    if (itypeData) {
      setCurrentInsightsData();
    }
  }, [itypeData]);

  const setCurrentInsightsData = () => {
    const {
      itype_title,
      itype_summary,
      itype_detail,
      itype_nlp_title,
      itype_nlp_summary,
      itype_nlp_detail,
    } = itypeData;

    let _title;

    if (itype_title) _title = itype_title;
    else if (itype_nlp_title) _title = itype_nlp_title;

    let _summary;
    if (itype_summary) _summary = itype_summary;
    else if (itype_nlp_summary) _summary = itype_nlp_summary;

    let _detail;
    if (itype_detail) _detail = itype_detail;
    else if (itype_nlp_detail) _detail = itype_nlp_detail;

    setTitle(_title);
    setSummary(_summary);
    setDetail(_detail);
  };

  const getFormContent = () => {
    if (props.type === "edit-note") {
      return getFullForm();
    } else if (
      props.type === "edit-routing" ||
      props.type === "accept-notify"
    ) {
      return getRoutingForm();
    } else if (props.type === "edit-volume") {
      return getVolumeForm();
    } else {
      return getFullForm();
    }
  };

  const getFullForm = () => {
    return (
      <Form ref={IncidentTypeForm} refs={fieldRefs}>
        <Row>
          <Field
            type={"description"}
            value={"Capture pertinent information about this alert rule."}
          />
        </Row>

        <Row>
          <PanelSection
            ref={itype_title}
            name="itype_title"
            type={PANEL_SECTION_TYPES.COLLAPSING}
            onChange={(value) => setTitle(value)}
            title="Title"
            height="50px"
            value={title}
            expanded={true}
            isEditing={true}
            disabled={processing}
            originalValue={
              data.itype_nlp_populated ? data.itype_nlp_title : data.itype_title
            }
          />
        </Row>

        <Row>
          <PanelSection
            ref={itype_summary}
            name="itype_summary"
            type={PANEL_SECTION_TYPES.COLLAPSING}
            onChange={(value) => setSummary(value)}
            title="Summary"
            value={summary}
            originalValue={
              data.itype_nlp_populated
                ? data.itype_nlp_summary
                : data.itype_summary
            }
            expanded={false}
            isEditing={true}
            disabled={processing}
          />
        </Row>

        <Row width="*">
          <PanelSection
            ref={itype_detail}
            name="itype_detail"
            type={PANEL_SECTION_TYPES.COLLAPSING}
            onChange={(value) => setDetail(value)}
            title="Detail"
            value={detail}
            height="250px"
            originalValue={
              data.itype_nlp_populated
                ? data.itype_nlp_detail
                : data.itype_detail
            }
            expanded={false}
            isEditing={true}
            disabled={processing}
          />
        </Row>

        <Row>
          <Column width={"50%"}>
            <FieldOutboundIntegrationSelector
              label={"Send Alert To"}
              {...fieldProps}
              ref={outboundIntegrationRef}
              required={true}
              fieldRef={itype_outbound_integration_ids}
              selectedOutboundChannels={data?.itype_outbound_integration_ids}
              tooltip={
                "Alerts will be sent to all dashboards plus any channels you select."
              }
            />
            <FieldPrioritySelector
              label={"Alert Priority"}
              {...fieldProps}
              fieldRef={itype_outbound_priority}
              placeholder={"Select"}
              required={true}
              fieldWidth={fieldWidth}
              selectedPriorityString={data.itype_outbound_priority}
            />
            <FieldAlertVolumeSelector
              {...fieldProps}
              fieldRef={itype_volume}
              selectedAlertVolume={data.itype_volume}
              label={"Alert Volume"}
            />
          </Column>
          <Column width={"50%"} css={{ marginLeft: "8px" }}>
            <Field
              ref={itype_owner}
              {...fieldProps}
              label={"Owner"}
              value={data.itype_owner}
              name={"itype_owner"}
              disabled={processing}
            />

            <FieldManualTagSelector
              {...fieldProps}
              label={<span>Manual Tags</span>}
              fieldRef={itype_tag_ids}
              selectedManualTagIDs={data?.itype_tag_ids} //comma separated string
            />
          </Column>
        </Row>

        <Row>
          <Field
            ref={itype_tracking_url}
            label={"Tracking URL"}
            {...fieldProps}
            fieldWidth={"auto"}
            value={data.itype_tracking_url}
            name={"itype_tracking_url"}
            disabled={processing}
          />
        </Row>

        <UICustomizer
          style={"editable-form"}
          refs={extensionFieldRefs}
          data={data}
          featureName={"AlertTypeFormExtension"}
        />
      </Form>
    );
  };

  const getRoutingForm = () => {
    return (
      <Form
        ref={IncidentTypeForm}
        refs={[
          itype_outbound_integration_ids,
          itype_outbound_priority,
          itype_volume,
        ]}
      >
        {getRoutingFields()}
      </Form>
    );
  };

  const getRoutingFields = () => {
    const isAcceptNotify = props.type === "accept-notify";

    const priorityLabel = isAcceptNotify ? (
      <span>Notification&nbsp;Priority</span>
    ) : (
      <span>Alert&nbsp;Priority</span>
    );
    let selectedOutboundIntegrationIds = data.itype_outbound_integration_ids;

    if (
      isAcceptNotify &&
      data?.inci_tags != null &&
      data?.inci_tags?.length > 0
    ) {
      //merge tag_outbound_integration_ids from inci_tags with unique ids

      selectedOutboundIntegrationIds = data?.inci_tags
        .map((tag) => tag.tag_outbound_integration_ids)
        .reduce((accu, arrIds) => {
          arrIds?.forEach((id) => {
            if (accu.indexOf(id) < 0) {
              accu.push(id);
            }
          });

          return accu;
        }, []);
    }

    return (
      <>
        {" "}
        <Row>
          <Field
            label={"Always Notify"}
            {...fieldProps}
            type={FieldTypes.READ_ONLY}
            value={"All Dashboards"}
          />
        </Row>
        <Row>
          <FieldOutboundIntegrationSelector
            label={<span>Send&nbsp;Alerts&nbsp;To</span>}
            {...fieldProps}
            fieldRef={itype_outbound_integration_ids}
            selectedOutboundChannels={selectedOutboundIntegrationIds}
          ></FieldOutboundIntegrationSelector>
        </Row>
        <Row>
          <FieldPrioritySelector
            label={priorityLabel}
            {...fieldProps}
            fieldRef={itype_outbound_priority}
            placeholder={""}
            fieldWidth={fieldWidth}
            selectedPriorityString={data.itype_outbound_priority}
          />
        </Row>
        <Row>
          <FieldAlertVolumeSelector
            {...fieldProps}
            fieldRef={itype_volume}
            selectedAlertVolume={data.itype_volume}
            label={"Alert Volume"}
          />
        </Row>
      </>
    );
  };

  const getVolumeForm = () => {
    return (
      <Form ref={IncidentTypeForm} refs={[itype_volume]}>
        <p>
          You can throttle the number of alerts Zebrium issues when an alert
          rule matches within your log stream.
        </p>

        <div style={{ paddingBottom: "20px" }}>
          <FieldAlertVolumeSelector
            {...fieldProps}
            fieldRef={itype_volume}
            selectedAlertVolume={data.itype_volume}
            label={"Alert Volume"}
          />
        </div>
      </Form>
    );
  };

  const processChangeKeys = () => {
    return Promise.resolve()
      .then(() => alertContext.enterEditKeysMode())
      .then(() => closeModalDialog());
  };

  const acceptAlert = (isSaveOnly) => {
    const itype_props = Object.keys(itypeData).filter(
      (p) => p.indexOf("itype") === 0
    );
    let values = {};
    itype_props.forEach((p) => (values[p] = itypeData[p]));
    values.itype_volume = "day";
    values.itype_state = "accepted";

    return Promise.resolve()
      .then(() => IncidentTypeForm?.current?.getValues())
      .then((formValues) => {
        if (formValues?.errors.length > 0) {
          dialogErrorMessage = getErrorMessageDisplay(formValues.errors);
          const err = { message: getErrorMessageDisplay(formValues.errors) };
          if (isSaveOnly) {
            //secondary button
            secondaryBtnProcessing = false;
            showDialog();
          }
          throw err;
        } else {
          if (formValues.itype_feedback_incident != null) {
            if (typeof formValues.itype_feedback_incident == "string") {
              if (formValues.itype_feedback_incident?.length === 0) {
                formValues.itype_feedback_incident = null;
              } else {
                formValues.itype_feedback_incident = parseInt(
                  formValues.itype_feedback_incident
                );
              }
            } else {
              formValues.itype_feedback_incident = null;
            }
          }

          //TODO: multi select dropdown getValue returns string
          // comma separated values
          if ("itype_outbound_integration_ids" in formValues) {
            formValues.itype_outbound_integration_ids =
              outboundIntegrationRef.current.deriveSelectedValues(
                formValues.itype_outbound_integration_ids
              );
          }
          if (formValues.itype_outbound_priority === "default") {
            formValues.itype_outbound_priority = "";
          }
          values = { ...values, ...formValues };
        }
        return;
      })
      .then(() => dataService.fetch("incidenttype/create", values))
      .then((response) => {
        if (isSaveOnly) {
          secondaryBtnProcessing = false;
        }
        if (response.response.code !== 200) {
          if (isSaveOnly) {
            showDialog();
          }
          throw response.response;
        }
      });
  };

  let buttonLabel;
  if (props.type === "edit-note") {
    buttonLabel = "Metadata";
  } else if (props.type === "edit-routing") {
    buttonLabel = "Routing";
  } else if (props.type === "edit-volume") {
    buttonLabel = "Volume";
  } else {
    buttonLabel = "Edit";
  }

  if (props.label) {
    buttonLabel = props.label;
  }

  let dialogTitle = "Edit Alert Rule Metadata";
  if (props.type === "edit-routing") {
    dialogTitle = "Edit Alert Routing";
  } else if (props.type === "accept-notify") {
    dialogTitle = "Notification on Future Occurrences";
  } else if (props.type === "edit-volume") {
    dialogTitle = "Edit Alert Volume";
  }

  const acceptAlertAndEditAlertRule = () => {
    return Promise.resolve()
      .then(() => acceptAlert())
      .then(() => alertContext.loadIncidentTypeData())
      .then(() => processChangeKeys());
  };

  const secondaryButtonClickHandler = () => {
    secondaryBtnProcessing = true;
    dialogErrorMessage = null;
    showDialog();
    return Promise.resolve()
      .then(() => acceptAlert(true))
      .then(() => alertContext.loadIncidentTypeData())
      .then(() => closeModalDialog());
  };

  const confirmRestore = () => {
    openConfirmationDialog({
      icon: faExclamationTriangle,
      submit: () => showDialog(),
      submitLabel: "Restore",
      closeOnSubmit: false,
      title: "Restore this Report and Create Alert Rule?",
      content: (
        <>
          <p>
            This action will restore this report, mark it accepted and form a
            rule based on the selected event keys.
          </p>
        </>
      ),
    });
  };

  const showDialog = () => {
    const SaveButton = (
      <Button
        type="secondary"
        processing={secondaryBtnProcessing}
        onClick={secondaryButtonClickHandler}
      >
        Save
      </Button>
    );

    const modalDialogSettings = {
      title: dialogTitle,
      minWidth: "90vw",
      maxWidth: "90vw",
      icon: faEdit,
      submitLabel: "Save & Edit Alert Rule",
      secondaryButtons: [SaveButton],
      submit: acceptAlertAndEditAlertRule,
      closeOnSubmit: false,
      content: getFormContent(),
      otherErrorMessage: dialogErrorMessage,
      cancelCallback: () => {},
    };

    openModalDialog(modalDialogSettings);
  };

  if (
    props.type !== "edit-note" &&
    props.type !== "edit-routing" &&
    props.type !== "edit-volume" &&
    props.type !== "edit-all"
  ) {
    return null;
  }

  if (props.renderType === "menuItem") {
    return (
      <StyledMenuItemDiv onClick={() => showDialog()}>
        {props.label}
      </StyledMenuItemDiv>
    );
  }

  if (props.renderType === "acceptButton") {
    return (
      <Button
        type="secondary"
        onClick={() => showDialog()}
        cssStyle={{
          width: "200px",
          color: "#bfd146",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {props.label}
      </Button>
    );
  }

  if (props.renderType === "restoreButton") {
    return (
      <Button
        type="secondary"
        onClick={() => confirmRestore()}
        cssStyle={{
          width: "200px",
          color: "#bfd146",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {props.label}
      </Button>
    );
  }

  return (
    <div style={{ marginRight: "4px" }}>
      {props.visible && (
        <Button
          type={"secondary"}
          processing={Object.keys(itypeData || {}).length < 1}
          onClick={() => showDialog()}
        >
          <div data-testid="edit-button">{buttonLabel}</div>
        </Button>
      )}
    </div>
  );
});

DialogAlertRuleMetadataEdit.defaultProps = {
  response: { code: 0 },
  visible: true,
};

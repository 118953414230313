import React, { useContext } from "react";
import {
  IncidentListContext,
  IncidentBucketLimitMessage,
  Incident,
} from "/components-biz/incidents";

import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";

export const IncidentBucket = ({ bucket }) => {
  const ac = useContext(IncidentListContext);

  if (bucket.alerts_count > ac.bucketAlertsLimit) {
    return <IncidentBucketLimitMessage bucket={bucket} />;
  } else if (bucket.alerts_count > 0) {
    return bucket.alerts.map((alert) => {
      return <Incident key={alert.inci_id} alert={alert} />;
    });
  } else {
    return null;
  }
};

import React, {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  DataServiceContext,
  LoadingAnim,
  useModalDialog,
} from "athena-next-ui-lib";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertRuleContext } from "./AlertRuleContextProvider";
import { TagCreateUpdateDialogContent } from "/components-biz";
import EventStyles from "/components-ui/list/events/EventList.module.scss";

export const DialogAlertRule = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const alertRuleContext = useContext(AlertRuleContext);

  const { openModalDialog, closeModalDialog } = useModalDialog();

  const [processing] = useState(false);
  const [errors] = useState(null);
  const [loading, setLoading] = useState(false);

  const editAlertRuleContent = useRef();

  const channels = alertRuleContext?.channels || [];

  const serviceGroups = alertRuleContext?.serviceGroups || [];

  const isAdd = props.action === "ADD";

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const saveTag = () => {
    const payload = editAlertRuleContent.current.getTagDef();

    return Promise.resolve()
      .then(() => dataServiceContext.fetch("tag/create", payload))
      .then((tagCall) => {
        let tagData = null;
        if (tagCall.response.code === 200) {
          const tagDataArr = (tagCall || {})?.data || [];
          tagData = tagDataArr[0];
          props.updateCallback && props.updateCallback();
        } else {
          throw tagCall.response;
        }

        return tagData;
      })
      .then((tagData) => {
        closeModalDialog();
        openSaveSuccessDialog(tagData);
        return;
      });
  };

  const openSaveSuccessDialog = (tag) => {
    let feedbackDialogTitle = null;
    let feedbackDialogDesc = null;

    if (props.action === "ADD") {
      feedbackDialogTitle = props.dialogDef.dialog.createdTitle;
      feedbackDialogDesc = props.dialogDef.dialog.createdDesc(tag.tag_name);
    } else {
      feedbackDialogTitle = props.dialogDef.dialog.updatedTitle;
      feedbackDialogDesc = props.dialogDef.dialog.editedDesc(tag.tag_name);
    }

    openModalDialog({
      title: feedbackDialogTitle,
      icon: props.dialogDef.icon,
      type: "warn",
      submitLabel: "Ok",
      content: <span style={{ margin: "10px 0" }}>{feedbackDialogDesc}</span>,
    });
  };

  const showDialog = (tag) => {
    let title = null;
    const isAdd = props.action === "ADD";

    if (props.useEventType === false) {
      tag.tag_etroot = "";
    }

    if (isAdd) {
      if (props.event) {
        //one-click flow
        title = props.dialogDef.dialog.createTitle(tag.tag_etroot);
      } else {
        title = props.dialogDef.dialog.createTitle();
      }
    } else {
      if (tag.tag_etroot && tag.tag_etroot !== "") {
        //one-click flow
        title = props.dialogDef.dialog.editTitle(tag.tag_etroot);
      } else {
        title = props.dialogDef.dialog.editTitle();
      }
    }

    const settings = {
      icon: props.dialogDef.icon,
      title: title,
      content: (
        <>
          <TagCreateUpdateDialogContent
            {...props}
            ref={editAlertRuleContent}
            tag={tag}
            errors={errors}
            channels={channels}
            serviceGroups={serviceGroups}
          />
        </>
      ),
      submit: saveTag,
      closeOnSubmit: false,
      submitLabel: "Save Changes",
    };

    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => openModalDialog(settings))
      .finally(() => {
        props.hideEventMenu && props.hideEventMenu();
        setLoading(false);
      });
  };

  if (props.event) {
    //this is one click flow
    const loadingCss = loading
      ? { visibility: "visible" }
      : { visibility: "hidden" };
    const dialogProps = { tag_type: props.dialogDef.tagType };
    if (props.tagType !== "user_driven") {
      //pass in et_root
      dialogProps.tag_etroot = props?.event?.ievt_etroot;
    }

    return (
      <div
        className={EventStyles.eventMenuItem}
        onClick={() => showDialog(dialogProps)}
      >
        <span className={EventStyles.eventMenuIcon}>
          <FontAwesomeIcon icon={props.icon} fixedWidth={true} />
        </span>
        <span>&nbsp;{props.menuLabel}&nbsp;</span>
        <span style={loadingCss} className={EventStyles.eventMenuLoading}>
          <LoadingAnim />
        </span>
      </div>
    );
  } else if (isAdd) {
    return (
      <Button onClick={() => showDialog({})} processing={processing}>
        <span>{props.dialogDef.dialog.addLabel}</span>
      </Button>
    );
  } else {
    return null;
  }
});

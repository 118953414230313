import React, { useContext } from "react";
import { ReportContext } from "/components-biz";
import { WordCloud } from "/components-ui";

export function WordCloudWrapper(props) {
  const alertContext = useContext(ReportContext);
  const itypeData = alertContext?.itypeData || {};
  const itypeDataResponse = alertContext?.itypeDataResponse || { code: 0 };

  let cloudWords = itypeData?.inci_words || [];

  return (
    <WordCloud
      loading={itypeDataResponse?.code === 0}
      words={cloudWords}
      scrollable={true}
      callback={(term) => alertContext.applyFindTerm(term)}
    />
  );
}

import { Shuttle } from "athena-next-ui-lib";
import React, { useContext } from "react";
import { ReportContext } from "/components-biz";

/*
 *  SearchField used on both report detail and list view, only report is relying on alert context
 *   this is a wrapper for detail view
 */
export const ShuttleWrapper = (props) => {
  const reportContext = useContext(ReportContext);
  const { onFilterUpdate } = reportContext;

  if (reportContext == null) return null;

  return <Shuttle {...props} onChange={onFilterUpdate} />;
};

import React, { useRef, useContext, useState, useEffect, useMemo } from "react";
import styles from "./report.module.scss";
import {
  Row,
  Panel,
  Content,
  PageContext,
  PageTemplate,
  DataServiceContext,
} from "athena-next-ui-lib";
import {
  AlertKeyTray,
  ReportHeader,
  PanelEventsGraph,
  PanelInsights,
  FilterSummaryWrapper,
  WordCloudWrapper,
  ReportContext,
  PageNavBar,
  PageBarContent,
  PanelIncidentInfo,
  SplitterBar,
  getReportHeaderHeight,
} from "/components-biz";
import {
  EventList,
  ListWrapper,
  ZoomBar,
  DisplayModes,
  ListModes,
  ListState,
} from "/components-ui";
import { userPrefsMapping } from "../../userPrefs/user-pref-definitions";

export function PageWrapper() {
  const pageContext = useContext(PageContext);
  const { router } = pageContext;
  const dataServiceContext = useContext(DataServiceContext);
  const reportContext = useContext(ReportContext);
  const {
    events,
    listMode,
    setListMode,
    queryState,
    setQueryState,
    getQueryState,
    sidePaneWidth,
    isSidePaneCollapsed,
    itypeData,
  } = reportContext;

  const [componentDidMount, setComponentDidMount] = useState(false);
  const [wrapEvents, setWrapEvents] = useState(true);

  const listId = "event-list";
  const groupByDay = true;

  const filters = useRef([]);

  useEffect(() => {
    //store the queryState once router reports it is initialized
    if (pageContext.isReady === true) {
      const qs = { ...router.query };
      setQueryState(qs);
      setComponentDidMount(true);
      const eventLineWrapPref = dataServiceContext.getUserPreference(
        userPrefsMapping.EVENT_LINE_WRAP
      );
      setWrapEvents(eventLineWrapPref == null ? true : eventLineWrapPref);
      console.info(
        "router initialized:",
        queryState,
        JSON.stringify(qs, null, 2)
      );
    }
  }, [pageContext.isReady]);

  useEffect(() => {
    //emulate component did mount method of class component
    //when we see this var is true, we know we can initiate the loading of data
    if (componentDidMount === true) {
      loadData();
    }
  }, [componentDidMount]);

  const getFilters = () => {
    return filters.current;
  };

  const [logNames, setLogNames] = useState(null);
  const [hostNames, setHostNames] = useState(null);
  const [sourceNames, setSourceNames] = useState(null);
  const [serviceGroups, setServiceGroups] = useState(null);
  const [rareness, setRareness] = useState(null);
  const [severities, setSeverities] = useState(null);
  const [routingRules, setRoutingRules] = useState(null);
  const [customRules, setCustomRules] = useState(null);

  const allFilters = useMemo(() => {
    const x =
      [
        logNames,
        hostNames,
        sourceNames,
        serviceGroups,
        rareness,
        severities,
        routingRules,
        customRules,
      ].filter((item) => item !== null) || [];

    return x;
  }, [
    logNames,
    hostNames,
    sourceNames,
    serviceGroups,
    rareness,
    severities,
    routingRules,
    customRules,
  ]);

  useEffect(() => {
    const { custom_rules: selectedCustomRules } = getQueryState();

    if (reportContext?.customRules?.length) {
      setCustomRules({
        seq: 4,
        expanded: false,
        category: "Custom Alert Rules",
        items: reportContext.customRules.map((item) => {
          const selected = selectedCustomRules
            ? selectedCustomRules?.includes(item.name)
            : false;
          return {
            ...item,
            selected,
          };
        }),
        selectionStyle: "multiple",
        apiParam: "custom_rules",
      });
    } else {
      setCustomRules(null);
    }
  }, [reportContext.customRules]);

  useEffect(() => {
    const { routing_rules: selectedRoutingRules } = getQueryState();

    if (reportContext?.routingRules?.length) {
      setRoutingRules({
        seq: 4,
        expanded: false,
        category: "ML Routing Rules",
        items: reportContext.routingRules.map((item) => {
          const selected = selectedRoutingRules
            ? selectedRoutingRules?.includes(item.name)
            : false;
          return {
            ...item,
            selected,
          };
        }),
        selectionStyle: "multiple",
        apiParam: "routing_rules",
      });
    } else {
      setRoutingRules(null);
    }
  }, [reportContext.routingRules]);

  const loadData = () => {
    const { itype_id, inci_id, ievt_gen } = getQueryState();

    if (!itype_id || !inci_id) return;

    const inPeekMode = ievt_gen && ievt_gen !== undefined;

    return Promise.all([
      setListMode(inPeekMode ? ListModes.peek : ListModes.read),

      loadLogNames(),
      loadHostNames(),
      loadSourceNames(),
      loadServiceGroups(),
      loadRareness(),
      loadSeverities(),
    ]).then(() => console.log("filters", getFilters()));
  };

  const loadLogNames = () => {
    const { inci_id, ievt_log: selections } = getQueryState();

    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch("incidentevent/read/logtypes", {
          time_from: 1,
          time_to: 99999999999,
          inci_id: inci_id,
        })
      )
      .then((response) => {
        setLogNames({
          seq: 4,
          expanded: false,
          category: "Logtype",
          items: response.data.map((item) => {
            return {
              ...item,
              selected: selections ? selections.includes(item.name) : false,
            };
          }),
          selectionStyle: "multiple",
          apiParam: "ievt_log",
        });

        return true;
      });
  };

  const loadHostNames = () => {
    const { inci_id, ievt_host: selections } = getQueryState();

    return dataServiceContext
      .fetch("incidentevent/read/hosts", {
        time_from: 1,
        time_to: 99999999999,
        inci_id: inci_id,
      })
      .then((response) => {
        setHostNames({
          seq: 5,
          expanded: false,
          category: "Host",
          items: response.data.map((item) => {
            return {
              ...item,
              selected: selections ? selections.includes(item.name) : false,
            };
          }),
          selectionStyle: "multiple",
          apiParam: "ievt_host",
        });

        return true;
      });
  };

  const loadSourceNames = () => {
    const { inci_id, ievt_source: selections } = getQueryState();

    return dataServiceContext
      .fetch("incidentevent/read/sources", {
        time_from: 1,
        time_to: 99999999999,
        inci_id: inci_id,
      })
      .then((response) => {
        setSourceNames({
          seq: 5,
          expanded: false,
          category: "Source",
          items: response.data.map((item) => {
            return {
              ...item,
              selected: selections ? selections?.includes(item.name) : false,
            };
          }),
          selectionStyle: "multiple",
          apiParam: "ievt_source",
        });

        return true;
      });
  };

  const loadServiceGroups = () => {
    const { inci_id, ievt_svc_grp: selections } = getQueryState();

    return dataServiceContext
      .fetch("incident/read/servicegroups", {
        time_from: 1,
        time_to: 99999999999,
        inci_id: inci_id,
      })
      .then((response) => {
        let items = [
          { name: "Shared Services", value: "default" },
          ...response.data,
        ];

        setServiceGroups({
          seq: 3,
          expanded: false,
          category: "Service Group",
          items: items.map((item) => {
            return {
              ...item,
              selected: selections
                ? selections.includes(item.value || item.name)
                : false,
            };
          }),
          selectionStyle: "multiple",
          apiParam: "ievt_svc_grp",
        });

        return true;
      });
  };

  const loadSeverities = () => {
    const { ievt_sevno: selected } = getQueryState();

    const response = {
      data: [
        {
          name: "Emergency",
          value: "0",
          selected: (selected || "").indexOf("0") > -1,
        },
        {
          name: "Alert",
          value: "1",
          selected: (selected || "").indexOf("1") > -1,
        },
        {
          name: "Critical",
          value: "2",
          selected: (selected || "").indexOf("2") > -1,
        },
        {
          name: "Error",
          value: "3",
          selected: (selected || "").indexOf("3") > -1,
        },
        {
          name: "Warning",
          value: "4",
          selected: (selected || "").indexOf("4") > -1,
        },
        {
          name: "Notice",
          value: "5",
          selected: (selected || "").indexOf("5") > -1,
        },

        {
          name: "Info",
          value: "6",
          selected: (selected || "").indexOf("6") > -1,
        },
        {
          name: "Debug",
          value: "7,8",
          selected: (selected || "").indexOf("7,8") > -1,
        },
      ],
      response: { timestamp: 1, code: 200 },
      selectedIndex: 0,
    };

    setSeverities({
      seq: 1,
      expanded: true,
      category: "Severity",
      items: response.data.map((item) => {
        return { ...item };
      }),
      selectionStyle: "multiple",
      apiParam: "ievt_sevno",
      getSelectedValues: "transformValuesIntoString",
    });

    return true;
  };

  const loadRareness = () => {
    const { ievt_rare_idx: selected } = getQueryState();

    const response = {
      data: [
        {
          name: "Not rare",
          value: "0",
          selected: (selected || "").indexOf("0") > -1,
        },
        {
          name: "Rare by Generator",
          value: "1",
          selected: (selected || "").indexOf("1") > -1,
        },
        {
          name: "Rare Overall",
          value: "2",
          selected: (selected || "").indexOf("2") > -1,
        },
      ],
      response: { timestamp: 1, code: 200 },
      selectedIndex: 0,
    };

    setRareness({
      seq: 2,
      expanded: false,
      category: "Rareness",
      items: response.data.map((item) => {
        return { ...item };
      }),
      selectionStyle: "multiple",
      apiParam: "ievt_rare_idx",
      getSelectedValues: "transformValuesIntoString",
    });

    return true;
  };

  const reportHeaderH = useMemo(() => {
    return getReportHeaderHeight(itypeData?.itype_state);
  }, [itypeData]);

  const content = () => {
    const { ievt_level, ievt_id, inci_id } = getQueryState();

    if (!inci_id) return null;

    const pagebarH = 49;
    const navbarW = 49;
    const padW = 30;
    const padH = 20;
    const mainPaneWidth = isSidePaneCollapsed()
      ? `calc(100vw - ${navbarW}px)`
      : `calc(100vw - ${sidePaneWidth}px - ${padW * 2}px - ${navbarW}px)`;

    return (
      <>
        <Row height={reportHeaderH}>
          <ReportHeader />
        </Row>

        <Row width={`calc(100vw - ${navbarW})`}>
          <div
            className={styles.mainPaneColumn}
            style={{
              flex: `0 0 ${mainPaneWidth}px`,
              height: `calc(100vh - ${pagebarH}px - ${reportHeaderH}px)`,
            }}
          >
            <Row
              height={`calc(100vh - ${pagebarH}px - ${reportHeaderH}px)`}
              width={mainPaneWidth}
              overflowY={"hidden"}
              overflowX={"hidden"}
            >
              <ListWrapper
                listId={listId}
                mode={listMode}
                displayMode={DisplayModes[0]}
                getQueryState={getQueryState}
                objectName={"Events"}
                router={router}
              >
                <AlertKeyTray wrapEvents={wrapEvents} width={mainPaneWidth} />

                <ZoomBar
                  listId={listId}
                  wrapEvents={wrapEvents}
                  setWrapEvents={setWrapEvents}
                />

                <FilterSummaryWrapper
                  mode={listMode}
                  listId={listId}
                  objectName={"Events"}
                />

                <ListState {...events} />

                <EventList
                  wrapEvents={wrapEvents}
                  width={mainPaneWidth}
                  listId={listId}
                  groupByDay={groupByDay}
                  objectName={"Events"}
                  displayFindBar={true}
                />
              </ListWrapper>
            </Row>
          </div>

          <SplitterBar />

          {isSidePaneCollapsed() === false && (
            <div
              className={styles.sidePaneColumn}
              style={{
                flex: `1 1 ${sidePaneWidth + padW}px`,
                minWidth: `${sidePaneWidth + padW}px`,
                height: `calc(100vh - ${pagebarH + reportHeaderH + padH}px)`,
              }}
            >
              <PanelInsights width={sidePaneWidth} />

              <PanelIncidentInfo width={sidePaneWidth} />

              <Panel width={sidePaneWidth} flex={"1 0 auto"}>
                <Content response={{ code: 200 }}>
                  <WordCloudWrapper />
                </Content>
              </Panel>

              <PanelEventsGraph
                ievt_level={ievt_level}
                ievt_id={ievt_id}
                router={router}
                notesHeight={40}
                width={sidePaneWidth}
              />
            </div>
          )}
        </Row>
      </>
    );
  };

  return (
    <PageTemplate
      title={"Root Cause Report"}
      pageBar={<PageBarContent filters={allFilters} />}
      navBar={<PageNavBar />}
      pageContent={content()}
    />
  );
}

import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Column,
  Form,
  Row,
  Field,
  PageContext,
  DataServiceContext,
  useModalDialog,
  CustomIcon,
  getErrorMessageDisplay,
} from "athena-next-ui-lib";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { UICustomizer } from "/components-ui";
import {
  FieldOutboundIntegrationSelector,
  FieldPrioritySelector,
  FieldManualTagSelector,
  FieldAlertVolumeSelector,
  PANEL_SECTION_TYPES,
  PanelSection,
} from "/components-biz";

export const DialogAcceptedAlertRuleEdit = forwardRef((props, ref) => {
  const dataService = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();
  const pageContext = useContext(PageContext);

  const [data, setData] = useState(props.data || {});
  const [keys, setKeys] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const IncidentTypeForm = useRef();

  const itype_volume = useRef();
  const itype_title = useRef();
  const itype_owner = useRef();
  const itype_tracking_url = useRef();
  const itype_feedback_incident = useRef();
  const itype_summary = useRef();
  const itype_detail = useRef();
  const itype_keys = useRef();
  const itype_force_rcr_from_keys = useRef();

  const itype_outbound_integration_ids = useRef();
  const itype_outbound_priority = useRef();
  const itype_tag_ids = useRef();

  let extensionFieldRefs = {};
  extensionFieldRefs[`itype_ext_f1`] = useRef();
  extensionFieldRefs[`itype_ext_f2`] = useRef();
  extensionFieldRefs[`itype_ext_f3`] = useRef();
  extensionFieldRefs[`itype_ext_f4`] = useRef();
  extensionFieldRefs[`itype_ext_f5`] = useRef();
  extensionFieldRefs[`itype_ext_f6`] = useRef();
  extensionFieldRefs[`itype_ext_f7`] = useRef();
  extensionFieldRefs[`itype_ext_f8`] = useRef();
  extensionFieldRefs[`itype_ext_f9`] = useRef();
  extensionFieldRefs[`itype_ext_f10`] = useRef();

  let fieldRefs = [
    itype_keys,
    itype_title,
    itype_summary,
    itype_detail,
    itype_owner,
    itype_tracking_url,
    itype_feedback_incident,
    itype_outbound_integration_ids,
    itype_outbound_priority,
    itype_tag_ids,
    itype_force_rcr_from_keys,
    itype_volume,
  ];

  fieldRefs = fieldRefs.concat(extensionFieldRefs); //combining out-of-box with custom defined fields
  Object.keys(extensionFieldRefs).forEach((name) =>
    fieldRefs.push(extensionFieldRefs[name])
  );

  const { env } = pageContext;
  const { processing } = false;
  const fieldWidth = "200px";
  const fieldProps = {
    fieldWidth: fieldWidth,
    labelWidth: "130px",
    labelAlign: "right",
  };

  useImperativeHandle(ref, () => ({
    showDialog,
    setIncidentData,
    setKeys,
  }));

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (data && dialogVisible) {
      showDialog();
    }
  }, [data, dialogVisible]);

  useEffect(() => {
    const keys = data?.itype_keys || "";
    setKeys(keys);
  }, [data.itype_keys]);

  useEffect(
    () => {
      if (!pageContext.isReady) {
        return;
      }
    },
    [props.response?.timestamp],
    pageContext.isReady
  );

  const setIncidentData = (data) => {
    setData(data);
    setDialogVisible(true);
  };

  const getFullForm = () => {
    return (
      <Form ref={IncidentTypeForm} refs={fieldRefs}>
        <Row>
          <Field
            type={"description"}
            value={"Capture pertinent information about this alert rule."}
          />
        </Row>

        <Row>
          <PanelSection
            ref={itype_title}
            name="itype_title"
            type={PANEL_SECTION_TYPES.COLLAPSING}
            onChange={(value) => setTitle(value)}
            title="Title"
            height="50px"
            value={data.itype_title}
            expanded={true}
            isEditing={true}
            disabled={processing}
            originalValue={
              data.itype_nlp_populated ? data.itype_nlp_title : data.itype_title
            }
          />
        </Row>

        <Row>
          <PanelSection
            ref={itype_summary}
            name="itype_summary"
            type={PANEL_SECTION_TYPES.COLLAPSING}
            onChange={(value) => setSummary(value)}
            title="Summary"
            value={data.itype_summary}
            originalValue={
              data.itype_nlp_populated
                ? data.itype_nlp_summary
                : data.itype_summary
            }
            expanded={false}
            isEditing={true}
            disabled={processing}
          />
        </Row>

        <Row width="*">
          <PanelSection
            ref={itype_detail}
            name="itype_detail"
            type={PANEL_SECTION_TYPES.COLLAPSING}
            onChange={(value) => setDetail(value)}
            title="Detail"
            height="250px"
            value={data.itype_detail}
            originalValue={
              data.itype_nlp_populated
                ? data.itype_nlp_detail
                : data.itype_detail
            }
            expanded={false}
            isEditing={true}
            disabled={processing}
          />
        </Row>

        <Row>
          <Column width={"50%"}>
            <FieldOutboundIntegrationSelector
              label={"Send Alerts To"}
              {...fieldProps}
              fieldRef={itype_outbound_integration_ids}
              required={true}
              selectedOutboundChannels={data.itype_outbound_integration_ids}
              tooltip={
                "Alerts will be sent to all dashboards plus any channels you select."
              }
            />
            <FieldPrioritySelector
              label={"Alert Priority"}
              {...fieldProps}
              fieldRef={itype_outbound_priority}
              required={true}
              selectedPriorityString={data.itype_outbound_priority}
            />
            <FieldAlertVolumeSelector
              {...fieldProps}
              fieldRef={itype_volume}
              selectedAlertVolume={data.itype_volume}
              label={"Alert Volume"}
            />
          </Column>
          <Column width={"50%"} css={{ marginLeft: "8px" }}>
            <Field
              ref={itype_owner}
              {...fieldProps}
              label={"Owner"}
              width={fieldWidth}
              value={data.itype_owner}
              name={"itype_owner"}
              disabled={processing}
            />

            <FieldManualTagSelector
              label={"Manual Tags"}
              {...fieldProps}
              fieldRef={itype_tag_ids}
              selectedManualTagIDs={data?.itype_tag_ids} //comma separated string
            ></FieldManualTagSelector>
          </Column>
        </Row>

        <Row>
          <Field
            ref={itype_keys}
            {...fieldProps}
            fieldWidth={fieldWidth}
            type={"read-only"}
            label={
              <span>
                Alert Rule Keys
                <CustomIcon
                  name={"key"}
                  options={{
                    wrapInSVG: true,
                    rotate: 30,
                    size: 18,
                  }}
                />
              </span>
            }
            value={keys || <i>Selected by Zebrium ML</i>}
            name={"itype_keys"}
            disabled={processing}
          />
        </Row>

        <Row>
          <Field
            ref={itype_tracking_url}
            label={"Tracking URL"}
            {...fieldProps}
            fieldWidth={"auto"}
            value={data.itype_tracking_url}
            name={"itype_tracking_url"}
            disabled={processing}
          />
        </Row>

        <UICustomizer
          style={"editable-form"}
          refs={extensionFieldRefs}
          data={data}
          featureName={"AlertTypeFormExtension"}
        />
      </Form>
    );
  };

  const saveIncidentType = () => {
    const itype_props = Object.keys(data).filter(
      (p) => p.indexOf("itype") === 0
    );
    let values = {};
    itype_props.forEach((p) => (values[p] = data[p]));

    const formValues = IncidentTypeForm.current.getValues();
    values = { ...values, ...formValues };

    if (typeof values.itype_outbound_integration_ids === "string") {
      if (values.itype_outbound_integration_ids !== "") {
        values.itype_outbound_integration_ids =
          values.itype_outbound_integration_ids.split(",");
      } else {
        values.itype_outbound_integration_ids = [];
      }
    } else {
      values.itype_outbound_integration_ids = [];
    }

    if (values.itype_outbound_priority === "default") {
      values.itype_outbound_priority = "";
    }

    if (typeof values.itype_feedback_incident == "string") {
      values.itype_feedback_incident = parseInt(values.itype_feedback_incident);
    }

    if (typeof values.itype_keys == "object") {
      //this is when we store italic place holder for the field
      delete values.itype_keys;
    }

    return Promise.resolve()
      .then(() => {
        if (formValues?.errors.length > 0) {
          //form contains error(s), throw error , submit in modal dialog handles it
          throw new Error(getErrorMessageDisplay(formValues.errors));
        } else {
          return;
        }
      })
      .then(() => dataService.fetch("incidenttype/create", values))
      .then((response) => {
        if (response.response.code === 200) {
          props.updateCallback && props.updateCallback(response.data[0]);

          return response;
        } else {
          throw response.response;
        }
      })
      .then((response) => {
        if (response.response.code === 200) {
          setDialogVisible(false);
        }
        return;
      });
  };

  let dialogTitle = "Edit Accepted Alert Rule Metadata";

  const showDialog = () => {
    const modalDialogSettings = {
      title: dialogTitle,
      icon: faEdit,
      submitLabel: "Save",
      submit: saveIncidentType,
      closeOnSubmit: true,
      content: getFullForm(),
      cancelCallback: setDialogVisible(false),
      minWidth: "90vw",
      maxWidth: "90vw",
    };

    openModalDialog(modalDialogSettings);
  };
  return null;
});

DialogAcceptedAlertRuleEdit.defaultProps = {
  response: { code: 0 },
};

import React, { useMemo, useContext } from "react";
import {
  IncidentListContext,
  TimelineContext,
} from "/components-biz/incidents";
import { PageContext, Utilities } from "athena-next-ui-lib";
import styles from "./IncidentTimeline.module.scss";

export const TimeRangeLabel = () => {
  const ac = useContext(IncidentListContext);
  const tc = useContext(TimelineContext);
  const pageContext = useContext(PageContext);

  const range = useMemo(() => {
    const { alertBuckets } = ac;
    const { bucketWidth, selectedBuckets, xAxisH, tooltip } = tc;

    if (
      tooltip === null &&
      selectedBuckets.start > -1 &&
      selectedBuckets.end > -1
    ) {
      const start = alertBuckets[selectedBuckets.start]?.start;
      const end = alertBuckets[selectedBuckets.end]?.end;
      if (!start || !end) return null;
      const endFormat = start.isSame(end, "day") ? "HH:mm" : "MMM DD HH:mm";
      const s = Utilities.TZ.formatTS(
        start,
        pageContext.timeZone,
        "MMM DD HH:mm"
      );
      const e = Utilities.TZ.formatTS(end, pageContext.timeZone, endFormat);
      const bucketMidPont =
        selectedBuckets.start +
        (1 + selectedBuckets.end - selectedBuckets.start) * 0.5;
      const midX = bucketMidPont * bucketWidth;
      const w = (s.length + 3 + e.length) * 7;
      return (
        <g transform={`translate(${midX} ${xAxisH - 2})`}>
          <rect
            className={styles.timeRangeBox}
            x={-w * 0.5}
            y={-12}
            width={w}
            height={16}
          />
          <text className={styles.timeRangeLabel} x={0} y={0}>
            {s}&nbsp;&mdash;&nbsp;{e}
          </text>
        </g>
      );
    } else {
      return null;
    }
  }, [tc.selectedBuckets, tc.tooltip]);

  return range;
};

import React, {
  useState,
  useMemo,
  useContext,
  createContext,
  useRef,
} from "react";
import {
  IncidentListContext,
  HistogramTooltips,
  SpikePlot,
  IncidentTooltip,
  TimeRangeLabel,
  IncidentSymbolPlot,
  IncidentBuckets,
  Xaxis,
} from "/components-biz/incidents";
import {
  LoadingAnim,
  useWindowSize,
  Button,
  PageContext,
  Utilities,
} from "athena-next-ui-lib";
import styles from "./IncidentTimeline.module.scss";
import { faBackward as HistoryBackIcon } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const TimelineContext = createContext();

export const IncidentTimeline = (props) => {
  const incidentListContext = useContext(IncidentListContext);
  const pageContext = useContext(PageContext);

  const {
    alertBuckets,
    spikeState,
    refreshAlertsData,
    queryState,
    getQueryState,
    addToHistory,
    setMaxNumBuckets,
    minBucketWidth,
    ingestMode,
    history,
    historyBack,
    isLoadingAlertData,
    alertList,
    alertLoadError,
    onSearchTermUpdate,
  } = incidentListContext;

  const windowSize = useWindowSize();
  const DOM_timeline = useRef();
  const [tooltip, setTooltip] = useState(null);
  const [timelineContext, setTimelineContext] = useState({
    mouse: null,
  });

  const handleMouseLeave = (evt) => {
    setTimelineContext({
      ...timelineContext,
      selectedBuckets: {
        start: -1,
        end: -1,
        last: -1,
        first: -1,
      },
      tooltip: null,
    });
  };

  const handleMouseDown = (evt) => {
    const { insetW, graphW, bucketWidth } = timelineContext;
    const parent = DOM_timeline?.current?.getBoundingClientRect();
    const x = evt.clientX;
    const x0 = x;
    const y = evt.clientY;
    const rX = evt.clientX - parent.left;
    const rY = evt.clientY - parent.top;

    let currentBucket = -1;

    if (rX >= insetW && rX < insetW + graphW) {
      currentBucket = Math.floor((rX - insetW) / bucketWidth);
    }

    setTimelineContext({
      ...timelineContext,
      tooltip,
      selectedBuckets: {
        start: currentBucket,
        end: currentBucket,
        last: currentBucket,
        first: currentBucket,
      },
      mouse: {
        x0,
        x,
        y,
        rX,
        rY,
        isDragging: false,
      },
    });
  };

  const handleMouseMove = (evt) => {
    const { insetW, graphW, bucketWidth, selectedBuckets } = timelineContext;
    const parent = DOM_timeline?.current?.getBoundingClientRect();
    const x = evt.clientX;
    const y = evt.clientY;
    const x0 = timelineContext.mouse.x0;
    const rX = evt.clientX - parent.left;
    const rY = evt.clientY - parent.top;
    const isDragging =
      evt.buttons === 1 &&
      selectedBuckets.start > -1 &&
      selectedBuckets.end > -1 &&
      Math.abs(x - x0) > 10;

    let currentBucket = -1;

    if (rX >= insetW && rX < insetW + graphW) {
      currentBucket = Math.floor((rX - insetW) / bucketWidth);
    }

    let selected = { start: -1, end: -1, last: -1, first: -1 };
    if (!isDragging) {
      if (currentBucket > -1) {
        selected = {
          start: currentBucket,
          end: currentBucket,
          first: currentBucket,
          last: currentBucket,
        };
      }
    } else {
      let s, e;
      if (currentBucket >= selectedBuckets.first) {
        s = selectedBuckets.first;
        e = currentBucket;
      } else {
        s = currentBucket;
        e = selectedBuckets.first;
      }

      selected = {
        start: s,
        end: e,
        first: selectedBuckets.first,
        last: currentBucket,
      };
    }

    setTimelineContext({
      ...timelineContext,
      selectedBuckets: selected,
      tooltip,
      mouse: {
        x0,
        x,
        y,
        rX,
        rY,
        isDragging,
      },
    });
  };

  const handleMouseUp = (evt) => {
    evt.stopPropagation();
    const { start, end } = timelineContext.selectedBuckets;

    if (start > -1 && end > -1 && timelineContext.mouse.isDragging) {
      //perform zoom
      const timeBucketStart = alertBuckets[start].start
        .utc()
        .format("YYYY-MM-DDTHH:mm");
      const timeBucketEnd = alertBuckets[end].end
        .utc()
        .format("YYYY-MM-DDTHH:mm");

      const params = { ...queryState };
      params.time_range = `${timeBucketStart},${timeBucketEnd}`;

      addToHistory(params.time_range);
      refreshAlertsData(params);
    }

    setTimelineContext({
      ...timelineContext,
      tooltip,
      selectedBuckets: { start: -1, end: -1, last: -1, first: -1 },
    });
  };

  const graph = useMemo(() => {
    const { alertBuckets } = incidentListContext;

    if (!alertBuckets || alertBuckets?.length === 0) return null;

    const numBuckets = alertBuckets?.length || 1;
    const navbarWidth = 50;
    const totalW = windowSize.width - navbarWidth;
    const totalH = props.height;
    const insetW = 100;
    const graphW = totalW - insetW * 2;
    const xAxisH = 40;
    const graphH = totalH - xAxisH;
    const bucketWidth = graphW / numBuckets;
    const tickH = 5;
    const symbolWidth = minBucketWidth - 2;
    setMaxNumBuckets(Math.floor(graphW / minBucketWidth));

    setTimelineContext({
      numBuckets,
      navbarWidth,
      totalW,
      totalH,
      insetW,
      graphW,
      xAxisH,
      graphH,
      bucketWidth,
      symbolWidth,
      tickH,
      tooltip,
      selectedBuckets: { start: -1, end: -1, first: -1, last: -1 },
      mouse: { x: -1000, y: -1000 },
    });

    let ingestContent = null;

    if (ingestMode === "stream") {
      const logsLinePath = getHistogramLinePlot(
        alertBuckets,
        "logs",
        bucketWidth,
        xAxisH
      );
      const rareLinePath = getHistogramLinePlot(
        alertBuckets,
        "rare",
        bucketWidth,
        xAxisH
      );

      ingestContent = (
        <>
          <path className={styles.logsLine} d={logsLinePath} />
          <path className={styles.rareLine} d={rareLinePath} />
          <TimeRangeLabel />
          <HistogramTooltips setTooltip={setTooltip} />
        </>
      );
    } else {
      ingestContent = (
        <g transform={`translate(0 -10)`}>
          <TimeRangeLabel />
        </g>
      );
    }

    const sBucket = alertBuckets?.[0];
    const eBucket = alertBuckets?.[alertBuckets.length - 1];

    return (
      <svg width={totalW} height={totalH}>
        <g className={styles.buckets} transform={`translate(${insetW} ${0})`}>
          <IncidentBuckets />
        </g>
        <g className={styles.xAxis} transform={`translate(${0} ${graphH})`}>
          <rect
            className={styles.region}
            x={0}
            y={0}
            width={totalW}
            height={xAxisH}
          />
          <Xaxis />
          <g transform={`translate(${insetW - 5} ${5})`}>
            <g
              className={styles.openTimeRangePicker}
              onClick={props.openTimeRangePicker}
            >
              <text
                className={`${styles.xAxisLabel} ${styles.start}`}
                x={0}
                y={12}
              >
                {Utilities.TZ.formatTS(
                  sBucket?.start,
                  pageContext?.timeZone,
                  "YYYY-MM-DD"
                )}
              </text>
              <text
                className={`${styles.xAxisLabel} ${styles.start} ${styles.minor}`}
                x={0}
                y={25}
              >
                {Utilities.TZ.formatTS(
                  sBucket?.start,
                  pageContext?.timeZone,
                  "HH:mm"
                )}
              </text>
            </g>
          </g>
          <g transform={`translate(${totalW - insetW + 5} ${5})`}>
            <g
              className={styles.openTimeRangePicker}
              onClick={props.openTimeRangePicker}
            >
              <text
                className={`${styles.xAxisLabel} ${styles.end}`}
                x={0}
                y={12}
              >
                {Utilities.TZ.formatTS(
                  eBucket.end,
                  pageContext?.timeZone,
                  "YYYY-MM-DD"
                )}
              </text>
              <text
                className={`${styles.xAxisLabel} ${styles.end} ${styles.minor}`}
                x={0}
                y={25}
              >
                {Utilities.TZ.formatTS(
                  eBucket.end,
                  pageContext?.timeZone,
                  "HH:mm"
                )}
              </text>
            </g>
            <foreignObject x={0} y={-40} width={120} height={25}>
              {history?.length > 1 && (
                <Button
                  type={"secondary"}
                  size={"sm"}
                  onClick={() => historyBack()}
                >
                  <FontAwesomeIcon icon={HistoryBackIcon} />
                  &nbsp;Back
                </Button>
              )}
            </foreignObject>
          </g>
        </g>
        <g className={styles.graph} transform={`translate(${insetW} ${0})`}>
          <rect
            className={styles.region}
            x={0}
            y={0}
            width={graphW}
            height={graphH}
          />
          <SpikePlot />
          <IncidentSymbolPlot setTooltip={setTooltip} />
        </g>

        <g
          className={styles.histogram}
          transform={`translate(${insetW} ${graphH})`}
        >
          {ingestContent}
        </g>
      </svg>
    );
  }, [alertBuckets, windowSize, history, pageContext?.timeZone, spikeState]);

  let searchMessage = (
    <>
      <div>
        <strong>We couldn't find any matching results</strong>
      </div>
      {alertLoadError && <div>ERROR: {alertLoadError}</div>}
    </>
  );
  const qs = getQueryState();
  const setSearchScope = (evt) => {
    evt.stopPropagation();
    onSearchTermUpdate(qs.search, { value: 5 });
  };
  if (qs && qs.search && qs.search.length && qs.ievt_level === "2") {
    searchMessage = (
      <>
        {searchMessage}
        <Button type={"secondary"} onClick={(evt) => setSearchScope(evt)}>
          Search All Events
        </Button>
      </>
    );
  }
  
  if (isLoadingAlertData) {
    return <LoadingAnim styles={{ margin: "20px" }} />;
  } else if (alertList?.some((item) => item.alerts !== null)) {
    return (
      <TimelineContext.Provider value={timelineContext}>
        <div
          className={styles.timeline}
          ref={DOM_timeline}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {graph}
        </div>
        <IncidentTooltip tooltip={tooltip} />
      </TimelineContext.Provider>
    );
  } else {
   
    return <div className={styles.noData}>{searchMessage}</div>;
  }
};

function getHistogramLinePlot(buckets, propName, bucketWidth, height) {
  const max = Math.max(...buckets.map((item) => item[propName]));
  const min = Math.min(...buckets.map((item) => item[propName]));

  const dY = height * 0.2;
  const adjustedH = height - dY * 2;
  return buckets
    .map((item, x) => {
      const cX = x * bucketWidth + bucketWidth * 0.5;
      const cY = dY + (1 - (item[propName] - min) / (max - min)) * adjustedH;

      return x === 0 ? `M${cX} ${cY}` : getPointPath(cX, cY, 2);
    })
    .join(" ");
}

function getPointPath(cX, cY, r) {
  return `L ${cX} ${cY}`;
}

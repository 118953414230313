import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  IncidentListContext,
  Incident,
  applyTextHighlights,
} from "/components-biz/incidents";
import styles from "./IncidentList.module.scss";
import { Utilities, LoadingAnim, PageContext } from "athena-next-ui-lib";
import {
  faCaretRight as arrowCollapsed,
  faCaretDown as arrowExpanded,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";

export const IncidentBucketLimitMessage = ({ bucket }) => {
  const incidentListContext = useContext(IncidentListContext);
  const pageContext = useContext(PageContext);
  const start = bucket?.start;
  const end = bucket?.end;
  const endFormat = start.isSame(end, "day") ? "HH:mm" : "MMM DD HH:mm";
  const [rawIncidents, setRawIncidents] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggleBucketExpansion = () => {
    const state = !isExpanded;
    incidentListContext.toggleSpikeExpansionState(bucket.id);
    setIsExpanded(state);
  };

  useEffect(async () => {
    if (isExpanded === true && rawIncidents.length === 0) {
      const timeBucketStart = start.utc().format("YYYY-MM-DDTHH:mm");
      const timeBucketEnd = end.utc().format("YYYY-MM-DDTHH:mm");

      setIsLoading(true);
      setError(null);

      const time_range = `${timeBucketStart},${timeBucketEnd}`;

      const apiCall = await incidentListContext.fetchIncidentsForTimeRange(
        time_range
      );

      if (apiCall.response.code === 200) {
        const bucketData =
          apiCall?.data?.length && apiCall?.data[0]?.alerts
            ? apiCall.data[0].alerts
            : null;
        if (bucketData) {
          setRawIncidents(bucketData);
        } else {
          setError("No alerts found in this range.");
        }
      } else {
        setError(apiCall.response.message);
      }
      setIsLoading(false);
    }
  }, [isExpanded]);

  const incidents = useMemo(() => {
    const findId = 10000;
    const processedIncidents = rawIncidents.map((alert) => {
      alert.inci_events.forEach(
        (event) =>
          (event.event_text = applyTextHighlights(
            event.ievt_etext,
            incidentListContext.search,
            incidentListContext.findTerm,
            findId
          ))
      );
      return alert;
    });

    return processedIncidents.map((alert) => {
      return <Incident key={alert.inci_id} alert={alert} />;
    });
  }, [rawIncidents, incidentListContext.search, incidentListContext.findTerm]);

  const css = isExpanded
    ? `${styles.row} ${styles.limitRow} ${styles.expanded}`
    : `${styles.row} ${styles.limitRow}`;

  const sDate = Utilities.TZ.formatTS(
    bucket.start,
    pageContext.timeZone,
    "MMM DD HH:mm"
  );

  const eDate = Utilities.TZ.formatTS(
    bucket.end,
    pageContext.timeZone,
    endFormat
  );

  return (
    <div key={`range-${bucket.id}`} id={`range-${bucket.id}`}>
      <div className={css} onClick={toggleBucketExpansion}>
        <div className={styles.timeRangeIcon}>
          {isExpanded === true && (
            <FontAwesomeIcon icon={arrowExpanded} size={"lg"} />
          )}
          {isExpanded === false && (
            <FontAwesomeIcon icon={arrowCollapsed} size={"lg"} />
          )}
        </div>
        <div className={styles.innerRow}>
          <div className={styles.timestamp}>
            {sDate}&nbsp;&mdash;&nbsp;{eDate}
          </div>
          <div className={styles.occurrencesCount}>
            {bucket.alerts_count.toLocaleString()} Alerts
          </div>

          {bucket.findMatchCount > 0 && (
            <div className={styles.findCount}>
              {bucket.findMatchCount.toLocaleString()} Matching{" "}
              {Utilities.Strings.pluralize("Alert", bucket.findMatchCount)}
            </div>
          )}

          <div className={styles.zoomText}>
            {!error && (
              <>Click to toggle visibility of alerts in this time range</>
            )}
          </div>
          <div className={styles.errorText}>{error}</div>

          <div className={styles.coreEvents}>
            {isLoading === true && <LoadingAnim />}
          </div>
        </div>

        <div style={{ marginLeft: "8px" }}>
          {isExpanded === true && incidents}
        </div>
      </div>
    </div>
  );
};

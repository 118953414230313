import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from "react";
import { PageContext, DataServiceContext } from "athena-next-ui-lib";
import IntegrationUtils from "../../_utilities/integrationUtils";

const AlertRuleContext = createContext();

function AlertRuleContextProvider(props) {
  const dataService = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);

  const [channels, setChannels] = useState(null);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [highVolumePeriod, setHighVolumePeriod] = useState(null);

  useEffect(() => {
    if (pageContext.isReady) {
      return Promise.resolve()
        .then(() => dataService.fetch("bakeparams/create", {}))
        .then((bakeParamsCall) => {
          const bakeParamArray = (bakeParamsCall || {}).data || [];
          const highVolPeriodValue =
            bakeParamArray?.[0]?.high_volume_period || "hour";
          setHighVolumePeriod(highVolPeriodValue);

          return;
        })
        .then(() =>
          IntegrationUtils.getOutboundChannels(pageContext, dataService)
        )
        .then((outboundChannelsCall) => {
          if (outboundChannelsCall.response.code === 200) {
            const channelsLookup = outboundChannelsCall.data || [];

            setChannels([...channelsLookup]);
            return true;
          } else {
            throw outboundChannelsCall.response;
          }
        })
        .then(() => dataService.fetch("incident/read/servicegroups", {}))
        .then((serviceGroupsCall) => {
          if (serviceGroupsCall.response.code === 200) {
            const sg = serviceGroupsCall.data.map((g) => {
              return { label: g.name, value: g.name };
            });
            setServiceGroups(sg);
          } else {
            throw serviceGroupsCall.response;
          }
        })

        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    }
  }, [pageContext.isReady]);

  const ctx = useMemo(() => {
    return {
      channels,
      serviceGroups,
      highVolumePeriod,
    };
  }, [channels, serviceGroups, highVolumePeriod]);

  return (
    <AlertRuleContext.Provider value={ctx}>
      {props.children}
    </AlertRuleContext.Provider>
  );
}

export { AlertRuleContextProvider, AlertRuleContext };

import {
  Button,
  DataServiceContext,
  Field,
  Form,
  Panel,
  Row,
  useModalDialog,
  CustomIcon,
} from "athena-next-ui-lib";
import React, { useContext, useRef } from "react";
import Moment from "moment";
import { faFlask } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReportContext } from "/components-biz";
import IncidentTypeUtils from "../../../_utilities/incidentTypeUtils";

export function DialogAlertKeysTest(props) {
  const alertContext = useContext(ReportContext);
  const { events } = alertContext;

  const { keyCount } = props;
  const { openModalDialog } = useModalDialog();
  const dataService = useContext(DataServiceContext);

  const timestampFromRef = useRef();
  const timestampToRef = useRef();
  const itype_keysRef = useRef();
  const formRef = useRef();

  const fieldWidth = "200px";
  const keys = IncidentTypeUtils.extractEventKeys(events?.data || []);

  const rightNow = Moment();
  const fromDate = rightNow.clone().subtract(7, "d"); //7 days ago
  const timestampFrom = fromDate.format(
    Moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
  );
  const timestampTo = rightNow.format(Moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
  const processing = false;

  const createDialogContent = (testResult) => {
    return (
      <>
        <Form
          ref={formRef}
          refs={[timestampFromRef, timestampToRef, itype_keysRef]}
        >
          <Row>
            <Field
              type={"description"}
              value={
                "Run this test to determine the number of times these alert keys would have matched over the time range specified..."
              }
            />
          </Row>

          <Row>
            <Field
              fieldWidth={fieldWidth}
              type={"read-only"}
              label={<span>Alert Keys</span>}
              value={
                <span style={{ display: "flex", flexDirection: "column" }}>
                  {keys.split(",").map((key) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "wrap",
                      }}
                    >
                      <CustomIcon
                        name={"key"}
                        options={{ wrapInSVG: true, rotate: 30, size: 18 }}
                      />
                      &nbsp;{key}&nbsp;
                    </div>
                  ))}
                </span>
              }
              name={"key_display"}
            />

            <Field
              ref={itype_keysRef}
              fieldWidth={fieldWidth}
              type={"hidden"}
              label={<span>Alert Keys</span>}
              value={keys}
              name={"itype_keys"}
            />
          </Row>

          <Row>
            <Field
              ref={timestampFromRef}
              type="datetime"
              label={"From"}
              value={timestampFrom}
              name={"timestampFrom"}
              disabled={processing}
            />
          </Row>

          <Row>
            <Field
              ref={timestampToRef}
              type="datetime"
              label={<div style={{ textAlign: "right" }}>To</div>}
              value={timestampTo}
              name={"timestampTo"}
              disabled={processing}
            />
          </Row>

          <TestResult result={testResult} />
        </Form>
      </>
    );
  };

  const startTest = () => {
    const values = formRef.current.getValues();
    const fromTS = Moment(values.timestampFrom).utc().format();
    const toTS = Moment(values.timestampTo).utc().format();

    const payload = {
      itype_keys: values.itype_keys,
      filter: [`inci_ts>=${fromTS}`, `inci_ts<=${toTS}`],
    };

    //passing in eg:
    // "itype_keys": "enabling_automatic_account_rebalancing,ingestqueue_ingestqueueinfo_list"
    return (
      Promise.resolve()
        //.then(()=>setTestResult(""))
        .then(() => dataService.fetch("incidenttype/read/test", payload))
        .then((resp) => {
          if (resp.response.code == 200) {
            const matchCount = (resp.data && resp.data[0].match_count) || 0;
            return createDialogContent(matchCount);
          } else {
            return createDialogContent(resp.response.message);
          }
        })
        .then((dialogContent) => {
          const modalDialogSettings = {
            title: `Test Alert Keys`,
            icon: faFlask,
            submitLabel: "Start Test",
            cancelLabel: "Close",
            closeOnSubmit: false,
            submit: () => startTest(),
            content: dialogContent,
          };

          return openModalDialog(modalDialogSettings);
        })
    );
  };

  const showDialog = () => {
    const modalDialogSettings = {
      title: `Test Alert Keys`,
      icon: faFlask,
      submitLabel: "Start Test",
      cancelLabel: "Close",
      closeOnSubmit: false,
      submit: () => startTest(),
      content: createDialogContent(),
    };

    return openModalDialog(modalDialogSettings);
  };

  return (
    <>
      <Button
        type={"secondary"}
        onClick={() => showDialog()}
        disabled={keyCount < 1 || keyCount > 2}
      >
        <FontAwesomeIcon icon={faFlask} />
        <span>Test these Keys</span>
      </Button>
    </>
  );
}

function TestResult(props) {
  if (props.result == null) return null;

  return (
    <Row align={"center"}>
      <Panel width={"100%"}>
        <div style={{ width: "100%", textAlign: "center" }}>
          Matched <b>{props.result}</b> times.
        </div>
      </Panel>
    </Row>
  );
}

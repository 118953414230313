import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  DataServiceContext,
  Field,
  FieldTypes,
  PageContext,
} from "athena-next-ui-lib";
import IntegrationUtils, {
  IntegrationDisplayNameLookup,
} from "../../../_utilities/integrationUtils";

export const FieldOutboundIntegrationSelector = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);

  const { fieldRef, selectedOutboundChannels, label } = props;

  const [outboundChannels, setOutboundChannels] = useState(null);

  const [response, setResponse] = useState({ code: 0 });
  const fieldWidth = "200px";

  const noop = () => {};

  useImperativeHandle(ref, () => ({
    deriveSelectedValues,
  }));

  const deriveSelectedValues = (stringValues) => {
    let selValues = stringValues;
    if (selValues !== "") {
      selValues = selValues.split(",");
    } else {
      selValues = [];
    }
    return selValues;
  };

  useEffect(() => {
    loadOutboundChannels();
  }, []);

  const loadOutboundChannels = () => {
    return Promise.resolve()
      .then(() =>
        IntegrationUtils.getOutboundChannels(pageContext, dataServiceContext)
      )
      .then((getOutboundCall) => {
        if (getOutboundCall.response.code == 200) {
          const dataOut = (getOutboundCall || {}).data || [];
          const derOutboundChannels = deriveOutboundChannels(dataOut);

          setOutboundChannels([...derOutboundChannels]);
          setResponse({ code: 200 });
          return;
        } else {
          throw getOutboundCall.response;
        }
      });
  };

  const deriveOutboundChannels = (rawOutboundChannels) => {
    const derivedOutboundChannels = (rawOutboundChannels || []).map((itm) => {
      const displayName =
        itm.name +
        ` (${IntegrationDisplayNameLookup[itm.integration] || itm.siid})`;
      return { label: displayName, value: itm.siid };
    });

    return derivedOutboundChannels;
  };

  const onUpdateValue = (selected) => {
    if (
      props.onUpdateValue &&
      outboundChannels != null &&
      response.code != 0 &&
      !!selectedOutboundChannels
    ) {
      // if selected value is the same as orig, do nothing
      // const curSelectedChannels = selected.map(ch=>ch.value).sort().toString();
      // const origSelectedChannel = selectedOutboundChannels.sort().toString();
      // const matchingChannels = curSelectedChannels === origSelectedChannel;
      // if( matchingChannels) {
      //    return;
      // }

      return props.onUpdateValue(selected);
    }
  };

  const defaultContentProps = {
    type: FieldTypes.MULTI_SELECT,
    label: label,
    labelWidth: props.labelWidth || "auto",
    ref: fieldRef,
    placeholder: "Select...",
    fieldWidth: fieldWidth,
    labelAlign: props.labelAlign || "left",
    allowSelectAll: true, //true
    selectAllLabel: "No one",
    response: response,
    name: "itype_outbound_integration_ids",
    onUpdateValue: onUpdateValue || noop,
    ...props,
  };

  const [content, setContent] = useState(<Field {...defaultContentProps} />);

  useEffect(() => {
    //selectedOutboundChannels passed as string array, not initialized if value is null
    if (outboundChannels != null && response.code != 0) {
      const derivedSelectedOutboundChannels =
        selectedOutboundChannels == null
          ? null
          : outboundChannels.filter(
              (oc) => selectedOutboundChannels.indexOf(oc.value) > -1
            );

      setContent(
        <Field
          {...defaultContentProps}
          value={derivedSelectedOutboundChannels}
          values={outboundChannels}
          truncateDisplayBeyond={20}
          response={response}
        />
      );
    }
  }, [outboundChannels, response]);

  return <>{content}</>;
});

import React, { useContext, useRef, useEffect, useState } from "react";
import {
  Form,
  Column,
  Field,
  FieldTypes,
  Row,
  Button,
  useModalDialog,
  DataServiceContext,
} from "athena-next-ui-lib";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useRouter } from "next/router";

export function UICustomizer(props) {
  const dataService = useContext(DataServiceContext);

  const profile = dataService.getUserProfile();
  const customizations = profile?.ui_customization || [];
  const feature = customizations.find((ui) => ui.feature === props.featureName);
  if (!feature) return null;

  switch (props.style) {
    case "service-group-feedback":
      return <RenderServiceGroupFeedback featureDefinition={feature.object} />;
    case "read-only-form":
      return (
        <RenderAsReadOnlyForm
          featureDefinition={feature.object}
          data={props.data}
        />
      );
    case "editable-form":
      return (
        <RenderAsEditableForm
          featureDefinition={feature.object}
          refs={props.refs}
          data={props.data}
        />
      );
    default:
      return null;
  }
}

function RenderAsReadOnlyForm(props) {
  const { featureDefinition, data } = props;
  return featureDefinition?.map((row) => {
    return row?.map((field) => {
      const fldValue = data[field.name];

      if (fldValue && fldValue.length > 0) {
        return (
          <Field
            {...field}
            type="display-text"
            tooltip={null}
            value={fldValue}
          />
        );
      } else {
        return null;
      }
    });
  });
}

function RenderAsEditableForm(props) {
  const { featureDefinition, refs, data } = props;
  return featureDefinition.map((row) => (
    <Row>
      {row?.map((field) => {
        let fldValue = data[field.name] || field.value;
        let fld = null;
        if ([FieldTypes.SINGLE_SELECT, FieldTypes.MULTI_SELECT].includes(field.type)) {
          fld = (
            <ItypeExtDropdownField
              fieldRef={refs[field.name]}
              data={{ [field.name]: fldValue }}
              field={field}
            />
          );
        } else {
          fld = <Field ref={refs[field.name]} {...field} value={fldValue} />;
        }

        if (row.length > 1) {
          return <Column key={`c-${field.ts}`}>{fld}</Column>;
        } else {
          return fld;
        }
      })}
    </Row>
  ));
}

function RenderServiceGroupFeedback(props) {
  const { featureDefinition } = props;
  const dForm = useRef();
  const fieldWidth = "300px";
  const dataService = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();
  const [canProvideFeedback, setCanProvideFeedback] = useState(false);
  const [svcGrp, setSvcGrp] = useState(null);
  const router = useRouter();

  useEffect(() => {
    if( router ) {
      let grp = router.query["service_groups"];
      if (grp) {
        grp = grp.split(",");
        setCanProvideFeedback(grp !== null && grp.length === 1);
        if (grp.length) {
          setSvcGrp(grp[0]);
        } else {
          setSvcGrp(null);
        }
      } else {
        setSvcGrp(null);
      }
    }
  }, [router, router?.query]);

  const fDesc = useRef();
  let fieldRefs = { svga_desc: fDesc };
  fieldRefs[`svga_ext_f1`] = useRef();
  fieldRefs[`svga_ext_f2`] = useRef();
  fieldRefs[`svga_ext_f3`] = useRef();
  fieldRefs[`svga_ext_f4`] = useRef();
  fieldRefs[`svga_ext_f5`] = useRef();
  fieldRefs[`svga_ext_f6`] = useRef();
  fieldRefs[`svga_ext_f7`] = useRef();
  fieldRefs[`svga_ext_f8`] = useRef();
  fieldRefs[`svga_ext_f9`] = useRef();
  fieldRefs[`svga_ext_f10`] = useRef();

  const fieldRefsArray = Object.keys(fieldRefs).map((key) => fieldRefs[key]);

  const saveFeedback = () => {
    const values = dForm.current.getValues();

    let payload = {
      ...values,
      svga_svc_grp: svcGrp,
    };

    return Promise.resolve()
      .then(() => dataService.fetch("servicegroupannotation/create", payload))
      .then((response) => {
        if (response.response.code > 200) {
          throw response.response;
        }
      });
  };

  const openDialog = () => {
    return Promise.resolve()
      .then(() =>
        dataService.fetch("servicegroupannotation/read", {
          filter: [`svga_svc_grp=${svcGrp}`],
        })
      )
      .then((response) => {
        if (response.response.code === 200) {
          return response.data && response.data.length > 0
            ? response.data[0]
            : {};
        } else {
          return {};
        }
      })
      .then((data) => {
        const modalDialogSettings = {
          title: `${featureDefinition.title}`,
          icon: faEdit,
          submitLabel: "Save",
          submit: (values) => saveFeedback(),
          content: (
            <Form ref={dForm} refs={fieldRefsArray}>
              <Field
                ref={fDesc}
                name="svga_desc"
                type={"textarea"}
                label={"Description"}
                width={fieldWidth}
                value={data["svga_desc"]}
              />
              {renderAsEditableForm(featureDefinition.form, fieldRefs, data)}
            </Form>
          ),
        };

        return openModalDialog(modalDialogSettings);
      });
  };

  return (
    canProvideFeedback && (
      <Button type={"primary"} onClick={() => openDialog()}>
        {featureDefinition.title}
      </Button>
    )
  );
}

const ItypeExtDropdownField = (props) => {
  const { fieldRef, data, field } = props;
  const [selectedValues, setSelectedValues] = useState([]); //used for both single/multi select

  useEffect(() => {
    if (data?.[field?.name] ) {
      if (field.type === FieldTypes.SINGLE_SELECT) {
        //string input for single select
        setSelectedValues(deriveSelValueByString(data?.[field?.name]));
      } else {
        //string array for multi select
        setSelectedValues(deriveSelValuesByStringArr(data?.[field?.name]));
      }
    }
  }, [data?.[field?.name]]);

  const deriveSelValueByString = (inString) => {
    const matchingVal = field.values.filter(
      (item) => item.value === inString || item.label === inString
    );
    return matchingVal;
  };

  const deriveSelValuesByStringArr = (inArr) => {
    const matchingVals = field.values.filter(
      (item) => inArr.includes(item.value) || inArr.includes(item.label)
    );
    return matchingVals;
  };

  const fieldProps = {
    ref: fieldRef,
    ...field,
  };

  return (
    <Field {...fieldProps} value={selectedValues} />
  );
}

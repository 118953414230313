import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import styles from "/components-ui/IncidentList.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-solid-svg-icons";

export const DialogAlertManualTagSelectorContent = forwardRef((props, ref) => {
  const { itype_tags } = props.alert;

  const manualTags = (itype_tags ? itype_tags : []).map((t) => t.tag_name);

  const [manualTagSelections, setManualTagSelections] = useState([]);

  useEffect(() => {
    const selections = props.allManualTags.map((t) => {
      const isChecked = manualTags.includes(t.tag_name);
      return {
        tag_name: t.tag_name,
        tag_id: t.tag_id,
        selected: isChecked,
        css:
          isChecked === false
            ? `${styles.tagCheckbox} ${styles.disabled}`
            : styles.tagCheckbox,
      };
    });

    setManualTagSelections(selections);
  }, [props.alert]);

  const update = (t) => {
    let selections = [...manualTagSelections];
    const index = selections.findIndex((_t) => _t.tag_id === t.tag_id);
    if (index > -1) {
      let tag = { ...selections[index] };
      tag.selected = !tag.selected;
      tag.css =
        tag.selected === false
          ? `${styles.tagCheckbox} ${styles.disabled}`
          : styles.tagCheckbox;
      selections.splice(index, 1, tag);
      setManualTagSelections(selections);
    }
  };

  const getTags = () => {
    return manualTagSelections
      .filter((s) => s.selected === true)
      .map((s) => s.tag_id)
      .join(",");
  };

  useImperativeHandle(ref, () => ({
    getTags: getTags,
  }));

  return (
    <div className={styles.tagSelector}>
      {manualTagSelections && manualTagSelections.length > 0 && (
        <div className={styles.tagRow}>
          <p>
            This <b>Alert Type</b> is <b>Manually</b> tagged:
          </p>
          <div>
            {manualTagSelections.map((t) => {
              return (
                <label key={t.id} className={t.css} htmlFor={t.tag_id}>
                  <input
                    id={t.tag_id}
                    type={"checkbox"}
                    checked={t.selected}
                    onChange={() => update(t)}
                  />
                  <span className={`${styles.tagType} ${styles.manual}`}>
                    {t.tag_name}
                  </span>
                </label>
              );
            })}
          </div>
        </div>
      )}
      {(!manualTagSelections || manualTagSelections.length === 0) && (
        <div className={styles.tagRow}>
          <i>
            No tags have been created for this deployment. To get started, visit
            the main menu in the upper right of the UI&nbsp;&nbsp;
            <FontAwesomeIcon icon={faBars} size={"lg"} />
            &nbsp;&nbsp;and click Manage Tags
          </i>
        </div>
      )}
    </div>
  );
});

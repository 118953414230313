import React, { useContext, useMemo } from "react";
import {
  Row,
  Column,
  DataServiceContext,
  MenuButton,
  StyledMenuItemDiv,
} from "athena-next-ui-lib";
import {
  ReportContext,
  DialogOneTimeAlertSend,
  DialogAlertRuleMetadataEdit,
  DialogAlertReject,
  Title,
  Banner,
  OccurrencesGraph,
  getReportHeaderHeight,
} from "/components-biz";
import { AlertSummary } from "../AlertSummary";
import { StyledBannerRowDiv, StyledTitleRowDiv } from "../css";
import { ListModes, AIConfidence } from "/components-ui";

export const Content = (props) => {
  const alertContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);

  const {
    outboundChannels,
    itypeData,
    navigateToList,
    enterEditKeysMode,
    listMode,
  } = alertContext;
  const {
    itype_state,
    itype_title,
    itype_nlp_judgment: confidence,
  } = itypeData || {};
  const title = itype_title;

  const menuItems = [
    <DialogAlertRuleMetadataEdit
      key={1}
      type={"edit-note"}
      data={props}
      renderType="menuItem"
      label="Edit Alert Rule Metadata"
    />,

    <StyledMenuItemDiv key={2} onClick={() => enterEditKeysMode()}>
      Edit Alert Rule
    </StyledMenuItemDiv>,

    <DialogOneTimeAlertSend
      key={3}
      renderType="menuItem"
      data={itypeData}
      label="Send One Time Alert"
    />,

    <DialogAlertReject
      key={4}
      label="Reject this Alert"
      renderType="menuItem"
      itypeData={itypeData}
      navigateToList={navigateToList}
    />,

    <StyledMenuItemDiv key={5} onClick={() => revertToSuggested()}>
      Revert to Suggested
    </StyledMenuItemDiv>,
  ];

  const revertToSuggested = () => {
    return Promise.resolve()
      .then(() =>
        dataService.fetch("incidenttype/create", {
          itype_id: alertContext.itypeData.itype_id,
          itype_state: "suggested",
        })
      )
      .then(() => alertContext.loadIncidentTypeData());
  };

  const itype_outbound_integration_names = useMemo(() => {
    if (
      Array.isArray(itypeData?.itype_outbound_integration_ids) &&
      itypeData?.itype_outbound_integration_ids.length > 0
    ) {
      const filteredOutboundChannels = (outboundChannels || []).filter(
        (outbound) =>
          itypeData?.itype_outbound_integration_ids.indexOf(outbound.value) > -1
      );
      return filteredOutboundChannels.map((itm) => itm.label);
    }

    return [];
  }, [itypeData]);

  const fieldsMap = [
    { label: "Owner", property: "itype_owner", type: "string" },
    {
      label: "Notifications",
      property: "itype_outbound_integration_names",
      type: "list",
    },
    {
      label: "Priority",
      property: "itype_outbound_priority",
      type: "string",
      requires: "itype_outbound_integration_names",
    },
    {
      label: "Alert Volume",
      property: "itype_volume",
      type: "string",
    },
    { label: "URL", property: "itype_tracking_url", type: "url" },
  ];

  const valuesMap = {
    ...itypeData,
    itype_outbound_integration_names,
  };

  return (
    <Row width="*" css={{ margin: "8px 8px" }}>
      <Column width="*" css={{ margin: "0 0" }}>
        <Row align="center">
          <StyledTitleRowDiv>
            <StyledBannerRowDiv>
              <AIConfidence confidence={confidence} />
              <Banner state={itype_state} />
            </StyledBannerRowDiv>

            <Title state="accepted" title={title} />
          </StyledTitleRowDiv>
        </Row>

        <Row align="center">
          <StyledTitleRowDiv>
            {listMode !== ListModes.edit && <MenuButton items={menuItems} />}
          </StyledTitleRowDiv>
        </Row>

        {listMode !== ListModes.edit && (
          <Row>
            <AlertSummary valuesMap={valuesMap} fields={fieldsMap} />
          </Row>
        )}

        {listMode === ListModes.edit && (
          <Row>
            <p>
              An alert rule is made up of 1 or 2 event keys. The key(s) are used
              to trigger an alert if they occur when new log data is ingested.
              It’s important to select the most appropriate key events that
              represent this type of problem.
            </p>
          </Row>
        )}
      </Column>

      <Column width="400px" css={{ margin: "0 24px 0 0" }}>
        {listMode !== ListModes.edit && (
          <Row>
            <OccurrencesGraph
              width={400}
              height={getReportHeaderHeight(itype_state) - 75}
            />
          </Row>
        )}
      </Column>
    </Row>
  );
};

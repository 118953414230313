import { Utilities, LoadingAnim } from "athena-next-ui-lib";
import styles from "/components-ui/FindBar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { ReportContext } from "/components-biz";

let keyInputTimer = null;

export function FindBar(props) {
  const reportContext = useContext(ReportContext);

  const [findTerm, setFindTerm] = useState(reportContext.findTerm);
  const [searchTerm, setSearchTerm] = useState(reportContext.searchTerm);
  const [findIndex, setFindIndex] = useState(0);
  const [processing, setProcessing] = useState(false);


  useEffect(() => {
    if (
      (reportContext.findTerm && reportContext.findTerm !== findTerm) ||
      (reportContext.search && reportContext.search !== searchTerm)
    ) {
      setFindIndex(0);
      setSearchTerm(reportContext.search);
      setFindTerm(reportContext.findTerm);
      sendQuery(reportContext.findTerm);
    }
  }, [reportContext.findTerm, reportContext.search]);

  const clearFindTerm = (evt) => {
    evt.stopPropagation();
    setFindTerm("");
    setFindIndex(0);
    delayedQuery("");
  };

  const updateFindTerm = (evt) => {
    evt.stopPropagation();
    const term = evt.target.value;
    setFindTerm(term);
    delayedQuery(term);
  };

  const sendQuery = (find) => {
    return Promise.resolve()
      .then(() => {
        setProcessing(true);
        return true;
      })
      .then(() => reportContext.applyFindTerm(find))
      .then(() => {
        setProcessing(false);
        return true;
      });
  };

  const delayedQuery = useCallback(
    debounce((find) => sendQuery(find), 1000),
    []
  );

  const find = (dir) => {
    let newIndex = findIndex + dir;
    newIndex = Math.max(1, newIndex);
    newIndex = Math.min(newIndex, props.findMatchCount);

    newIndex = props.findMatchCount === 1 ? 1 : newIndex;

    const element = document.getElementById("find-" + newIndex);

    if (element) {
      //console.log("find-"+ newIndex, props.findMatchCount)
      element.scrollIntoView(true);
      setFindIndex(newIndex);
    }
  };

  const css = `${styles.findBar} ${styles[props.mode || "read"]}`;

  const count = (props.data || []).length;

  const findTermExists = findTerm && findTerm.length > 0;

  const resultCount =
    count !== 0 ? (
      <span>
        Displaying <b>{count.toLocaleString()}</b>{" "}
        {Utilities.Strings.pluralize(props.objectName, count)}
      </span>
    ) : null;

  let matchCount = null;

  if (findTermExists) {
    const count =
      processing === true ? (
        <LoadingAnim />
      ) : (
        (props.findMatchCount || 0).toLocaleString()
      );
    matchCount = (
      <div className={styles.resultCount}>
        <span>
          Matched on <b>{count}</b>{" "}
          {Utilities.Strings.pluralize(props.objectName, props.findMatchCount)}
        </span>
      </div>
    );
  } else {
    matchCount = (
      <label>
        Highlight {Utilities.Strings.pluralize(props.objectName, 2)} that Match:
      </label>
    );
  }
  let numberOfResults = <div className={styles.resultCount}>{resultCount}</div>;

  const inputCSS =
    props?.findTerm?.length ? styles.notEmpty : "";

  let disableDownControl = false;

  if (
    !findTermExists ||
    (props.matchCount === 1 && findIndex !== 0) ||
    findIndex >= props.findMatchCount
  ) {
    disableDownControl = true;
  }

  let disableUpControl = false;

  if (
    !findTermExists ||
    (props.matchCount === 1 && findIndex === 1) ||
    (props.matchCount > 1 && findIndex < 2)
  ) {
    disableUpControl = true;
  }

  let findControls = (
    <div className={styles.controls}>
      {matchCount}
      <div className={styles.field}>
        <input
          type={"text"}
          className={inputCSS}
          placeholder={"Enter text or /regex/"}
          value={findTerm}
          spellCheck={false}
          onChange={(evt) => updateFindTerm(evt)}
        />
        {findTermExists && (
          <div
            className={styles.clearField}
            onClick={(evt) => clearFindTerm(evt)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>

      <button onClick={() => find(1)} disabled={disableDownControl}>
        <FontAwesomeIcon icon={faArrowDown} />
      </button>

      <button onClick={() => find(-1)} disabled={disableUpControl}>
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </div>
  );

  if (!props.response || props.response.code !== 200) {
    return null;
  }

  return (
    <div className={css}>
      {numberOfResults}

      <span className={styles.forceRight}>&nbsp;</span>

      {findControls}
    </div>
  );
}

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

import React, { useRef, useContext } from "react";
import { PageTemplate, DataServiceContext } from "athena-next-ui-lib";

import {
  IncidentListContext,
  IncidentFindBar,
  IncidentList,
  IncidentFilterSummary,
  IncidentTimeline,
  IncidentListPageBar,
} from "/components-biz/incidents";

import { ListWrapper } from "/components-ui";

export function IncidentListPageTemplate() {
  const dataServiceContext = useContext(DataServiceContext);
  const ac = useContext(IncidentListContext);
  const {
    filters,
    search,
    onTimeRangeUpdate,
    onFilterUpdate,
    onSearchTermUpdate,
    queryState,
    serviceGroups,
  } = ac;
  const DOM_FilterSummary = useRef();
  const timeRangePickerRef = useRef();

  const pagebar = () => {
    return (
      <IncidentListPageBar
        timeZone={dataServiceContext.getUserTimeZone()}
        timeRangePickerRef={timeRangePickerRef}
        filters={filters}
        onTimeRangeUpdate={onTimeRangeUpdate}
        onFilterUpdate={onFilterUpdate}
        search={search}
        onSearchTermUpdate={onSearchTermUpdate}
        serviceGroups={serviceGroups}
        queryState={queryState}
        extensionFieldRefs={[]}
      />
    );
  };
  const timelineH = 201;
  const pageBarH = 41;
  const filterSummaryH =
    DOM_FilterSummary?.current?.getBoundingClientRect().height + 5 || 43;
  const findBarH = 40;

  const content = () => {
    return (
      <ListWrapper
        listId={ac.listId}
        data={ac.alerts}
        isLoading={ac.isLoadingAlertData}
        getQueryState={ac.getQueryState}
      >
        <div ref={DOM_FilterSummary}>
          <IncidentFilterSummary filterSummary={ac.filterSummary} />
        </div>

        <IncidentTimeline
          height={timelineH}
          openTimeRangePicker={() => timeRangePickerRef?.current?.open()}
        />

        <IncidentList
          height={`calc(100vh - ${
            timelineH + filterSummaryH + pageBarH + findBarH
          }px)`}
        />

        <IncidentFindBar />
      </ListWrapper>
    );
  };

  return (
    <PageTemplate
      title="Alerts"
      pageBar={pagebar()}
      navBar={null}
      pageContent={content()}
    />
  );
}

import React, {useMemo} from 'react';
import styles from "./FilterSummary.module.scss"
import {faSync} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ButtonGroup, Button} from 'athena-next-ui-lib'

export function FilterSummary(props) {

    const refreshPage = () =>{
        window.location.reload();
    }

    const filterSummary = useMemo(() => {

        let summary = [];
        props.filterSummary.forEach((f, index) => {
            const key = `${new Date().getTime()}${index}`;
            summary.push(<span key={key}>{f}</span>);
            if (index < props.filterSummary.length - 1) {
                summary.push(<i key={key + "and"}>and</i>);
            }
        });

        let filterSummaryBar = null;
        if (summary.length === 0) {
            filterSummaryBar = <>No filters applied</>;
        } else {
            filterSummaryBar = <>Filtered on{summary}</>
        }


        const displayMode = props.displayModes ? <ButtonGroup
            values={props.displayModes}
            default={props.selectedDisplayMode}
            size={"sm"}
            onChange={props.switchDisplayMode}/> : null;

        return <div className={styles.filterSummary}>
            {displayMode}
            <div className={styles.filterSummaryText}>{filterSummaryBar}</div>
            <div className={styles.forceRight}/>
            <div className={styles.hidden}><Button  size={"sm"}>&nbsp;</Button></div>
            <div>
                {props.allowRefresh && <Button type={"secondary"} size={"md"} onClick={refreshPage}><FontAwesomeIcon icon={faSync}/>&nbsp;Refresh</Button>}
            </div>

        </div>;


    }, [props.filterSummary]);

    return !props.hidden || props.hidden === false ?  filterSummary : null;
}

FilterSummary.defaultProps = {
    hidden:false
}





import React, { useContext } from "react";
import { Row, Column, DataServiceContext } from "athena-next-ui-lib";
import { StyledTitleRowDiv, StyledBannerRowDiv } from "../css";
import styled from "styled-components";
import {
  ReportContext,
  Title,
  DialogAlertReject,
  Banner,
  OccurrencesGraph,
  DialogAlertRuleMetadataEdit,
  getReportHeaderHeight,
} from "/components-biz";
import { AIConfidence } from "/components-ui";

export const Content = (props) => {
  const alertContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);
  const canEditFeature = !!dataService?.AccessManifest?.( "canFullyEditFeature", "alert-types");

  const { itypeData } = alertContext;
  const {
    itype_state,
    itype_title,
    itype_nlp_judgment: confidence,
  } = itypeData || {};
  const title = itype_title;

  return (
    <Row width="*" css={{ margin: "8px 8px" }}>
      <Column width="*" css={{ margin: "0 0" }}>
        <Row width="*">
          <Column width="*">
            <Row align="center">
              <StyledTitleRowDiv>
                <StyledBannerRowDiv>
                  <AIConfidence confidence={confidence} />
                  <Banner state={itype_state} />
                </StyledBannerRowDiv>

                <Title state="suggested" title={title} />
              </StyledTitleRowDiv>
            </Row>

            {canEditFeature && <Row>
              <Column>
                <StyledTitleRowDiv>
                  <h3>Like this Report?</h3>
                </StyledTitleRowDiv>
                <p>
                  Create an alert rule and be notified when AI/ML finds this
                  issue in the future.
                </p>
                <Row css={{ gap: "8px", width: "auto" }}>
                  <Column>
                    <DialogAlertRuleMetadataEdit
                      type={"edit-note"}
                      data={props}
                      renderType="acceptButton"
                      label="Accept"
                    />
                  </Column>
                  <Column>
                    <DialogAlertReject label={"Reject"} />
                  </Column>
                </Row>
              </Column>
            </Row>}
          </Column>
        </Row>
      </Column>

      <Column width="400px" css={{ margin: "0 24px 0 0" }}>
        <Row>
          <OccurrencesGraph
            width={400}
            height={getReportHeaderHeight(itype_state) - 75}
          />
        </Row>
      </Column>
    </Row>
  );
};

import React, { useContext } from "react";
import { ReportContext } from "/components-biz";
import { ShuttleWrapper, SearchFieldWrapper } from "/components-biz/report";

export const PageBarContent = (props) => {
  const reportContext = useContext(ReportContext);
  const { getQueryState } = reportContext;

  const { ievt_gen, search } = getQueryState();

  const inPeekMode = ievt_gen && ievt_gen !== undefined;

  if (!inPeekMode) {
    return (
      <>
        <ShuttleWrapper filters={props.filters} />
        &nbsp;&nbsp;
        <SearchFieldWrapper
          label={"Search"}
          labelWidth="85px"
          size={24}
          placeholder={"events using text or /regex/"}
          value={search}
        />
      </>
    );
  } else {
    return null;
  }
};

import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom";
import Moment from "moment";
import styles from "./IncidentTimeline.module.scss";
import { useWindowSize } from "athena-next-ui-lib";
import {
  IncidentList,
  FilterSummary,
  ListWrapper,
  ListState,
} from "/components-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faStar } from "@fortawesome/pro-solid-svg-icons";

let tooltipTimer;

let timeOfLastMouseDown = 0;
export function IncidentTimeline(props) {
  const incidentTooltip = null;

  const windowSize = useWindowSize();

  const binWidth = 18;
  let containerWidth =
    parseInt((0.9 * window.innerWidth) / binWidth) * binWidth;

  const insetWidth = binWidth * 5;
  const innerWidth = containerWidth - 2 * insetWidth;

  const timelineData = useMemo(() => {
    let selectedIncidents = [];

    if (
      props?.inciCodes?.length &&
      props?.inciCodes?.[0] === "none"
    ) {
      //select none
      selectedIncidents = [];
    } else if (
      props.inciCodes === undefined ||
      !props.inciCodes ||
      props.inciCodes.length === 0
    ) {
      //select all
      selectedIncidents = props.data ? [...props.data] : [];
    } else {
      //match given inci_codes
      selectedIncidents = props.data
        ? props.data.filter((i) => props.inciCodes.indexOf(i.inci_code) > -1)
        : [];
    }

    return deriveTimelineData(
      innerWidth,
      binWidth,
      selectedIncidents,
      props.findMatches,
      props.data || []
    );
  }, [props.data, props.inciCodes, props.findMatches, windowSize]);

  const drawIncidentTooltip = () => {
    if (!incidentTooltip) return null;

    const tooltip = (
      <div className={styles.incidentTooltip} style={incidentTooltip.position}>
        {incidentTooltip.content}
      </div>
    );

    return ReactDOM.createPortal(tooltip, document.getElementById("__next"));
  };

  const promptToSelectIncidentsFromTimeline =
    props?.data?.length &&
    props.inciCodes &&
    timelineData.selectedIncidents.length === 0 ? (
      <h3 className={styles.prompt}>
        Select one or more <b>reports</b> from the timeline
      </h3>
    ) : null;

  return (
    <ListWrapper
      listId={props.listId}
      mode={props.mode}
      displayMode={props.displayMode}
      banner={props.banner}
      response={props.response}
      data={props.data}
      router={props.router}
    >
      <EventSummary
        hidden={props.eventSummaryHidden}
        eventSummary={props.eventSummary}
      ></EventSummary>

      <FilterSummary
        hidden={props.filterSummaryHidden}
        response={props.response}
        data={props.data}
        mode={props.mode}
        listId={props.listId}
        router={props.router}
        objectName={props.objectName}
        selectedRow={props.selectedRow}
        filterSummary={props.filterSummary}
        displayModes={props.displayModes}
        allowRefresh={true}
        selectedDisplayMode={props.selectedDisplayMode}
        switchDisplayMode={props.switchDisplayMode}
      />

      {incidentTooltip && drawIncidentTooltip()}

      <ListState response={props.response} data={props.data} />

      {promptToSelectIncidentsFromTimeline}

      <IncidentList
        response={props.response}
        alertCentric={props.alertCentric}
        data={timelineData.selectedIncidents}
        mode={props.mode}
        allManualTags={props.allManualTags}
        saveTags={props.saveTags}
        searchTerm={props.searchTerm}
        findTerm={props.findTerm}
        findMatches={props.findMatches}
        listId={props.listId}
        groupByDay={props.groupByDay}
        reportLink={props.reportLink}
        reportLinkForWordCloud={props.reportLinkForWordCloud}
        storeRCLinkURL={props.storeRCLinkURL}
        nlpLabel={props.nlpLabel}
        categoryId2LabelMap={props.categoryId2LabelMap}
      />

      {/*<FindBar*/}
      {/*    response={props.response}*/}
      {/*    data={timelineData.selectedIncidents}*/}
      {/*    mode={ListModes.read}*/}
      {/*    findMatchCount={props.findMatches && props.findMatches.length ? props.findMatches.length : 0}*/}
      {/*    listId={props.listId}*/}
      {/*    objectName={"Report"}*/}
      {/*    findTerm={props.findTerm}*/}
      {/*    applyFindTerm={props.applyFindTerm}*/}
      {/*    displayFindBar={true}*/}
      {/*    // filterSummary={props.filterSummary}*/}
      {/*/>*/}
    </ListWrapper>
  );
}

function deriveTimelineData(
  uiWidth,
  binWidth,
  selectedIncidents,
  matchedIncidents,
  allIncidents
) {
  const numBins = uiWidth / binWidth;

  //sort time ascending
  allIncidents.sort((a, b) => (a.inci_ts > b.inci_ts ? 1 : -1));
  allIncidents.forEach((incident) => {
    incident.selected =
      selectedIncidents && selectedIncidents.length > 0
        ? selectedIncidents.findIndex((i) => i.inci_id === incident.inci_id) >
          -1
        : false;
    incident.matched =
      matchedIncidents && matchedIncidents.length > 0
        ? matchedIncidents.findIndex(
            (i) => i.inci_code === incident.inci_code
          ) > -1
        : false;
  });

  let tData = {
    start: Moment().subtract(1, "week"),
    end: Moment(),
    numBins: numBins,
    points: allIncidents,
    maxBinSize: 0,
    timeBinHash: {},
    selectedIncidents: allIncidents
      .filter((i) => i.selected === true)
      .sort((a, b) => (a.inci_ts < b.inci_ts ? 1 : -1)),
  };

  if (allIncidents.length === 0) return tData;

  tData.start = Moment(allIncidents[0].inci_ts);
  tData.end = Moment(allIncidents[allIncidents.length - 1].inci_ts);
  tData.duration = tData.end.diff(tData.start, "ms") + 1; //thus we never divide by 0
  tData.timeBinDuration = tData.duration / numBins;

  allIncidents.forEach((point) => {
    point.ts = Moment(point.inci_ts);
    point.elapsedTs = point.ts.diff(tData.start, "ms");
    point.timeBinIndex = Math.abs(
      parseInt(point.elapsedTs / tData.timeBinDuration)
    );

    if (!tData.timeBinHash.hasOwnProperty(point.timeBinIndex)) {
      tData.timeBinHash[point.timeBinIndex] = [];
    }

    point.yIndex = tData.timeBinHash[point.timeBinIndex].length;
    point.xIndex = point.timeBinIndex;

    tData.timeBinHash[point.timeBinIndex].unshift(point);
  });

  tData.maxBinSize = Object.keys(tData.timeBinHash)
    .map((key) => tData.timeBinHash[key].length)
    .reduce((max, value, index, arr) => Math.max(max, value));

  return tData;
}

function EventSummary(props) {
  if (!props.hidden || props.hidden === false)
    return (
      <div className={styles.eventSummary}>
        <b>Target Event:</b> {props.eventSummary}
      </div>
    );
  else return null;
}

EventSummary.defaultProps = { hidden: true, etext: "" };

import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ReportContext } from "/components-biz";
import { reportHeaderCSS } from "./css";
import {
  SuggestedContent,
  AcceptedContent,
  CustomContent,
  RejectedContent,
  LoadingContent,
} from "./Content";

const StyledContainerDiv = styled("div")`
  position: relative;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  height: ${(props) => {
    return `${props.height || 0}px`;
  }};
  min-height: ${(props) => {
    return `${props.height || 0}px`;
  }};
  max-height: ${(props) => {
    return `${props.height || 0}px`;
  }};
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  border-width: 0;
  border-style: solid;

  border-bottom: 1px solid rgba(102, 102, 102, 1);

  padding: 0;

  h3 {
    margin: 0;
  }
  p {
    margin: 4px 0;
    word-break: break-all;
  }

  .lightTheme & {
    color: #000;
  }
`;

export const ReportHeader = () => {
  const alertContext = useContext(ReportContext);
  const { itypeData } = alertContext;

  const reportHeight = useMemo(() => {
    return getReportHeaderHeight(itypeData?.itype_state);
  }, [itypeData]);

  const content = useMemo(() => {
    switch (itypeData?.itype_state) {
      case "suggested":
        return <SuggestedContent {...itypeData} />;

      case "accepted":
        return <AcceptedContent {...itypeData} />;

      case "rejected":
        return <RejectedContent {...itypeData} />;

      case "custom":
        return <CustomContent {...itypeData} />;
      default:
        return <LoadingContent />;
    }
  });

  return (
    <StyledContainerDiv
      $css={reportHeaderCSS[itypeData?.itype_state || "loading"]}
      height={reportHeight}
    >
      {content}
    </StyledContainerDiv>
  );
};

export const getReportHeaderHeight = (state) => {
  switch (state) {
    case "accepted":
    case "custom":
    case "loading":
      return 150;
    case "rejected":
    case "suggested":
    default:
      return 180;
  }
};

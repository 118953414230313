import React, { useMemo, useContext } from "react";
import styles from "/components-ui/FilterSummary.module.scss";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "athena-next-ui-lib";
import { IncidentListContext } from "/components-biz/incidents";

export const IncidentFilterSummary = (props) => {
  const incidentListContext = useContext(IncidentListContext);

  const refreshPage = () => {
    window.location.reload();
  };

  const filterSummary = useMemo(() => {
    let summary = [];
    incidentListContext?.filterSummary?.forEach((f, index) => {
      const key = `${f.toString()}${index}`;
      summary.push(<span key={key}>{f}</span>);
      if (index < incidentListContext.filterSummary.length - 1) {
        summary.push(<i key={`${key}and`}>and</i>);
      }
    });

    let filterSummaryBar = null;
    if (summary.length === 0) {
      filterSummaryBar = <>No filters applied</>;
    } else {
      filterSummaryBar = <>Filtered on {summary}</>;
    }

    return (
      <div className={styles.filterSummary}>
        <div className={styles.filterSummaryText}>{filterSummaryBar}</div>
        <div className={styles.forceRight} />
        <div className={styles.hidden}>
          <Button size={"sm"}>&nbsp;</Button>
        </div>
        <div>
          <Button type={"secondary"} size={"md"} onClick={refreshPage}>
            <FontAwesomeIcon icon={faSync} />
            &nbsp;Refresh
          </Button>
        </div>
      </div>
    );
  }, [incidentListContext?.filterSummary]);

  return !props.hidden || props.hidden === false ? filterSummary : null;
};

IncidentFilterSummary.defaultProps = {
  hidden: false,
};

import React, { useContext } from "react";
import styles from "./ListState.module.scss";
import { Button, LoadingAnim } from "athena-next-ui-lib";
import { ReportContext } from "/components-biz";

export function ListState(props) {
  const ac = useContext(ReportContext);

  let content = null;

  if (props.response && props.response.code === 0) {
    content = <LoadingAnim styles={{ margin: "12px" }} />;
  } else if (props.response && props.response.code > 200) {
    content = (
      <div className={styles.error}>
        <h3>Something went wrong!</h3>
        <p>{props.response.message}</p>
      </div>
    );
  } else if (
    props.response &&
    props.response.code === 200 &&
    (!props.data || props.data.length === 0)
  ) {
    if (ac) {
      let searchMessage = <span>We couldn't find any matching results</span>;
      const qs = ac.getQueryState();
      const setSearchScope = (evt) => {
        evt.stopPropagation();
        ac.applySearchTerm(qs.search, { value: 5 });
      };
      if (qs?.search?.length && qs.ievt_level === "2") {
        searchMessage = (
          <>
            <div>We couldn't find any matching results in Core Events.</div>
            <Button type={"secondary"} onClick={(evt) => setSearchScope(evt)}>
              Search All Events
            </Button>
          </>
        );
      }

      content = <div className={styles.noData}>{searchMessage}</div>;
    } else {
      content = (
        <div className={styles.noData}>
          <div>We couldn't find any matching results</div>
        </div>
      );
    }
  }

  return content;
}

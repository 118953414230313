import React, { useContext } from "react";
import {
  DialogScanForRootCause,
  SearchField,
  Shuttle,
  DataServiceContext,
  TimeRangePicker,
} from "athena-next-ui-lib";
import { Bookmarks, UICustomizer } from "/components-ui";

export const IncidentListPageBar = (props) => {
  const dataService = useContext(DataServiceContext);

  const {
    filters,
    timeZone,
    timeRangePickerRef,
    onTimeRangeUpdate,
    onFilterUpdate,
    search,
    onSearchTermUpdate,
    queryState,
    serviceGroups,
    extensionFieldRefs,
  } = props;

  const canScanForRC = dataService.AccessManifest(
    "canFullyEditFeature",
    "scan-for-rc"
  );

  const applyTimeRange = (range) => {
    return (
      Promise.resolve()
        // .then(()=>{
        //     alert(range);
        //     return true;
        // })
        .then(() => onTimeRangeUpdate(range))
    );
  };

  return (
    <>
      <Bookmarks />
      <TimeRangePicker
        ref={timeRangePickerRef}
        value={queryState?.time_range || "last-7-days"}
        apply={applyTimeRange}
        timezone={timeZone}
      />
      <Shuttle filters={filters} onChange={onFilterUpdate} />
      &nbsp;&nbsp;&nbsp;
      <SearchField
        label={"Search"}
        labelWidth="85px"
        size={24}
        placeholder={"reports using text or /regex/"}
        searchScope={queryState?.ievt_level || 2}
        value={search}
        applySearchTerm={onSearchTermUpdate}
      />
      &nbsp;&nbsp;&nbsp;
      {canScanForRC && (
        <DialogScanForRootCause
          serviceGroups={serviceGroups}
          queryState={queryState}
        />
      )}
      <UICustomizer
        style={"service-group-feedback"}
        // dataService={this.dataService}
        refs={extensionFieldRefs}
        data={[]}
        featureName={"ServiceGroupFeedbackExtension"}
      />
      {/*<ListViewToggle/>*/}
    </>
  );
};

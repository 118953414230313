import {
  useModalDialog,
  DataServiceContext,
  Button,
  StyledMenuItemDiv,
} from "athena-next-ui-lib";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { ReportContext } from "/components-biz";

export const DialogAlertReject = (props) => {
  const { openConfirmationDialog, closeModalDialog } = useModalDialog();
  const dataService = useContext(DataServiceContext);
  const alertContext = useContext(ReportContext);
  const { itypeData, navigateToList, loadIncidentTypeData } = alertContext;

  const update = () => {
    return Promise.resolve()
      .then(() => loadIncidentTypeData())
      .then(() => closeModalDialog());
    //.then(()=> navigateToList());
  };

  const processReject = (accepted) => {
    const itype_props = Object.keys(itypeData).filter(
      (p) => p.indexOf("itype") === 0
    );
    let values = {};
    itype_props.forEach((p) => (values[p] = itypeData[p]));
    values.itype_state = "rejected";

    return Promise.resolve()
      .then(() => dataService.fetch("incidenttype/create", values))
      .then((response) => {
        if (response.response.code === 200) {
          return update();
        } else {
          throw response.response;
        }
      })
      .catch((e) => {
        alert(`${e.code}: ${e.message}`);
      });
  };

  const confirmReject = () => {
    const ignoreButton = (
      <Button type="secondary" onClick={() => navigateToList()}>
        Ignore
      </Button>
    );
    openConfirmationDialog({
      title: `Are you sure you want to Reject this suggestion?`,
      icon: faExclamationTriangle,
      submit: () => processReject(),
      closeOnSubmit: false,
      submitLabel: "Reject",
      secondaryButtons: [ignoreButton],
      content: (
        <div>
          <p>
            Rejecting a suggestion will hide it in the Alerts list view. You can
            always find it in that view by filtering on the Alert Rule state
            'rejected'.
          </p>

          <p>
            If you are unsure if this is a good suggestion, you can just ignore
            it. It will still appear in the Alert list view but we won't report
            on this type of issue again.
          </p>
        </div>
      ),
    });
  };

  if (props.renderType === "menuItem") {
    return (
      <StyledMenuItemDiv onClick={() => confirmReject()}>
        {props.label}
      </StyledMenuItemDiv>
    );
  }

  return (
    <Button
      type="secondary"
      onClick={() => confirmReject()}
      cssStyle={{
        width: "200px",
        color: "rgb(242,140,140)",
        fontWeight: "bold",
        fontSize: "14px",
      }}
    >
      {props.label}
    </Button>
  );
};

import React, { useState, useEffect, useContext } from "react";
import styles from "./IncidentList.module.scss";
import { Utilities, PageContext } from "athena-next-ui-lib";

import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";

export const Timestamp = (props) => {
  const pageContext = useContext(PageContext);
  const { alert } = props;

  const [timestamp, setTimestamp] = useState(null);

  useEffect(() => {
    if (!pageContext?.timeZone) return;
    const convertedTS = Utilities.TZ.formatTS(
      alert.inci_ts,
      pageContext.timeZone,
      "MMM DD HH:mm:ss"
    );
    setTimestamp(<div className={styles.timestamp}>{convertedTS}</div>);
  }, [pageContext?.timeZone]);

  //return <div className={styles.timestamp}>{Moment( alert.inci_ts ).format("MMM DD HH:mm:ss")}</div>
  return timestamp;
};

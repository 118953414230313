import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  PageContext,
  DataServiceContext,
  TableCrud,
  Utilities,
} from "athena-next-ui-lib";
import styles from "../../pages/tags/tags-list.module.scss";
import { DialogTag } from "./DialogTag";

export const TagsTable = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);

  const [listData, setListData] = useState(null);

  const tagDialog = useRef();
  const tableRef = useRef();

  const canFullyEdit = dataServiceContext.AccessManifest(
    "canFullyEditFeature",
    "define-custom-alert-types"
  );

  useImperativeHandle(ref, () => ({
    refreshList,
  }));

  const refreshList = ()=>{
    tableRef?.current?.loadTableData();
  }

  const loadData = ( sortCol ) => {
    //default to -ts for sorting on internal column
    const sortColFld = sortCol ? sortCol.replace("-", "") : "-ts";
    const sortColObj = columnDefs.find((col) => col.fldName === sortColFld);
    const sortOn = sortColObj.internalSort ? "-ts" : sortCol;

    return Promise.resolve()
      .then(() => setListData(null))
      .then(() => {
        return dataServiceContext.fetch("tag/read", {
          sort: ["tag_type", sortOn],
          filter: ["tag_type=manual"],
          args: { load_rules: true },
        });
      })
      .then((tagsCall) => {
        if (tagsCall.response.code === 200) {
          const tagDefs = tagsCall.data || [];

          setListData([...tagDefs]);
          return tagDefs;
        } else {
          throw tagsCall.response;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const isTagDeletable = (group) => {
    return canFullyEdit;
  };

  const isTagEditable = (group) => {
    return canFullyEdit;
  };

  const getDeleteConfirmObj = (obj) => {
    return {
      title: "Delete Tag",
      desc: (
        <span>
          Are you sure you want to delete this tag: <b>{obj.tag_name}</b>?
        </span>
      ),
    };
  };

  const editTagAction = (tag) => {
    tagDialog.current.showDialog(tag);
  };

  const deleteTagHandler = (tag) => {
    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch("tag/delete", { tag_id: tag.tag_id })
      )
      .then((response) => {
        try {
          if (response.response.code === 200) return tableRef?.current?.loadTableData();
        } catch (err) {
          console.log("Err:" + err.message);
        }

        return response;
      });
  };

  const displayTagName = (row) => {
    return (
      <>
        <div className={styles.tagTitle}>
          <div className={styles.tagName}>{row.tag_name}</div>
        </div>
      </>
    );
  };

  const displayModifiedInfo = (row) => {
    return (
      <>
        <div>
          <b>{Utilities.TZ.timeAgo(row.ts, pageContext.timeZone)}</b>
        </div>
        <div style={{ opacity: 0.75 }}>by&nbsp;{row.modify_user_name}</div>
      </>
    );
  };
  const columnDefs = [
    { label: "Name", renderer: displayTagName, width: "70%", fldName: "tag_name", internalSort: true},
    { label: "Modified", renderer: displayModifiedInfo, width: "30%", fldName: "ts", sort: "desc" },
  ];

  const rowActions = canFullyEdit
    ? {
        edit: {
          isAllowed: isTagEditable,
          handler: editTagAction,
        },
        delete: {
          isAllowed: isTagDeletable,
          handler: deleteTagHandler,
          confirm: getDeleteConfirmObj,
        },
      }
    : {};

  return (
    <>
      <TableCrud
        ref={tableRef}
        loadData={loadData}
        listData={listData}
        columnDefs={columnDefs}
        rowActions={rowActions}
        maxHeight={"calc(100vh - 100px)"}
      />
      <DialogTag
        action={"EDIT"}
        ref={tagDialog}
        updateCallback={tableRef?.current?.loadTableData}
      ></DialogTag>
    </>
  );
});

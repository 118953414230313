import React, { useMemo, useContext } from "react";
import {
  IncidentListContext,
  TimelineContext,
} from "/components-biz/incidents";

import styles from "./IncidentTimeline.module.scss";

export const IncidentBuckets = () => {
  const ac = useContext(IncidentListContext);
  const tc = useContext(TimelineContext);
  const { totalH } = tc;

  const buckets = useMemo(() => {
    const { selectedBuckets, mouse, bucketWidth } = tc;
    const { alertBuckets } = ac;
    return alertBuckets.map((bucket, x) => {
      let css = styles.bucket,
        leftDragHandle,
        rightDragHandle;
      const key = `${bucket.id}${x}`;

      if (x >= selectedBuckets.start && x <= selectedBuckets.end) {
        css = `${styles.bucket} ${styles.selected}`;
      }

      if (mouse.isDragging && x === selectedBuckets.start) {
        leftDragHandle = (
          <path
            className={styles.dragHandle}
            d={`M${0} ${0} l 0 ${
              totalH * 0.5
            } l -5 0 l 0 -3 l -4 3 l 4 3 l 0 -3 l 5 0 l 0 ${totalH * 0.5}`}
          />
        );
      }

      if (mouse.isDragging && x === selectedBuckets.end) {
        rightDragHandle = (
          <path
            className={styles.dragHandle}
            strokeWidth={"1px"}
            d={`M${bucketWidth} ${0} l 0 ${
              totalH * 0.5
            } l 5 0 l 0 -3 l 4 3 l -4 3 l 0 -3 l -5 0 l 0 ${totalH * 0.5}`}
          />
        );
      }

      return (
        <g key={key} transform={`translate(${tc.bucketWidth * x} ${0})`}>
          <rect
            className={css}
            x={1}
            y={0}
            width={tc.bucketWidth - 2}
            height={tc.totalH}
          />

          <rect
            className={styles.histogramBucket}
            x={1}
            y={tc.graphH}
            width={tc.bucketWidth - 2}
            height={tc.totalH}
          />
          {leftDragHandle}
          {rightDragHandle}
        </g>
      );
    });
  }, [
    ac.alertBuckets,
    tc.selectedBuckets,
    tc.numBuckets,
    tc.totalW,
    tc.totalH,
  ]);

  return buckets;
};

import React, { useState, useMemo, useContext, useRef } from "react";
import styled from "styled-components";
import {
  Utilities,
  Button,
  DataServiceContext,
  useModalDialog,
} from "athena-next-ui-lib";
import { Event, ListModes } from "/components-ui";
import EventStyles from "/components-ui/list/events/EventList.module.scss";
import {
  ReportContext as ReportContext,
  DialogAlertRuleMetadataEdit,
  DialogAlertKeysTest,
} from "/components-biz/report";
import {
  faExclamationTriangle,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IncidentTypeUtils from "../../_utilities/incidentTypeUtils";

const TrayDiv = styled("div")`
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.25);
  height: 135px;
  width: ${(props) => props.$width};
  overflow: auto;
`;

const HeaderDiv = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #62812a;
  color: #fff;

  h3 {
    margin: 0;
  }
  span {
    margin: 2px 8px;
  }

  .right {
    margin-left: auto;
  }
`;

const SubheaderDiv = styled(HeaderDiv)`
  background: #3f541b;

  .keycount {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.75);
    padding: 3px 0 3px 8px;
  }

  .keycount.invalid {
    color: #d55c5c;
  }
  .keycount.valid {
    color: #a3c961;
  }
`;

export const AlertKeyTray = (props) => {
  const alertContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();
  const { listMode, alertKeys, exitEditKeysMode } = alertContext;

  const [processingSaveKeys, setProcessingSaveKeys] = useState(false);

  const { width } = props;
  const keyCount = alertKeys?.length;

  // useEffect(() => {
  //   //on first open of the tray, we ask to compose the array of alert keys
  //   //we maintain this array on the ac context until the tray is closed/destroyed
  //   ac.initAlertKeys();

  //   return () => {
  //     //tray is being destroyed, so clear the alert keys
  //     ac.clearAlertKeys();
  //   };
  // }, [listMode]);

  const listOfAlertKeys = useMemo(() => {
    let list = [];
    if (alertKeys) {
      list = alertKeys.map((e) => (
        <Event
          alertKeyEvent={true}
          wrapEvents={props.wrapEvents}
          prefix={"-alert-key"}
          {...e}
          key={`event-key-${e.ievt_id}`}
        />
      ));
    }
    let eventListCSS = props.groupByDay
      ? `${EventStyles.eventList}`
      : `${EventStyles.eventList} ${EventStyles.notGroupedByDay}`;
    eventListCSS =
      props.wrapEvents === false
        ? `${eventListCSS} ${EventStyles.nowrap}`
        : `${eventListCSS} ${EventStyles.wrap}`;

    return <div className={eventListCSS}>{list}</div>;
  }, [alertKeys]);

  const header = useMemo(() => {
    return (
      <HeaderDiv>
        <span>
          <h3>Define Alert Rule Keys</h3>
        </span>
        <span>
          <i>Select 1 or 2 events as keys</i>
        </span>
        <span className={"right"}>
          <Button
            type={"primary"}
            disabled={keyCount < 1 || keyCount > 2}
            size={"lg"}
            processing={processingSaveKeys}
            onClick={() => saveAlertKeys()}
          >
            Save
          </Button>
        </span>
        <span>
          <Button
            type={"secondary"}
            size={"jumbo"}
            onClick={() => exitEditKeysMode()}
          >
            Cancel
          </Button>
        </span>
      </HeaderDiv>
    );
  }, [alertKeys, processingSaveKeys]);

  const subheader = useMemo(() => {
    const keyCount = alertKeys?.length;
    const keyCountState = keyCount >= 1 && keyCount <= 2 ? "valid" : "invalid";
    const keyCountIcon =
      keyCount >= 1 && keyCount <= 2 ? faCheck : faExclamationTriangle;

    let keyCountLabel = keyCount;

    if (keyCount === 0) {
      keyCountLabel = "No";
    } else if (keyCount > 2) {
      keyCountLabel = "Too Many";
    }

    return (
      <SubheaderDiv>
        <span className={`keycount ${keyCountState}`}>
          <FontAwesomeIcon icon={keyCountIcon} />

          <span>
            {keyCountLabel}
            &nbsp;{Utilities.Strings.pluralize("Alert Key", keyCount)}
            &nbsp;Selected
          </span>
        </span>

        {/* <span>
          <Button type="secondary" onClick={ac.restoreOriginalAlertKeys}>Reset</Button>
        </span> */}

        <span>
          <DialogAlertKeysTest keyCount={keyCount} />
        </span>
      </SubheaderDiv>
    );
  }, [alertKeys]);

  const dialogRef = useRef();

  const saveAlertKeys = () => {
    const keys = IncidentTypeUtils.extractEventKeys(alertContext.alertKeys);
    const payload = {
      itype_keys: keys,
      itype_id: alertContext.itypeData.itype_id,
      itype_orig_inci_id: alertContext.itypeData.inci_id,
    };

    setProcessingSaveKeys(true);
    return Promise.resolve()
      .then(() => dataService.fetch("incidenttype/create", payload))
      .then((itypeSaveCall) => {
        if (itypeSaveCall.response.code === 200) {
          openModalDialog({
            type: "warn",
            title: "Alert Rule Saved",
            content: "Your changes can be seen in the Alert list view.",
            submitLabel: "View Alert List",
            submit: alertContext.navigateToList,
            hideCloseIcon: true,
          });

          return itypeSaveCall;
        } else {
          throw response.response;
        }
      })
      .catch((e) => {
        alert(`${e.code}: ${e.message}`);
      })
      .finally(() => setProcessingSaveKeys(false));
  };

  if (listMode === ListModes.edit) {
    return (
      <>
        {header}
        {subheader}
        <TrayDiv $width={width}>{listOfAlertKeys}</TrayDiv>
        <DialogAlertRuleMetadataEdit
          ref={dialogRef}
          listMode={ListModes.edit}
          response={{ timestamp: new Date() }}
          visible={false}
        />
      </>
    );
  }

  return null;
};

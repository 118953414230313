import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Panel,
  Toolbar,
  Content,
  DataServiceContext,
} from "athena-next-ui-lib";
import { ReportContext, DialogAlertRuleMetadataEdit } from "/components-biz";
import { AIConfidence, ListModes } from "/components-ui";

import {
  faChevronDown as expandedStateIcon,
  faChevronRight as collapsedStateIcon,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PANEL_SECTION_TYPES, PanelSection } from "./PanelSection";

const SCROLLBAR_WIDTH = 10;

export const PanelInsights = (props) => {
  const alertContext = useContext(ReportContext);
  const dataService = useContext(DataServiceContext);
  const { itypeData, itypeDataResponse, listMode } = alertContext;
  const { width } = props;
  const [isExpanded, setIsExpanded] = useState(true);

  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [detail, setDetail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (itypeData) {
      setCurrentInsightsData();
    }
  }, [itypeData]);

  const setCurrentInsightsData = () => {
    const {
      itype_title,
      itype_summary,
      itype_detail,
      itype_nlp_title,
      itype_nlp_summary,
      itype_nlp_detail,
    } = itypeData;

    let _title;
    if (itype_title) _title = itype_title;
    else if (itype_nlp_title) _title = itype_nlp_title;

    let _summary;
    if (itype_summary) _summary = itype_summary;
    else if (itype_nlp_summary) _summary = itype_nlp_summary;

    let _detail;
    if (itype_detail) _detail = itype_detail;
    else if (itype_nlp_detail) _detail = itype_nlp_detail;

    setTitle(_title);
    setSummary(_summary);
    setDetail(_detail);
  };

  const updateItype = () => {
    console.log("useCallback", title);
    const payload = {
      itype_id: itypeData.itype_id,
      itype_title: title,
      itype_summary: summary,
      itype_detail: detail,
    };

    return Promise.resolve()
      .then(() => setIsSaving(true))
      .then(() => dataService.fetch("incidenttype/create", payload))
      .then((itypeCreateCall) => {
        if (itypeCreateCall.response.code !== 200) {
          throw itypeCreateCall.response;
        } else {
          return;
        }
      })

      .then(() => alertContext.loadIncidentTypeData())
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setIsSaving(false);
        setIsEditing(false);
      });
  };

  const toolbarItems = useMemo(() => {
    const canEdit =
      listMode !== ListModes.edit &&
      (itypeData?.itype_state === "accepted" ||
        itypeData?.itype_state === "custom");

    if (!canEdit) return null;

    return (
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
        }}
      >
        <DialogAlertRuleMetadataEdit
          type={"edit-note"}
          data={itypeData}
          label="Edit"
        />
      </span>
    );
  }, [itypeData, updateItype, isSaving]);

  if (!itypeData) return null;

  if (!title && !summary && !detail) {
    //if we don't have any info, hide the insights panel
    return null;
  }

  const { itype_nlp_populated: hasInsights, itype_nlp_judgment: confidence } =
    itypeData;

  const pTitle = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 3,
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: "#ffd500",
          background: "rgba(0,0,0,.5)",
          padding: "2px 4px",
          borderRadius: "2px",
        }}
      >
        Insights
      </span>
      <AIConfidence confidence={confidence} />
    </div>
  );

  const panelTitle = isExpanded ? (
    <span
      onClick={() => setIsExpanded(false)}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon
        icon={expandedStateIcon}
        style={{ marginRight: "4px" }}
      />
      {pTitle}
    </span>
  ) : (
    <span
      onClick={() => setIsExpanded(true)}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon
        icon={collapsedStateIcon}
        style={{ marginRight: "4px" }}
      />
      {pTitle}
    </span>
  );

  const content = !isExpanded ? null : (
    <div>
      <Content
        {...props}
        response={itypeDataResponse}
        width={props.width - SCROLLBAR_WIDTH}
        height="auto"
        maxHeight={"80vh"}
      >
        <PanelSection
          type={PANEL_SECTION_TYPES.COLLAPSING}
          onChange={(value) => setSummary(value)}
          title="Summary"
          value={summary}
          expanded={true}
          isEditing={isEditing}
        />
        <PanelSection
          type={PANEL_SECTION_TYPES.COLLAPSING}
          onChange={(value) => setDetail(value)}
          title="Details"
          value={detail}
          expanded={false}
          isEditing={isEditing}
        />
      </Content>
    </div>
  );

  return (
    <Panel width={width} flex={"1 0 auto"}>
      <Toolbar {...props} response={itypeDataResponse} title={panelTitle}>
        {toolbarItems}
      </Toolbar>
      {content}
    </Panel>
  );
};
PanelInsights.defaultProps = {
  graphData: { occurrences: { response: { code: 0 } } },
  response: { code: 0 },
  data: [],
  marginX: 10,
  marginY: 0,
  width: 200,
  height: 0,
};

import React, { useContext } from "react";
import { IncidentListContext } from "/components-biz/incidents";
import { tooltipStyles } from "athena-next-ui-lib";
import "/components-biz/incidents/timeline/IncidentTimeline.module.scss";
import commonStyles from "/components-biz/common/common.module.scss";
import { TAG_TYPE_LABELS } from "/components-ui/list/events/EventListConstants";

export const Tags = (props) => {
  const ac = useContext(IncidentListContext);
  const { alert } = props;

  const navigateToReportWithTag = (evt, tagObj) => {
    if (!ac?.navigateToReport) return;
    if (!["routing", "evt_int"].includes(tagObj.tag_type)) return;

    evt.stopPropagation();
    evt.preventDefault();
    ac.navigateToReport(alert.itype_id, alert.inci_id, null, tagObj);
  };

  const allTags = (alert?.inci_tags || []).concat(alert?.itype_tags || []);

  if (allTags?.length > 0) {
    return allTags.map((tag) => {
      return (
        <span
          key={tag.tag_id}
          onClick={(evt) => navigateToReportWithTag(evt, tag)}
          className={`${commonStyles.alertRuleTag} ${
            commonStyles[tag.tag_type]
          } ${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipSouth}`}
        >
          {tag.tag_name}
          <div className={tooltipStyles.cssTooltipText}>
            {TAG_TYPE_LABELS[tag.tag_type]}
          </div>
        </span>
      );
    });
  } else {
    return null;
  }
};

import React, { useRef, useContext } from "react";
import {
  Column,
  Form,
  Row,
  Field,
  DataServiceContext,
  Button,
  StyledMenuItemDiv,
  useModalDialog,
  PageContext,
} from "athena-next-ui-lib";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FieldOutboundIntegrationSelector } from "../fields";

export const DialogOneTimeAlertSend = (props) => {
  const dataService = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();
  const pageContext = useContext(PageContext);

  const oneTimeAlertForm = useRef();
  const outboundChannelsRef = useRef();

  const fieldProps = {
    fieldWidth: "200px",
    labelWidth: "135px",
    labelAlign: "right",
  };

  const sendOneTimeAlert = () => {
    const values = oneTimeAlertForm.current.getValues();
    let payload = {
      inci_id: props?.data?.inci_id,
      itype_id: props?.data?.itype_id,
    };

    //convert comma separated string value into string array
    payload.itype_outbound_integration_ids = (
      values.itype_outbound_integration_ids || ""
    ).split(",");

    return Promise.resolve()
      .then(() => dataService.fetch("incident/read/sendnotification", payload))
      .then((response) => {
        if (response.response.code == 200) {
          //show success dialog
          openModalDialog({
            type: "warn",
            title: "Alert Sent",
            icon: faCheckCircle,
            content: "One-time alert sent.",
          });
          return response;
        } else {
          throw response.response;
        }
      });
  };

  const dialogContent = (
    <Form ref={oneTimeAlertForm} refs={[outboundChannelsRef]}>
      <Row>
        <Field
          type={"description"}
          value={"Which outbound channels would you like to immediately alert?"}
        />
      </Row>

      <Row>
        <Column>
          <FieldOutboundIntegrationSelector
            {...fieldProps}
            label={""}
            fieldRef={outboundChannelsRef}
            selectedOutboundChannels={
              props?.data?.itype_outbound_integration_ids
            }
          />
        </Column>
      </Row>
    </Form>
  );

  const showDialog = () => {
    openModalDialog({
      title: "Send a One-time Alert",
      icon: faExclamationTriangle,
      submitLabel: "Send",
      submit: sendOneTimeAlert,
      closeOnSubmit: false,
      content: dialogContent,
    });
  };

  if (props.renderType === "menuItem") {
    return (
      <StyledMenuItemDiv onClick={() => showDialog()}>
        {props.label}
      </StyledMenuItemDiv>
    );
  }

  return (
    <Button
      disabled={!pageContext.isReady}
      type={"secondary"}
      onClick={() => showDialog()}
    >
      {/*<FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;*/}
      Alert Now
    </Button>
  );
};

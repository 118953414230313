import React, { useState, useEffect, useContext } from "react";
import { DataServiceContext, Field, FieldTypes } from "athena-next-ui-lib";

export function FieldManualTagSelector(props) {
  const dataServiceContext = useContext(DataServiceContext);
  const { fieldRef, selectedManualTagIDs, label } = props; //selectedManualTagIDs is a string with comma separated ids

  const [selectedManualTags, setSelectedManualTags] = useState(null);
  const [manualTags, setManualTags] = useState([]);

  const [response, setResponse] = useState({ code: 0 });
  const fieldWidth = "200px";

  const defaultContentProps = {
    type: FieldTypes.MULTI_SELECT,
    label: label,
    ref: fieldRef,
    fieldWidth: fieldWidth,
    placeholder: "Select...",
    value: selectedManualTags || [],
    values: manualTags || [],
    response: response,
    name: "itype_tag_ids",
    ...props,
  };

  useEffect(() => {
    loadManualTags();
  }, []);

  useEffect(() => {
    if (selectedManualTagIDs?.length >= 0 && manualTags) {
      //comma separated string
      const selTagsIdArr = selectedManualTagIDs.split(",");
      const selTagsObjArr = manualTags.filter(
        (tag) => selTagsIdArr.indexOf(tag.value) > -1
      );
      setSelectedManualTags([...selTagsObjArr]);
    }
  }, [selectedManualTagIDs]);

  const loadManualTags = () => {
    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch("tag/read", {
          sort: ["tag_name"],
          args: { load_rules: false },
          filter: [`tag_type=manual`],
        })
      )
      .then((getManualTags) => {
        if (getManualTags.response.code === 200) {
          const dataOut = (getManualTags || {}).data || [];
          const derivedTags = (dataOut || []).map((itm) => {
            return { label: itm.tag_name, value: itm.tag_id };
          });

          setManualTags([...derivedTags]);
          setResponse({ ...getManualTags?.response });

          if (selectedManualTagIDs?.length >= 0 && derivedTags) {
            //comma separated string
            const selTagsIdArr = selectedManualTagIDs.split(",");
            const selTagsObjArr = derivedTags.filter(
              (tag) => selTagsIdArr.indexOf(tag.value) > -1
            );
            setSelectedManualTags([...selTagsObjArr]);
          }
        } else {
          throw getManualTags.response;
        }
        return;
      });
  };

  return (
    <>
      <Field
        {...defaultContentProps}
        value={selectedManualTags || []}
        values={manualTags}
        truncateDisplayBeyond={20}
        response={response}
      />
    </>
  );
}

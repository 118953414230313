import React, { useMemo, useContext } from "react";
import { TimelineContext } from "/components-biz/incidents";
import styles from "./IncidentTimeline.module.scss";

export const Xaxis = () => {
  const tc = useContext(TimelineContext);

  const xAxis = useMemo(() => {
    const {
      numBuckets,
      tickH,
      bucketWidth,
      selectedBuckets,
      insetW,
      xAxisH,
    } = tc;
    const xAxisLinePoints = [];
    Array.from({ length: numBuckets }, (i) => i).forEach((i, x) => {
      let midpointY = 0;

      const dX = 6,
        x0 = 0,
        x1 = bucketWidth * 0.5 - dX,
        x2 = dX,
        x3 = dX,
        x4 = bucketWidth - (x0 + x1 + x2 + x3);
      if (x === selectedBuckets.start && x === selectedBuckets.end) {
        midpointY = xAxisH * 0.2;
      }
      xAxisLinePoints.push(
        `l ${0} ${0} 
                     l 0 -${tickH} 
                     l 0 ${tickH} 
                     l ${x1} 0
                     l ${x2} ${midpointY}
                     l ${x3} ${-midpointY}
                     l ${x4} 0 
                     l 0 -${tickH} 
                     l 0 ${tickH}`
      );
    });

    const xAxisLine = `M 0 0 l ${insetW} 0 ${xAxisLinePoints.join(
      " "
    )} l ${insetW} ${0}`;

    return <path className={styles.xAxisLine} d={xAxisLine} />;
  }, [tc.numBuckets, tc.selectedBuckets]);

  return xAxis;
};
